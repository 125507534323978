import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) {
   }
   getProfile(Email) {
    debugger;
    return this.http.get(`${environment.apiUrl}/api/CrmUser/Get?email=${Email}`).pipe(
      //  retry(1), 
      catchError(this.handleError)
    );
}
updateProfile(profile) {
  
  return this.http.post(`${environment.apiUrl}/api/CrmUser/Update`,profile).pipe(
    //  retry(1), 
    catchError(this.handleError)
  );
}

UploadPhoto(formData) {
  
  return this.http.post(`${environment.apiUrl}/api/CrmUser/UpdateImage`,formData).pipe(
    //  retry(1), 
    catchError(this.handleError)
  );
}
// consent code//

getProceduredetail(proceduredata) {
  
  return this.http.get(`${environment.apiUrl}/api/Consent/Procedure/GetProcedureDetail?procedureId=${proceduredata}`).pipe(
  // retry(1),
  catchError(this.handleError)
  );
  }
   //#region 

   getContactGP(){
    return this.http.get(`${environment.apiUrl}/api/CrmUser/GetContactForGP`).pipe(
      // retry(1),
      catchError(this.handleError)
      );
   }
   getRelationship(){
    return this.http.get(`${environment.apiUrl}/api/CrmUser/GetRelationShipType`).pipe(
      // retry(1),
      catchError(this.handleError)
      );
   } 
// getAccount(){
//   return this.http.get(`${environment.apiUrl}/api/CrmUser/GetRelationShipKins`).pipe(
//     // retry(1),
//     catchError(this.handleError)
//     );
// }
 //#endregion
  getconsentquestion(procedureId) {
    
    return this.http.get(`${environment.apiUrl}/api/Consent/Procedure/GetProcedureQuestion?procedureId=${procedureId}`).pipe(
    // retry(1),
    catchError(this.handleError)
    );
    }  
      saveproceduredata(proceduredata){
      
      return this.http.post(`${environment.apiUrl}/api/Consent/Procedure/updateProcedure`,proceduredata).pipe(
      // retry(1),
      catchError(this.handleError)
      );
      }
      //proxy add//

      AddProxy(proxydata) {debugger
        return this.http.post(`${environment.apiUrl}/api/CrmUser/createPatientInCRM`,proxydata).pipe(
        // retry(1),
        catchError(this.handleError)
        );
        }

handleError(error) {
  
  //*************Show / hide loader *******************/
  $('#ftco-loader').removeClass('show');
  /**************************************** */
  let errorMessage = '';
  let errorMessageUi='';
  if (error.error instanceof ErrorEvent) {
    // client-side error 
    errorMessage = `Error: ${error.error.message}`;  
    errorMessageUi=error.error.message;
  } else {
    // server-side error 
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    if(error.error!=undefined){
      errorMessageUi=error.error.message
    }
    else{
      errorMessageUi=error.message
    }
  }
  //  // Show error on hte Ui and Deirect to home
  //  this.toastr.error("Error", errorMessageUi);
  //  this.router.navigate(['/']);

  return throwError(errorMessageUi);
}

}
