<div class="main-wrapper">
  <div class="page-wrapper">
    <div class="page-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row heading">
                <div class="col">
                  <h5> Observations <span style="color: gray;"></span></h5>
                </div>

                <div class="col"> &nbsp; <button type="button" class="btn btn-primary float-right ml-1"
                  (click)="RefreshPage()"><i class="fa fa-refresh ref"
                      aria-hidden="true"></i></button>
                      &nbsp; <button type="submit" href="#CreateObservationModel" data-toggle="modal"
                    class="btn btn-primary float-right mr-2 mb-2 mb-md-0">+ Add Observation</button></div>
              </div>
              <hr>
              <div class="table-responsive">
                <table class="table" id="dataTableExample">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Reading Categories</th>
                      <th>Values </th>
                      <th>Comments </th>
                      <th>Created On</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of ObservationsList; index as i">
                      <td>{{data.msemr_description}}</td>
                      <td>{{data.bcrm_readingcategories_name}}</td>
                      <td>
                        <p *ngIf="data.bcrm_readingcategories_name=='Weight'">{{data.bcrm_weightinkilograms}} KG</p>
                        <p *ngIf="data.bcrm_readingcategories_name=='Height'">{{data.bcrm_heightinmetres}} Meters</p>
                        <p *ngIf="data.bcrm_readingcategories_name=='Blood Pressure'">
                         {{data.msemr_valuerangelowlimit}}/ {{data.msemr_valuerangehighlimit}}</p>
                        <p *ngIf="data.bcrm_readingcategories_name=='Diabetes'">
                          {{data.bcrm_bloodsugarbeforemeals}}/{{data.bcrm_bloodsugaraftermeal}}</p>
                          <p *ngIf="data.bcrm_readingcategories_name=='Heart Rate'">{{data.bcrm_heartrate}}</p>
                          <p *ngIf="data.bcrm_readingcategories_name=='Temperature'">{{data.bcrm_temperature}}</p>
                          <p *ngIf="data.bcrm_readingcategories_name=='Oxygen Saturation (SpO2)'">{{data.bcrm_oxygensaturation}}</p>
                          <p *ngIf="data.bcrm_readingcategories_name=='Respiratory Rate (rr)'">{{data.bcrm_respiratoryrate}}</p>
                          <p *ngIf="data.bcrm_readingcategories_name=='BMI'">{{data.bcrm_bmicalculation}}</p>
                          <p *ngIf="data.bcrm_readingcategories_name=='Peak Flow'">{{data.bcrm_peakflow}}</p>
                      </td>
                      <td>{{data.msemr_comment}}</td>
                      <td>{{data.createdOn | date:'dd-MMM-yyyy'}}</td>
                    </tr>
                  </tbody>
                </table>

                <table class="table" id="">
                  <thead>
                    <tr>
                      <th>Observation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="form-group row">
                          <label class="col-md-3  col-sm-3 ">ReadingCategories</label>
                          <div class="col-sm-9">
                            <select class="form-control" [value]="" (change)="DisplayChart($event.target.value)"
                            >
                              <option value="" selected disabled>Select Reading Categories</option>
                              <option value="101">Blood Pressure</option>
                              <option value="102">Diabetes</option>
                              <option value="107">Heart Rate</option>
                              <option value="108">Oxygen Saturation(spo2)</option>
                              <option value="109">Respiratory Rate (rr)</option>
                              <option value="110">Peak Flow</option>
                              <option value="111">Temperature</option>
                            </select>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                
              </div>

              <div id="CreateObservationModel" class="modal fade">
                <!-- class modal and fade -->
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <!-- modal header -->
                      Create Observation Request
                      <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clearObservation()">
                        ×
                      </button>
                    </div>
                    <div class="modal-body">
                      <!-- modal body -->
                      <form class="form" #it="ngForm" (ngSubmit)="onSubmit(it)" #f="ngForm" autocomplete="off"
                        id="CreateObservation">

                        <div class="form-group row">
                          <label
                            class="col-md-3  col-sm-3 required">{{'NewObservatiom.ReadingCategories'|translate}}</label>
                          <div class="col-sm-9">
                            <select class="form-control " id='purpose' name="bcrm_readingcategories" ngModel required
                              (change)="onCategory($event.target.value)" #bcrm_readingcategories="ngModel"
                              #name="ngModel">
                              <option value="">Select Reading Categories</option>
                              <option value="101">Blood Pressure</option>
                              <option value="102">Diabetes</option>
                              <option value="103">Height</option>
                              <option value="104">Weight</option>
                              <option value="107">Heart Rate</option>
                              <option value="106">BMI</option>
                              <option value="108">Oxygen Saturation (SpO2)</option>
                              <option value="109">Respiratory Rate(rr)</option>
                              <option value="111">Temperature</option>
                              <option value="105">Other</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group row" id="msemr_description">
                          <label class="control-label col-sm-3">{{'Common.description'|translate}}</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control1" id="msemr_description1"
                              placeholder="{{'Common.description'|translate}}" name="msemr_description" ngModel required
                              #msemr_description="ngModel" readonly>
                            <input type="hidden" name="browser" id="browser1">
                            <input type="hidden" name="bcrm_snomedname" id="snomedname">
                            <input type="hidden" id="bcrm_conceptid">
                          </div>
                        </div>
                        
                        <div class="form-group row" id="Snomed">
                          <label class="control-label col-sm-3">{{'NewObservatiom.Snomed'|translate}}</label>
                          <div class="col-sm-9">
                            <input list="browsers" name="browser" class="form-control getSnomed">
                            <datalist id="browsers">
                              <option [value]="title.pt.term" *ngFor="let title of QueryResult" class="form-control">
                                {{title.pt.term}}</option>
                              <input type="hidden" [value]="title.conceptId" id="getCode"
                                *ngFor="let title of QueryResult">
                              <input type="hidden" name="name" class="form-control" ngModel required #name="ngModel"
                                id="snomedname">
                            </datalist>
                          </div>
                        </div>


                        <div class="form-group row" id="SnomedCode">
                          <label class="control-label col-sm-3">{{'Condition.code'|translate}}</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control1" placeholder="{{'Condition.code'|translate}}"
                              name="msemr_code" ngModel required #msemr_code="ngModel" id="msemr_code" disabled>
                          </div>
                        </div>

                        <div class="form-group row" id="msemr_valuerangelowlimit">
                          <label
                            class="control-label col-md-3 col-sm-3 required">{{'NewObservatiom.DiastolicPressure'|translate}}</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Enter Low Value"
                              name="msemr_valuerangelowlimit" id="DiastolicPressure" ngModel required #msemr_valuerangelowlimit="ngModel">
                          </div>
                        </div>
                        <div class="form-group row" id="msemr_valuerangehighlimit">
                          <label
                            class="control-label col-md-3 col-sm-3 required">{{'NewObservatiom.SystolicPressure'|translate}}</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Enter High Value"
                              name="msemr_valuerangehighlimit" id="SystolicPressure" ngModel required #msemr_valuerangehighlimit="ngModel">
                          </div>
                        </div>
                        <div class="form-group row" id="bcrm_bloodsugarbeforemeals">
                          <label
                            class="control-label col-md-3 col-sm-3 required">{{'NewObservatiom.BeforeMeals'|translate}}</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" id="BeforeMeals"
                              placeholder="{{'NewObservatiom.BeforeMeals'|translate}}" name="bcrm_bloodsugarbeforemeals"
                              ngModel required #bcrm_bloodsugarbeforemeals="ngModel">
                          </div>
                        </div>
                        <div class="form-group row" id="bcrm_bloodsugaraftermeal">
                          <label
                            class="control-label col-md-3 col-sm-3 required">{{'NewObservatiom.AfterMeals'|translate}}</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" id="AfterMeals"
                              placeholder="{{'NewObservatiom.AfterMeals'|translate}}" name="bcrm_bloodsugaraftermeal"
                              ngModel required #bcrm_bloodsugaraftermeal="ngModel">
                          </div>
                        </div>
                        
                        <!-- Heart Rate Field -->
                        <div class="form-group row" id="bcrm_heartrate">
                          <label
                            class="control-label col-md-3 col-sm-3 required">Heart Rate</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" id="HeartRate"
                              placeholder="Enter Heart Rate in bpm(Beats per minute)" name="bcrm_heartrate"
                              ngModel required #bcrm_heartrate="ngModel">
                          </div>
                        </div>
                        <!-- Heart Rate Field -->

                        <!-- Oxygen(SPo2) Field -->
                        <div class="form-group row" id="bcrm_oxygensaturation">
                          <label
                            class="control-label col-md-3 col-sm-3 required">Oxygen Saturation(SpO2): </label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" id="oxygensaturation"
                              placeholder="Enter Osygen Saturation (SpO2) in %" name="bcrm_oxygensaturation"
                              ngModel required #bcrm_oxygensaturation="ngModel">
                          </div>
                        </div>
                        <!-- Oxygen(SPo2) Rate Field -->

                        <!--  Respiratory Rate Field -->
                        <div class="form-group row" id="bcrm_respiratoryrate">
                          <label
                            class="control-label col-md-3 col-sm-3 required">Respiratory Rate(RR): </label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" id="respiratoryrate"
                              placeholder="Enter Respiratory Rate (RR) in bpm breaths per minute" name="bcrm_respiratoryrate"
                              ngModel required #bcrm_respiratoryrate="ngModel">
                          </div>
                        </div>
                        <!-- Respiratory Rate Field -->

                        <!--  Temprature Field -->
                        <div class="form-group row" id="bcrm_temperature">
                          <label
                            class="control-label col-md-3 col-sm-3 required">Temprature: </label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" id="temperature"
                              placeholder="Enter Temperature in (Degrees)" name="bcrm_temperature"
                              ngModel required #bcrm_temperature="ngModel">
                          </div>
                        </div>
                        <!-- Temprature Field -->

                        <!--  BMI Field -->
                        <div id="bcrm_bmicalculation">
                        <div class="form-group row">
                          <label
                            class="control-label col-md-3 col-sm-3 required">{{'NewObservatiom.Weight'|translate}}</label>
                          <div class="col-sm-2">
                            <input type="number" class="form-control" placeholder="kg" id="bcrm_weight_kg_BMI"
                              name="bcrm_bmicalculation" ngModel required #bcrm_bmicalculation="ngModel"
                              (keyup)="weightConverter('bcrm_weight_kg_BMI')">
                            <small id="bcrm_height_MetersText"
                              class="form-text text-muted required">{{'NewObservatiom.WeightinKg'|translate}}</small>
                          </div>

                          <div class="col-sm-2">
                            <input type="number" class="form-control" placeholder="Gram" id="bcrm_weight_gram_BMI"
                              (keyup)="weightConverter('bcrm_weight_gram_BMI')">
                            <small id="bcrm_height_MetersText"
                              class="form-text text-muted ">{{'NewObservatiom.Weightingram'|translate}}</small>
                          </div>

                          <div class="col-sm-2">
                            <input type="number" class="form-control" placeholder="Pound" id="bcrm_weight_pound_BMI"
                              (keyup)="weightConverter('bcrm_weight_pound_BMI')">
                            <small id="bcrm_height_MetersText"
                              class="form-text text-muted">{{'NewObservatiom.Weightinpound'|translate}}</small>
                          </div>
                        </div>

                        <div class="form-group row" >
                          <label class="control-label col-md-3 col-sm-3">{{'NewObservatiom.Height'|translate}}</label>
                          <div class="col-sm-2">
                            <input type="number" class="form-control" placeholder="Meter" id="bcrm_height_meter_BMI"
                              (keyup)="LengthConverter('bcrm_height_meter_BMI')" name="bcrm_bmicalculation" ngModel required
                              #bcrm_bmicalculation="ngModel">
                            <small id="bcrm_height_MetersText"
                              class="form-text text-muted required">{{'NewObservatiom.HeightinMeter'|translate}}</small>
                          </div>

                          <div class="col-sm-2">
                            <input type="number" class="form-control" placeholder="Feet" id="bcrm_height_feet_BMI"
                              (keyup)="LengthConverter('bcrm_height_feet_BMI')">
                            <small id="bcrm_height_MetersText"
                              class="form-text text-muted">{{'NewObservatiom.Heightinfeet'|translate}}</small>
                          </div>
                          <div class="col-sm-2">
                            <input type="number" class="form-control" placeholder="Cm" id="bcrm_height_cm_BMI"
                              (keyup)="LengthConverter('bcrm_height_cm_BMI')">
                            <small id="bcrm_height_MetersText"
                              class="form-text text-muted">{{'NewObservatiom.Heightincm'|translate}}</small>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            class="control-label col-md-3 col-sm-3 required">BMI: </label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" id="bcrm_bmi"
                              placeholder="Your BMI" name="bcrm_bmicalculation"
                              ngModel required #bcrm_bmicalculation="ngModel">
                          </div>
                        </div>
                      </div>
                        
                        
                      
                        <!-- BMI Field -->




                        <div class="form-group row" id="bcrm_weightinkilograms">
                          <label
                            class="control-label col-md-3 col-sm-3 required">{{'NewObservatiom.Weight'|translate}}</label>
                          <div class="col-sm-2">
                            <input type="number" class="form-control" placeholder="kg" id="bcrm_weight_kg"
                              name="bcrm_weightinkilograms" ngModel required #bcrm_weightinkilograms="ngModel"
                              (keyup)="weightConverter('bcrm_weight_kg')">
                            <small id="bcrm_height_MetersText"
                              class="form-text text-muted required">{{'NewObservatiom.WeightinKg'|translate}}</small>
                          </div>

                          <div class="col-sm-2">
                            <input type="number" class="form-control" placeholder="Gram" id="bcrm_weight_gram"
                              (keyup)="weightConverter('bcrm_weight_gram')">
                            <small id="bcrm_height_MetersText"
                              class="form-text text-muted ">{{'NewObservatiom.Weightingram'|translate}}</small>
                          </div>

                          <div class="col-sm-2">
                            <input type="number" class="form-control" placeholder="Pound" id="bcrm_weight_pound"
                              (keyup)="weightConverter('bcrm_weight_pound')">
                            <small id="bcrm_height_MetersText"
                              class="form-text text-muted">{{'NewObservatiom.Weightinpound'|translate}}</small>
                          </div>

                        </div>
                        <div class="form-group row" id="bcrm_heightinmetres">
                          <label class="control-label col-md-3 col-sm-3">{{'NewObservatiom.Height'|translate}}</label>
                          <div class="col-sm-2">
                            <input type="number" class="form-control" placeholder="Meter" id="bcrm_height_meter"
                              (keyup)="LengthConverter('bcrm_height_meter')" name="bcrm_heightinmetres" ngModel required
                              #bcrm_heightinmetres="ngModel">
                            <small id="bcrm_height_MetersText"
                              class="form-text text-muted required">{{'NewObservatiom.HeightinMeter'|translate}}</small>
                          </div>

                          <div class="col-sm-2">
                            <input type="number" class="form-control" placeholder="Feet" id="bcrm_height_feet"
                              (keyup)="LengthConverter('bcrm_height_feet')">
                            <small id="bcrm_height_MetersText"
                              class="form-text text-muted">{{'NewObservatiom.Heightinfeet'|translate}}</small>
                          </div>
                          <div class="col-sm-2">
                            <input type="number" class="form-control" placeholder="Cm" id="bcrm_height_cm"
                              (keyup)="LengthConverter('bcrm_height_cm')">
                            <small id="bcrm_height_MetersText"
                              class="form-text text-muted">{{'NewObservatiom.Heightincm'|translate}}</small>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="control-label col-md-3 col-sm-3">{{'Common.comments'|translate}}</label>
                          <div class="col-sm-9">
                            <textarea type="text" class="form-control" rows="3" id="comment"
                              placeholder="{{'Common.comments'|translate}}" name="msemr_comment" ngModel
                              #msemr_comment="ngModel"></textarea>
                          </div>
                        </div>
                        <input type="submit" class="btn btn-primary pull-right" value="{{'Common.Save'|translate}}">
                      </form>
                    </div>
                  </div>
                  <!-- / .modal-content -->

                </div>
                <!-- / .modal-dialog -->

              </div>

              <div id="UpdateObservationModel" class="modal fade">
                <!-- class modal and fade -->
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      {{'Common.UpdateObservation'|translate}}
                      <!-- modal header -->
                      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        ×
                      </button>
                    </div>
                    <div class="modal-body  result-shade1">
                      <!-- modal body -->
                      <form class="form" #EditDetails="ngForm" (ngSubmit)="onUpdate(EditDetails)" #f="ngForm"
                        autocomplete="off">
                        <div class="form-group row">
                          <label class="control-label col-sm-3">{{'Common.description'|translate}}:</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="{{'Common.description'|translate}}"
                              [(ngModel)]="Observation.msemr_description" name="msemr_description" ngModel required
                              #msemr_description="ngModel">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3  col-sm-3 ">{{'NewObservatiom.ReadingCategories'|translate}}:</label>
                          <div class="col-sm-9">
                            <select class="form-control" id='purpose' name="bcrm_readingcategories"
                              [(ngModel)]="Observation.bcrm_readingcategories" ngModel required
                              #bcrm_readingcategories="ngModel" #name="ngModel">
                              <option value="">Select Reading Categories</option>
                              <option value="101">Blood Pressure</option>
                              <option value="102">Diabetes</option>
                              <option value="103">Height</option>
                              <option value="104">Weight</option>
                              <option value="105">Other</option>
                            </select>
                          </div>
                        </div>
                        <!-- <div class="form-group row">
                      <label class="col-md-3  col-sm-3">Snomed Name :</label>
                      <div class="col-sm-4">
                        <input type="text" class="form-control getUpdateSnomed" placeholder="Search Snomad By Name">
                      </div>
                      <div class="col-sm-5">
                        <select class="form-control" id='snomedname' name="bcrm_conceptid" [(ngModel)]="Observation.bcrm_conceptid" ngModel required
                          #bcrm_conceptid="ngModel" #name="ngModel">
                          <option value="">Select Snomed Code</option>
                          <option *ngFor="let snomedData of snomedCodeList" value="{{snomedData.conceptId}}">
                            {{snomedData.code}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="control-label col-md-3 col-sm-3">Quantity:</label>
                      <div class="col-sm-9">
                        <input type="text" class="form-control" placeholder="Enter Quantity" [(ngModel)]="Observation.msemr_valuetypequantityvalue"
                          name="msemr_valuetypequantityvalue" ngModel required #msemr_valuetypequantityvalue="ngModel">
                      </div>
                    </div> -->
                        <div class="form-group row">
                          <label class="control-label col-md-3 col-sm-3">{{'NewObservatiom.High'|translate}}:</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Enter High Value"
                              [(ngModel)]="Observation.msemr_valuerangehighlimit" name="msemr_valuerangehighlimit"
                              ngModel required #msemr_valuerangehighlimit="ngModel">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="control-label col-md-3 col-sm-3">{{'NewObservatiom.Low'|translate}}:</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Enter Low Value"
                              name="msemr_valuerangelowlimit" [(ngModel)]="Observation.msemr_valuerangelowlimit" ngModel
                              required #msemr_valuerangelowlimit="ngModel">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="control-label col-md-3 col-sm-3">{{'Common.comments'|translate}}:</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="{{'Common.comments'|translate}}"
                              name="msemr_comment" [(ngModel)]="Observation.msemr_comment" ngModel required
                              #msemr_comment="ngModel">
                          </div>
                        </div>
                        <input type="submit" class="btn btn-primary pull-right" value="{{'Profile.update'|translate}}">
                      </form>
                    </div>
                  </div>
                  <!-- / .modal-content -->

                </div>
                <!-- / .modal-dialog -->

              </div>
<!-- Observation Charts Start -->
              
          <div #ObCharts  id="ObservationChart" class="modal fade">
                <!-- class modal and fade -->
                <div class="modal-dialog modal-lg container">
                  <div class="modal-content">
                    <div class="modal-header">                    
                      <!-- modal header -->
                      <h5 class="modal-title shadow p-3 dropdown-toggle  bg-white rounded" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Observation :
                        
                          <i class="fa fa-download" aria-hidden="true"></i>
                        
                        <div class="dropdown-menu shadow p-3" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item shadow p-3" (click)="download_Png()">PNG file</a>
                          <a class="dropdown-item shadow p-3" (click)="download_Jpg()">JPG file</a>
                          <a class="dropdown-item shadow p-3" (click)="DownloadPdf()">PDF file</a>
                          <a class="dropdown-item shadow p-3" (click)="printCanvas()">Print</a>
                        </div>
                      </h5>
                      <button class="close" type="button" (click)="closeChart()"  data-dismiss="modal" aria-hidden="true" aria-label="Close">
                        ×
                      </button>
                    </div>
                    <div class="modal-body">
                    
                    
                          <div class="chartMenu">
                            
                          </div>
                          <!-- <div class="chartCard">
                            <div class="chartBox"> -->
                              <canvas id="myChart" class="shadow-lg p-2 Width 100%"></canvas>
                            <!-- </div> -->
                          <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>

<!-- Observation Charts END -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>