import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
declare var $ :any
@Component({
  selector: 'app-joinus',
  templateUrl: './joinus.component.html',
  styleUrls: ['./joinus.component.css']
})
export class JoinusComponent implements OnInit {
  Lead:any={}
  Specialitylist:any=[];
  Qualificationslist:any=[]
  constructor( private toastr: ToastrService,  private router: Router) { }

  ngOnInit(): void {
    
    this.getQualification();
    this.getSpecification();
  }
  getQualification(){

 //*************Show / hide loader *******************/
  $('#ftco-loader').addClass('show');
  /**************************************** */
    axios.get(`${environment.apiUrl}/api/NoAuth/GetAllPractitionerQualification`).then(
      (response) => {
        this.Qualificationslist = response.data;
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      },
      (error) => {
        console.log(error);
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      }
    );
  }
  getSpecification(){

 //*************Show / hide loader *******************/
 $('#ftco-loader').addClass('show');
 /**************************************** */
axios.get(`${environment.apiUrl}/api/NoAuth/GetAllPractitionerSpeciality`).then(
  (response) => {
    this.Specialitylist = response.data;
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */
  },
  (error) => {
    console.log(error);
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */
  }
);
  }
  getPatientDeatils(){
    
    this.router.navigate(['/contactus/ContactDetails'],{skipLocationChange: true});
  }
  onSubmit(it: NgForm){
    
    this.Lead = it.value;
    this.Lead.bcrm_contacttype=935000001;
  //*************Show / hide loader *******************/
  $('#ftco-loader').addClass('show');
  /**************************************** */
    axios.post(`${environment.apiUrl}/api/NoAuth/CreateCrmLead`, this.Lead).then(
      (response) => {
        var result = response.data;
        if (result == "Success") {
          this.toastr.success("We will Contact you Shortly");
          this.router.navigate(['/'],{skipLocationChange: true});

        }
        else {
          this.toastr.info(result.toString());
        }
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      },
      (error) => {
        console.log(error);
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      }
    );
  }
    
  }
  

