
<div class="bradcam_area breadcam_bg bradcam_overlay">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="bradcam_text">
            <h3>Conditions</h3>
            <p><a href="index.html">Home /</a> Conditions</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="container">
    <div class="row">
        <div class="col-xl-12 col-md-12 col-lg-12">
            <div class="form-select" id="default-select" style="margin-top:2%;margin-bottom:5%">
                <div class="" tabindex="0">
                    <span class="current">Choose which Condition(s) you want content on. </span>
                    <select style="display: none;" id="condit" class="list">
                        <option value="" placeholder="Select Condition"></option>
                        <option value="acne">Acne</option>
                        <option value="abdominal-aortic-aneurysm">Abdominal aortic aneurysm</option>
                        <option value="addisons-disease">Addisons disease</option>
                        <option value="alcohol-misuse">Alcohol misuse</option>
                        <option value="alcohol-poisoning">Alcohol poisoning</option>
                        <option value="alcohol-related-liver-disease-arld">Alcohol related liver disease arld</option>
                        <option value="allergic-rhinitis">Allergic rhinitis</option>
                        <option value="allergies">Allergies</option>
                        <option value="anal-cancer">Anal cancer</option>
                        <option value="anal-fissure">Anal fissure</option>
                        <option value="anaphylaxis">Anaphylaxis</option>
                        <option value="angina">Angina</option>
                        <option value="anorexia">Anorexia</option>
                        <option value="appendicitis">Appendicitis</option>
                        <option value="arthritis">Arthritis</option>
                        <option value="asthma">Asthma</option>
                        <option value="athletes-foot">Athletes foot</option>
                        <option value="atopic-eczema">Atopic eczema</option>
                        <option value="atrial-fibrillation">Atrial fibrillation</option>
                        <option value="attention-deficit-hyperactivity-disorder-adhd">Attention deficit hyperactivity disorder adhd</option>
                        <option value="back-pain">Back pain</option>
                        <option value="bacterial-vaginosis">Bacterial vaginosis</option>
                        <option value="bartholins-cyst">Bartholins cyst</option>
                        <option value="bedbugs">Bedbugs</option>
                        <option value="bells-palsy">Bells palsy</option>
                        <option value="bipolar-disorder">Bipolar disorder</option>
                        <option value="blepharitis">Blepharitis</option>
                        <option value="blisters">Blisters</option>
                        <option value="body-dysmorphia">Body dysmorphia</option>
                        <option value="boils">Boils</option>
                        <option value="borderline-personality-disorder">Borderline personality disorder</option>
                        <option value="brain-aneurysm">Brain aneurysm</option>
                        <option value="brain-tumours">Brain tumours</option>
                        <option value="broken-finger">Broken finger</option>
                        <option value="broken-or-bruised-ribs">Broken or bruised ribs</option>
                        <option value="broken-toe">Broken toe</option>
                        <option value="bunions">Bunions</option>
                        <option value="bursitis">Bursitis</option>
                        <option value="c-difficile">C difficile</option>
                        <option value="Cancer">Cancer</option>
                        <option value="carpal-tunnel-syndrome">Carpal tunnel syndrome</option>
                        <option value="catarrh">Catarrh</option>
                        <option value="cellulitis">Cellulitis</option>
                        <option value="cervical-cancer">Cervical cancer</option>
                        <option value="chest-infection">Chest infection</option>
                        <option value="chickenpox">Chickenpox</option>
                        <option value="chlamydia">Chlamydia</option>
                        <option value="chronic-fatigue-syndrome-cfs">Chronic fatigue syndrome cfs</option>
                        <option value="chronic-pancreatitis">Chronic pancreatitis</option>
                        <option value="cirrhosis">Cirrhosis</option>
                        <option value="clinical-depression">Clinical depression</option>
                        <option value="cluster-headaches">Cluster headaches</option>
                        <option value="coeliac-disease">Coeliac disease</option>
                        <option value="cold-sores">Cold sores</option>
                        <option value="colic">Colic</option>
                        <option value="common-cold">Common cold</option>
                        <option value="conjunctivitis">Conjunctivitis</option>
                        <option value="constipation">Constipation</option>
                        <option value="corns-and-calluses">Corns and calluses</option>
                        <option value="coronary-heart-disease">Coronary heart disease</option>
                        <option value="costochondritis">Costochondritis</option>
                        <option value="cradle-cap">Cradle cap</option>
                        <option value="crohns-disease">Crohns disease</option>
                        <option value="croup">Croup</option>
                        <option value="cushings-syndrome">Cushings syndrome</option>
                        <option value="cystitis">Cystitis</option>
                        <option value="dehydration">Dehydration</option>
                        <option value="dental-abscess">Dental abscess</option>
                        <option value="dissociative-disorders">Dissociative disorders</option>
                        <option value="dry-eyes">Dry eyes</option>
                        <option value="ear-infections">Ear infections</option>
                        <option value="earache">Earache</option>
                        <option value="earwax-build-up">Earwax build up</option>
                        <option value="eating-disorders">Eating disorders</option>
                        <option value="ehlers-danlos-syndromes">Ehlers danlos syndromes</option>
                        <option value="encephalitis">Encephalitis</option>
                        <option value="endometriosis">Endometriosis</option>
                        <option value="epididymitis">Epididymitis</option>
                        <option value="epilepsy">Epilepsy</option>
                        <option value="erection-problems-erectile-dysfunction">Erection problems erectile dysfunction</option>
                        <option value="erythromelalgia">Erythromelalgia</option>
                        <option value="fever-in-children">Fever in children</option>
                        <option value="fibromyalgia">Fibromyalgia</option>
                        <option value="flatulence">Flatulence</option>
                        <option value="flu">Flu</option>
                        <option value="frozen-shoulder">Frozen shoulder</option>
                        <option value="fungal-nail-infection">Fungal nail infection</option>
                        <option value="gallstones">Gallstones</option>
                        <option value="ganglion">Ganglion</option>
                        <option value="gastritis">Gastritis</option>
                        <option value="generalised-anxiety-disorder">Generalised anxiety disorder</option>
                        <option value="genital-herpes">Genital herpes</option>
                        <option value="genital-warts">Genital warts</option>
                        <option value="giardiasis">Giardiasis</option>
                        <option value="gilberts-syndrome">Gilberts syndrome</option>
                        <option value="glandular-fever">Glandular fever</option>
                        <option value="glaucoma">Glaucoma</option>
                        <option value="glue-ear">Glue ear</option>
                        <option value="goitre">Goitre</option>
                        <option value="gonorrhoea">Gonorrhoea</option>
                        <option value="gout">Gout</option>
                        <option value="gum-disease">Gum disease</option>
                        <option value="hamstring-injury">Hamstring injury</option>
                        <option value="hand-foot-mouth-disease">Hand foot mouth disease</option>
                        <option value="hay-fever">Hay fever</option>
                        <option value="head-lice-and-nits">Head lice and nits</option>
                        <option value="heart-attack">Heart attack</option>
                        <option value="heart-failure">Heart failure</option>
                        <option value="heartburn-and-acid-reflux">Heartburn and acid reflux</option>
                        <option value="heat-exhaustion-heatstroke">Heat exhaustion heatstroke</option>
                        <option value="heat-rash-prickly-heat">Heat rash prickly heat</option>
                        <option value="heavy-periods">Heavy periods</option>
                        <option value="hepatitis-b">Hepatitis b</option>
                        <option value="hepatitis-c">Hepatitis c</option>
                        <option value="hiatus-hernia">Hiatus hernia</option>
                        <option value="high-blood-pressure-hypertension">High blood pressure hypertension</option>
                        <option value="high-blood-sugar-hyperglycaemia">High blood sugar hyperglycaemia</option>
                        <option value="high-cholesterol">High cholesterol</option>
                        <option value="hiv-and-aids">Hiv and aids</option>
                        <option value="hives">Hives</option>
                        <option value="human-papilloma-virus-hpv">Human papilloma virus hpv</option>
                        <option value="hypothermia">Hypothermia</option>
                        <option value="impetigo">Impetigo</option>
                        <option value="indigestion">Indigestion</option>
                        <option value="ingrown-toenail">Ingrown toenail</option>
                        <option value="insomnia">Insomnia</option>
                        <option value="interstitial-cystitis">Interstitial cystitis</option>
                        <option value="iron-deficiency-anaemia">Iron deficiency anaemia</option>
                        <option value="irritable-bowel-syndrome-ibs">Irritable bowel syndrome ibs</option>
                        <option value="jaundice-newborn">Jaundice newborn</option>
                        <option value="joint-hypermobility-syndrome">Joint hypermobility syndrome</option>
                        <option value="keratosis-pilaris">Keratosis pilaris</option>
                        <option value="kidney-infection">Kidney infection</option>
                        <option value="lactose-intolerance">Lactose intolerance</option>
                        <option value="laryngitis">Laryngitis</option>
                        <option value="leg-cramps">Leg cramps</option>
                        <option value="lichen-planus">Lichen planus</option>
                        <option value="lichen-sclerosus">Lichen sclerosus</option>
                        <option value="lipoma">Lipoma</option>
                        <option value="low-blood-pressure-hypotension">Low blood pressure hypotension</option>
                        <option value="low-blood-sugar-hypoglycaemia">Low blood sugar hypoglycaemia</option>
                        <option value="lupus">Lupus</option>
                        <option value="lyme-disease">Lyme disease</option>
                        <option value="measles">Measles</option>
                        <option value="melanoma-skin-cancer">Melanoma skin cancer</option>
                        <option value="menieres-disease">Menieres disease</option>
                        <option value="meningitis">Meningitis</option>
                        <option value="menopause">Menopause</option>
                        <option value="migraine">Migraine</option>
                        <option value="miscarriage">Miscarriage</option>
                        <option value="molluscum-contagiosum">Molluscum contagiosum</option>
                        <option value="motor-neurone-disease">Motor neurone disease</option>
                        <option value="mouth-ulcers">Mouth ulcers</option>
                        <option value="mrsa">Mrsa</option>
                        <option value="multiple-sclerosis">Multiple sclerosis</option>
                        <option value="mumps">Mumps</option>
                        <option value="nasal-and-sinus-cancer">Nasal and sinus cancer</option>
                        <option value="nasal-polyps">Nasal polyps</option>
                        <option value="non-alcoholic-fatty-liver-disease">Non alcoholic fatty liver disease</option>
                        <option value="non-hodgkin-lymphoma">Non hodgkin lymphoma</option>
                        <option value="non-melanoma-skin-cancer">Non melanoma skin cancer</option>
                        <option value="obesity">Obesity</option>
                        <option value="obsessive-compulsive-disorder-ocd">Obsessive compulsive disorder ocd</option>
                        <option value="obstructive-sleep-apnoea">Obstructive sleep apnoea</option>
                        <option value="oedema">Oedema</option>
                        <option value="oral-thrush-mouth-thrush">Oral thrush mouth thrush</option>
                        <option value="ovarian-cancer">Ovarian cancer</option>
                        <option value="ovarian-cyst">Ovarian cyst</option>
                        <option value="pancreatic-cancer">Pancreatic cancer</option>
                        <option value="panic-disorder">Panic disorder</option>
                        <option value="parkinsons-disease">Parkinsons disease</option>
                        <option value="pelvic-inflammatory-disease-pid">Pelvic inflammatory disease pid</option>
                        <option value="pelvic-organ-prolapse">Pelvic organ prolapse</option>
                        <option value="perforated-eardrum">Perforated eardrum</option>
                        <option value="pericarditis">Pericarditis</option>
                        <option value="peripheral-neuropathy">Peripheral neuropathy</option>
                        <option value="personality-disorder">Personality disorder</option>
                        <option value="phlebitis">Phlebitis</option>
                        <option value="phobias">Phobias</option>
                        <option value="piles-haemorrhoids">Piles haemorrhoids</option>
                        <option value="pilonidal-sinus">Pilonidal sinus</option>
                        <option value="pityriasis-rosea">Pityriasis rosea</option>
                        <option value="pityriasis-versicolor">Pityriasis versicolor</option>
                        <option value="plantar-fasciitis">Plantar fasciitis</option>
                        <option value="pleurisy">Pleurisy</option>
                        <option value="pneumonia">Pneumonia</option>
                        <option value="polycystic-ovary-syndrome-pcos">Polycystic ovary syndrome pcos</option>
                        <option value="polymorphic-light-eruption">Polymorphic light eruption</option>
                        <option value="polymyalgia-rheumatica">Polymyalgia rheumatica</option>
                        <option value="pompholyx">Pompholyx</option>
                        <option value="post-natal-depression">Post natal depression</option>
                        <option value="post-traumatic-stress-disorder-ptsd">Post traumatic stress disorder ptsd</option>
                        <option value="pre-eclampsia">Pre eclampsia</option>
                        <option value="pre-menstrual-syndrome">Pre menstrual syndrome</option>
                        <option value="prostate-cancer">Prostate cancer</option>
                        <option value="prostate-enlargement">Prostate enlargement</option>
                        <option value="psoriasis">Psoriasis</option>
                        <option value="psychosis">Psychosis</option>
                        <option value="pulmonary-embolism">Pulmonary embolism</option>
                        <option value="raynauds">Raynauds</option>
                        <option value="reflux-in-babies">Reflux in babies</option>
                        <option value="restless-legs-syndrome">Restless legs syndrome</option>
                        <option value="ringworm">Ringworm</option>
                        <option value="rubella">Rubella</option>
                        <option value="scabies">Scabies</option>
                        <option value="scarlet-fever">Scarlet fever</option>
                        <option value="schizophrenia">Schizophrenia</option>
                        <option value="sciatica">Sciatica</option>
                        <option value="shingles">Shingles</option>
                        <option value="shoulder-impingement-syndrome">Shoulder impingement syndrome</option>
                        <option value="sinusitis-sinus-infection">Sinusitis sinus infection</option>
                        <option value="sjogrens-syndrome">Sjogrens syndrome</option>
                        <option value="skin-cyst">Skin cyst</option>
                        <option value="skin-picking-disorder">Skin picking disorder</option>
                        <option value="skin-tags">Skin tags</option>
                        <option value="slapped-cheek-syndrome">Slapped cheek syndrome</option>
                        <option value="slipped-disc">Slipped disc</option>
                        <option value="social-anxiety">Social anxiety</option>
                        <option value="sprains-and-strains">Sprains and strains</option>
                        <option value="stroke">Stroke</option>
                        <option value="stye">Stye</option>
                        <option value="sunburn">Sunburn</option>
                        <option value="supraventricular-tachycardia-svt">Supraventricular tachycardia svt</option>
                        <option value="syphilis">Syphilis</option>
                        <option value="teeth-grinding">Teeth grinding</option>
                        <option value="temporal-arteritis">Temporal arteritis</option>
                        <option value="temporomandibular-disorder-tmd">Temporomandibular disorder tmd</option>
                        <option value="tendonitis">Tendonitis</option>
                        <option value="tension-headaches">Tension headaches</option>
                        <option value="threadworms">Threadworms</option>
                        <option value="thrush-in-men-and-women">Thrush in men and women</option>
                        <option value="tinnitus">Tinnitus</option>
                        <option value="tongue-tie">Tongue tie</option>
                        <option value="tonsillitis">Tonsillitis</option>
                        <option value="transient-ischaemic-attack-tia">Transient ischaemic attack tia</option>
                        <option value="trichomoniasis">Trichomoniasis</option>
                        <option value="trigger-finger">Trigger finger</option>
                        <option value="type-2-diabetes">Type 2 diabetes</option>
                        <option value="ulcerative-colitis">Ulcerative colitis</option>
                        <option value="underactive-thyroid-hypothyroidism">Underactive thyroid hypothyroidism</option>
                        <option value="urinary-tract-infections-utis">Urinary tract infections utis</option>
                        <option value="vaginal-dryness">Vaginal dryness</option>
                        <option value="vaginismus">Vaginismus</option>
                        <option value="vaginitis">Vaginitis</option>
                        <option value="vascular-dementia">Vascular dementia</option>
                        <option value="vertigo">Vertigo</option>
                        <option value="vitamin-b12-or-folate-deficiency-anaemia">Vitamin b12 or folate deficiency anaemia</option>
                        <option value="vulval-cancer">Vulval cancer</option>
                        <option value="vulvodynia">Vulvodynia</option>
                        <option value="warts-and-verrucas">Warts and verrucas</option>
                        <option value="zika">Zika</option>
                    </select>
                </div>
            </div>
            <hr />
            <h3 id="conditionHeader" class="text-center"></h3>
            <p id="CondDescription" class="text-center"></p>
            <hr />
            <div class="nhsuk-inset-text" id="Relatedlink"></div>
            <div id="hasPart"></div>
            <div id="mainContent"></div>
        </div>
    </div>

</div>
