import { Component, ViewChild, NgZone, ElementRef, OnInit } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import axios from 'axios';
import { ContactusService } from 'src/app/ApiServices/ContactUs/contactus.service';
declare var $: any

@Component({
  selector: 'app-contactdetail',
  templateUrl: './contactdetail.component.html',
  styleUrls: ['./contactdetail.component.css']
})
export class ContactdetailComponent implements OnInit {
  Lead: any;
  insuranceCompanyList: any;
  dialcode: string;
  isInsuranceEnable: boolean = false;
  selectedQuantity = "935000000";
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'

  };
  mobileinput;
  specialCharactermobile = false;
  Mobilevalue: any;
  Emailinput;
  specialCharacteremail = false;

  isSelectfunded: boolean = false;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  followemail: boolean;
  donotbulkemail: boolean;
  donotphone: boolean;
  donotpostalmail: boolean;
  donotfax: boolean;
  donotemail: boolean


  constructor(private contactusservice: ContactusService,
    private router: Router,
    private toastr: ToastrService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) { }

  ngOnInit(): void {
    this.isSelectfunded = false;

    //#region 
    $('.btnNext').click(function () {
      $('.nav-tabs > .nav-item > .active').parent().next('li').find('a').trigger('click');
    });

    $('.btnPrevious').click(function () {
      $('.nav-tabs > .nav-item > .active').parent().prev('li').find('a').trigger('click');
    });

    //#endregion

    $(document).ready(function () {
      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;

      $(".next").click(function () {

        current_fs = $(this).parent();
        next_fs = $(this).parent().next();
        //Add Class Active

        if ($("#Profiledetails").hasClass("active") && !validateForm()) return false;

        $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");
        //show the next fieldset
        next_fs.show();
        //hide the current fieldset with style
        current_fs.animate({ opacity: 0 }, {
          step: function (now) {
            // for making fielset appear animation
            opacity = 1 - now;

            current_fs.css({
              'display': 'none',
              'position': 'relative'
            });
            next_fs.css({ 'opacity': opacity });
          },
          duration: 600
        });
      });

      $(".previous").click(function () {

        current_fs = $(this).parent();
        previous_fs = $(this).parent().prev();

        //Remove class active
        $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

        //show the previous fieldset
        previous_fs.show();

        //hide the current fieldset with style
        current_fs.animate({ opacity: 0 }, {
          step: function (now) {
            // for making fielset appear animation
            opacity = 1 - now;

            current_fs.css({
              'display': 'none',
              'position': 'relative'
            });
            previous_fs.css({ 'opacity': opacity });
          },
          duration: 600
        });
      });
      $('.radio-group .radio').click(function () {

        $(this).parent().find('.radio').removeClass('selected');
        $(this).addClass('selected');
      });
      var currentTab = 0;
      function validateForm() {

        // This function deals with validation of the form fields
        var x, y, i, valid = true;
        x = document.getElementsByClassName("Profiledetails");
        y = x[currentTab].getElementsByTagName("input");
        // A loop that checks every input field in the current tab:
        for (i = 0; i < y.length; i++) {
          // If a field is empty...
          if (y[i].value == "") {
            // add an "invalid" class to the field:
            y[i].className += " invalid";
            // and set the current valid status to false
            valid = false;
          }
        }
        return valid; // return the valid status
      }


    });
    // 

    $('#datepicker').datepicker({
      format: 'mm/dd/yyyy',
      weekStart: 1,
      daysOfWeekHighlighted: "6,0",
      autoclose: true,
      todayHighlight: true,
    })

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {

        this.ngZone.run(() => {
          //get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          for (var i = 0; i < place.address_components.length; i++) {

            var addressType = place.address_components[i].types[0];
            // for house no.

            var number = place.address_components[0].long_name;
            var streetname = place.address_components[1].long_name;
            //var street = number.concat(streetname);
            function spaces(x) {
              var res = '';
              while (x--) res += ' ';
              return res;
            }


            var street = number + spaces(1) + streetname;
            $("#route").val(street);


            if (this.componentForm[addressType]) {

              var val = place.address_components[i][this.componentForm[addressType]];

              if (document.getElementById(addressType) != null) {
                var myInput = $("#" + addressType);
                myInput.val(val);
                myInput.trigger('input'); // Use for Chrome/Firefox/Edge
                myInput.trigger('change');
                (<HTMLInputElement>document.getElementById(addressType)).value = val;

              }
            }
          }
        });
      });
    });
    // For show defaul country code
    setTimeout(function () {
      debugger
      $("#mobilephone").val("+44");
      $("#businessPhone").val("+44");
      $("#homePhone").val("+44");
    }, 200);
  }
  getPatientDeatils() {

    this.router.navigate(['/contactus/Joinus'], { skipLocationChange: true });
  }
  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {

      navigator.geolocation.getCurrentPosition((position) => {

        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  markerDragEnd($event: MouseEvent) {
    // console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;

        }
        else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  onCountryChange($event) {
    if (this.Mobilevalue != "+") {
      this.dialcode = $event.dialCode;
      if (this.dialcode != undefined) {
        $("#mobilephone").val('+' + this.dialcode);
        this.specialCharactermobile = true;
      }
      }
      var mobilevalidationno = $("#mobilephone").val();
      if(mobilevalidationno != "+"){
        this.specialCharactermobile = true;
      }
    // this.dialcode = $event.dialCode;
    // if (this.dialcode != undefined) {
    //   $("#mobilephone").val('+' + this.dialcode);
    // }

  }
  // For mobile phone validation
  checkmobile(Event) {

    this.Mobilevalue = Event['currentTarget']['value'];
    this.mobileinput = document.getElementById('mobileInfo');
    const mobilephone = Event['currentTarget']['value'];
    var format = /^\+[0-9]{6,18}[0-9]$/;


    if (format.test(mobilephone)) {
      this.specialCharactermobile = true;
    }

    if (!format.test(mobilephone)) {
      this.specialCharactermobile = false;
    }

    this.mobileinput.classList.add('toolBox');
    this.mobileinput.classList.remove('tooltiptext');

  }

  // check email validation//
  checkEMAIL(Event) {

    //

    this.Emailinput = document.getElementById('emailInfo');
    const emailaddress1 = Event['currentTarget']['value'];
    var format = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (format.test(emailaddress1)) {
      this.specialCharacteremail = true;
    }

    if (!format.test(emailaddress1)) {
      this.specialCharacteremail = false;
    }

    this.Emailinput.classList.add('toolBox');
    this.Emailinput.classList.remove('tooltiptext');

  }
  IsCompanyEnable(fundedValue: string) {

    this.insuranceCompanyList = [];
    if (fundedValue == "3")//Insurance
    {
      this.getInsuranceCompany();
      this.isSelectfunded = false;
      this.isInsuranceEnable = true;

    } else {
      this.isInsuranceEnable = false;
      this.isSelectfunded = true;
    }
  }
  getInsuranceCompany() {
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    axios.get(`${environment.apiUrl}/api/NoAuth/GetAccount`).then(
      (response) => {
        this.insuranceCompanyList = response.data;
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      },
      (error) => {
        console.log(error);
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      }
    );
  }
  // Toggle change function
  //#region 
  getEmail(Value) {

    if (Value == false)
      this.donotemail = true;
    else
      this.donotemail = false
  }
  getFollowEmail(Value) {
    debugger
    if (Value == false)
      this.followemail = false;
    else
      this.followemail = true;
  }
  getBulkEmail(Value) {
    if (Value == false)
      this.donotbulkemail = true;
    else
      this.donotbulkemail = false
  }
  getPhone(Value) {
    if (Value == false)
      this.donotphone = true;
    else
      this.donotphone = false
  }
  getMails(Value) {
    if (Value == false)
      this.donotpostalmail = true;
    else
      this.donotpostalmail = false
  }
  getFax(Value) {
    if (Value == false)
      this.donotfax = true;
    else
      this.donotfax = false
  }
  //#endregion
  onSubmit(it: NgForm) {
debugger;
    var mobilephone = $("#mobilephone").val();
    // Chnage  value of toggle
    if(this.donotbulkemail==undefined || it.value.donotbulkemail==true){
      it.value.donotbulkemail=false;
    }else{
      it.value.donotbulkemail =this.donotbulkemail;
    }

    if(this.donotemail==undefined || it.value.donotbulkemail==true){
      it.value.donotemail=false;
    }else{
      it.value.donotemail =this.donotemail;
    }

    if(this.donotfax==undefined || it.value.donotfax==true){
      it.value.donotfax=false;
    }else{
      it.value.donotfax = this.donotfax;
    }
    
    if(this.donotphone==undefined || it.value.donotphone==true){
      it.value.donotphone=false;
    }else{
      it.value.donotphone =this.donotphone;
    }
   
    if(this.donotpostalmail==undefined || it.value.donotpostalmail==true){
      it.value.donotpostalmail=false;
    }else{
      it.value.donotpostalmail =this.donotpostalmail;
    }

    if(this.followemail==undefined || it.value.followemail==true){
      it.value.followemail=true;
    }else{
      it.value.followemail = this.followemail;
    }
   
   

    if ($.isNumeric(mobilephone) && mobilephone.length > 5 && mobilephone.length < 20) {
      //*************Show / hide loader *******************/
      $('#ftco-loader').addClass('show');
      /**************************************** */
      this.Lead = it.value;
      this.Lead.bcrm_contacttype = 935000000;
      this.Lead.address1_line1 = $("#route").val();
      this.Lead.address1_city = $("#locality").val();
      this.Lead.address1_stateorprovince = $("#administrative_area_level_1").val();
      this.Lead.address1_postalcode = $("#postal_code").val();
      this.Lead.address1_country = $("#country").val();
      this.Lead.mobilephone = mobilephone;
      // set Toggel value 
      this.Lead.donotbulkemail = it.value.donotbulkemail;
      this.Lead.donotemail = it.value.donotemail;
      this.Lead.donotfax = it.value.donotfax;
      this.Lead.donotphone = it.value.donotphone;
      this.Lead.donotpostalmail = it.value.donotpostalmail;
      this.Lead.followemail = it.value.followemail;

      axios.post(`${environment.apiUrl}/api/NoAuth/CreateCrmLead`, this.Lead).then(
        (response) => {
          var result = response.data;
          if (result == "Success") {
            this.toastr.success("We will Contact you Shortly");
            this.router.navigate(['/'], { skipLocationChange: true });

          }
          else {
            this.toastr.info(result.toString());
          }
          //*************Show / hide loader *******************/
          $('#ftco-loader').removeClass('show');
          /**************************************** */
        },
        (error) => {
          console.log(error);
          //*************Show / hide loader *******************/
          $('#ftco-loader').removeClass('show');
          /**************************************** */
        }
      );
    }



  }

}
  