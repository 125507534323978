import { Component, OnInit } from '@angular/core';
import { InvoiceService } from 'src/app/ApiServices/Invoice/invoice.service';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  invoiceList: object;
  constructor(private invoiceService: InvoiceService) { }

  ngOnInit(): void {
    var data:any=JSON.parse(localStorage.getItem("userProfileDataClaims"));
     environment.LoginUserId= data.userId;
    this.getInvoices()
  }
  RefreshPage(){
    this.ngOnInit()
  }
  getInvoices(): void {
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.invoiceService.getInvoices(environment.LoginUserId).subscribe((res) => {
      
      if(res != null){
        this.invoiceList = res;
        
      }
   
      setTimeout(function () {
        $('#dataTableExample').DataTable({
          "aLengthMenu": [
            [10, 30, 50, -1],
            [10, 30, 50, "All"]
          ],
          "iDisplayLength": 10,
          "language": {
            search: ""
          }
        });
        $('#dataTableExample').each(function() {
          var datatable = $(this);
          // SEARCH - Add the placeholder for Search and Turn this into in-line form control
          var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
          search_input.attr('placeholder', 'Search');
          search_input.removeClass('form-control-sm');
          // LENGTH - Inline-Form control
          var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
          length_sel.removeClass('form-control-sm');
        });
        }, 50);
     //*************Show / hide loader *******************/
     $('#ftco-loader').removeClass('show');
     /**************************************** */
    });
  }
}
