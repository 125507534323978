<section class="ftco-section ">
    <div class="container px-md-0 table-responsive">
      <div class="card">
        <form class="form" #it="ngForm" (ngSubmit)="onSubmit(it)" #f="ngForm" autocomplete="off">

          <div class="form-group row">
            <div class="col-md-6">
                <!-- <label class="control-label"> </label>   -->
                <select  name="bcrm_patientguardian" [(ngModel)]="bcrm_patientguardianvalue" id="bcrm_patientguardian"
                 ngModel #bcrm_patientguardian="ngModel" class="form-control" (change)="ChecktheGuardianValue($event)">
                    <option value="" disabled>Select Guardian / Proxy</option>
                    <option value={{userMainId}}>{{userMainname}} (Me)</option>
                    <option [value]="data.relatedPersonId" *ngFor="let data of ProxyData" class="form-control">{{data.fullName}}
                    </option>
                  </select>
            </div>

            <div class="col-md-6">
                <div class="form-group row bmd-form-group"  *ngIf="GuardianValue == true">
                    <!-- <div class="col-sm-1">
                        <input type="checkbox" id="GuardianValue"  name="bcrm_isproxy" [(ngModel)]="GuardianValue">
                    </div> -->
                    <label class="md-label-floating col-sm-6">Guardian / Proxy</label>
                </div>
            </div>
          </div>
  
          <!-- <div class="form-group row" id="name">
            <label class="control-label col-sm-2">Name:</label>
            <div class="col-sm-10">
              <input type="text" name="Name" class="form-control" [(ngModel)]="prescriptionName" ngModel required
                #Name="ngModel" placeholder="Enter name">
            </div>
          </div>
          <div class="form-group row" id="appointment">
            <label class="control-label col-sm-2">Appointment:</label>
            <div class="col-sm-10">
              <select class="form-control form-control-sm" name="bcrm_appointment" ngModel #bcrm_appointment="ngModel" #name="ngModel">
                <option value="" selected>Select Appointment</option>
                <option *ngFor="let app of appointments" value="{{app.Id}}">
                  {{app.subject}}</option>
              </select>
            </div>
          </div>  -->
          <!-- <div class="form-group row">
            <label class="control-label col-sm-2">Type:</label>
            <div class="col-sm-10">
              <select class="form-control" id='purpose' name="bcrm_type" (change)="onType($event.target.value)" ngModel
                required #bcrm_type="ngModel" #name="ngModel" [(ngModel)]="selectedQuantity">
                <option [disabled]="item.disabled" *ngFor="let item of items" [value]="item.id">{{item.name}}</option>
              </select>
            </div>
          </div> -->
          <table class="table table-bordered tablelist" id="prescriptionRecord">
            <thead class="">
              <tr>
                <th style="display: none;">S/No</th>
                <th>Select</th>
                <th>Drug</th>
                <th>Dosage</th>
                <th>Dosage Unit</th>
                <!-- <th>Frequency</th>-->
                <th>Expiry Date</th> 
                <th>Status</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of repeatPrescriptionDrug; index as i">
                <td style="display: none;">{{i + 1}}</td>
                <td>
                  <div class="custom-control custom-checkbox">
                      <!-- <input  *ngIf="data.IsReasonshow" type="checkbox" id="{{data.Id}}_checkbox" name="name1" disabled>&nbsp;   -->
                      <input  *ngIf="!data.IsReasonshow" type="checkbox" id="{{data.Id}}_checkbox" name="name1">&nbsp;                       
                  </div>       
                  
              </td>

             
                <td>{{data.Name}}</td>
                <td>{{data.bcrm_dosage}}</td>
                <td>{{data.bcrm_dosageunit}}</td>
                <!-- <td>{{data.bcrm_frequency}}</td>-->
                <td><span
                  *ngIf="data.bcrm_expirydate!= '0001-01-01T00:00:00'">{{data.bcrm_expirydate | date:'dd/MM/yyyy'}}</span>
              </td> 
              <td *ngIf="!data.IsReasonshow">{{data.bcrm_statusFormattedName}}</td>
              <td *ngIf="data.IsReasonshow" style="color: red;">Expired</td>
                <!-- <td>{{data.bcrm_startcomment}}</td> -->
                <td *ngIf="!data.IsReasonshow"><input type="text" class="form-control" id="{{data.Id}}_comment" placeholder="Enter comments"></td>
                <td *ngIf="data.IsReasonshow">This drug has been expired. Please contact your GP.</td>
                </tr>
            </tbody>
          </table>
          <!-- <div class="form-group row" id="Prescription">
            <label class="control-label col-sm-2">Drugs:</label>
            <div class="col-sm-10">
              <div class="row">
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <select class="form-control" id="drugid">
                        <option value="" selected disabled>Select Drugs</option>
                        <option  *ngFor="let drug of prescriptiondrugsdropdown" [value]="drug.id">{{drug.name}}</option>
                    </select>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <input class="form-control" type="text" id="comments" placeholder="Add Comments"/>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-12">
                  <a style="cursor: pointer;color: #3498DB;"><i class="fa fa-plus" aria-hidden="true" (click)="addDrugIntoList()"></i></a>
                </div>
            </div><br>
              <table class="table tablelist">
              <thead class="">
                <tr>
                  <th></th>
                  <th>Drug</th>
                  <th>Frequency</th>
                  <th>Dosage</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let prescriptionDrug of prescriptiondruglist; let i = index;">
                    <td>{{i+1}}</td>
                    <td>{{prescriptionDrug.bcrm_name}}</td>
                    <td>{{prescriptionDrug.bcrm_frequency}}</td>
                    <td>{{prescriptionDrug.bcrm_dosage}}</td>
                    <td>{{prescriptionDrug.bcrm_startcomment}}</td>
                    <td><i class="fa fa-window-close" style="color:red;cursor: pointer;" (click)="removedrugs(prescriptionDrug.Id)" aria-hidden="true"></i></td>
                </tr>
                </tbody>
            </table>
            </div>
          </div> -->
         <!-- <div class="form-group row" id="drugs">
            <label class="control-label col-sm-2">Drugs:</label>
            <div class="col-sm-10">
              <select class="js-example-basic-multiple" placeholder="Enter name" multiple="multiple" id="drugList">
              </select>
            </div>
          </div>  -->
          <!-- <div class="form-group row">
            <label class="control-label col-sm-2">Reason :</label>
            <div class="col-sm-10">
              <textarea type="text" class="form-control" id="bcrm_reason" name="bcrm_reason" rows="3" #name="ngModel" ngModel
                #bcrm_reason="ngModel" #name="ngModel"></textarea>
            </div>
          </div> 
          <div class="form-group row custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" (change)="showComment()" id="defaultUnchecked">
            <label class="custom-control-label" for="defaultUnchecked">If you want another drug then you can request for it by adding comment in below box.</label>
        </div>-->
        <div class="form-group row" id="bcrm_commentfornewdrug">
          <label class="control-label col-sm-2">Comment :</label>
          <div class="col-sm-10">
            <textarea type="text" class="form-control" id="bcrm_commentfornewdrug" name="bcrm_commentfornewdrug" rows="3" #name="ngModel" ngModel
              #bcrm_reason="ngModel" #name="ngModel"></textarea>
          </div>
        </div>
          <input type="submit" class="btn btn-primary pull-right" value="Request">
        </form>
       </div>

      <!-- <table class="table table-bordered" id="prescriptionRecord">
        <thead class="thead-light">
          <tr>
            <th>Drug</th>
            <th>Dosage</th>
            <th>Frequency</th>
            <th>Expiry Date</th>
            <th>Select</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of prescriptiondrugsdropdown">
            <td>{{data.bcrm_drug}}</td>
            <td>{{data.bcrm_dosage}}</td>
          
            <td>{{data.bcrm_frequency}}</td>
            <td><span
              *ngIf="data.bcrm_expirydate!= '0001-01-01T00:00:00'">{{data.bcrm_expirydate | date:'dd/MM/yyyy'}}</span>
          </td>
            <td>
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" id="{{data.id}}_checkbox" name="name1" />&nbsp;
                </div>
            </td>
            <td><input type="text" class="form-control" id="{{data.id}}_comment" placeholder="Enter comments"></td>
        </tbody>
      </table>
      <div class="row pull-left" style="padding: 1% 1% 3% 1%;">
        <input type="submit" class="btn btn-primary " value="Request" (click)="requestPrescrition(prescriptiondrugsdropdown)">
    </div> -->

    </div>
  </section>
