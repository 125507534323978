import { Component, OnInit } from '@angular/core';
import * as Chart from 'chart.js'


@Component({
  selector: 'app-ob-charts',
  templateUrl: './ob-charts.component.html',
  styleUrls: ['./ob-charts.component.css']
})
export class ObChartsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.createLineChart(this.labels,this.datacases,'myChart')
  }

  public canvas:any;
  public ctx:any;
  public labels:any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  public datacases: any = {
    Char1:[2000, 10000, 12000, 14000, 6000, 0, 0, 0, 0, 0, 0, 0],
    Char2:[4000, 80000, 14000, 16000, 5000, 0, 0, 0, 0, 0, 0, 0],
  
  };
  private createLineChart(labels, dataCases, ChartId){
    this.canvas = document.getElementById(ChartId);
    this.ctx = this.canvas.getContext('2d');
    
    let chart = new Chart(this.ctx,{
      type:'line',
      data:{
        labels: labels,
        datasets:[
              {
                label:'Chart 1',
                data:dataCases.Char1,
                backgroundColor:'#ffbb33',
                borderColor:'#ffbb33',
                fill: false,
              },
              {
                label:'Chart 2',
                data:dataCases.Char2,
                backgroundColor:'#ffbb33',
                borderColor:'#ffbb33',
                fill: false,
              }
        ] 
      },
      options:{
        title:{
          display: true,
          text: 'First Chart'
        },
        tooltips:{
          mode:'index',
          intersect: true
        },
        hover:{
          mode:'nearest',
          intersect: true
        }
      }



    })
  }

}

