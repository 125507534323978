import { Component, HostListener, Input } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  // @HostListener('window:beforeunload', ['$event'])
  // beforeunloadHandler(event) {
  //     alert('By refreshing this page you may lost all data.');
  // }
  userName: string;
  title = 'Virtual Hospital';

  constructor(private authService: MsalService, public translate: TranslateService) {
    debugger

    translate.addLangs(['English', 'Polish', 'Spanish', 'German']);
    if (localStorage.getItem('locale')) {
      debugger
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/English|Polish|Spanish|German/) ? browserLang : 'English');
    } else {
      localStorage.setItem('locale', 'English');
      translate.setDefaultLang('English');
    }

    // 
    // window.onbeforeunload = function (e) {
    //   
    //   return '.';
    // };

  }
  ngOnInit() {
    // this.userService.currentUser.subscribe(message => this.userName = message)
    debugger;
    var test = localStorage.getItem("token");
    console.log(test);
  }
  // ngOnDestroy(){
  //   localStorage.clear();
  //   this.authService.logout(); 
  // }
}
