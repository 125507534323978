<!-- <section style="margin: 3% 0% 1% 0%;">
    <div class="row no-gutters" style="justify-content: center;">
  
        <div class="col-lg-9 col-md-12 heading-section3">
          <h1 style="color: #fff;font-family: 'Yantramanav', sans-serif;margin: 0% 0% 0% 2%;">Documents</h1>
        </div>
      </div>
  </section> -->
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <div class="card">
            <div class="card-body">
                <div class="list-group">
                    <a class="list-group-item active" style="color: white;font-size:20px;">{{'Documents.Files'|translate}} <span
                            style="float:right;cursor:pointer;">
                                 <button type="button" class="btn btn-info btn-sm" href="#W2P1" data-toggle="modal" style="background-color: #009FE3;">
                                    {{'Documents.createFolder'|translate}}</button> 
                        </span></a>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item active" *ngFor="let p of path ;let indexOfelement=index;" (click)="back(p)">
                            <span *ngIf="indexOfelement==0; else elseTemplate">
                                <a>{{'Header.home'|translate}}</a>
                            </span>
                            <ng-template #elseTemplate>
                                <a>{{p}}</a>
                            </ng-template>
                        </li>
                    </ol>
                    <form class="navbar-form" autocomplete="off">
                        <span class="bmd-form-group">
                            <div class="input-group no-border">
                                <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                    placeholder="{{'Documents.GlobalSearchForAllDocuments'|translate}}">
                            </div>
                        </span>
                    </form>
                    <hr />
                    <div class="row" style="padding: 1%;">
                        <div class="col-md-7">
                            <div class="row" style="background-color:#009FE3;color:white;height:35px;">
                                <div class="col-md-6" style="font-size: 18px; font-weight: 700;">{{'Common.name'|translate}}</div>
                                <div class="col-md-6" style="font-size: 18px; font-weight: 700;">{{'Documents.uploadedDate'|translate}} </div>
                            </div>
                            <div class="ScrollDocumnentdata">
                            <div class="row" *ngFor="let tdata of tableData['children'] | filter: searchText ">
                                <div class="col-md-6" (click)="DownloadIntoLocal(tdata)" style="cursor:pointer;">
                                    <i class="fa fa-{{tdata.type}}"></i> {{tdata.label}}
                                </div>
                                <div class="col-md-6">
                                    {{tdata.date | date:'medium'}}
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <p>{{'Documents.acceptedfile'|translate}} :-<br> (jpg, jpeg, png, xlsx, word, pdf, mp4, mp3, docx, doc, txt)</p>
                            <div class="text-center">
                                <input type="file" (change)="onUploadFile($event)" />
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </div>
    <div class="col-md-2"></div>
</div><br />
<div id="push"></div>
<div class="modal fade" id="W2P1" role="dialog">
    <form class="form" #it="ngForm" (ngSubmit)="onUploadFolder(it)" #f="ngForm" autocomplete="off">
        <div class="modal-dialog modal-lg">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{'Documents.Information'|translate}}</h5>
                    <!-- modal header -->
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        ×
                    </button>
                </div>
                <div class="modal-body">

                    <div class="form-group">
                        <label class="control-label">{{'Documents.FolderName'|translate}}<span class="required-asterisk">*</span> :</label>
                        <div class="">
                            <input type="text" name="name" class="form-control" ngModel required #name="ngModel" placeholder="{{'Documents.FolderName'|translate}}" id="foldername">
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="onUploadFolder(it)" data-dismiss="modal" [disabled]="!it.valid">{{'Documents.Submit'|translate}}</button>
                    <!-- <input type="submit" class="btn btn-primary" value="Submit" (click)="onUploadFolder(it)" data-dismiss="modal" [disabled]="!it.valid"> -->
                    <button type="button" class="btn btn-default" data-dismiss="modal">{{'Common.Close'|translate}}</button>
                </div>
            </div>

        </div>
    </form>
</div>