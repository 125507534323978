<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="row heading">
                                <div class="col-md-4">
                                    <h5> <i data-feather="file-text"> </i> Digital Imagery <span style="color: gray;"></span>
                                    </h5>
                                </div>
                                <div class="col-md-6"></div>

                            </div>
                            <hr>
                            <br>
                            <div class="container" style="border: 1px solid #999;">
                                <div id="Cimar_Report_Data" style="padding: 1%">

                                </div>
                            </div><br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>