<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">

            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="row heading">
                                <div class="col">
                                    <h5> Allergy <span style="color: gray;"></span>
                                    </h5>
                                </div>
                                <div class="col">
                                    &nbsp; <button type="button" class="btn btn-primary float-right ml-1"
                                        (click)="RefreshPage()"><i class="fa fa-refresh ref"
                                            aria-hidden="true"></i></button>
                                            &nbsp; <button type="submit" href="#Modal1" data-toggle="modal"
                                        class="btn btn-primary float-right ">+ ADD ALLERGY</button>
                                </div>
                            </div>
                            <hr>
                            <div class="table-responsive">
                                <table class="table" id="dataTableExample">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Critically</th>
                                            <th>Type</th>
                                            <th>Verification Status</th>
                                            <th>Created on</th>
                                            <th></th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of allergies">
                                            <td>{{data.Name}}</td>
                                            <!-- <td>{{data.bcrm_snomedcode}}</td> -->
                                            <td>{{data.msemr_criticality}}</td>
                                            <td>{{data.type}}</td>
                                            <td>{{data.msemr_verificationstatus}}</td>
                                            <td>{{data.createdOn | date:'dd-MMM-yyyy '}}</td>
                                            <th>
                                                <span>
                                                    <i (click)="showConfirm(data.Id)" class="fa fa-trash"
                                                        aria-hidden="true"></i>
                                                </span>&nbsp;&nbsp;&nbsp;<span><a data-target="#Modal2"
                                                        (click)="editallergy(data)" data-toggle="modal"
                                                        class="fa fa-pencil" aria-hidden="true"></a>
                                                </span>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div id="Modal1" class="modal fade">
                                <!-- class modal and fade -->
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <!-- modal header -->
                                            <h4 class="modal-title" id="myModalLabel">Add Allergy </h4>
                                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clearAllergy()">
                                                ×
                                            </button>
                                        </div>
                                        <div class="modal-body  result-shade1">
                                            <!-- modal body -->
                                            <form class="form" #it="ngForm" (ngSubmit)="onSubmit(it)" #f="ngForm"
                                                autocomplete="off">
                                                <div class="form-group row">
                                                    <label
                                                        class="control-label col-sm-3">{{'MedicalHistory.Allergy.searchallergy'|translate}}</label>
                                                    <div class="col-sm-7">
                                                        <input type="text" class="form-control" id="searchallergy"
                                                            placeholder="{{'Common.search'|translate}}" name="query1"
                                                            ngModel #query1="ngModel">
                                                    </div>
                                                    <div class="col-sm-2"><i class="fa fa-search" aria-hidden="true"
                                                            (click)="findByQuery(query1,'add')"></i> </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label
                                                        class="bmd-label-floating col-sm-3 required">{{'MedicalHistory.Allergy.allergyname'|translate}}:</label>
                                                    <div class="col-sm-9">
                                                        <select list="browsers" name="browser" id="browser" ngModel
                                                            #browser="ngModel" (change)="getdata($event.target.value)"
                                                            class="form-control">
                                                            <option [value]="title.ID" *ngFor="let title of QueryResult"
                                                                class="form-control">{{title.Name}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label
                                                        class="control-label col-sm-3 required">{{'MedicalHistory.Allergy.code'|translate}}:</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" class="form-control1"
                                                            placeholder="{{'MedicalHistory.Allergy.code'|translate}}"
                                                            name="bcrm_snomedcode" ngModel required
                                                            #bcrm_snomedcode="ngModel" id="bcrm_snomedcode" disabled>
                                                    </div>
                                                </div>


                                                <div class="form-group row">
                                                    <label
                                                        class="col-sm-3">{{'MedicalHistory.Allergy.Critically'|translate}}:</label>
                                                    <div class="col-sm-9">
                                                        <input type="radio" name="crtically" id="mild" value="935000000" ngModel
                                                            #name="ngModel">&nbsp;Mild
                                                        <input type="radio" name="crtically" id="moderate" value="935000001" ngModel
                                                            #name="ngModel">&nbsp;Moderate
                                                        <input type="radio" name="crtically" id="severe" value="935000002" ngModel
                                                            #name="ngModel">&nbsp;Severe

                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label
                                                        class="col-sm-3">{{'MedicalHistory.StoredSample.type'|translate}}:</label>
                                                    <div class="col-sm-9">
                                                        <input type="radio" name="typecode" id="Allergy" value="935000000" ngModel
                                                            #name="ngModel">&nbsp;Allergy
                                                        <input type="radio" name="typecode" id="Intolerance" value="935000001" ngModel
                                                            #name="ngModel">&nbsp;Intolerance

                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label
                                                        class="col-sm-3">{{'MedicalHistory.Allergy.verificationStatus'|translate}}:</label>
                                                    <div class="col-sm-9">
                                                        <input type="radio" name="typecode" value="935000000"
                                                            id='Verification' name="verification" ngModel required
                                                            #verification="ngModel" #name="ngModel">&nbsp;Unconfirmed
                                                        <input type="radio" name="typecode" value="935000001"
                                                            id='Confirmed' name="verification" ngModel required
                                                            #verification="ngModel" #name="ngModel">&nbsp;Confirmed
                                                        <input type="radio" name="typecode" value="935000002"
                                                            id='Refuted' name="verification" ngModel required
                                                            #verification="ngModel" #name="ngModel">&nbsp;Refuted
                                                        <input type="radio" name="typecode" value="935000003"
                                                            id='Entered' name="verification" ngModel required
                                                            #verification="ngModel" #name="ngModel">&nbsp;Entered In
                                                        Error


                                                    </div>
                                                </div>

                                                <input type="submit" class="btn btn-primary pull-right"
                                                    value="{{'Common.Save'|translate}}">
                                            </form>
                                        </div>
                                    </div>
                                    <!-- / .modal-content -->

                                </div>
                                <!-- / .modal-dialog -->

                            </div>

                            <div id="Modal2" class="modal fade">
                                <!-- class modal and fade -->
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <!-- modal header -->
                                            <h4 class="modal-title" id="myModalLabel">Edit Allergy </h4>
                                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                                                ×
                                            </button>
                                        </div>
                                        <div class="modal-body  result-shade1">
                                            <!-- modal body -->
                                            <form class="form" #EditAllergy="ngForm" (ngSubmit)="onUpdate(EditAllergy)"
                                                #f="ngForm" autocomplete="off">

                                                <div class="form-group row">
                                                    <label
                                                        class="control-label col-sm-3">{{'MedicalHistory.Allergy.searchallergy'|translate}}</label>
                                                    <div class="col-sm-7">
                                                        <input type="text" class="form-control"
                                                            placeholder="{{'Common.search'|translate}}" name="query1"
                                                            [(ngModel)]="allergy.query1" ngModel #query1="ngModel">
                                                    </div>
                                                    <div class="col-sm-2"><i class="fa fa-search" aria-hidden="true"
                                                            (click)="findByQuery(allergy.query1,'update')"></i> </div>


                                                </div>

                                                <div class="form-group row">
                                                    <label
                                                        class="bmd-label-floating col-sm-3 required">{{'MedicalHistory.Allergy.allergyname'|translate}}:</label>
                                                    <div class="col-sm-9">
                                                        <select list="browsers" name="browser" id="browser" ngModel (value)="selected"
                                                            #browser="ngModel" (change)="getdata($event.target.value)"
                                                            class="form-control">
                                                            <option *ngFor="let title of QueryResult" [value]="title.ID" [attr.selected]="title.ID === selected ? true : null"
                                                                class="form-control">{{title.Name}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label
                                                        class="control-label col-sm-3">{{'MedicalHistory.Allergy.code'|translate}}:</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" class="form-control1"
                                                            placeholder="{{'MedicalHistory.Allergy.code'|translate}}"
                                                            [(ngModel)]="allergy.bcrm_snomedcode" name="bcrm_snomedcode"
                                                            ngModel required #bcrm_snomedcode="ngModel"
                                                            id="bcrm_snomedcode1" disabled>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label
                                                        class="col-md-3">{{'MedicalHistory.Allergy.Critically'|translate}}:</label>

                                                    <div class="col-sm-9">
                                                        <input type="radio" name="crtically" [value]="935000000" ngModel
                                                            [(ngModel)]="allergy.crtically" #name="ngModel">&nbsp;Low
                                                        Risk
                                                        <input type="radio" name="crtically" [value]="935000001" ngModel
                                                            [(ngModel)]="allergy.crtically" #name="ngModel">&nbsp;High
                                                        Risk
                                                        <input type="radio" name="crtically" [value]="935000002" ngModel
                                                            [(ngModel)]="allergy.crtically" #name="ngModel">&nbsp;Unable
                                                        to Assess Risk



                                                    </div>

                                                </div>
                                                <div class="form-group row">
                                                    <label
                                                        class="col-md-3">{{'MedicalHistory.StoredSample.type'|translate}}:</label>
                                                    <div class="col-sm-9">
                                                        <input type="radio" name="typecode" [value]="935000000" ngModel
                                                            [(ngModel)]="allergy.typecode" #name="ngModel">&nbsp;Allergy
                                                        <input type="radio" name="typecode" [value]="935000001" ngModel
                                                            [(ngModel)]="allergy.typecode"
                                                            #name="ngModel">&nbsp;Intolerance

                                                    </div>

                                                </div>

                                                <div class="form-group row">
                                                    <label
                                                        class="col-md-3">{{'MedicalHistory.Allergy.verificationStatus'|translate}}:</label>
                                                    <div class="col-sm-9">
                                                        <input type="radio" name="typecode" [value]="935000000"
                                                            id='Verification' name="verification" ngModel required
                                                            [(ngModel)]="allergy.verification" #verification="ngModel"
                                                            #name="ngModel">&nbsp;Unconfirmed
                                                        <input type="radio" name="typecode" [value]="935000001"
                                                            id='Verification' name="verification" ngModel required
                                                            [(ngModel)]="allergy.verification" #verification="ngModel"
                                                            #name="ngModel">&nbsp;Confirmed
                                                        <input type="radio" name="typecode" [value]="935000002"
                                                            id='Verification' name="verification" ngModel required
                                                            [(ngModel)]="allergy.verification" #verification="ngModel"
                                                            #name="ngModel">&nbsp;Refuted
                                                        <input type="radio" name="typecode" [value]="935000003"
                                                            id='Verification' name="verification" ngModel required
                                                            [(ngModel)]="allergy.verification" #verification="ngModel"
                                                            #name="ngModel">&nbsp;Entered In Error

                                                    </div>
                                                </div>
                                                <input type="submit" class="btn btn-primary pull-right"
                                                    value="{{'Profile.update'|translate}}">
                                            </form>
                                        </div>
                                    </div>
                                    <!-- / .modal-content -->

                                </div>
                                <!-- / .modal-dialog -->

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>