<div class="container">
    <div class="row">
        <div class="card">
            <div class="card-body">

                <h2>Enabling Third-Party Cookies on Web Browsers</h2>
                <p>Your browser must be configured to accept third-party cookies to avoid issues with&nbsp;accessing
                    the&nbsp;ResOnline pages. Please follow the browser-specific instructions below to enable
                    third-party cookies.
                </p>
                <h3><span style="color: inherit; font-size: inherit;">Microsoft Internet Explorer</span></h3>
                <p><strong>To enable cookies in IE 7:</strong></p>
                <ol>
                    <li>Click Start &gt; Control Panel. (Note: with Windows XP Classic View, click the Windows Start
                        button &gt;
                        Settings &gt; Control Panel).</li>
                    <li>Double-click the Internet Options icon <img class="alignnone"
                            style="font-family: Ubuntu; background-color: #282832;"
                            src="http://images.tourismholdings.com/public/2015/11/563698dff200f.png" alt="" width="29"
                            height="25">.
                    </li>
                    <li>Click the Privacy tab.</li>
                    <li>Click the Advanced button.</li>
                    <li>Select the option 'Override automatic cookie handling' under the Cookies section in the Advanced
                        Privacy
                        Settings window.</li>
                    <li>Select the 'Accept' or 'Prompt' option under 'First-party Cookies.'</li>
                    <li>Select the 'Accept' or 'Prompt' option under 'Third-party Cookies.' (Note: if you select the
                        'Prompt'
                        option, you'll be prompted to click OK every time a website attempts to send you a cookie.)</li>
                    <li>In the Internet Options window, click OK to exit.</li>
                </ol>
                <h3>Mozilla Firefox</h3>
                <p><strong>To enable cookies in Mozilla Firefox 3.x (PC):</strong></p>
                <ol>
                    <li>Click Tools &gt; Options.</li>
                    <li>Click Privacy in the top panel.</li>
                    <li>Select the checkbox labeled 'Accept cookies from sites.'</li>
                    <li>Select the checkbox labeled 'Accept third-party cookies.'</li>
                    <li>Click OK.</li>
                </ol>
                <p><strong>To enable cookies in Mozilla Firefox 2.x (PC):</strong></p>
                <ol>
                    <li>Click Tools &gt; Options.</li>
                    <li>Click Privacy in the top panel.</li>
                    <li>Select the checkbox labeled 'Accept cookies from sites.'</li>
                    <li>Click OK.</li>
                </ol>
                <p><strong>To enable cookies in Mozilla Firefox 1.x (PC):</strong></p>
                <ol>
                    <li>Click Tools &gt; Options.</li>
                    <li>Click Privacy in the top panel.</li>
                    <li>Click the Cookies tab.</li>
                    <li>Select the checkbox labeled 'Allow sites to set cookies.'</li>
                    <li>Click OK.</li>
                </ol>
                <p><strong>To enable cookies in Mozilla Firefox (Mac):</strong></p>
                <ol>
                    <li>Go to the Firefox drop-down menu.</li>
                    <li>Select Preferences.</li>
                    <li>Click Privacy.</li>
                    <li>Under Cookies, select the option 'Accept cookies from sites.'</li>
                </ol>
                <h3>Safari</h3>
                <p><strong>To enable cookies in Safari (Mac):</strong></p>
                <ol>
                    <li>Go to the Safari drop-down menu.</li>
                    <li>Select Preferences.</li>
                    <li>Click Privacy in the top panel.</li>
                    <li>Under 'Block cookies' select the option 'Never.'</li>
                    <li>For increased security, once you have finished using the site, please change the Privacy setting
                        back to
                        Always.</li>
                </ol>
                <p style="margin-top: 25px;">To enable cookies in Safari (iPhone/iPad):</p>
                <ol>
                    <li>Open your Settings.</li>
                    <li>Scroll down and select Safari.</li>
                    <li>Under Privacy &amp; Security, select 'Block Cookies.'</li>
                    <li>Select 'Always Allow.'</li>
                </ol>
                <h3>Google Chrome</h3>
                <p>To enable cookies in Google Chrome (PC):</p>
                <ol>
                    <li>Select the Chrome menu icon <img class="alignnone"
                            style="font-family: Ubuntu; background-color: #282832;"
                            src="http://images.tourismholdings.com/public/2015/11/563696cccb17a.png"
                            alt="Chrome Setting" width="32" height="30">.</li>
                    <li>Select Settings.</li>
                    <li>Near the bottom of the page, select Show advanced settings.</li>
                    <li>In the "Privacy" section, select Content settings.</li>
                    <li>Select Allow local data to be set (recommended).</li>
                    <li>Select Done.</li>
                </ol>
                <p>&nbsp;</p>
                <p><strong>To enable cookies in Google Chrome (Mac):</strong></p>
                <ol>
                    <li>Open Chrome preferences click on Settings, then Show Advanced Settings.</li>
                    <li>Under Privacy, click on Content Settings.</li>
                    <li>Make sure "Block third-party cookies and site data" is not checked</li>
                    <li>If your browser is not listed above, please refer to your browser's help pages.</li>
                </ol>
                <p>To enable cookies in Google Chrome (Android):</p>
                <ol>
                    <li>On your Android device, open the Chrome app <img
                            style="color: #212121; font-family: Roboto, 'Helvetica Neue', Helvetica, sans-serif; font-size: 13px; background-color: #ffffff;"
                            title="Chrome"
                            src="https://storage.googleapis.com/support-kms-prod/hoyrHHY874OtJFVNTfoeYGZiKzTzaOrGqeop"
                            alt="Chrome" width="20" height="20">.</li>
                    <li>At the top right, tap More More and then Settings.</li>
                    <li>Tap Site settings and then Cookies.</li>
                    <li>Next to "Cookies," switch the setting on.</li>
                    <li>To allow third-party cookies, check the box next to "Allow third-party cookies."</li>
                </ol>
                <p>&nbsp;</p>
                <p>You may need to restart your browser to proceed.</p>
            </div>
        </div>
    </div>
</div>