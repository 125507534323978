<div class="container">
    <div class="row">
        <div class="col-xl-12">
            <div class="section_title text-center mb-55">
                <h3>Covid-19</h3>
                <p>
                    This page includes links to general information on COVID-19 disease.
                </p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div id="des"></div>
            <div id="accordion"></div>
            <div id="mainContent"></div>
            <div id="MoreInfo"></div>

        </div>
    </div>
</div>