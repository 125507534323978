import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { HeaderComponent } from 'src/app/Navigation/header/header.component';
import { AllergyService } from 'src/app/ApiServices/Allergies/allergy.service';
declare var $: any;
import axios from 'axios';

@Component({
  selector: 'app-allergies',
  templateUrl: './allergies.component.html',
  styleUrls: ['./allergies.component.css']
})

export class AllergiesComponent implements OnInit {
  allergies: object;
  allergy: any = {};
  Token: string;
  isLoggedIn: boolean = false;
  selectDropdown:any;
  findByQueryUrl:any;
  Snomedcode:string;
  query1: string = "";
  SnomedName:string;
  allergyObj:any;
  snomedjson:any;
QueryResult:any=[];
codeList:any;
dropdownList;
public codeValue: string;
  EditSnomadedata: any;
  constructor(private allergyservice: AllergyService,
    private router: Router,
    private toastr: ToastrService,
    private headerComponent: HeaderComponent) {
    this.Token = "";//"";
    
    this.isLoggedIn = headerComponent.loggedIn;
    if (!this.isLoggedIn) {
      toastr.error("Have To login First", "To See The Doctors Details");
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
  this.getAllergies();
  }

  getAllergies(): void {
    $('.AllergyTable').DataTable().destroy();
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.allergies = null;
    this.allergyservice.getAllergy(environment.LoginUserId).subscribe((res) => {

      if (res != null) {
        this.allergies = res;
      }
    setTimeout(() => {
      $('.AllergyTable').DataTable();
    }, 1000);
    $("#your-image1").attr("src", "../../../assets/images/Allergies.png");
    $("#your-image").attr("src", "../../../assets/images/profile.png");
    $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
    $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
    $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
    $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
    $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
    $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
    $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
    $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/']);
    });
  }
    // Find by query and set the scrollable raw json result

      // Find by query and set the scrollable raw json result
findByQuery(query,text){
  let LocalThis=this
 LocalThis.QueryResult=[];
 
 if (text == 'add') {
  if (query.value == "") {
    return
  }
}
else if (text == 'update') {
  if (query == "") {
    return
  }
}

  var baseUrl = "https://browser.ihtsdotools.org/snowstorm/snomed-ct";
  var edition = "MAIN";
  var version = "2019-07-31";
  // if condition for add and update
  if (text == 'add') {
    query = query.value;
  } else if (text == 'update') {
    query = query;
    LocalThis.QueryResult.push({
      ID: LocalThis.EditSnomadedata.bcrm_snomedcode,
      Name: LocalThis.EditSnomadedata.Name,
    });
  }
        // Make the HTTP Call
        axios.get(`${baseUrl}/${edition}/${version}/concepts?term=${encodeURIComponent(query)}&activeFilter=true&offset=0&limit=20`).then(
          (response) => {
        LocalThis.dropdownList=response.data['items'];
        LocalThis.dropdownList.forEach(function (item) {
          var data={
            ID :item.conceptId,
            Name:item.pt.term
          }
          LocalThis.QueryResult.push(data);
        });
       

    }, (err) => {
      this.toastr.error("No data found");
    });

    }
    getdata(value) {
      debugger;
      var snomedDetail = this.QueryResult.find(x => x['ID'] == value)
      this.Snomedcode = snomedDetail["ID"];
      this.SnomedName = snomedDetail["Name"];
      (<HTMLInputElement>document.getElementById("bcrm_snomedcode")).value = this.Snomedcode;
      (<HTMLInputElement>document.getElementById("bcrm_snomedcode1")).value = this.Snomedcode;
    }
    

  onSubmit(it: NgForm) {
    debugger;
    it.value.bcrm_snomedcode= this.Snomedcode
      it.value.name= this.SnomedName;
     if(it.value.name ==null || it.value.bcrm_snomedcode==null){
      return this.toastr.error("Please fill mandatory fields");
    }
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /************************************************** */
    this.allergyObj = it.value;
    this.allergyObj.bcrm_modifiedby = "Patient_" + environment.LoginUserEmail + "",
    this.allergyservice.createAllergy(this.allergyObj, environment.LoginUserId).subscribe((res) => {
      
      if(res == true){
        this.toastr.success("Allergy successfully created");
        $('#Modal1').modal('hide');
        this.ReloadComponent();   
        $('#Modal1 form')[0].reset();
        $("#Critically")[0].selectedIndex = -1;
        $("#Type")[0].selectedIndex = -1;
        $("#Verification")[0].selectedIndex = -1;
      }
      else {
        this.toastr.error("Error while creating allergy");
      }
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/']);
    });
  }

  editallergy(data) {
    debugger;
    this.EditSnomadedata = data;
    this.QueryResult = [];
    var data1 = {
      ID: data.bcrm_snomedcode,
      Name: data.Name,
    }
    this.QueryResult.push(data1);
    this.allergy = data;
    this.allergy.Id = data.Id;
    this.allergy.query1 = "";
    (<HTMLInputElement>document.getElementById("bcrm_snomedcode1")).value =data.bcrm_snomedcode
  }
 
  showConfirm(allergyid) {  
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No, Cancel'
  }).then((result) => {
    if (result.value) {
      //*************Show / hide loader *******************/
      $('#ftco-loader').addClass('show');
      /**************************************** */
      this.allergyservice.deleteAllergy(allergyid).subscribe((res) => {
        
        if(res == true){
          this.toastr.info("Deleted Successfully");
          this.ReloadComponent();   
        }
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      },(err) => {
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /****************** -------- **********************/
        this.toastr.error(err, "Error : ");
        this.router.navigate(['/']);
      });
   
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
          'Cancelled',
      );
  }
  });
  
  }

  onUpdate(EditAllergy) {
    debugger;
    this.allergy.Name = this.SnomedName;
    this.allergy.bcrm_snomedcode = this.Snomedcode;
    if(this.allergy.Name ==null || this.allergy.bcrm_snomedcode==null){
      return this.toastr.error("Please fill mandatory fields");
    }
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.allergyservice.updateAllergy(this.allergy, environment.LoginUserId).subscribe((res) => {
      if (res == true) {
        this.toastr.success("Updating Allergy");
        $('#Modal2').modal('hide');
        this.ReloadComponent();   
      }
      else {
        this.toastr.error("Allergy not updated");
      }
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/']);
    });
  }

  ReloadComponent(): void {
    let Location = this.router.url;
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([Location], { skipLocationChange: true });
    });
  }
}