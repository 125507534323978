<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="row heading">
                                <div class="col">
                                    <h5> Test Result <span style="color: gray;"></span></h5>
                                    &nbsp; <button type="button" class="btn btn-primary float-right ml-1"
                                        (click)="RefreshPage()"><i class="fa fa-refresh ref"
                                            aria-hidden="true"></i></button>
                                            &nbsp; 
                                </div>
                            </div>
                            <br>
                            <div class="card">
                                <div style="height: 550px; overflow-y: scroll;"
                                    class="container px-md-0 table-responsive" id="output"></div>
                            </div>
                            <div class=" px-md-0 table-responsive">
                                <table class="table table-bordered" id="tdlTable">
                                    <thead class="thead-light">
                                        <tr>
                                            <th style="display: none;">S/No</th>
                                            <th>{{'Common.name'|translate}}</th>
                                            <th>{{'ObservationResult.ObservationBundle'|translate}}</th>
                                            <th>{{'ObservationResult.orderStatus'|translate}}</th>
                                            <th>{{'Common.Status'|translate}}</th>
                                            <th>{{'Common.createdOn'|translate}}</th>
                                            <th>{{'ObservationResult.dateTimeOfTransaction'|translate}}</th>
                                            <th>{{'ObservationResult.consultingDoctor'|translate}}</th>
                                            <th>{{'ObservationResult.doctorsComment'|translate}}</th>
                                            <th>{{'ObservationResult.approve'|translate}}</th>

                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let data of observations;let i = index;">

                                        <tr>
                                            <td style="display: none;">{{i + 1}}</td>
                                            <td (click)="OpenDetails(data)">
                                                <a style="color: #007bff;">{{data.Name}}</a>
                                            </td>
                                            <td>{{data.bcrm_observationbundle}}</td>
                                            <td>{{data.bcrm_orderstatusname}}</td>
                                            <td>{{data.bcrm_statusname}}</td>
                                            <td>{{data.createdOn | date:'dd-MMM-yyyy'}}</td>
                                            <td>{{data.bcrm_datetimeoftransaction | date:'dd-MMM-yyyy | HH:mm'}}</td>
                                            <td>{{data.bcrm_consultingdoctor}}</td>
                                            <td>{{data.bcrm_doctors_comment}}</td>
                                            <td>{{data.bcrm_approve}}</td>

                                        </tr>

                                    </tbody>
                                </table>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>