<div class="bradcam_area breadcam_bg bradcam_overlay">
    <div class="container containerpadding">
        <div class="row" style="justify-content: center;">

            <p class="hcolor">Departments</p>
        
        </div>
    </div>
</div>
<section class="ftco-section bg-light">
    <div class="container px-md-0">
        <div class="card">
            <div class="card-body">
                <div class="section_title mb-55">
                    <form class="navbar-form" autocomplete="off">
                        <span class="bmd-form-group">
                            <div class="input-group no-border">
                                <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                    placeholder="Search Doctors Based On Their Name & Speciality">
                            </div>
                        </span>
                    </form>
                    <hr />
                </div>
                <div class="row">
                    <div class="col-md-4" *ngFor="let doctors of allDoctors | filter:searchText">
                        <div class="card testimonial-card" style="margin-top:15px;">
                          <div class="avatar mx-auto white">
                            <img src="data:image/jpeg;base64,{{doctors.ProfilePhoto}}" class="rounded-circle"
                              alt="woman avatar">
                          </div>
                          <div class="card-body text-center">
                            <h4><a [routerLink]="['/doctors', doctors.Mail ]" skipLocationChange>{{doctors.DisplayName}}</a></h4>
                            <h6><b>GMC Number: </b><span>&nbsp; {{doctors.GMCNumber}}</span></h6>
                            <h6><b>Speciality: </b><span>&nbsp; {{doctors.SpecialityRecognition}}</span></h6>
                            <h6><b>Qualification: </b><span>&nbsp; {{doctors.LetterQualifications}}</span></h6>
                            <span *ngIf="this.isLoggedIn">
                              <button class="btn btn-primary text-center" [routerLink]="['/doctors', doctors.Id ]" skipLocationChange> View
                                Details</button>
                                &nbsp;&nbsp;<button class="btn btn-primary text-center" [routerLink]="['/bookAppointment', doctors.Mail ]" skipLocationChange> Book
                                  Now</button> 
                            </span>                
                          </div>
                        </div>
                      </div>



                </div>
            </div>
        </div>
    </div>
</section>
<section class="bg-light">
    <div class="container px-md-0">
        <div class="card">
            <div class="card-body">
                <div id="KBcontent">

                </div>
            </div>
        </div>
    </div>
</section><br>
