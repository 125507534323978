import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class SickNotesService {

  constructor(private http: HttpClient) { }

  
  getSickNotes(ID) {
    
    return this.http.get(`${environment.apiUrl}/api/SickNotes/GetSickNotesByPatientId?Id=${ID}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }

  createSickNotes(SickNotes, userId) {
    
    return this.http.post(`${environment.apiUrl}/api/SickNotes/CreateSickNotes?userId=${userId}`, SickNotes).pipe(
      
      // retry(1), 
      catchError(this.handleError)
    );
  }

  deleteSickNotes(ID) {
    
    return this.http.delete(`${environment.apiUrl}/api/SickNotes/DeleteSickNote?Id=${ID}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }

  updateSickNotes(SickNotesAll, userId) {
    
    return this.http.post(`${environment.apiUrl}/api/SickNotes/UpdateSickNotes?userId=${userId}`, SickNotesAll).pipe(
      // retry(1),SickNotesAll
      catchError(this.handleError)
    );
  }


  getProxyDetails(ID) {
    
    return this.http.get(`${environment.apiUrl}/api/CrmUser/GetAllRelatedPerson?patient_Id=${ID}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }


  Getsharepointpdf(URL) {
    debugger
    return this.http.get(`${environment.apiUrl}/api/SharepointFiles/DownloadFile?retrivalURL=/bcrm_sicknote${URL}` , {responseType: 'arraybuffer'}).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }

  

  handleError(error) {
    
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */
    let errorMessage = '';
    let errorMessageUi='';
    if (error.error instanceof ErrorEvent) {
      // client-side error 
      errorMessage = `Error: ${error.error.message}`;  
      errorMessageUi=error.error.message;
    } else {
      // server-side error 
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      if(error.error!=undefined){
        errorMessageUi=error.error.message
      }
      else{
        errorMessageUi=error.message
      }
    }
    //  // Show error on hte Ui and Deirect to home
    //  this.toastr.error("Error", errorMessageUi);
    //  this.router.navigate(['/']);

    return throwError(errorMessageUi);
  }


}
