import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class PrescriptionsService {

  constructor(private http: HttpClient) { }
  getPrescriptionsDrugsByPatientId(id) {  
      
    return this.http.get(`${environment.apiUrl}/api/Prescriptions/GetPrescriptionDrugsByPatientId?patientId=${id}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  GetAppointmentByPatientId(id) {  
      
    return this.http.get(`${environment.apiUrl}/api/CrmAppointment/GetAppointmentByPatientId?PatientId=${id}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  
  getAlldrugs() {    
    return this.http.get(`${environment.apiUrl}/api/Prescriptions/GetAllDrugs`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  
  GetPrescriptionHeaderDetailById(prescriptionID) {    
    return this.http.get(`${environment.apiUrl}/api/Prescriptions/GetPrescriptionHeaderDetailById?prescriptionID=${prescriptionID}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  getPrescriptionDrugs(patientId) {    
    return this.http.get(`${environment.apiUrl}/api/Prescriptions/GetPrescriptionDrugs?patientId=${patientId}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  UpdateForRepeatPrescription(Obj) {
  
    return this.http.post(`${environment.apiUrl}/api/Prescriptions/UpdateForRepeatPrescription`,Obj).pipe(
      //  retry(1), 
      catchError(this.handleError)
    );
  }
  CreateNewPrescription(Obj,userId) {
    return this.http.post(`${environment.apiUrl}/api/Prescriptions/CreateNewPrescription?userId=${userId}`, Obj).pipe(
      //  retry(1), 
      catchError(this.handleError)
    );
  }
  handleError(error) {
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */
    let errorMessage = '';
    let errorMessageUi = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error 
      errorMessage = `Error: ${error.error.message}`;
      errorMessageUi = error.error.message;
    } else {
      // server-side error 
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      errorMessageUi = error.error.message
    }
    //  // Show error on hte Ui and Deirect to home
    //  this.toastr.error("Error", errorMessageUi);
    //  this.router.navigate(['/']);

    return throwError(errorMessageUi);
  }

}
