import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
declare var $:any;
@Injectable({
  providedIn: 'root'
})
export class LobbyService {

  constructor(private http: HttpClient) {
    
   }

   getLobbyDetailsByDateRange(patientId,Todate,FromDate){     
   // return this.http.get(`${environment.apiUrl}/api/Teams/GetLobbyRecords`).pipe(
   return this.http.get(`${environment.apiUrl}/api/OXDHAppointments/GetAllAppointmentEmr?patientId=${patientId}&startDate=${Todate}&endDate=${FromDate}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
   }
   addLobby(lobbyData){   
      return this.http.post(`${environment.apiUrl}/api/Teams/CreateRecordsInLobby`,lobbyData).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
   }
   sendMessage(SendMessageObj){   
    return this.http.post(`${environment.apiUrl}/api/Teams/CreateSMS`,SendMessageObj).pipe(
    // retry(1), 
    catchError(this.handleError)
  );
   }
    getMessages(appointmentId){
          
        return this.http.get(`${environment.apiUrl}/api/Teams/GetSMS?appointmentId=${appointmentId}`).pipe(
          // retry(1), 
          catchError(this.handleError)
        );
    }


    GetRebrand(formData){   
      debugger
      return this.http.post(`${environment.apiUrl}/api/BlobUpload`,formData).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
     }
 
   handleError(error) {
  
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */
    let errorMessage = '';
    let errorMessageUi = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error 
      errorMessage = `Error: ${error.error.message}`;
      errorMessageUi = error.error.message;
    } else {
      // server-side error 
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      errorMessageUi = error.error.message
    }
    return throwError(errorMessageUi);
  }
}
