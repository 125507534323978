import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
// import { ToastrService } from 'ngx-toastr';
// import { Router } from '@angular/router';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ValidateLoginService {


  constructor(private http: HttpClient){}
    // private toastr: ToastrService,
    // private router: Router, ) { }

  // Get Doctors for this api //
  getLoginDetials(B2C_Data) {
    return this.http.post(`${environment.apiUrl}/api/account/Login`, B2C_Data).pipe(
      //  retry(1), 
      catchError(this.handleError)
    );
  }
  // Get Doctors for this api //
  postRegistrationDataIntoCRM(B2C_Data) {
    return this.http.post(`${environment.apiUrl}/api/account/Register`, B2C_Data).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }


  handleError(error) {
    
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */
    let errorMessage = '';
    let errorMessageUi='';
    if (error.error instanceof ErrorEvent) {
      // client-side error 
      errorMessage = `Error: ${error.error.message}`;  
      errorMessageUi=error.error.message;
    } else {
      // server-side error 
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      if(error.error!=undefined){
        errorMessageUi=error.error.message
      }
      else{
        errorMessageUi=error.message
      }
   
    }
    //  // Show error on hte Ui and Deirect to home
    //  this.toastr.error("Error", errorMessageUi);
    //  this.router.navigate(['/']);

    return throwError(errorMessageUi);
  }
}

