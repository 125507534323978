import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nhs',
  templateUrl: './nhs.component.html',
  styleUrls: ['./nhs.component.css']
})
export class NHSComponent implements OnInit {
  Token:string;
  constructor( private router: Router) {
    this.Token= localStorage.getItem("AuthToken");
    if(this.Token==null || this.Token==undefined)
    {
      alert("Loging Required");
      this.router.navigate(['/']);
    }
   }

  ngOnInit(): void {
   
   }
}
