<!-- <section style="margin: 3% 0% 1% 0%;">
    <div class="row no-gutters" style="justify-content: center;">

        <div class="col-lg-9 col-md-12 heading-section3">
            <h1 style="color: #fff;font-family: 'Yantramanav', sans-serif;margin: 0% 0% 0% 2%;">Test Results</h1>
        </div>
    </div>
</section> -->
<br>
<div style="height: 550px; overflow-y: scroll;" class="container px-md-0 table-responsive" id="output"></div>
<section class="ftco-section ">
    <div class="container px-md-0 table-responsive">
        <table class="table table-bordered" id="tdlTable">
            <thead class="thead-light">
                <tr>
                    <th style="display: none;">S/No</th>
                    <th>{{'Common.name'|translate}}</th>
                    <th>{{'ObservationResult.ObservationBundle'|translate}}</th>
                    <th>{{'ObservationResult.orderStatus'|translate}}</th>
                    <th>{{'Common.Status'|translate}}</th>
                    <th>{{'Common.createdOn'|translate}}</th>
                    <th>{{'ObservationResult.dateTimeOfTransaction'|translate}}</th>
                    <th>{{'ObservationResult.consultingDoctor'|translate}}</th>
                    <th>{{'ObservationResult.doctorsComment'|translate}}</th>
                    <th>{{'ObservationResult.approve'|translate}}</th>

                </tr>
            </thead>
            <tbody *ngFor="let data of observations;let i = index;">
              
                <tr>
                    <td style="display: none;">{{i + 1}}</td>
                    <td (click)="OpenDetails(data)">
                        <a style="color: #007bff;">{{data.Name}}</a>
                    </td>
                    <td>{{data.bcrm_observationbundle}}</td>
                    <td>{{data.bcrm_orderstatusname}}</td>
                    <td>{{data.bcrm_statusname}}</td>
                    <td>{{data.createdOn | date:'dd/MM/yyyy'}}</td>
                    <td>{{data.bcrm_datetimeoftransaction}}</td>
                    <td>{{data.bcrm_consultingdoctor}}</td>
                    <td>{{data.bcrm_doctors_comment}}</td>
                    <td>{{data.bcrm_approve}}</td>

                </tr>

            </tbody>
        </table>


    </div>
</section>