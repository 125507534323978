<div class="main-wrapper">
    <div class="page-wrapper">
      <div class="page-content">
        <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row heading">
                  <div class="col">
                    <h5> Observations <span style="color: gray;"></span></h5>
                  </div>


    <canvas id="myChart">

    </canvas>


</div>

</div>

</div>
</div>
</div>
</div>
</div>
</div>