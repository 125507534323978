<div class="bradcam_area breadcam_bg bradcam_overlay">
   <div class="container">
      <div class="row">
         <div class="col-xl-12">
            <div class="bradcam_text">
               <h3>Terms & Conditions</h3>
               <p><a href="">Home /</a> Terms & Conditions</p>
            </div>
         </div>
      </div>
   </div>
</div>
<br />
<!-- <div class="container"> <div class="pull-left"><a  type="button" class="btn btn-primary"routerLink="../newprofile">Back</a></div></div>
  -->
<div class="parent-page">




   <div class="section content-wrap">

      <div class="container">

         <article class="row">

            <div class="col-sm-12">

               <div class="row">
                  <div class="main-content col-sm-12">
                     <p>&nbsp;</p>
                     <ol>
                        <li><strong><u>Scope</u></strong><strong>. </strong>Client may use the Solution only in the
                           ordinary course of Client’s internal business operations for the benefit of Client and only
                           in accordance with the terms of this Agreement (including the Documentation, the Order and
                           the Scope of Use). Client shall be liable for any breach of this Agreement by any persons
                           granted access to the Solution by Client.</li>
                     </ol>
                     <p><strong> </strong></p>
                     <ol start="2">
                        <li><strong><u>Business Purpose. </u></strong>Performance by Client of patient management
                           functions in support of Client&#8217;s private clinic.</li>
                     </ol>
                     <p>&nbsp;</p>
                     <ol start="3">
                        <li><strong><u>Number of Environments.</u></strong>
                           <ul>
                              <li>One (1) Environment comprised of:</li>
                           </ul>
                        </li>
                     </ol>
                     <h2><em>A. </em>One (1) live production Environment</h2>
                     <ul>
                        <li>In the event Client wishes to increase the number of Environments beyond the scope specified
                           (each such Environment an &#8220;Additional Environment&#8221;), the parties shall negotiate,
                           acting reasonably, an applicable Additional Environment fee (the &#8220;<strong>Additional
                              Environment Annual Fees</strong>&#8220;), such fee to be payable quarterly in advance.
                        </li>
                     </ul>
                     <p><strong> </strong></p>
                     <ol start="4">
                        <li><strong><u>Specified Configuration</u></strong><strong>. </strong>Client shall, at its
                           expense, procure and maintain the computer hardware, systems software and other items
                           required for use of, or access to, the Solution, including those described in the Order and
                           Documentation (the “<strong>Specified Configuration</strong>”) and for updating the Specified
                           Configuration in accordance with OX.ms’s published updates. If not yet completed, Client
                           shall complete its procurement and installation of the Specified Configuration prior to the
                           scheduled start of implementation of the Solution. Client shall devote all equipment,
                           facilities, personnel and other resources reasonably necessary to begin using the Solution in
                           production on a timely basis as contemplated by this Agreement and satisfy any Client
                           requirements necessary for OX.ms to complete the professional services described in Section
                           10. OX.ms is not responsible for any delays or additional fees and costs associated with
                           Client’s failure to timely perform its obligations under this Section 4.</li>
                     </ol>
                     <p><strong> </strong></p>
                     <ol start="5">
                        <li><strong><u>A</u></strong><strong><u>cceptance</u></strong><strong>. </strong>If Client has
                           not accepted the Solution under Section 3(a) of the OST, notwithstanding any of the
                           provisions of Section 13.3(a)of the OST, the Acceptance Date shall be the first day on which
                           Client uses the Solution or part thereof in a live production Environment for the Business
                           Purpose.</li>
                     </ol>
                     <p><strong> </strong></p>
                     <ol start="6">
                        <li><strong><u>Transmission of Client Supplied Data. </u></strong>
                           <ul>
                              <li>Client shall transmit the Client Supplied Data (for the avoidance of doubt, including
                                 any third party data to be processed by the Solution) in accordance with the Scope of
                                 Use, using the network connectivity specified at Section 2 and in accordance with the
                                 format and schedule established by OX.ms to permit the timely processing of Client’s
                                 data.</li>
                           </ul>
                        </li>
                     </ol>
                     <p>&nbsp;</p>
                     <ul>
                        <li>Client shall maintain copies of all source data and current backup copies of all Client
                           Supplied Data, and OX.ms shall have no liability for any loss or damage caused by Client’s
                           failure to maintain copies.</li>
                     </ul>
                     <p><strong><u> </u></strong></p>
                     <ol start="7">
                        <li><u></u>
                           <ul>
                              <li>Client shall pay to OX.ms the fees stated in the Order in consideration for the
                                 Solution, in accordance with the payment terms stated on the Order. OX.ms shall invoice
                                 all other fees and/or disbursements as and when incurred. All invoices shall be sent to
                                 Client&#8217;s address for invoices stated on the Order. Except as otherwise specified
                                 on the Order, Client&#8217;s payments shall be due within thirty (30) days after the
                                 invoice date. A late payment fee at the rate of 4% above Bank of England’s base rate
                                 per year shall accrue on any amounts thirty (30) days overdue and unpaid by Client to
                                 OX.ms, except for Disputed Amounts. On an annual basis, OX.ms may increase recurring
                                 fees for the Solution or other products and services stated on the Order, including the
                                 Support Fees under Section 13.4 and Professional Services fees under Section 7.11, by
                                 the Price Index Change. OX.ms shall notify the Client no less than 30 days in advance
                                 of any implementation of an increase in recurring fees. All fees and other amounts paid
                                 by Client under this Agreement are non-refundable except: (a) as provided in Sections
                                 8.2<a href="#page2">c) </a>and 13.3(a)<a href="#page6">iii; </a>and (b) the portion of
                                 prepaid fees relating to support or hosting services for the period between the date of
                                 Client’s termination of such services and the end of the period for which such fee was
                                 pre-paid (but only to the extent early termination of such services is permitted in
                                 this Agreement).</li>
                           </ul>
                        </li>
                     </ol>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Termination Fees. </strong>In the event of termination by Client or OX.ms of an
                           Order prior to the normal expiration date of the Initial Term or then current Renewal Term
                           (as applicable), a Termination Fee shall be payable by the Client as specified below.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <h3>a) In the event either party terminates an Order in accordance with the provisions of Section
                        11.6, no Termination Fee shall be payable.</h3>
                     <h3>b) In the event of termination without cause by Client of this Order prior to the Acceptance
                        Date, the Termination Fee shall be the sum of fifty percent (50%) of the Annual Processing Fees
                        and the total Implementation Fee, minus any portion of the Implementation Fee already paid by
                        Client on or prior to the termination effective date.</h3>
                     <h3>c) In the event of termination without cause by Client of an Order on or after the Acceptance
                        Date during the Initial Term, the Termination Fee shall be calculated as the sum of fifty
                        percent (50%) of the Annual Processing Fees and Additional User Annual Fees (if any). The Client
                        agrees that the Termination Fee is a reasonable reflection of the OX.ms’s decommissioning costs.
                     </h3>
                     <h3>d) In the event of termination without cause by Client of an Order during the then current
                        Renewal Term, the Termination Fee shall be calculated as the sum of the Annual Processing Fee
                        and Additional User Annual Fees (if any) multiplied by the number of years (or part thereof) in
                        the relevant Renewal Term, minus the sum of any Annual Processing Fees and Additional User
                        Annual Fees already paid by Client in respect of the relevant Renewal Term on or prior to the
                        termination effective date.</h3>
                     <h3>e) Any Termination Fee will be paid by the Client within one (1) month of the Termination Date
                     </h3>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Legacy Release Maintenance Fee:</strong> Client shall pay an additional monthly fee
                           (the <strong>&#8220;Legacy Release Maintenance Fee&#8221;</strong>) of three point five
                           percent (3.5%) of the Annual Processing Fee per month or part thereof (as applicable) in
                           respect of any period for which Client requires the live production Environment to remain on
                           a Release that pre-dates the Supported Release by more than six (6) months from the general
                           release date of the Supported Release, except that no Legacy Release Maintenance Fee is
                           payable if circumstances under OX.ms’s control (including but not limited to material Errors
                           reported by Client in respect of the Supported Release and awaiting resolution by OX.ms)
                           would have a materially adverse effect on the performance of the Solution using the Supported
                           Release in a live production Environment.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Performance Upgrade Fee:</strong> In the event that (a) the performance of the
                           Solution is adversely affected by material changes in Client&#8217;s usage patterns of the
                           Solution, whether within the Scope of Use or beyond, or (b) Client requests (other than for
                           reasons as specified in the foregoing provision (a)) increased performance of the Solution
                           that, in OX.ms’s reasonable opinion, can be provided by way of additional computing capacity
                           in the relevant Environment; or (c) Client requests improvements to the performance of the
                           Solution while the circumstances under which the Legacy Release Fee is payable apply; or (d)
                           the performance of the Solution is adversely affected by any other circumstance outside
                           OX.ms’s control, the parties shall negotiate, acting reasonably, any fees in respect of
                           appropriate computing capacity upgrades (the <strong>&#8220;Performance Upgrade
                              Fee&#8221;</strong>).</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Other Terms: </strong>The payment frequency of any recurring fees under an Order
                           (including but not limited the Additional User Annual Fees) shall be quarterly, unless
                           otherwise specified.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Price Index and Percentage Increment: </strong>Annually capped at three percent (3%)
                           plus UK CPI.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong>Customer’s Purchase Order: </strong>The terms of the OST and an Order override any
                           terms or conditions as may be stipulated or referred to by Client in any purchase order. In
                           the event that Client wishes for OX.ms to specify a purchase order number, project code or
                           similar billing reference on any invoice, Client shall supply such reference promptly, prior
                           to the end of the calendar month to which the services rendered relate and in respect of
                           which either party expects for an invoice to be raised. Client may not withhold payment on
                           due invoices on account of any missing Client billing code or reference.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong>Third-Party Fees. </strong>The fees payable by Client to OX.ms do not include any
                           applicable royalties, costs, expenses and/or fees that may be imposed by the Third-Party
                           Providers for the Third-Party Data and Services provided in accordance with this Agreement.
                           Client shall be solely responsible for, and shall pay, all such fees to Third-Party Providers
                           as and when directed by OX.ms or by the Third-Party Providers.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li>The fees and other amounts payable by Client to OX.ms under this Agreement do not include
                           any taxes, value added taxes, duties, levies, fees or similar charges of any jurisdiction
                           (“<strong>Taxes</strong>”) that may be assessed or imposed in connection with the
                           transactions contemplated by this Agreement, excluding only taxes based upon OX.ms’s net
                           income. Client shall directly pay any such Taxes assessed against it, and Client shall
                           promptly reimburse OX.ms for any such Taxes payable or collectable by OX.ms. If any
                           withholding or deduction for or on account of any Taxes is required by law to be made from
                           any payment by Client to OX.ms, Client will: (a) promptly notify OX.ms; (b) withhold or
                           deduct such amount from the payment due to OX.ms and promptly (and in any event no later than
                           sixty (60) days following the date the applicable payment is due to OX.ms) pay that Tax to
                           the relevant government agency; and (c) promptly (and in any event no later than ninety (90)
                           days following the date the applicable payment is due to OX.ms) give OX.ms an official
                           receipt or other official document evidencing payment of the Tax in order to allow OX.ms to
                           recover tax credits from the relevant tax authorities.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Certain Remedies for Non-payment.</strong> If Client fails to pay to OX.ms, within
                           thirty (30) days after OX.ms makes written demand for the overdue amount, any overdue amount
                           payable under this Agreement (including any applicable late payment fee) that is not a
                           Disputed Amount, in addition to all other rights and remedies which OX.ms may have, OX.ms
                           may, in its sole discretion by serving a written notice to Client stating the suspension
                           date, suspend performance of any or all of its obligations under this Agreement (other than
                           Section 5). OX.ms shall have no liability for Client’s use of the Solution until all such
                           past-due amounts and any applicable reinstatement fees are paid in full.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Professional Services Fees</strong></li>
                     </ul>
                     <p>&nbsp;</p>
                     <h3>a) The rate per OX.ms Staff Day shall be per the table below plus VAT, excluding expenses and
                        applicable taxes. The Client and OX.ms may agree to invoice at a blended date rate based on a
                        defined set of tasks.</h3>
                     <p>&nbsp;</p>
                     <table>
                        <tbody>
                           <tr>
                              <td width="106"><strong>Role</strong></td>
                              <td width="120"><strong>Day Rate </strong><strong>plus VAT</strong>
                              </td>
                              <td><strong>Hourly Rate</strong><strong>plus VAT</strong>
                              </td>
                           </tr>
                           <tr>
                              <td width="106">Consultant</td>
                              <td width="120"> £ 850</td>
                              <td>£ 113</td>
                           </tr>
                           <tr>
                              <td width="106">Senior Consultant</td>
                              <td width="120"> £ 1,000</td>
                              <td>£ 133</td>
                           </tr>
                           <tr>
                              <td width="106">Lead Consultant, Project Manager</td>
                              <td width="120"> £ 1,100</td>
                              <td>£ 147</td>
                           </tr>
                           <tr>
                              <td width="106">Blended Day Rate</td>
                              <td width="120"> £ 1,000</td>
                              <td>£ 133</td>
                           </tr>
                        </tbody>
                     </table>
                     <p>&nbsp;</p>
                     <h3>b) 50% of travel time for on-site engagements (i.e. at Client’s premises or other location as
                        requested by Client) may optionally be chargeable at the applicable Professional Services rate
                        only to the extent that such on-site engagement is authorized by Client, in writing.</h3>
                     <h3>c) Only to the extent that work outside the Standard Working Hours has been specifically
                        authorized by Client, in writing, rates for Professional Services performed outside of Standard
                        Working Hours are calculated as follows: (a) Monday to Friday, excluding public holidays at the
                        regular place of work of the relevant OX.ms Personnel, at the rate specified in Section 7.11(b)
                        multiplied by one and a half (1.5); or (b) Saturday to Sunday and on public holidays at the
                        regular place of work of the relevant OX.ms Personnel, at the rate specified in Section 7.11(b)
                        multiplied by two (2). The rate specified in Section 7.11(b) apply for Professional Services
                        performed outside Standard Working Hours where such has not been specifically authorized by
                        Client.</h3>
                     <h3>d) With respect to any Professional Services engagement approved by Client in writing but
                        cancelled or postponed by Client without cause thereafter, Client shall reimburse any expenses
                        which OX.ms is irrevocably committed to incurring in relation to the engagement and which OX.ms
                        cannot avoid or mitigate by taking reasonable steps. In addition, if Client&#8217;s notice of
                        cancellation (or request for rescheduling to an unspecified future date) or postponement is
                        given less than ten (10) working days (at the place at which the Professional Services were to
                        be performed) prior to or after the scheduled commencement of the Professional Services, Client
                        shall pay a cancellation fee under this Order equal to fifty percent (50%) of the scheduled time
                        associated with such Professional Services engagement provided that such cancellation fee shall
                        not exceed an amount equal to the aggregate fees payable for twenty (20) days of Professional
                        Services with respect to each consultant who was scheduled to performed the Professional
                        Services being cancelled or postponed.</h3>
                     <h3>e) For the avoidance of any doubt, the terms set out in section 7.11 above shall equally apply
                        to the Initial Implementation Services being provided under an Order.</h3>
                     <p><strong><u> </u></strong></p>
                     <ol start="8">
                        <li><strong><u>Warranties, Covenants and Limitations.</u></strong>
                           <ul>
                              <li><strong>Compliance with Laws. </strong>ms shall comply with laws, enactments, orders
                                 and regulations applicable to it as the provider of services under this Agreement.
                                 Client shall comply with all laws, enactments, orders and regulations applicable to it
                                 as the recipient and user of services under this Agreement.</li>
                           </ul>
                        </li>
                     </ol>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>No Infringement. </strong>ms shall indemnify and defend Client against any loss
                           resulting from any third-party claim asserting that the Solution, as and when made available
                           to Client by OX.ms and when properly used for the purpose and in the manner specifically
                           authorised by this Agreement, infringes upon any Intellectual Property Rights of such third
                           party. OX.ms shall have no obligation under this Section 8.2 Client gives notice to OX.ms as
                           soon as possible (and in any event no later than within ten (10) days) after the date Client
                           first becoming aware of the any actual or threatened infringement claim (provided that later
                           notice shall relieve OX.ms of its liability and obligations under this Section 8.2 only to
                           the extent that OX.ms is prejudiced by such later notice); and (b) allows OX.ms to have sole
                           control of the conduct, defence or settlement of any such claim. Client may monitor the
                           conduct and process of any such claim, related litigation or proceeding at its own expense
                           (including the cost of using its own legal advisers of its choosing). The remedies provided
                           in this Section 8.2 are the sole remedies for a claim of infringement or misappropriation
                           hereunder. If any infringement claim referred to in this Section 8.2 is initiated by a third
                           party, or in OX.ms’s sole opinion is likely to be initiated, then OX.ms may at its option and
                           expense:</li>
                     </ul>
                     <p>&nbsp;</p>
                     <h3>a) (without accepting or admitting any alleged infringement by the Solution of any third-party
                        Intellectual Property Rights) modify or replace all or the allegedly infringing part of the
                        Solution so that the Solution is no longer capable of infringing the third-party Intellectual
                        Property Rights, provided that the functionality of the Solution does not change in any material
                        adverse respect; or</h3>
                     <h3>b) procure for Client the right to continue using the allegedly infringing part of the
                        Solution; or</h3>
                     <h3>c) remove the Solution in its entirety or the allegedly infringing part of the Solution, and:
                     </h3>
                     <p>&nbsp;</p>
                     <ol>
                        <li>if Client has paid a one-time upfront initial licence fee for the applicable Solution,
                           refund to Client the corresponding portion of the licence fee paid by Client to OX.ms for the
                           applicable Solution, less a reasonable rental charge equal to one-sixtieth (1/60) of the
                           initial licence fee for each month of use following the Order Effective Date; or</li>
                        <li>if Client is paying for the use of the Solution on a recurring basis, refund to Client the
                           corresponding portion of the unused recurring fee(s) paid by Client to OX.ms for the
                           applicable Solution, and in each such case as referred to in subsections (c) (i) and (ii)
                           above this Agreement shall terminate with respect to the Solution or part thereof so removed.
                        </li>
                     </ol>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Harmful Code. </strong>Using a recent version of a reputable virus-checking product
                           (to the extent commercially available at a reasonable cost), OX.ms will check the Solution,
                           as well as any systems used by OX.ms to deliver the Solution, for any Harmful Code and will
                           use commercially reasonable efforts to eliminate any such Harmful Code that OX.ms discovers.
                        </li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Exclusion for Unauthorised Actions. </strong>ms is not liable under any provision of
                           this Agreement for any performance problem, claim of infringement or other matter to the
                           extent attributable to any unauthorized or improper use or modification of the Solution by or
                           on behalf of Client, any unauthorized combination of the Solution with other software or
                           services (other than as specified in the Specified Configuration), any use of any version of
                           the Solution other than the Supported Release, a failure to subscribe to ongoing support
                           services if then offered for the Solution, any Third-Party Data or Services, any Third-Party
                           Software or Open Source Software (except as set forth in Sections <a href="#page3">8.11
                           </a>and <a href="#page3">8.12), </a>any wrongful act or omission by Client, its Affiliates or
                           its customers or any breach of this Agreement by Client.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Conditions of Use. </strong>To the extent that Third-Party Users are permitted to
                           have access to the Solution: (a) Client represents and warrants to OX.ms, its Third-Party
                           Providers, agents, successors and assigns and their respective employees (collectively, the
                           “<strong>Indemnified Group</strong>”) that agreements between it and Third-Party Users
                           expressly provide, and covenants that all such agreements will provide, that none of the
                           Indemnified Group have or will have any liability in any circumstances with respect to any
                           services offered by Client to the Third-Party Users (“<strong>Client Services</strong>”),
                           including with respect to the data and information provided to the Third-Party Users as part
                           of the Client Services; and (b) Client shall indemnify and defend the Indemnified Group from
                           and against any of the following: any and all claims, liabilities and obligations (including
                           reasonable lawyers’ fees) by any third party, including Third-Party Users, against the
                           Indemnified Group and arising out of Client&#8217;s or Third-Party Users use of the Solution,
                           Third-Party Data or the Documentation with respect to the Client Services.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Force Majeure. </strong>Neither party shall be liable for, nor shall either party be
                           considered in breach of this Agreement due to, any failure to perform its obligations under
                           this Agreement (other than its payment obligations, which shall be suspended only for so long
                           as the force majeure event renders Client unable by any means to transmit payments when due
                           hereunder) as a result of a cause beyond its control, including any act of God or a public
                           enemy or terrorist, act of any military, civil or regulatory authority, change in any law or
                           regulation, fire, flood, earthquake, storm, pandemic or other like event, theft or criminal
                           misconduct by unrelated third parties, disruption or outage of communications (including the
                           Internet or other networked environment), power or other utility, unavailability of supplies
                           or any other cause, whether similar or dissimilar to any of the foregoing, which could not
                           have been prevented by the non-performing party with reasonable care.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong> EXCEPT AS STATED IN SECTIONS </strong><strong>8</strong><strong>,
                           </strong><strong>10.5</strong><strong> AND </strong><strong>13.6</strong><strong>, THE
                              SOLUTION, DOCUMENTATION AND SERVICES ARE PROVIDED UNDER THIS AGREEMENT ARE “AS IS”, AND
                              ALL OTHER REPRESENTATIONS, WARRANTIES, TERMS OR CONDITIONS, ORAL OR WRITTEN, EXPRESS OR
                              IMPLIED, ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, QUALITY OF
                              INFORMATION, QUIET ENJOYMENT OR OTHERWISE (INCLUDING IMPLIED WARRANTIES, TERMS OR
                              CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE,
                              TITLE, NON-INTERFERENCE, OR NON-INFRINGEMENT) ARE, TO THE FULLEST EXTENT PERMITTED BY
                              APPLICABLE LAW, EXCLUDED FROM THIS AGREEMENT.</strong></li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Limitations Cap. EACH PARTY’S TOTAL AGGREGATE LIABILITY TO EACH ORDER PURSUANT TO
                              THIS AGREEMENT SHALL UNDER NO CIRCUMSTANCES EXCEED THE AGGREGATE AMOUNT OF FEES ACTUALLY
                              PAID BY CLIENT TO OX.ms UNDER THE RELEVANT ORDER WHICH IS THE SUBJECT MATTER OF THE CLAIM
                              DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE DATE OF THE EVENT THAT IS
                              THE BASIS FOR THE FIRST CLAIM UNDER THAT ORDER</strong><strong>.</strong></li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Consequential Damage Exclusion. UNDER NO CIRCUMSTANCES SHALL EITHER PARTY (OR ANY OF
                              ITS AFFILIATES PROVIDING OR RECEIVING THE SOLUTION, SERVICES OR OTHER SOFTWARE UNDER THIS
                              AGREEMENT) BE LIABLE TO THE OTHER OR ANY OTHER PERSON FOR LOSSES OR DAMAGES WHICH FALL
                              INTO ANY OF THE FOLLOWING CATEGORIES: (a) LOST REVENUES; (b) LOST PROFITS; (c) LOSS OF
                              BUSINESS; (d) TRADING LOSSES; OR (f) ANY INCIDENTAL, INDIRECT, EXEMPLARY, CONSEQUENTIAL,
                              SPECIAL OR PUNITIVE DAMAGES OF ANY KIND, INCLUDING ANY OF THE FOREGOING LOSSES OR DAMAGES
                              RESULTING FROM CLIENT’S USE OF THE SOLUTION OR SERVICES PROVIDED HEREUNDER, OR ARISING
                              FROM ANY BREACH OF THIS AGREEMENT OR ANY TERMINATION OF THIS AGREEMENT, WHETHER SUCH
                              LIABILITY IS ASSERTED ON THE BASIS OF CONTRACT, TORT (INCLUDING NEGLIGENCE OR STRICT
                              LIABILITY) OR OTHERWISE AND WHETHER OR NOT FORESEEABLE, EVEN IF THE RELEVANT PARTY HAS
                              BEEN ADVISED OR WAS AWARE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES. FOR PURPOSES OF
                              CLARIFICATION, THE FOLLOWING SHALL BE DEEMED “DIRECT DAMAGES” AS BETWEEN CLIENT AND OX.ms
                              FOR THE PURPOSES OF THIS AGREEMENT ANY AND ALL DAMAGES, INCLUDING CONSEQUENTIAL AND
                              SIMILAR DAMAGES, AWARDED TO A THIRD PARTY FOR WHICH INDEMNIFICATION IS PROVIDED BY A PARTY
                              UNDER SECTIONS </strong><a href="#page2"><strong>5</strong><strong>,</strong></a>
                           <strong>10.8</strong><strong> OR </strong><a
                              href="#page5"><strong>12.3</strong></a><strong>.</strong></li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong> THE LIMITATIONS AND EXCLUSIONS SET FORTH IN SECTIONS
                           </strong><strong>8.8</strong><strong> AND </strong><strong>8.9</strong><strong> SHALL NOT
                              APPLY TO: (a) BREACHES OF THE SCOPE OF USE; (b) FAILURE TO PAY FEES WHEN DUE; (c) DAMAGES
                              CAUSED BY EITHER PARTY’S FRAUD OR WILLFUL MISCONDUCT; (d) A PARTY’S LIABILITY FOR DEATH OR
                              PERSONAL INJURY DUE TO THAT PARTY’S NEGLIGENCE; OR (e) A PARTY’S LIABILITY FOR DAMAGES TO
                              THE EXTENT THAT SUCH LIMITATION OR EXCLUSION IS NOT PERMITTED BY APPLICABLE LAW. THE
                              LIMITATIONS SET FORTH IN SECTION 4.8 DO NOT APPLY TO CLAIMS FOR WHICH INDEMNIFICATION IS
                              PROVIDED BY A PARTY UNDER SECTIONS </strong><strong>8.5</strong><strong>,
                           </strong><strong>10.8</strong><strong> OR </strong><strong>12.3</strong><strong>.</strong>
                        </li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong>Third-Party Software. </strong>ms shall use reasonable efforts, but without
                           additional cost to OX.ms, to provide Client the benefit of all indemnities and warranties
                           granted to OX.ms by the licensor(s) of the Third-Party Software, to the extent possible, as
                           and if permitted by OX.ms’s agreement with the licensor of the Third-Party Software, and to
                           the extent such warranties and indemnities pertain to Client’s use of the Third-Party
                           Software hereunder. In the event of any defect in any Third-Party Software (in the form
                           delivered by OX.ms and when properly used for the purpose and in the manner specifically
                           authorised by this Agreement), OX.ms will use commercially reasonable efforts to replace or
                           correct the Third-Party Software without charge. If OX.ms complies with this provision, it
                           shall accept no further liability with respect to any loss resulting from or in connection
                           with any defect of, or caused by the use of any Third-Party Software.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong>Open Source Software Components. </strong>The Solution may utilise Open Source
                           Software, including that Open Source Software identified in the Documentation or on the
                           ongoing support services website for the Solution. Open Source Software is licensed under the
                           terms of the open source licence that accompanies, or is made available with, such Open
                           Source Software, including via a website designated by OX.ms. Nothing in this Agreement
                           limits Client’s rights under, or grants Client rights that supersede, the terms and
                           conditions of any applicable licence for such Open Source Software. Open Source Software
                           shall not be deemed to be part of the Solution under this Agreement and OX.ms shall have no
                           liability relating to such Open Source Software; provided, however, that OX.ms shall be
                           responsible for fixing Errors caused by such Open Source Software to the same extent as
                           OX.ms’s ongoing support obligations as set forth in Sections <a href="#page5">5 </a>and <a
                              href="#page6">13.4 </a>of this Agreement.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong>Open Negotiation. </strong>Client and OX.ms have freely and openly negotiated this
                           Agreement each with the benefit of appropriate legal advice, including the pricing, with the
                           knowledge that the liability of the parties is to be limited in accordance with the
                           provisions of this Agreement.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong>Other Limitations. </strong>The warranties and representations made by OX.ms in this
                           Agreement, and the obligations of OX.ms under this Agreement, are only given to Client and
                           not to its Affiliates, its customers or any other Persons. Under no circumstances shall any
                           Affiliate or customer of Client or any other Person be considered a third-party beneficiary
                           of this Agreement or otherwise entitled to any rights or remedies under this Agreement
                           (including any right to be consulted in connection with any variation or rescission of the
                           Agreement agreed between OX.ms and Client), even if such Affiliates, customers or other
                           Persons are provided access to the Solution or data maintained in the Solution via the
                           Internet or other networked environment. Except to the extent specified in an Order, OX.ms
                           shall not be deemed Client’s official record keeper for regulatory or other purposes and
                           shall have no obligation to retain any records or data on Client’s behalf after termination
                           or expiration of this Agreement.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Third Party Rights. </strong>A person who is not a party to this Agreement (third
                           party) has no right under the Contracts (Rights of Third Parties) Act 1999 to enforce any
                           term of this Agreement or any document referred to in it, but this does not affect any right
                           or remedy of any such third party which exists or is available apart from that Act.</li>
                     </ul>
                     <p><strong><u> </u></strong></p>
                     <ol start="9">
                        <li><strong><u>Confidentiality, Security, Ownership and Use
                                 Restrictions</u></strong><strong>.</strong>
                           <ul>
                              <li>The party receiving Confidential Information (“<strong>Receiving Party</strong>”) of
                                 the other (“<strong>Disclosing Party</strong>”) shall not, and shall cause its
                                 Authorised Recipients not to, use Confidential Information for any purpose except as
                                 necessary to implement, perform or enforce this Agreement. Receiving Party will use the
                                 same reasonable efforts as it uses to protect its own proprietary information and data
                                 (but in any event not less than a reasonable standard of care) to: (a) keep all
                                 Confidential Information of Disclosing Party strictly confidential; and (b) not
                                 disclose the Confidential Information of Disclosing Party to anyone other than its
                                 Authorised Recipients. Prior to disclosing the Confidential Information to its
                                 Authorised Recipients, Receiving Party shall inform them of the confidential nature of
                                 the Confidential Information and require them to abide by the terms of this Agreement.
                                 Receiving Party will promptly notify Disclosing Party if Receiving Party discovers any
                                 improper use or disclosure of Confidential Information and will promptly commence all
                                 reasonable efforts to investigate and correct the causes of such improper use or
                                 disclosure. If Receiving Party believes the Confidential Information must be disclosed
                                 under applicable law, Receiving Party may do so provided that, to the extent permitted
                                 by law, the Disclosing Party is given a reasonable opportunity to contest such
                                 disclosure or obtain a protective order. Confidential Information of either party
                                 disclosed prior to the Order Effective Date shall, with effect from that Order
                                 Effective Date, be subject to the provisions of this Section 9.</li>
                           </ul>
                        </li>
                     </ol>
                     <p>&nbsp;</p>
                     <ul>
                        <li></li>
                     </ul>
                     <h3>a) OX.ms will implement commercially reasonable administrative, technical and physical
                        safeguards designed to: (i) ensure the security and confidentiality of Client Data; (ii) protect
                        against any anticipated threats or hazards to the security or integrity of Client Data; and
                        (iii) protect against unauthorized access to or use of Client Data. OX.ms will review and test
                        such safeguards on no less than an annual basis.</h3>
                     <h3>b) If Client makes the Solution or data maintained by the Solution accessible through the
                        Internet or other networked environment, Client shall be solely responsible for all aspects of
                        Internet use, and shall maintain, in connection with the operation or use of the Solution,
                        adequate technical and procedural access controls and system security requirements and devices,
                        necessary for data privacy, confidentiality, integrity, authorisation, authentication and
                        non-repudiation and virus detection and eradication.</h3>
                     <h3>c) The Client is solely responsible for the security of its own IT infrastructure, used to
                        access the Solution, and for the acts and inactions of its authorised users. To the extent that
                        Third-Party Users are permitted to have access to the Solution, Client shall maintain agreements
                        with such Third-Party Users that adequately protect the confidentiality and Intellectual
                        Property Rights of OX.ms in the Solution and Documentation, and disclaim any liability or
                        responsibility of OX.ms with respect to such Third-Party Users.</h3>
                     <ul>
                        <li><strong>Client Data and Data Protection</strong></li>
                     </ul>
                     <ul>
                        <li>The Client shall own all right, title and interest in and to all of the Client Data and
                           shall have sole responsibility for the legality, reliability, integrity, accuracy and quality
                           of the Client Data inputted or provided by the Client at the time of such inputting or
                           provision.</li>
                        <li>If the Client, during this Agreement, is unable to retrieve or access any of the Client
                           Data, the Client must immediately report the same to OX.ms. ms will promptly investigate the
                           cause of the same. Client agrees to provide reasonable assistance to OX.ms during such
                           investigation.</li>
                        <li>Client is a Data Controller (as defined under the Data Protection Act 2018
                           (&#8220;<strong>DPA</strong>&#8220;) all applicable data protection and privacy legislation
                           in force from time to time in the UK and in the EU, including the General Data Protection
                           Regulation ((EU) 2016/679 (“<strong>GDPR</strong>”); the DPA; the Privacy and Electronic
                           Communications Directive 2002/58/EC (as updated by Directive 2009/136/EC) and the Privacy and
                           Electronic Communications Regulations 2003 (SI 2003/2426) as amended (“<strong>Data
                              Protection Legislation</strong>”).</li>
                        <li>The parties acknowledge that OX.ms acts as a Data Processor (as defined under Data
                           Protection Legislation) and may be Processing Personal Data of the Third Party End Users and
                           that the terms of this section apply to the Processing of all such Personal Data.</li>
                        <li>As a Data Processor under this Agreement, OX.ms shall:</li>
                     </ul>
                     <ol>
                        <li>only act upon the instructions of Client in relation to the Client Data and at all times
                           comply with its obligations under the DPA and relevant Data Protection Legislation including
                           but not limited to, taking appropriate technical and organisational measures against
                           unauthorised or unlawful Processing of Personal Data and against accidental loss or
                           destruction of, or damage to, Personal Data. When considering what measure is appropriate,
                           OX.ms shall have regard to the state of good practice, technical development and the cost of
                           implementing any measures to ensure a level of security appropriate to the harm that might
                           result from such unauthorised or unlawful Processing or accidental loss or destruction, and
                           to the nature of the data to be protected;</li>
                        <li>provide to Client a written description of the technical and organisational methods employed
                           by OX.ms for Processing Personal Data, including maintaining and making available to the
                           Client from time to time records (in writing in a human readable format) of OX.ms’s and any
                           sub-contractor’s Processing activities and all other information required by Client to ensure
                           its compliance with the requirements of the Data Protection Legislation;</li>
                     </ol>
                     <ul>
                        <li>ensure the reliability of its employees, agents and sub-contractors by vetting such
                           personnel appropriately who have access to Personal Data;</li>
                     </ul>
                     <ol>
                        <li>not transfer Personal Data contained in the Client Data outside the European Economic Area
                           without Client’s prior written consent;</li>
                        <li>not transfer or provide access to any Client Data to any person without the express written
                           consent of Client;</li>
                        <li>if OX.ms is permitted by Client to transfer any Client Data to a third party, such transfer
                           shall be made by OX.ms imposing the same data protection obligations as set out in this
                           Agreement, including a requirement that the third party does not Process any Personal Data
                           contained in the Client Data except on the Client’s instructions;</li>
                     </ol>
                     <ul>
                        <li>permit the Client or a third party appointed by it (subject to reasonable and appropriate
                           confidentiality undertakings), to inspect and audit, OX.ms’s data processing activities (and
                           or those of its sub-contractors) and comply with all reasonable requests or directions by the
                           Client to enable the Client to verify and or procure that OX.ms is in full compliance with
                           its obligations under this section, upon reasonable prior notice of such audit and/or
                           inspection. The Client shall be responsible for the costs of such audit but If any audit or
                           inspection reveals a material non-compliance by OX.ms with its obligations under Data
                           Protection Legislation, OX.ms shall pay the reasonable costs of the Client or its mandated
                           auditors, of the audit or inspection;</li>
                        <li>carry out regular security audits as may be required by the British Standards Institute to
                           ensure conformity and shall, on request, provide summaries of the reports of such audits to
                           Client. If such audits show any non-conformity, OX.ms shall remedy such breaches of standards
                           of security promptly at its own expense;</li>
                     </ul>
                     <ol>
                        <li>reasonably assist Client in responding to any requests from Third Party End Users and in
                           ensuring compliance with all obligations under the Data Protection Legislation with respect
                           to security, breach notification, impact assessments and consultations with supervisory
                           authorities or regulators and with the implementation and performance of any legal rights a
                           Data Subject requests;</li>
                        <li>restrict access to Client Data to such persons who need access to meet OX.ms’s obligations
                           under this Order and limit any access to any Personal Data contained therein to Personal Data
                           that is strictly necessary;</li>
                        <li>at the written directions of the Client, delete or return any Client Data and copies thereof
                           to the Client on termination of this Agreement;</li>
                     </ol>
                     <ul>
                        <li>notify the Client immediately if it becomes aware of any actual, threatened or potential
                           breach of this section or the security of the Client Data and, if a breach of security
                           occurs, immediately take all reasonable steps to:
                           <ul>
                              <li>remedy such breach or protect any Personal Data against any breach or threat;</li>
                              <li>prevent an equivalent breach in the future; and</li>
                              <li>as soon as reasonably practicable provide to the Client details (using such reporting
                                 mechanisms as may be specified by the Client from time to time) of any actual,
                                 potential or threatened breach and the steps taken by OX.ms in respect of such breach.
                              </li>
                           </ul>
                        </li>
                        <li>ms shall notify the Client immediately and in all cases within 24 (twenty four) hours if it
                           receives:</li>
                     </ul>
                     <ol>
                        <li>a request from a Data Subject to have access to that person’s Personal Data; or</li>
                        <li>a complaint or request relating to OX.ms’s or the Client’s obligations under the DPA or any
                           Data Protection Legislation; or</li>
                     </ol>
                     <ul>
                        <li>any notice or communication which relates directly or indirectly to the Processing of
                           Personal Data or to either OX.ms’s or the Client’s compliance with the DPA or any Data
                           Protection Legislation, or the protection principles set out therein.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li>ms shall defend, indemnify and hold harmless the Client, its officers, directors and
                           employees, promptly on first request, against all actions, costs, expenses, claims,
                           proceedings and demands which may be brought or made against the Client, its officers,
                           directors and employees, for breach of statutory duty under any Data Protection Legislation
                           which arises from the actions or Processing of Personal Data relating to or in connection
                           with this Order by OX.ms, its employees, agents or sub-contractors and or a breach of the
                           provisions of this section save to the extent that such breach occurred because OX.ms was
                           acting on the Client’s instructions or otherwise caused by or contributed to by the Client.
                        </li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li>ms shall (and shall ensure that all of OX.ms’s employees, agents and sub-contractors) not do
                           any act that puts the Client in breach of its obligations under Data Protection Legislation.
                        </li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>ms Solution Details. </strong>The OX.ms Solution Details are trade secrets and
                           proprietary property of OX.ms or its licensors, having great commercial value to OX.ms or its
                           licensors. Title to all OX.ms Solution Details and all related intellectual property and
                           other ownership rights shall be and remain exclusively with OX.ms or its licensors, even with
                           respect to such items that were created by OX.ms specifically for or on behalf of Client.
                           OX.ms and its Affiliates may freely use Feedback without attribution or the need for OX.ms,
                           its Affiliates or any third party to pay Client or any third party any royalties or other
                           fees of any kind. This Agreement is not an agreement of sale, and no intellectual property or
                           other ownership rights to any OX.ms Solution Details are transferred to Client by virtue of
                           this Agreement. All copies of OX.ms Solution Details in Client&#8217;s possession shall be
                           deemed to be used by the Client under a licence (and held by Client on trust for OX.ms) will
                           during the term of this Agreement.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Use Restrictions. </strong>Except to the extent specifically authorised by this
                           Agreement, Client shall not, shall not attempt to, and shall not permit any other Person
                           under its reasonable control to: (a) use any OX.ms Solution Detail for any purpose, at any
                           location or in any manner not specifically authorised by this Agreement; (b) make or retain
                           any Copy of any OX.ms Solution Detail; (c) create or recreate the source code for the
                           Solution, or re-engineer, reverse engineer, decompile or disassemble the Solution except to
                           the extent specifically permitted by applicable law; (d) modify, adapt, translate or create
                           derivative works based upon the Solution or Documentation, or combine or merge any part of
                           the Solution or Documentation with or into any other software or documentation except to the
                           extent specifically permitted by applicable law; (e) refer to, disclose or otherwise use any
                           OX.ms Solution Detail as part of any effort either: (i) to develop a program having any
                           functional attributes, visual expressions or other features similar to those of the Solution;
                           or (ii) to compete with OX.ms; (f) remove, erase or tamper with any copyright or other
                           proprietary notice printed or stamped on, affixed to, or encoded or recorded in any OX.ms
                           Solution Detail, or fail to preserve all copyright and other proprietary notices in any Copy
                           of any OX.ms Solution Detail made by Client; (g) sell, market, license, sublicense,
                           distribute or otherwise grant to any Person, including any outsourcer, vendor,
                           sub-contractor, consultant or partner, any right to use any OX.ms Solution Detail or allow
                           such other Person to use or have access to any OX.ms Solution Detail, whether on
                           Client&#8217;s behalf or otherwise; or (h) use the Solution to conduct any type of
                           application service provider, service bureau or time-sharing operation or to provide remote
                           processing, network processing, network telecommunications or similar services to any Person,
                           whether on a fee basis or otherwise.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Notice and Remedy of Breaches. </strong>Each party shall promptly give notice to the
                           other of any actual or suspected breach by it of any of the provisions of this Section 9,
                           whether or not intentional, and the breaching party shall, at its expense, take all steps
                           reasonably requested by the other party to prevent or remedy the breach.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li>Each party acknowledges that any breach of any of the provisions of this Section 16.11 may
                           result in irreparable injury to the other for which money damages would not adequately
                           compensate. If there is a breach, then the injured party shall be entitled, in addition to
                           all other rights and remedies which it may have, to have a decree of specific performance or
                           an injunction issued by any competent court, requiring the breach to be cured or enjoining
                           all Persons involved from continuing the breach.</li>
                     </ul>
                     <ol start="10">
                        <li><strong><u>Professional Services</u></strong><strong>.</strong>
                           <ul>
                              <li><strong>Professional Services. </strong>An Order may identify certain Professional
                                 Services. In addition, at Client’s reasonable request and subject to the availability
                                 of OX.ms’s personnel, OX.ms shall provide to Client other Professional Services
                                 requested by Client in accordance with a fully executed Order setting out the scope and
                                 other specifications of such Professional Services Order.</li>
                           </ul>
                        </li>
                     </ol>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Professional Services Fees. </strong>Client shall pay to OX.ms the professional
                           services fees stated on the Order in accordance with Section 11 (Professional Services Fees).
                           In each case where professional services fees are not specified on the Order, then the fees
                           for such services shall be based upon OX.ms’s then standard professional services fee rates.
                           OX.ms’s standard professional services fee rates in effect on the Order Effective Date are
                           stated on the Order and are subject to increase in the ordinary course of business.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Expense Reimbursements. </strong>Client shall reimburse OX.ms for reasonable travel,
                           living and other out-of-pocket expenses incurred by OX.ms personnel in connection with all
                           services, including, but not limited to, Professional Services and maintenance and support
                           rendered by OX.ms. Reimbursable expenses shall be incurred by OX.ms personnel in accordance
                           with OX.ms’s then current per diem travel policy, a copy of which will be furnished at
                           Client’s request. OX.ms shall invoice Client for reimbursement of these expenses on a monthly
                           basis, as incurred.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Cooperation and Access to Facilities, Data and Employees. </strong>To the extent
                           reasonably necessary for OX.ms to perform its obligations under this Agreement, Client shall
                           provide to OX.ms access to Client’s location site, equipment, data and employees, and shall
                           otherwise cooperate with OX.ms in its performance hereunder, all as reasonably necessary for
                           OX.ms to perform its obligations under this Agreement.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Professional Services Warranty. </strong>ms warrants to Client that Professional
                           Services will be performed in a good and workmanlike manner by qualified personnel, subject
                           to Section 10.4. OX.ms shall have no liability under this Section <a href="#page4">10.5
                           </a>unless, within thirty (30) days after the actual date of the performance (or delivery) of
                           a particular Professional Service, OX.ms receives notice from Client describing the breach of
                           this warranty, together with adequate supporting documentation and data. Upon receipt of any
                           such notice, OX.ms’s only obligation under this Section <a href="#page4">10.5 </a>is to
                           remedy the breach and re-perform the particular Professional Services affected as soon as
                           reasonably practical at no additional charge.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Compliance with Client Policies. </strong>While OX.ms personnel are performing
                           services at Client&#8217;s site, OX.ms will ensure that such personnel comply with Client’s
                           reasonable security procedures and site policies that are generally applicable to Client’s
                           other suppliers providing similar services and that have been provided to OX.ms in writing
                           and in advance. Client shall promptly reimburse OX.ms for any out-of-pocket costs incurred in
                           complying with such procedures and policies.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Work Product. </strong>In relation to any results of the Professional Services
                           provided under an Order (“<strong>Work Product</strong>”):</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li>ms and its licensors (as applicable) shall retain ownership of all Intellectual Property
                           Rights in the Work Product, excluding the Contributed Materials;</li>
                        <li>ms grants the Client, or shall procure the direct grant to the Client of, a fully paid-up,
                           worldwide, non-exclusive, royalty-free, perpetual licence to copy and modify the Work Product
                           (excluding the Contributed Materials) for the purpose of receiving and using the Work Product
                           for its internal business purposes only;</li>
                        <li>the Client shall not sub-license, assign or otherwise transfer the rights granted in Section
                           7.2.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Contributed Material. </strong>In the process of ms’s performing Professional
                           Services, Client may, from time to time, provide OX.ms with designs, plans, or
                           specifications, improvements, works or other material for inclusion in, or making
                           modifications to, the Solution, the Documentation or any other deliverable, including any
                           Work Product (“<strong>Contributed Material</strong>”). Client grants to OX.ms a
                           nonexclusive, irrevocable, perpetual, transferable right, without the payment of any
                           royalties or other compensation of any kind and without the right of attribution, for OX.ms,
                           OX.ms’s Affiliates and OX.ms’s licensees to make, use, sell and create derivative works of
                           the Contributed Material. Client shall indemnify and defend OX.ms against any third-party
                           claim asserting that Client does not have the full legal right (including any moral rights)
                           to grant to OX.ms such rights to use the Contributed Material and/or that the Contributed
                           Material in the form delivered to OX.ms infringes upon any third party’s Intellectual
                           Property Rights.</li>
                     </ul>
                     <p><strong><u> </u></strong></p>
                     <ol start="11">
                        <li><strong><u>Term and Termination</u></strong><strong>.</strong>
                           <ul>
                              <li><strong><u>Order Term</u></strong><strong>. </strong>The Order may state an initial
                                 term for the use of the Solution (“<strong>Initial Term</strong>”) and may state
                                 renewal terms (each a “<strong>Renewal Term</strong>”). “<strong>Order Term</strong>”
                                 means the Initial Term together with any Renewal Terms.</li>
                           </ul>
                        </li>
                     </ol>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong><u>Initial Term.</u></strong> The period commencing on the Order Effective Date and
                           ending on the first (1<sup>st</sup>) anniversary of the last day of the calendar month into
                           which the Acceptance Date falls, unless an Order is terminated earlier in accordance with
                           this Section.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Renewal Term</strong>. Unless stated otherwise in the Order, a Renewal Term for the
                           Order is two years.</li>
                     </ul>
                     <p><strong><u> </u></strong></p>
                     <ul>
                        <li><strong><u>Without Cause</u></strong>. Either Party may terminate an Order without cause at
                           any time during the Order Term, with effect from the end of the applicable calendar quarter
                           by giving no less than thirty (30) days&#8217; written notice, subject to payment of the
                           Termination Fee, as defined and specified in Section 2 below.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong><u>Renewal Rights.</u></strong> ms shall notify Client in writing at least sixty
                           (60) days prior to the expiry of the Initial Term or then-current Renewal Term, as the case
                           may be, of the upcoming expiry date. In the event that both parties desire to enter into a
                           Renewal Term, the parties shall negotiate, acting reasonably, the terms applicable to such
                           Renewal Term including the term of the Renewal Term, and execute an amendment to this Order
                           accordingly. For the avoidance of doubt, in the event the parties have not, for whatever
                           reason, executed by the date of expiry of the Initial Term or then-current Renewal Term an
                           amendment to this Order governing such Renewal Term, then the Order will automatically
                           terminate and OX.ms’s obligations for provision of the Solution under this Order shall cease.
                        </li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><u></u> Either party may terminate this Agreement with <strong>immediate</strong> effect by
                           giving notice of termination to the other party:</li>
                     </ul>
                     <ul>
                        <li>if the other party breaches any of its material obligations (or is repeatedly in breach of
                           the terms of this Agreement) under this Agreement and does not cure the breach within thirty
                           (30) days after receiving notice describing the breach in reasonable detail; or</li>
                        <li>if the other party suspends, or threatens to suspend, payment of its debts or is unable to
                           pay its debts as they fall due or admits inability to pay its debts or (being a company or
                           limited liability partnership) is deemed unable to pay its debts within the meaning of
                           section 123 of the Insolvency Act 1986;</li>
                        <li>if the other party commences negotiations with all or any class of its creditors with a view
                           to rescheduling any of its debts, or makes a proposal for or enters into any compromise or
                           arrangement with its creditors;</li>
                        <li>a petition is filed, a notice is given, a resolution is passed, or an order is made, for or
                           in connection with the winding up of that other party other than for the sole purpose of a
                           scheme for a solvent amalgamation of that other party with one or more other companies or the
                           solvent reconstruction of that other party;</li>
                        <li>an application is made to court, or an order is made, for the appointment of an
                           administrator, or if a notice of intention to appoint an administrator is given or if an
                           administrator is appointed, over the other party;</li>
                        <li>the holder of a qualifying floating charge over the assets of that other party has become
                           entitled to appoint or has appointed an administrative receiver;</li>
                        <li>a person becomes entitled to appoint a receiver over the assets of the other party or a
                           receiver is appointed over the assets of the other party;</li>
                        <li>a creditor or encumbrancer of the other party attaches or takes possession of, or a
                           distress, execution, sequestration or other such process is levied or enforced on or sued
                           against, the whole or any part of the other party&#8217;s assets and such attachment or
                           process is not discharged within 14 days;</li>
                        <li>any event occurs, or proceeding is taken, with respect to the other party in any
                           jurisdiction to which it is subject that has an effect equivalent or similar to any of the
                           events mentioned in section 6(d) to (k) (inclusive);</li>
                        <li>the other party suspends or ceases, or threatens to suspend or cease, carrying on all or a
                           substantial part of its business; or</li>
                        <li>there is a change of control of the other party (within the meaning of section 1124 of the
                           Corporation Tax Act 2010).</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong><u>Effect of Termination.</u></strong> The provisions of Sections 7, 8, 9, 7, 10.8,
                           11.7 and 20 shall survive any termination of this Agreement, whether under this Section 11 or
                           otherwise. Client shall be liable for all payments due to OX.ms for the period ending on the
                           date of termination. Upon a termination of this Agreement, whether under this Section 11,
                           upon the expiration or termination of an Order Term or otherwise, Client shall: (a)
                           discontinue all use of the affected Solution, Documentation and any Work Product; (b)
                           promptly return to OX.ms all copies of the affected Solution, Documentation, any Work Product
                           and any other affected OX.ms Solution Details then in Client&#8217;s possession; and (c) give
                           notice to OX.ms certifying that all copies of such items have been permanently deleted.</li>
                     </ul>
                     <p><strong><u> </u></strong></p>
                     <p><strong><u> </u></strong></p>
                     <p><strong><u> </u></strong></p>
                     <ol start="12">
                        <li><strong><u>Terms Applicable To SaaS, BPaaS, ASP and Hosting</u></strong><strong>.</strong>
                        </li>
                     </ol>
                     <p>The following provisions in this Section 12 apply solely to Hosting Services and to Orders for
                        an ASP Solution, BPaaS Solution or SaaS Solution.</p>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>SaaS, BPaaS and Hosting. </strong>ms shall provide the Hosting Services and/or
                           access to the BPaaS Solution or SaaS Solution, as described and for the term specified on the
                           Order.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Passwords and Solution Access. </strong>If OX.ms provides Client or its Authorised
                           Users with unique access codes to access the Solution (each, a “<strong>Password</strong>”),
                           Client shall hold any such Passwords in strict confidence and shall not assign, share, misuse
                           or abuse the Passwords or attempt to render ineffective the password protection of the
                           Solution. If Client suspects or learns that a Password is being used to gain unauthorised
                           access to the Solution, Client will immediately notify OX.ms so that it can change, or assist
                           Client in changing, the applicable Password. To the extent the Solution is within OX.ms’s
                           network, OX.ms may suspend access to the Solution without advance notice if OX.ms reasonably
                           believes the Solution is being used or accessed in an unauthorised, illegal or disruptive
                           manner, provided that OX.ms will promptly notify Client of any such event. OX.ms shall not be
                           liable for any loss resulting from or in connection with Client’s breach of its obligations
                           under this Section 2.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong>Client Data.</strong></li>
                     </ul>
                     <ul>
                        <li>Client shall supply, or cause to be supplied, all Client Supplied Data. Client shall
                           transmit the Client Supplied Data to OX.ms by communications link or in another manner
                           described on the Order. As between OX.ms and Client, Client shall be responsible for ensuring
                           that the Client Supplied Data is accurate and complete. Client represents and warrants to
                           OX.ms that Client has the full legal right for Client and OX.ms, its affiliates and agents to
                           use the Client Supplied Data for processing hereunder. Client shall indemnify and defend
                           OX.ms, its affiliates and agents against any third-party claim alleging breach of the
                           foregoing warranty and representation.</li>
                        <li>Within thirty (30) days after termination of Hosting Services or of an Order for a Solution
                           (including BPaaS Solution or SaaS Solution), Client shall give OX.ms an instruction notice
                           regarding the disposition of any tapes, data, files and other property belonging to Client
                           and then in OX.ms’s possession. To the extent practicable and at Client&#8217;s expense after
                           receipt of such notice, OX.ms shall use commercially reasonable efforts to comply with the
                           notice, including converting the data on the Solution to machine-readable form. OX.ms may
                           retain such property subject to and until OX.ms receives all payments due to OX.ms under that
                           Order. If Client fails to give that notice within thirty (30) days after such termination,
                           then OX.ms may dispose of such property in a commercially reasonable manner without any
                           further recourse to Client.</li>
                        <li>Client grants to OX.ms a non-exclusive, non-transferable, irrevocable licence to use the
                           Client Supplied Data on an aggregated and de-identified basis, in accordance with the terms
                           of this Section 12.3(c) (“<strong>Anonymised Client Data</strong>”). In order to improve
                           OX.ms’s product and service offerings for its clients, OX.ms may maintain a database of
                           information residing on the Solution, including the Anonymised Client Data, (such information
                           and the Anonymised Client Data, together, the “<strong>Development Data</strong>”). OX.ms and
                           its affiliates may use and distribute such Development Data in an aggregated and
                           de-identified format, including (without limitation): (i) as a part of the development,
                           distribution and licensing of any OX.ms product or service offering and (ii) sharing such
                           Development Data with selected third parties.</li>
                        <li>Regulatory Access. To the extent permitted by law, each party will notify the other promptly
                           of any formal request by an authorised governmental agency or regulator to examine Client
                           Data or other records, if any, regarding Client that are maintained in OX.ms facilities under
                           this Agreement. In the event of such a request, OX.ms shall make such Client Data or other
                           records, if any, reasonably available for examination and audit by the governmental agency or
                           regulatory authority that has jurisdiction over Client’s business and Client agrees to
                           reimburse OX.ms for the reasonable out-of-pocket costs OX.ms incurs and time OX.ms spends in
                           doing so.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li>OX.ms shall provide to Client the ongoing support services as described in the Order.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Data Backup and Disaster Recovery. </strong>If the Solution maintains a database
                           then, unless otherwise stated on the Order:</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li>ms shall electronically backup the Client Data in accordance with the backup cycle defined
                           in the Order (and if no backup cycle is defined, at reasonable intervals); and</li>
                        <li>ms shall maintain a disaster recovery plan which includes a procedure for the restoration of
                           Client’s production environment at an alternate facility in the event of a disaster. OX.ms’s
                           disaster recovery plan shall be tested by the Client at least once each calendar year during
                           the term of the relevant Order and Client agrees to reimburse OX.ms for the reasonable costs
                           OX.ms incurs and time OX.ms spends in doing so.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Interruption to Solution. </strong>From time to time, OX.ms shall be entitled (at
                           its discretion, without incurring liability for so doing) to interrupt the Solution to: (a)
                           perform repairs and other maintenance and install enhancements on OX.ms’s equipment, software
                           and/or other systems that are required for the provision of the Solution; or (b) make
                           adjustments to its infrastructure (including, for example, in relation to resources shared by
                           its other clients) and thereby cause a disruption in the provision of the Solution. Except in
                           the case of emergency repairs, maintenance or adjustments, OX.ms will: (i) give Client
                           reasonable prior notice of the interruption; (ii) limit such interruptions to outside of
                           OX.ms’s normal business hours; and (iii) use commercially reasonable efforts to minimise the
                           impact of the interruption.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Harmful Code. </strong>Using a recent version of a reputable virus-checking product
                           (to the extent commercially available), Client will check the Specified Configuration for
                           Harmful Code and ensure no Harmful Code is introduced by its end users or from its systems
                           into any systems used in the Solution and will use commercially reasonable efforts to
                           eliminate any such Harmful Code that either Client or OX.ms discovers.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong>Volume Increases. </strong>Client shall give notice to OX.ms whenever Client intends
                           to materially increase the volume of data to be processed on the Solution. Any such increase
                           that results in an increase beyond the Scope of Use requires an additional executed Order.
                        </li>
                     </ul>
                     <p><strong><u> </u></strong></p>
                     <ol start="13">
                        <li><strong><u>Terms Applicable to Software Licenses</u></strong><strong>.</strong></li>
                     </ol>
                     <p>The following provisions in this Section 13 apply solely to an Order that provides the right for
                        Client to install the Solution at the facility identified on the Order.</p>
                     <p>&nbsp;</p>
                     <ul>
                        <li>Except as otherwise provided in an Order, OX.ms grants to Client a non-transferable,
                           non-exclusive, term licence to use the Solution in accordance with this Agreement and the
                           Scope of Use. The Solution shall be installed only in object code form and only at the
                           Designated Location(s). Client may, subject to Section 20.3, use or access the Solution at or
                           from Client locations worldwide. Client may copy and use the Solution installed at the
                           Designated Location(s) for inactive back-up and disaster recovery purposes. Client may copy
                           the Documentation to the extent reasonably necessary for use of the Solution under this
                           Agreement.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Initial Activation. </strong>ms shall deliver to Client the initial Copies of the
                           Solution stated on the Order by supplying such initial Copies by electronic delivery only.
                           Electronic delivery is deemed effective at the time OX.ms provides Client with access to
                           download the Solution. The date of such delivery shall be referred to as the
                           “<strong>Delivery Date</strong>.” Client shall activate the Solution for access from the
                           Designated Location(s) on or before the Scheduled Installation Date. If a Scheduled
                           Installation Date is not specified on the Order, Client shall activate the Solution within
                           thirty (30) days after the Delivery Date.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <p><strong> </strong></p>
                     <ul>
                        <li>If the Solution is specified in the Order as being subject to acceptance testing, then the
                           following terms apply:</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li>Client is deemed to have accepted the Solution at the end of the Acceptance Period
                           (regardless of the date when Client installs the Solution), unless during that period, the
                           Solution fails to perform in accordance with the Documentation in some material respect that
                           precludes acceptance of the Solution by Client, and, by the end of the Acceptance Period,
                           Client gives notice of non-acceptance to OX.ms describing the material failure in reasonable
                           detail and explaining why the failure precludes acceptance of the Solution by Client. If
                           Client gives a proper notice of non-acceptance to OX.ms, then:</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ol>
                        <li>OX.ms shall investigate the reported failure. Client shall provide to OX.ms reasonably
                           detailed documentation and explanation, together with underlying data, to substantiate the
                           failure and to assist OX.ms in its efforts to diagnose and correct the failure.</li>
                        <li>If there was no material failure to perform or the failure to perform was not attributable
                           to a defect in the Solution or an act or omission of OX.ms, then OX.ms shall give notice to
                           Client explaining that determination in reasonable detail, and Client shall be deemed to have
                           accepted the Solution as of the date of OX.ms’s notice.</li>
                     </ol>
                     <ul>
                        <li>If there was a material failure to perform that was attributable to a defect in the Solution
                           or an act or omission of OX.ms, and if OX.ms cannot correct the failure within thirty (30)
                           days (or such longer period as may be reasonable under the circumstances) after receipt of
                           Client&#8217;s notice of non-acceptance, then Client shall promptly return to OX.ms all
                           copies of the Solution and Documentation and any other items delivered to Client by OX.ms,
                           and as Client&#8217;s sole and exclusive remedy, OX.ms shall then refund to Client the
                           licence fee paid by Client. If, within such period, OX.ms does correct the failure, then
                           OX.ms shall give notice to Client certifying that the failure has been corrected, and another
                           thirty (30) day acceptance period shall begin from the date of such notice in accordance with
                           this Section 9.3.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li>If Client has not accepted the Solution under Section <a href="#page6">3(a), </a>then
                           notwithstanding any of the provisions of Section 13.3(a)Client shall be deemed to have
                           accepted the Solution on the first day that Client uses the Solution in a live production
                           environment or as Client’s system of record.</li>
                        <li>The first date on which Client is deemed to have accepted the Solution under Section 3(a) or
                           under Section13.3(b) is referred to as the <strong>“Acceptance Date”</strong>.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Support</strong>. Beginning on the Order Effective Date and continuing for the
                           duration of the initial support term set forth on the Order <strong>(“Initial Support
                              Term”</strong>), OX.ms shall provide the ongoing support services described in the Order
                           and Client shall pay to OX.ms the Support Fees. Upon expiration of the Initial Support Term,
                           the ongoing support services shall automatically renew and Client shall be obligated to pay
                           the Support Fees for additional annual support periods (each a <strong>“Renewal Support
                              Term”</strong>) unless and until the termination of this Agreement.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong><u>Support Termination. </u></strong>On a time and materials basis, at then current
                           day Rates<u>,</u> ms will provide resources to assist in extracting Client Data. The Client
                           will need to request this Support Termination within thirty (30) days of Termination Date.
                           OX.ms will use reasonable efforts to assist the Client in the extraction of Client Data. Both
                           parties will use reasonable efforts to agree the method and format of the Client Data and
                           will confirm if any additional costs, fees or expenses payable by the Client in respect to
                           the Client Data extraction and transfer.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Software Warranty. </strong>ms warrants to Client that, for a period of thirty (30)
                           days from the Scheduled Installation Date, the Solution (as delivered to Client by OX.ms and
                           when properly used for the purpose and in the manner specifically authorised by this
                           Agreement), will perform as described in the Documentation in all material respects. OX.ms’s
                           sole obligation and liability under this warranty is to comply with the provisions of Section
                           13.4of this Agreement.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong>Escrow of Source Code. </strong>If stated on the Order, then within a reasonable
                           time after the first day that Client uses the Solution in a live production environment,
                           OX.ms shall arrange, for the benefit of Client (and Client shall execute any documents
                           required to receive such benefit), an escrow of the Solution source code with an escrow agent
                           nominated by OX.ms (“<strong>Escrow Agent</strong>”), in accordance with OX.ms’s standard
                           escrow agreement (“<strong>Escrow Agreement</strong>”). The source code for the Solution may
                           be released from escrow, in accordance with the terms and conditions specified in the Escrow
                           Agreement. Client shall pay all fees due to the Escrow Agent arising in connection with or as
                           a result of such escrow. Client acknowledges that the Escrow Agreement shall be deemed null
                           and void and of no effect and that neither OX.ms nor the Escrow Agent shall be under any
                           further obligation with respect to such escrow arrangement from whichever is the earlier of:
                           (a) the date Client fails to pay the escrow fees when due; or (b) the Support Termination
                           Date.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Remote Access of Installed Software. </strong>Provided that OX.ms performs such
                           services in accordance with the confidentiality provisions of this Agreement, Client shall
                           permit OX.ms, at OX.ms’s option, to remotely access the Solution installed at the Designated
                           Location(s) for the purpose of providing ongoing support services to Client under Section <a
                              href="#page6">4 </a>and otherwise implementing the purposes of this Agreement. In remotely
                           accessing such Solution, OX.ms will comply with Client’s reasonable security procedures and
                           company policies that have been provided to OX.ms in writing. Client shall promptly reimburse
                           OX.ms for any out-of-pocket costs incurred in complying with such procedures and policies.
                        </li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li>Client acknowledges that it is the best judge of the value and importance of the data held
                           on Client’s systems and that Client shall be solely responsible for maintaining secure and
                           complete back-up copies of all data that Client processes using the Solution, which data will
                           be backed-up on not less than a daily basis and which will be readily available on machines
                           controlled by Client to facilitate the prompt restoration of such data in the event of any
                           loss of or damage to it. OX.ms shall have no liability for any loss or damage caused by
                           Client&#8217;s failure to maintain such backed-up copies.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li>At OX.ms’s expense and upon written request with reasonable notice, Client will permit
                           OX.ms, its personnel or its outside auditors to enter the relevant Client locations during
                           normal business hours and audit the number of copies of the Solution and Documentation in
                           Client’s possession and information pertaining to Client’s compliance with this Agreement.
                           Such audits shall not occur more than once in any twelve (12) month period (unless OX.ms
                           believes, in good faith, that there has been a breach of this Agreement by Client) and shall
                           be performed in a manner not to disrupt Client&#8217;s business and operations and will
                           respect the confidentiality of Client, its suppliers and customers. Client will, in a timely
                           manner, reasonably cooperate with the auditors and provide the auditors all assistance as
                           they may reasonably request in connection with the audit. Client may require auditors acting
                           on behalf of OX.ms to execute reasonable confidentiality agreements and comply with Client’s
                           reasonable security requirements, but this requirement will not apply to OX.ms’s internal
                           auditors otherwise bound by the confidentiality conditions of this Agreement.</li>
                     </ul>
                     <p><strong><u> </u></strong></p>
                     <ol start="14">
                        <li><strong><u>Third-Party Data and Services</u></strong><strong>.</strong>
                           <ul>
                              <li><strong>Ancillary Services. </strong>ms shall provide the Third-Party Data and
                                 Services originating with a Third-Party Provider, as described and for the term
                                 specified on the Order.</li>
                           </ul>
                        </li>
                     </ol>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Third-Party Providers. </strong>Client acknowledges and agrees that the Third-Party
                           Data and Services provided under this Agreement contain information obtained, selected and
                           consolidated by the Third-Party Providers under the authority of the Third-Party Providers,
                           that Client&#8217;s use of the Third-Party Data and Services is authorised and regulated by
                           the Third-Party Providers and further that the Third-Party Providers may require to be
                           provided with information and data about Client and the Third-Party Users in connection with
                           their provision of Third-Party Data and Services. Client also acknowledges that the
                           Third-Party Providers may modify the Third-Party Data and Services, or discontinue
                           availability of Third-Party Data and Services or modify the rules concerning the availability
                           of and the applicable fees, costs and expenses for any of the Third-Party Data and Services,
                           in which case neither OX.ms nor the Third-Party Providers shall be held responsible for such
                           modification and discontinuance. Any changes required by the Third-Party Providers shall be
                           made a part of this Agreement by OX.ms’s notice of any such changes to Client. For a thirty
                           (30) day period after receiving such notice from OX.ms, Client may reject such changes and
                           terminate the affected Third-Party Data and Service by notice to OX.ms. If such notice is not
                           received by OX.ms within such thirty (30) day period, this Agreement shall be modified by
                           such changes, and shall remain in full force and effect.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Third-Party Provider Notices.</strong></li>
                     </ul>
                     <ul>
                        <li>Client acknowledges that OX.ms is required to display certain notices and agreements and to
                           report certain data related to Client&#8217;s use of the Third-Party Data and Services to the
                           Third-Party Providers. To enable OX.ms to meet its obligation in this regard, Client shall
                           inform OX.ms, in writing, whenever its display of notices or utilisation of any information
                           derived from the Third-Party Data and Services changes.</li>
                        <li>The notices and agreements required to be displayed as of the Order Effective Date are set
                           forth on the Order. Client shall display all such notices and agreements in the exact manner
                           specified by OX.ms or a Third-Party Provider in writing or as set forth in this Agreement.
                           Client acknowledges and agrees that the number and content of such notices, as well as other
                           terms and conditions, may be changed from time to time by the Third-Party Providers. Upon
                           written notification of such changes by OX.ms to Client, the Order shall be deemed modified
                           to include such changes and Client will update such notices.</li>
                        <li>Client shall comply with all applicable laws and obtain all necessary consents from any
                           person, including its employees and the Third-Party Users and their respective employees, if
                           any, regarding the collection, use and distribution to OX.ms of any information or data
                           regarding any Third-Party User and to the use by Client and the Third-Party Users of the
                           Third-Party Data and Services for the purposes set forth in this Agreement. The information
                           and data may include personal and other information about Client, its employees, the
                           Third-Party Users and their employees, including their use of the Third-Party Data and
                           Services. OX.ms may use this information and data to carry out its obligations under this
                           Agreement, including the provision of such information to the Third-Party Providers under
                           this Agreement or for its own internal purposes.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ol start="15">
                        <li><strong><u>Support &amp; Maintenance Services</u></strong></li>
                     </ol>
                     <p><u> </u></p>
                     <ul>
                        <li><strong>GENERAL </strong><strong>SUPPORT</strong><strong> SERVICES TERMS:</strong></li>
                     </ul>
                     <ul>
                        <li>ms shall provide the support services as described in this Section 15 to Client in relation
                           to the Solution in English.</li>
                        <li>ms shall use commercially reasonable efforts to answer questions, correct Errors in the
                           Solution or Documentation or provide a work-around solution in accordance with the procedures
                           detailed in Section 15.3 and OX.ms’s then current Client Services procedures, as amended from
                           time to time.</li>
                        <li>Any support services provided to Client other than those described in this Section 3 below
                           are subject to the Change Control Procedure and may be charged for by OX.ms at the standard
                           Professional Services terms set out in Section 7.</li>
                        <li>Client Services shall have access to the Solution and Client Data as may be required, in
                           OX.ms’s sole opinion, to provide the support services and conform to its obligations under
                           the Agreement. Client shall provide Client Services with access to any additional information
                           as OX.ms may reasonably require to fulfil its obligations hereunder.</li>
                        <li>The Client Services help desk shall be available to Client during the Normal Support Hours.
                        </li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>SAAS OPERATIONS SERVICES:</strong></li>
                     </ul>
                     <ul>
                        <li>ms shall provide Client remote access to the Solution during the Service Hours or during
                           such additional time periods as the parties may agree from time to time, in writing, to
                           support exceptional activities.</li>
                        <li>ms shall procure that the Solution adheres to the then current availability, security, data
                           privacy and audit compliance standards provided by Microsoft for Microsoft Azure, a summary
                           of which can be found, as at the Order Effective Date, at <a
                              href="https://azure.microsoft.com/en-gb/support/trust-center/">https://azure.microsoft.com/en-gb/support/trust-center/</a>
                           and <a
                              href="https://www.microsoft.com/en-us/privacystatement/OnlineServices/Default.aspx">https://www.microsoft.com/en-us/privacystatement/OnlineServices/Default.aspx</a>
                           .</li>
                        <li>ms shall procure that Client Data stored within the Solution is backed up no less than once
                           each day Monday to Friday, and available for restoration during the Normal Support Hours on
                           Client’s request.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>SUPPORT SERVICES WORKFLOW:</strong></li>
                     </ul>
                     <ul>
                        <li>Before contacting Client Services, Client is required to research any issue thoroughly in
                           the Specifications and to reasonably try to resolve any issue themselves. Notwithstanding the
                           foregoing, in relation to actual or suspected Errors of a severity code of “Critical” (as
                           defined in Section (d) the parties acknowledge that Client personnel may contact Client
                           Services.</li>
                        <li>Provided that the issue could not be resolved by Client&#8217;s own personnel, an
                           appropriate Client representative may contact Client Services to raise an Action Request as
                           follows: <a href="#_ftn1" name="_ftnref1">[1]</a> Client users with appropriate training will
                           capture details within the Zygote support workflow system and it will be routed to the OX.ms
                           Client Services first line support team. The Client representative should make sure that
                           relevant details relating to the issue are available, including:</li>
                        <li><em>Has the problem been encountered before?</em></li>
                        <li><em>If so, can the log number or cause be identified?</em></li>
                        <li><em>Are there any specific customizations to the product that OX.ms needs to be aware
                              of?</em></li>
                        <li><em>Can the problem be recreated?</em></li>
                        <li><em>What was the sequence of events leading up to the problem?</em></li>
                        <li><em>Severity of problem or urgency of the question. What impact is it having?</em></li>
                        <li>Client Services will review the information above and record:</li>
                        <li><em>Name of contact</em></li>
                        <li><em>Location</em></li>
                        <li><em>Description of the problem or question, including Error messages, log files, audit
                              information and screen shots (as applicable)</em></li>
                        <li><em>Exceptional conditions which exist</em></li>
                        <li><em>Environmental issues and version of the Solution</em></li>
                        <li>Client Services will assign the issue a unique problem log number in Zygote’s internal
                           support workflow system. This problem log number should be used in all further communication
                           with OX.ms relating to the Action Request. Further, Client Services will use the information
                           supplied by Client to assess the nature of the issue, and if the issue is due to a suspected
                           Error, will determine, in consultation with Client, an Error severity code and take the
                           appropriate action in accordance with the table immediately below.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <table>
                        <tbody>
                           <tr>
                              <td><strong>Severity Code</strong></td>
                              <td><strong>Description</strong></td>
                              <td><strong>Response Time</strong></td>
                              <td></td>
                           </tr>
                           <tr>
                              <td rowspan="2">Critical</td>
                              <td rowspan="2">A suspected Error that causes complete unavailability of the Solution in
                                 the live production Environment or so severely limits functionality or performance that
                                 day-to-day business cannot feasibly be continued.</td>
                              <td>Start investigating within thirty (30) minutes of issue being logged.</td>
                              <td></td>
                           </tr>
                           <tr>
                              <td>Work continuously on the issue until a resolution or workaround is provided, the issue
                                 is reclassified as an Error with a lower severity code (in which case the provisions
                                 related to such new severity code shall apply) or as a non-Error, or deferred to a
                                 later time only by mutual agreement between the Parties.</td>
                              <td></td>
                           </tr>
                           <tr>
                              <td rowspan="3">High</td>
                              <td rowspan="3">A suspected Error that causes complete unavailability of a key Solution
                                 function in the live production environment, or limits functionality or performance of
                                 a key Solution function but still allows day-to-day business to be carried out.</td>
                              <td>Start investigating within two (2) hours of issue being logged.</td>
                              <td></td>
                           </tr>
                           <tr>
                              <td rowspan="2">Work continuously on the issue until a resolution or workaround is
                                 provided, the issue is reclassified as an Error with a lower severity code (in which
                                 case the provisions related to such new severity code shall apply) or as a non-Error,
                                 or deferred to a later time only by mutual agreement between the parties.</td>
                              <td></td>
                           </tr>
                           <tr>
                              <td></td>
                           </tr>
                           <tr>
                              <td rowspan="2">Medium</td>
                              <td rowspan="2">A suspected Error that causes reduction of availability or performance of
                                 the Solution, without notable effects on the functionality of the overall Solution.
                              </td>
                              <td rowspan="2">Start investigating within eight (8) hours of issue being logged.</td>
                              <td></td>
                           </tr>
                           <tr>
                              <td></td>
                           </tr>
                           <tr>
                              <td rowspan="2">Low</td>
                              <td rowspan="2">A suspected Error that does not impede functionality but is inconvenient.
                                 Questions on the use or functionality of the Solution.</td>
                              <td rowspan="2">Start investigating within one week of issue being logged.</td>
                              <td></td>
                           </tr>
                           <tr>
                              <td></td>
                           </tr>
                           <tr>
                              <td rowspan="2">Trivial or cosmetic</td>
                              <td rowspan="2">A trivial or cosmetic issue</td>
                              <td rowspan="2">Start investigating as and when all higher severity issues have been
                                 addressed.</td>
                              <td></td>
                           </tr>
                           <tr>
                              <td></td>
                           </tr>
                        </tbody>
                     </table>
                     <p>&nbsp;</p>
                     <ul>
                        <li>Response and activity times and escalation timeframes in the table above refer to time
                           periods during Normal Support Hours. Any issues relating to a non-production environment will
                           be assigned a severity code no higher than &#8216;Medium&#8217;. In the event of a dispute
                           relating to the handling of an Action Request or Error, the parties shall adhere to the
                           dispute management procedure as set out in Section 8.</li>
                        <li>Once the issue has been addressed by a workaround or resolution or has been classified by
                           Client Services as a non-Error, Client Services will inform the relevant Client
                           representative accordingly. If Client has not responded, in writing, within fourteen (14)
                           days as to whether the feedback provided by Client Services is satisfactory, OX.ms may close
                           the Action Request and OX.ms shall take no further action.</li>
                        <li>The implementation by OX.ms of solutions to Errors in a Supported Release is not considered
                           a chargeable activity. Notwithstanding the foregoing, in case OX.ms reasonably demonstrates
                           to Client that the Solution performs as described in the Documentation, or that the cause
                           giving rise to the Action Request reported by Client is due to circumstances under Client’s
                           control (including due to third party products supplied by or on behalf of Client), and
                           Client requests continued OX.ms assistance, then, except in the event that the root cause of
                           the problem is found to be an issue under OX.ms’s sole control, OX.ms reserves the right to
                           charge Client in accordance with the standard Professional Services terms as set out in
                           Section 10 for OX.ms’s reasonable efforts expended in any further tracking, investigating or
                           resolving the cause raised in the Action Request.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong>EXCLUSIONS: </strong></li>
                     </ul>
                     <ul>
                        <li>ms shall not be obliged to make corrections to the Solution in the event that any Errors
                           therein arise out of any of the circumstances set out in this Section 15.4 below:
                           <ul>
                              <li>any fault in the Specified Configuration or no-compliance of the Client’s environment
                                 with the Specified Configuration;</li>
                              <li>any modifications made by Client (or a third party acting on Client&#8217;s behalf) to
                                 the Solution without OX.ms’s written permission;</li>
                              <li>the failure of Client to implement recommendations in respect of or solutions to
                                 faults previously advised by OX.ms;</li>
                              <li>any changes in Client-supplied third-party product interacting with the Solution;</li>
                              <li>any breach by Client of any of its obligations under this Agreement;</li>
                              <li>any negligent use of the Solution by Client; or</li>
                              <li>the use by Client of any Release of the Solution other than a Supported Release and
                                 for which Client is not paying a Legacy Release Maintenance Fee.</li>
                           </ul>
                        </li>
                     </ul>
                     <p>If any revisions to the Solution are requested by Client due to any of the circumstances set out
                        in this Section 15.4 above, such requests shall be processed in accordance with the Change
                        Control Procedure and OX.ms shall use reasonable efforts to provide such revisions (as part of a
                        Maintenance Update Package, where appropriate), subject to resource availability. Subject to an
                        agreed Change, OX.ms may charge for any such work effort at the standard Professional Services
                        terms set out in Section 10.</p>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>CLIENT-SUPPLIED EXTENSIONS AND THIRD-PARTY PRODUCTS:</strong></li>
                     </ul>
                     <ul>
                        <li>ms shall not have any support or other responsibility for data entry, or completeness,
                           validity, integrity, adequacy or accuracy of data as well as any queries, reports, interfaces
                           or applications, or the results thereof, that Client has developed or written itself, whether
                           or not such Solution extensions were generated by Client using facilities provided by OX.ms
                           as part of the Solution.</li>
                        <li>ms shall not have any support obligations or other responsibility for the completeness,
                           validity, integrity, adequacy or accuracy of any third-party software, data or services.
                           Client shall or shall cause any third-party provider of third party software, data and
                           services supplied by or on behalf of Client to give OX.ms reasonable prior notice of any
                           intended changes to the format or content of third party software, data and services and the
                           parties shall agree any resulting Changes as may be required to the Solution in order to
                           ensure continued operation of the Solution as intended, all in accordance with the Change
                           Control Procedure.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>DATA RETENTION. </strong>Throughout the Order Term, OX.ms shall retain backups of
                           the Solution production environment database (such backups to be taken by OX.ms at a
                           scheduled time during nightly batch processing, as reasonably determined by OX.ms) for no
                           less than 50 years.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>MAINTENANCE UPDATE PACKAGES AND NEW RELEASES:</strong></li>
                     </ul>
                     <ul>
                        <li>At its discretion, OX.ms may make available to Client from time to time Maintenance Update
                           Packages, for reasons of Error correction or for any other reasons OX.ms deems appropriate.
                           OX.ms shall give Client written notice, in accordance with the Change Control Procedure,
                           before any such Maintenance Update Package is installed on a designated test Environment, and
                           will allow Client reasonable time to test such Maintenance Update Package prior to promoting
                           it to the live production Environment, taking into consideration the urgency of the Error
                           corrections provided with the revision and Client’s needs on a case by case basis.</li>
                        <li>From time to time, OX.ms may issue a new Release together with any supporting documentation
                           where appropriate. Releases of the Solution are issued at OX.ms’s sole discretion if and when
                           OX.ms deems it appropriate, taking into account the requirements of the Zygote customer base.
                           Client acknowledges and accepts that modifications, revisions and updates to the Solution
                           provided in a Release may result in changes in the form, data input/output formats, timing,
                           rules of operation or other features of the Solution, and that Client (or OX.ms at
                           Client&#8217;s instruction and cost) is responsible for ensuring compatibility with the
                           Supported Release of any (i) interfaces between Client systems and the Solution; (ii)
                           portions of the Solution Client may have modified; and (iii) third party software (other than
                           the Third Party Software), data and services interacting with the Solution.</li>
                        <li>ms shall provide each Supported Release at no additional charge to Client, provided that the
                           Scope of Use does not change in conjunction with a new Release and save that Client shall pay
                           Professional Services fees, on a time and material basis, in respect of any Professional
                           Services the parties may agree in relation to the upgrade of any (pre-existing and
                           additional) configurations to the Solution made by OX.ms at Client’s request, and subject to
                           a fully executed statement of work in respect of any project to upgrade to such Supported
                           Release specifying the relevant Professional Services, applicable fees and any other impact
                           on the Solution provided under this Order (if applicable).</li>
                        <li>Unless otherwise agreed between the parties, in writing, OX.ms shall make any new Supported
                           Release available to Client in a test Environment for a period of seven (7) days (or such
                           other Period as the Parties may agree on a case-by-case basis, in writing) (the
                           &#8220;Upgrade Acceptance Period&#8221;). Client shall be deemed to have accepted the
                           Supported Release at the end of the Upgrade Acceptance Period, unless any Errors in such new
                           Supported Release which Client has reported to OX.ms during the Upgrade Acceptance Period
                           remain outstanding with OX.ms for resolution. If Client reports Errors during the Upgrade
                           Acceptance Period, and such Errors remain outstanding on the last day of the original Upgrade
                           Acceptance Period, the Upgrade Acceptance Period shall automatically be extended to the date
                           on which OX.ms has remedied all such Errors.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>SERVICE MANAGEMENT REPORTING AND GOVERNANCE:</strong></li>
                     </ul>
                     <ul>
                        <li>ms shall make available to Client such access to the OX.ms support workflow management
                           system in order for Client to generate service management reports showing information on
                           Action Requests, Change Requests, Errors, scope of planned Maintenance Update Packages and
                           other information.</li>
                        <li>ms and Client shall arrange formal monthly service review meetings or conference calls to
                           review service quality and performance. Unless otherwise agreed between the parties,
                           preparation of the minutes of such meetings or conference calls shall be the responsibility
                           of OX.ms.</li>
                        <li>In the event of a dispute in relation to the Initial Implementation Services or day-to-day
                           operation of the Solution, the parties shall escalate the issue through the joint management
                           levels as per the Order, with the dispute elevated to the next management level if unresolved
                           no later than seven (7) days after the issue has been raised by either party, in writing, to
                           the attention of the relevant lower joint management level (as identified in the Order of
                           levels 1-2). Each party shall notify the other party of (and may update from time to time)
                           its named representative(s) at each level identified in the Order, in writing. Only in the
                           event that no agreement can be reached within twenty (20) days at level 2 in the Order, a
                           party may bring a claim.</li>
                     </ul>
                     <ol start="16">
                        <li><strong><u>Service Levels.</u></strong></li>
                     </ol>
                     <p><strong><u> </u></strong></p>
                     <ul>
                        <li>ms shall provide the Solution (including the deliverables and Solution functionalities as
                           described in the Project Specifications for the Initial Implementation Services) in
                           compliance with the Specifications and in accordance with the Service Levels specified in
                           this Section 16 commencing from the Acceptance Date (or such later date as the parties may
                           agree, in writing):</li>
                     </ul>
                     <p><strong><u> </u></strong></p>
                     <ul>
                        <li><strong>SOLUTION AVAILABILITY</strong>. OX.ms shall monitor availability of the Solution.
                           The Up Time Service Level shall be no less than 99% during the Service Hours. Up Time is
                           measured within Microsoft Azure using Solution monitoring utilities that initiate “Monitor
                           Transaction Requests” (or “MTRs”) at intervals of no less than five (5) minutes.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <h3>(i) Up Time for the Solution is calculated as follows:</h3>
                     <table>
                        <tbody>
                           <tr>
                              <td colspan="2"><strong>Up Time Calculation Method</strong></td>
                           </tr>
                           <tr>
                              <td>Solution Up Time in % =</td>
                              <td>Number of successful MTRs / Number of expected MTRs x 100</td>
                           </tr>
                           <tr>
                              <td>Number of expected MTRs =</td>
                              <td>Number of possible MTRs &#8211; Number of MTRs during Maintenance Window</td>
                           </tr>
                           <tr>
                              <td>Number of possible MTRs =</td>
                              <td>Number of minutes in Service Period / MTR frequency in minutes</td>
                           </tr>
                           <tr>
                              <td>Number of MTRs during Maintenance Window =</td>
                              <td>Maintenance Window in minutes / MTR frequency in minutes</td>
                           </tr>
                        </tbody>
                     </table>
                     <h3>(ii) Example for illustration purposes:</h3>
                     <table>
                        <tbody>
                           <tr>
                              <td colspan="2" width="305"><strong>Solution Uptime – MM YYYY</strong></td>
                           </tr>
                           <tr>
                              <td width="220">Possible MTRs</td>
                              <td width="86">8,928</td>
                           </tr>
                           <tr>
                              <td width="220">Maintenance Window MTRs</td>
                              <td width="86">(108)</td>
                           </tr>
                           <tr>
                              <td width="220">Expected MTRs</td>
                              <td width="86">8,820</td>
                           </tr>
                           <tr>
                              <td width="220">Actually Recorded Successful MTRs</td>
                              <td width="86">8,814</td>
                           </tr>
                           <tr>
                              <td width="220"></td>
                              <td width="86"></td>
                           </tr>
                           <tr>
                              <td colspan="2" width="305"><strong>MTR Frequency: Every 5 minutes</strong></td>
                           </tr>
                           <tr>
                              <td width="220">Solution Up Time</td>
                              <td width="86">99.93%</td>
                           </tr>
                        </tbody>
                     </table>
                     <p>&nbsp;</p>
                     <ul>
                        <li>Up Time during the Service Period shall be expressed as a percentage value, rounded to two
                           (2) decimals.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li>In the event that OX.ms’s Up Time monitoring utilities did not record Up Time as specified
                           for a certain period (e.g. due to a fault or configuration error in the monitoring utility,
                           but not the Solution as such), and OX.ms can provide other reasonable evidence that the
                           Solution was in fact available, then the Solution module Up Time as indicated by such other
                           evidence shall substitute for MTR log data and may be used by OX.ms to compute the Solution
                           availability Service Level.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li>DOWNTIME EVENT SERVICE LEVEL. “Solution Downtime” shall be measured using MTRs as described
                           above.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li>MAINTENANCE WINDOW SERVICE LEVELS: The Service Level with respect to Solution Downtime
                           during Service Hours associated with any Maintenance Windows is defined as follows but may be
                           modified on a case-by-case basis with prior agreement between OX.ms and Client:</li>
                     </ul>
                     <p><strong> </strong></p>
                     <table>
                        <tbody>
                           <tr>
                              <td colspan="2" width="306"><strong>Maintenance Windows Metrics</strong></td>
                           </tr>
                           <tr>
                              <td width="217">OX.ms notification</td>
                              <td width="90">24 hours</td>
                           </tr>
                           <tr>
                              <td width="217">Maximum number of planned maintenance events</td>
                              <td width="90">24 per annum</td>
                           </tr>
                           <tr>
                              <td width="217">Maximum time period per planned maintenance event</td>
                              <td width="90">8 hours</td>
                           </tr>
                        </tbody>
                     </table>
                     <h2></h2>
                     <ul>
                        <li>ms will seek to schedule the Maintenance Windows outside the Service Hours or in any case
                           during a time where Client access to the Solution is expected to be minimal and always
                           following prior consultation with Client.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li>ms and Client may agree in writing to specify an exceptional circumstance requiring
                           additional Downtime periods to be defined.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li>Unplanned maintenance includes corrective actions to be taken by OX.ms to recover a failing
                           component of Microsoft Azure. OX.ms shall keep Client informed of such activity and the
                           expected Downtime.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li>ms shall always prioritize Solution availability over fault finding, to minimize Downtime.
                        </li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong>REMEDY</strong>. In the event that the Solution availability relating to the live
                           production environment fails to meet the Up Time Service Level (as specified in Section 16)
                           in any Service Period, Client may request and OX.ms shall apply a Service Credit in respect
                           of each Service Period in which a Service Level non-compliance occurred. The amount of such
                           Service Credit in respect of each such Service Period shall be determined by multiplying the
                           percentage value shown in the column headed <em>“Service Credit”</em> in the table below with
                           the amount that is one twelfth (1/12<sup>th</sup>) of the Annual Processing Fees.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <table>
                        <tbody>
                           <tr>
                              <td><strong>Solution Availability</strong></td>
                              <td><strong>Service Credit</strong></td>
                           </tr>
                           <tr>
                              <td>&lt; 99.0% and <u>&gt;</u> 98.5%</td>
                              <td>1%</td>
                           </tr>
                           <tr>
                              <td>&lt; 98.5% and <u>&gt;</u> 95.0%</td>
                              <td>3%</td>
                           </tr>
                           <tr>
                              <td>&lt; 95.0%</td>
                              <td>5%</td>
                           </tr>
                        </tbody>
                     </table>
                     <h2></h2>
                     <ul>
                        <li>In the event the Downtime Event Service Level is not met in any Service Period, Client may
                           request and OX.ms shall apply a Service Credit in respect of each Service Period in which a
                           Service Level non-compliance occurred. The amount of such Service Credit in respect of each
                           such Service Period shall be determined by multiplying the percentage value shown in the
                           column headed <em>“Service Credit”</em> in the table below with the amount that is one
                           twelfth (1/12<sup>th</sup>) of the Annual Processing Fees.</li>
                     </ul>
                     <table>
                        <tbody>
                           <tr>
                              <td><strong>Downtime Events &gt; 4 hours in Service Period</strong></td>
                              <td><strong>Service Credit</strong></td>
                           </tr>
                           <tr>
                              <td>&gt; zero</td>
                              <td>3%</td>
                           </tr>
                        </tbody>
                     </table>
                     <h2></h2>
                     <ul>
                        <li>The maximum aggregate Service Credit in respect of any Service Period is five percent (5%)
                           of 1/12th of the Annual Processing Fees.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li>The Service Credits specified in this Section 11 shall be Client’s sole and exclusive remedy
                           for OX.ms’s failure to meet the Service Levels.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li>ms shall have no obligation to provide any Service Credits other than by way of credit
                           against the next invoice for Annual Processing Fees due to Client.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li>LIMITING EVENTS: Notwithstanding any other provision of the Agreement, OX.ms shall not be
                           responsible for a failure to carry out any of its obligations under this Order, (including
                           its obligations to provide a Service Credit for any failure to meet a Service Level) if OX.ms
                           can reasonably demonstrate that the failure was caused by:</li>
                     </ul>
                     <h4>a. (i) failure by Client to comply with any material terms of the Agreement; (ii) failure by
                        Client to ensure that only appropriately trained personnel of Client or trained and authorized
                        personnel of its sub-contractor(s) interact with the Solution; or (iii) negligent or wilful acts
                        or omissions of the Client or its employees, contractors or agents;</h4>
                     <h2>b. failure by Client to provide access, to the extent reasonably necessary and at OX.ms’s
                        request with reasonable notice, to a Site and/or to assistance by suitable Client or third party
                        provider personnel;</h2>
                     <h2>c. Internet service quality impairments or communications links being modified or altered in
                        any way other than by OX.ms or service providers appointed by OX.ms;</h2>
                     <h2>d. use of the Solution outside of the Scope of Use;</h2>
                     <h2>e. adverse impact due to the defects in the Specified Configuration or non-compliance of Client
                        Data or Client-supplied third-party data and services with the Specifications;</h2>
                     <h2>f. adverse impact due to material changes made to the usage of the Solution by Client where
                        OX.ms has not agreed to such changes in advance and in writing;</h2>
                     <h2>g. adverse impact on Solution availability that occurs as a result of: (i) changes made by
                        Client to applications or data; (ii) Client retaining <em>ROOT</em> or <em>ADMIN</em>
                        privileges; (iii) Client requiring OX.ms to maintain, or continue to run, unsupported software
                        or hardware releases; (iv) Client refusing OX.ms maintenance changes for any reason, (v) Client
                        retaining access control to any devices comprised within the equipment for which OX.ms is
                        providing the Solution; or (vi) corrupt files or data supplied by Client;</h2>
                     <h2>h. Client supplying data for processing by the Solution on any day in excess of the volume
                        limits specified in Section 17; or</h2>
                     <h2>i. any other event or circumstance beyond OX.ms’s reasonable control.</h2>
                     <p>&nbsp;</p>
                     <p>For the avoidance of any doubt, any Maintenance Windows or any other agreed Downtime shall not
                        be taken into account when measuring compliance with the Service Levels.</p>
                     <p><strong> </strong></p>
                     <ol start="17">
                        <li><strong>Specified Configuration.</strong>
                           <ul>
                              <li><strong>Minimum Configuration</strong>: The following recommended minimum
                                 configuration for the Specified Configuration is based upon, among other factors,
                                 Client’s Scope of Use, including number of Authorised Users concurrently accessing the
                                 Solution, data update and data download volumes. Client recognizes changes in any of
                                 the determining factors may cause changes in the recommended configuration as set out
                                 herein.</li>
                           </ul>
                        </li>
                     </ol>
                     <p><strong> </strong></p>
                     <ul>
                        <li>Connecting to the Solution requires:</li>
                     </ul>
                     <h2>a. Network: The Client shall procure secure internet connectivity of sufficient bandwidth from
                        the Client’s Site to the Solution for purposes of receiving Client Supplied Data, transmitting
                        Client Data to Client, and permitting remote access by Authorized Users to the Solution.</h2>
                     <h2>b. User Workstation Access: Users access the Solution through a supported web browser; the
                        minimum requirement is Microsoft Internet Explorer (IE) 9.0. Client’s user workstations
                        communicate with the Solution via either HTTP or HTTPs as further detailed as at the Order
                        Effective Date, at <span><a
                              href="https://technet.microsoft.com/en-us/library/hh699710.aspx">https://technet.microsoft.com/en-us/library/hh699710.aspx</a></span>
                        . The Client will supply high definition devices for viewing images.</h2>
                     <h2>c. Programmatic Interfaces and Protocols: Connections between the Client’s network and the
                        Solution will involve a number of ports and IP addresses. Real-time interfaces may use a variety
                        of application protocols layered on top of TCP/IP. Batch file interfaces are supported via FTP
                        and sFTP.</h2>
                     <h2>d. Client must configure routers, firewall and proxy servers within the Specified Configuration
                        in accordance with the above requirements and the mutually agreed traffic types, TCP/IP
                        addresses and ports. Client is responsible for implementing adequate security measures
                        (including firewall and anti-malware and intrusion protection) at the Client end of the
                        connection to the internet.</h2>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>User Maintenance</strong>: Client shall be responsible for maintaining and supplying
                           to Client’s Authorised Users all passwords, pass-codes, PIN numbers, and any other security
                           code as may be required to access the Solution.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong>Traffic Encryption</strong>: Data transferred over the internet between the
                           Specified Configuration and the Solution shall be encrypted using SSL (HTTPs), as further
                           specified, as at the Order Effective Date, at <a
                              href="https://www.microsoft.com/en-us/trustcenter/security/dynamics365-security">https://www.microsoft.com/en-us/trustcenter/security/dynamics365-security</a>
                           .</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong>Technology Change</strong>: Minimum configuration requirements for the Specified
                           Configuration are subject to change based on technology updates and Third-Party Software
                           lifecycles. OX.ms shall notify Client from time to time of any such changes as may be
                           applicable and Client shall not unreasonably refuse to update the Specified Configuration as
                           may be required by OX.ms. OX.ms shall bear no responsibility for any adverse impact on user
                           experience or service quality in the event Client operates any part of the Specified
                           Configuration using third party software or hardware that is no longer supported or
                           maintained by the relevant third-party provider.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ol start="18">
                        <li></li>
                     </ol>
                     <p><strong> </strong></p>
                     <p>As used in this Agreement, the terms below (and their plural forms) have the following meanings:
                     </p>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>&#8220;Action Request&#8221; or &#8220;AR&#8221; </strong>means any written or
                           verbal request by Client or notification generated by an OX.ms automated Solution monitoring
                           utility for Client Services to investigate and respond to a query, suspected Error, or any
                           other issue relating to the Solution;</li>
                        <li><strong>“Acceptance Period” </strong>means the period of thirty (30) days after the Delivery
                           Date unless a different Acceptance Period is specified in the Order.</li>
                        <li><strong>“affiliate” </strong>whether capitalised or not, means, with respect to a specified
                           Person, any Person which directly or indirectly controls, is controlled by, or is under
                           common control with the specified Person as of the date of this Agreement, for as long as
                           such relationship remains in effect.</li>
                        <li><strong>“Authorised Recipient” </strong>means: (i) with respect to Client, Client, any
                           Authorised User and any employee of a Client contractor, provided that the contractor is not
                           a competitor of OX.ms; and (ii) with respect to OX.ms, OX.ms, its foreign and domestic
                           Affiliates and their respective contractors, and Third-Party Providers.</li>
                        <li><strong>“Authorised User” </strong>means a Client employee or contractor.</li>
                        <li><strong>&#8220;Change&#8221; </strong>shall have the meaning attributed to the term in
                           Section 19.</li>
                        <li><strong>&#8220;Change Control Procedure&#8221; </strong>means to the change management
                           procedure set out in Section 19.</li>
                        <li><strong>“Change Request” </strong>means a request for a Change submitted by one party to the
                           other party, in writing;</li>
                        <li><strong>&#8220;Chargeable Travel Time&#8221; </strong>means the time normally taken,
                           assuming a continuous journey using reasonable means of transport, between the regular place
                           of work of the OX.ms Personnel (or OX.ms sub-contractor personnel, as applicable) providing
                           the relevant Professional Services and the Client Site (or such other place as may specified
                           by Client), but only to the extent that such travel time is chargeable to Client in
                           accordance with the provisions of Section 7.</li>
                        <li><strong>“Client Data” </strong>means the data inputted or provided by the Client or
                           Authorised Users for the purpose of using the Solution or facilitating the Client’s use of
                           the Solution and any Personal Data and/or information relating to an identified or
                           identifiable Third Party End User (whether current or prior patient of the Client) that is
                           provided by the Client to the OX.ms for use in the Solution.</li>
                        <li><strong>&#8220;Client Services&#8221; </strong>means certain OX.ms Personnel (or
                           OX.ms&#8217; sub-contractor personnel, as applicable) designated as point of contact and to
                           provide first line help desk support to Client in response to queries about Solution
                           functionality or suspected Errors reported by Client.</li>
                        <li><strong>“Client Supplied Data” </strong>means any information or data introduced into the
                           Solution by or on behalf of Client.</li>
                        <li><strong>“CPI”</strong> means the UK Consumer Price Index as published by the Office of
                           National Statistics, series D7G7 (as at the Order Effective Date, at <a
                              href="https://www.ons.gov.uk/economy/inflationandpriceindices/timeseries/d7g7/mm23">https://www.ons.gov.uk/economy/inflationandpriceindices/timeseries/d7g7/mm23</a>
                           ) or, in the event such index is no longer published, such other index as most closely
                           substitutes for such index, with the reference period for the applicable CPI rate being the
                           most recent twelve (12) month period for which CPI data is available on or about the relevant
                           increase notification date;</li>
                        <li><strong>“Confidential Information” </strong>means all business or technical information
                           disclosed by Disclosing Party to Receiving Party in connection with this Agreement.
                           Confidential Information includes without limitation: (i) Client Data and the details of
                           Client&#8217;s computer operations; (ii) the OX.ms Solution Details; and (iii) the terms of
                           this Agreement, but not the fact that this Agreement has been signed, the identity of the
                           parties hereto or the identity of the Solution or services being provided under an Order.
                           Confidential Information does not include information that: (aa) prior to the receipt thereof
                           under this Agreement, had been developed independently by Receiving Party, or was lawfully
                           known to Receiving Party, or had been lawfully received by Receiving Party from other
                           sources, provided such other source did not receive it due to a breach of an agreement with
                           Disclosing Party, and Receiving Party knew of such breach or ought to have reasonably known
                           of such breach; or (bb) is publicly known at or after the time either party first learns of
                           such information, or generic information or knowledge which either party would have learned
                           in the course of its work in the trade, business or industry; or (cc) subsequent to the
                           receipt thereof under this Agreement: (1) is published by Disclosing Party or is disclosed
                           generally by Disclosing Party to others without a restriction on its use and disclosure; or
                           (2) has been lawfully obtained by Receiving Party from other sources which Receiving Party
                           reasonably believes lawfully came to possess it.</li>
                        <li><strong>“copy” </strong>whether capitalized or not, means any paper, disk, tape, film,
                           memory device or other material or object on or in which any words, object code, source code
                           or other symbols are written, recorded or encoded, whether permanent or transitory.</li>
                        <li><strong>“Data Protection Legislation” </strong>has the meaning given to it in Section 3.
                        </li>
                        <li><strong>“Data Subject” </strong>has the meaning set out in the Data Protection Legislation.
                        </li>
                        <li><strong>“Delivery Date” </strong>has the meaning given to it in Section 2.</li>
                        <li><strong>“Designated Location(s)” </strong>means the Client’s location(s) listed as
                           designated location(s) on the Order.</li>
                        <li><strong>“Disclosing Party” </strong>has the meaning given to it in Section <a
                              href="#page3">1.</a></li>
                        <li><strong>“Disputed Amount” </strong>means such amount which are invoiced under this Agreement
                           but remain unpaid as a result of a good faith dispute by Client of such amounts to the extent
                           that: (i) Client has given written notice of the dispute to OX.ms promptly after receiving
                           the invoice; and (ii) the notice explains Client&#8217;s position in reasonable detail. A
                           dispute will not exist as to an invoice in its entirety merely because certain amounts on the
                           invoice are Disputed Amounts.</li>
                        <li><strong>“Documentation” </strong>means the standard user documentation OX.ms provides for
                           the Solution, as such Documentation may be updated from time to time.</li>
                        <li><strong>“Downtime” </strong>means any period within the Service Hours that the Solution is
                           not available;</li>
                        <li><strong>&#8220;Environment&#8221;</strong> means a logically separate pool of computing
                           resources on which the Solution is installed for purposes of remote access by Client under
                           the terms of this Order;</li>
                        <li><strong>“Error” </strong>means a failure of a Supported Release to perform in all material
                           respects in accordance with the Documentation.</li>
                        <li><strong>“Export Laws” </strong>means any laws, administrative regulations and executive
                           orders of the U.S., the United Kingdom and any other jurisdiction where any OX.ms Solution
                           Details will be located or from where any OX.ms Solution Details will be accessed under this
                           Agreement relating to the control of imports and exports of commodities and technical data,
                           use or remote use of software and related property or services, embargo of goods or services
                           or registration of this Agreement including the Export Administration Regulations of the U.S.
                           Department of Commerce and the regulations and executive orders administered by the Office of
                           Foreign Asset Control of the U.S. Department of the Treasury.</li>
                        <li><strong>“Feedback” </strong>means any suggestions or recommendations for improvements or
                           modifications to the Solution made by or on behalf of Client.</li>
                        <li><strong>“Full User + Operations”</strong> means any Authorized User with full read and full
                           write capabilities to the Solution functionality within the Scope of Use (including Microsoft
                           Dynamics 365 and Office 365 E5) through any and all modes of access described in the
                           Documentation. For greater clarity, Client’s management and finance staff would typically
                           require licensing as Full Users + Operations.</li>
                        <li><strong>“Full User”</strong> means any Authorised User with full read and full write
                           capabilities to the Solution functionality within the Scope of Use (including Microsoft
                           Dynamics 365 and Office 365 E5) <u>except for the Operations and Finance module</u> (as
                           described in the Documentation) of the Solution, through any and all modes of access
                           described in the Documentation. For greater clarity, Client’s consultants, nurses,
                           embryologists &amp; lab staff would typically require licensing as Full Users.</li>
                        <li><strong>“Functional Implementation List” </strong>or<strong> “FIL”</strong> means a
                           description of the way in which OX.ms shall implement Client’s requirements. The FIL in
                           respect of the Initial Implementation Services is attached Part 3 of the Order.</li>
                        <li>“<strong>Harmful Code” </strong>means any viruses, worms or similar harmful code.</li>
                        <li><strong>“including” </strong>whether capitalised or not, means including but not limited to.
                        </li>
                        <li><strong>“Impact Review”</strong> means the collection of information as described in Section
                           19.</li>
                        <li><strong>&#8220;Implementation Fee&#8221;</strong> shall have the meaning ascribed to the
                           term in the Order;</li>
                        <li><strong>”Intellectual Property Rights”</strong> shall mean patents, utility models, rights
                           to inventions, copyright and neighbouring and related rights, moral rights, trade marks and
                           service marks, business names and domain names, rights in get-up and trade dress, goodwill
                           and the right to sue for passing off or unfair competition, rights in designs, rights in
                           computer software, database rights, rights to use, and protect the confidentiality of,
                           confidential information (including know-how and trade secrets) and all other intellectual
                           property rights, in each case whether registered or unregistered and including all
                           applications and rights to apply for and be granted, renewals or extensions of, and rights to
                           claim priority from, such rights and all similar or equivalent rights or forms of protection
                           which subsist or will subsist now or in the future in any part of the world, and Intellectual
                           Property Rights include, without limitation, any Marks.</li>
                        <li><strong>&#8220;Legacy Release Maintenance Fee&#8221;</strong> shall have the meaning
                           ascribed to the term in Section 3.</li>
                        <li><strong>&#8220;Maintenance Update Package&#8221;</strong> means a package of Changes, Error
                           corrections and/or other revisions to the Release of the Solution then operated by OX.ms to
                           Client under this Order (excluding any actual Release);</li>
                        <li><strong>“Maintenance Window”</strong> means a period of unavailability of the Solution
                           during the Service Hours due to planned maintenance or when otherwise agreed between the
                           parties;</li>
                        <li><strong>“Marks” </strong>Shall mean any and all trade marks, trade names, service marks,
                           trade dress, logos, URLs or identifying slogans of a party to the Agreement, whether or not
                           registered.</li>
                        <li><strong>&#8220;Microsoft Azure&#8221;</strong> means the Solution hosting facilities within
                           one or more Microsoft data centres;</li>
                        <li><strong>“Monitor Transaction Request”</strong> or “<strong>MTR”</strong> shall have the
                           meaning attributed to the term in Section 16.</li>
                        <li><strong>&#8220;Normal Support Hours&#8221;</strong> means the period on Monday through
                           Friday between 09:00 a.m. to 5:00 p.m. U.K. local time (excluding public holidays in
                           England);</li>
                        <li><strong>“OX.ms Personnel” </strong>means employees, contractors or sub-contractors of OX.ms;
                        </li>
                        <li>“<strong>ms Solution Details” </strong>means any of the following: the Solution and
                           Documentation, the object code and the source code for the Solution, the visual expressions,
                           screen formats, report formats and other design features of the Solution, all ideas, methods,
                           algorithms, formulae and concepts used in developing and/or incorporated into the Solution or
                           Documentation, all future modifications, updates, Releases improvements and enhancements of
                           the Solution or Documentation, all derivative works (as such term is used in the U.S.
                           copyright laws) based upon any of the foregoing and all copies of the foregoing.</li>
                        <li><strong>“Open Source Software” </strong>means computer software made generally available at
                           no charge by the copyright holder under a license which provides the right to modify and
                           distribute the software to anyone for any purpose at no charge.</li>
                        <li><strong>“person” </strong>whether capitalized or not, means any individual, sole
                           proprietorship, joint venture, partnership, corporation, company, firm, bank, association,
                           cooperative, trust, estate, government, governmental agency, regulatory authority or other
                           entity of any nature.</li>
                        <li><strong>&#8220;Performance Upgrade Fee&#8221; </strong>shall have the meaning ascribed to
                           the term in Section 4.</li>
                        <li><strong>“Personal Data” </strong>means personal data and sensitive person data which shall
                           each have the meaning set out in the Data Protection Legislation.</li>
                        <li><strong>“Price Index” </strong>means the index specified in an Order; provided that, if the
                           applicable Price Index is unavailable or materially changes in content and scope, then OX.ms
                           may in good faith, taking into account the geographic region of the personnel supporting the
                           Solution and/or providing the applicable services, select another generally recognized Price
                           Index as a substitute in order to obtain substantially the same result.</li>
                        <li><strong>“Price Index Change” </strong>means the amount of the percentage change in the Price
                           Index (calculated by averaging the annual Price Index change for the four (4) fiscal quarters
                           immediately preceding the date of the applicable fee increase) plus the percentage increment
                           specified in the Order, provided, in all cases, the minimum aggregate pricing change during
                           any 12-month period shall not be less than two percent (2%) of the fees then applicable to
                           the Solution or other products or service.</li>
                        <li><strong>“Processing” </strong>shall have the meaning set out in the Data Protection
                           Legislation.</li>
                        <li><strong>“Professional Services” </strong>whether capitalised or not, means installation,
                           implementation, training or consulting services including custom modification programming,
                           support services relating to custom modifications, on-site support services, assistance with
                           data transfers, system restarts and reinstallations provided by OX.ms under this Agreement.
                        </li>
                        <li><strong>“Receiving Party” </strong>has the meaning given to it in Section <a
                              href="#page3">1.</a></li>
                        <li><strong>“Release” </strong>means a modification or update to the Solution, which OX.ms, in
                           its sole discretion, incorporates into the Solution without requiring its then existing
                           client base to pay a separate fee (other than Support Fees).</li>
                        <li><strong>“Renewal Support Term” </strong>has the meaning given to it in Section 4.</li>
                        <li>“<strong>Scheduled Installation Date” </strong>means the scheduled installation date stated
                           on the Order, and if a scheduled installation date is not stated on the Order, the scheduled
                           installation date shall be the date on which the Client installed the Solution within thirty
                           (30) days after the Delivery Date in accordance with Section 2.</li>
                        <li><strong>“Scope of Use” </strong>means the Designated Computer(s), Designated Location(s),
                           Licence Term, Platform, Business Purpose, Number of Trades, Number of Work Stations, Number
                           of Developers, Number of Users, Volume Limit, Number of Production Databases, Number of
                           Production Servers, and/or other restrictions or parameters as are stated (and as defined) in
                           Section 5.5 or on the Order [Scope of Use shall not include the processing of any Acquired
                           Business] in connection with Client’s use of the Solution in production solely to process
                           Client&#8217;s business; provided that any increases in the Scope of Use shall require
                           execution of an amendment to the relevant Order amending the Scope of Use.</li>
                        <li><strong>“Service Credit” </strong>means any portion of the fees paid or payable by Client in
                           respect of the Solution under this Order which, due to a failure by OX.ms to meet the
                           contracted Service Levels during the applicable Service Period, OX.ms shall credit to Client
                           on the subsequent invoice for Annual Processing Fees;</li>
                        <li><strong>“Service Hours” </strong>means, in respect of each week of the Order Term, the
                           period between 08:00 a.m. Monday and 6:00 p.m. Friday (London local time), excluding any
                           Maintenance Windows and except as may be agreed otherwise by the Parties from time to time,
                           in writing. For the avoidance of doubt, OX.ms may, at its sole discretion, provide Client
                           with access to the Solution at any time (excluding any Maintenance Windows), irrespective of
                           the Service Hours;</li>
                        <li><strong>“Service Levels” </strong>means the performance levels to which the Solution shall
                           be provided as set out in Section 16.</li>
                        <li><strong>“Service Period” </strong>means each calendar month (or part thereof) in which OX.ms
                           makes the Solution available for access by Client;</li>
                        <li><strong>“Service User” </strong>means an Authorized User with full read, but certain limited
                           write capabilities (as set out in the Specifications) to all of the Solution functionality
                           within the Scope of Use through any and all modes of access;</li>
                        <li><strong>&#8220;Site&#8221;</strong> means, unless the context requires otherwise, any
                           Designated Location;</li>
                        <li><strong>“Specified Configuration” </strong>has the meaning given to it in Section <a
                              href="#page1">4.</a></li>
                        <li><strong>“Staff Day”</strong> means a quantum of Professional Services (including, as
                           applicable, Chargeable Travel Time) of seven and a half (7.5) hours, performed during the
                           Standard Working Hours;</li>
                        <li><strong>“Standard Working Hours”</strong> means the period between 09:00 a.m. and 5:00 p.m.
                           (local time at the place the relevant Professional Services are performed), Monday to Friday,
                           excluding public holidays at the regular place of work of the relevant OX.ms Personnel;</li>
                        <li><strong>“Supported Release” </strong>means, unless otherwise stated in the Order, the latest
                           Release of the Solution that is generally available to OX.ms’s client base.</li>
                        <li><strong>“Support Fees” </strong>means the support fees stated on the Order.</li>
                        <li><strong>“Support Termination Date” </strong>means the effective date of termination of
                           ongoing support services by either party or at any time when Client has failed to pay the
                           Support Fees.</li>
                        <li><strong>“Team User”</strong> means any Authorised User with full read and limited write
                           capabilities to the Solution functionality within the Scope of Use (including limited access
                           to Microsoft Dynamics 365 and Office 365) <u>except for the Operations and Finance module</u>
                           (as described in the Documentation) of the Solution, through any and all modes of access
                           described in the Documentation. For greater clarity, Team Users licenses would typically be
                           appropriate for Client’s administrative or authorized external accounting staff.</li>
                        <li><strong>&#8220;Termination Fee&#8221; </strong>means the fee payable by Client in the event
                           of termination by Client of this Order prior to the normal expiration date of the Initial
                           Term or then current Renewal Term (as applicable) and as specified in Section 2.</li>
                        <li><strong>“Third-Party Data and Services” </strong>means market data services or other data,
                           services or software.</li>
                        <li><strong>“Third Party End Users” </strong>means patients of the Client.</li>
                        <li><strong>“Third-Party Provider” </strong>means a securities exchange or other data, services
                           or software provider.</li>
                        <li><strong>“Third-Party Software” </strong>means the software specified as third-party software
                           on the Order.</li>
                        <li><strong>“Third-Party User” </strong>means any of Client’s customers, or their customers, to
                           the extent such persons are provided access to the Solution or Third-Party Data hereunder.
                        </li>
                        <li><strong>“Third Party Software”</strong> means Microsoft SharePoint, Microsoft Office 365,
                           Microsoft Dynamics 365 and any other third-party software listed in the Specifications;</li>
                        <li><strong>“Treatment”</strong> means a record in the Solution of any dispensation of medical
                           care provided by Client to Patient.</li>
                        <li><strong>“Up Time”</strong> means a time period during the Service Hours (excluding any
                           Maintenance Windows) in which any appropriately permissioned Authorised User is able to read
                           and update Patient Records within the Solution;</li>
                        <li><strong>&#8220;Upgrade Acceptance Period&#8221;</strong> shall have the meaning ascribed to
                           the term in Section 7; and</li>
                        <li><strong>“Work Product” </strong>has the meaning given to it in Section <a
                              href="#page4">7.</a></li>
                     </ul>
                     <p><strong> </strong></p>
                     <ol start="19">
                        <li><strong><u>Change Control Procedure</u></strong>
                           <ul>
                              <li>In the event either party requests a modification to any Professional Services, the
                                 Project Specifications, the Specified Configuration, any Environment or the Solution,
                                 or the addition of Professional Services that are not deliverables within the scope of
                                 the Initial Implementation Services, or an alteration of the Scope of Use or to any
                                 other terms of this Order (each such modification, addition or alteration, a
                                 &#8220;Change&#8221;), the parties shall adhere to the Change Control Procedure as set
                                 out in this Section 19.</li>
                              <li>A Change shall only be made with the written agreement of both parties and all Changes
                                 shall be processed using the OX.ms support workflow management system. If either party
                                 identifies a requirement for a Change, it will provide the other with a Change Request
                                 for approval. If the Change Request affects the Initial Implementation Services or
                                 other Professional Services, such Change Request shall be addressed to the other
                                 party&#8217;s designated Project Manager and each such person shall have authority on
                                 behalf of the party that it represents to authorize all aspects of the Change Request.
                                 If the Change Request concerns the Solution or any other deliverable relating to its
                                 on-going operation, such Change Request shall be addressed to OX.ms’s Service Delivery
                                 Manager (where the Change Request is being raised by Client) or Client&#8217;s Project
                                 Manager or such other person notified by Client to OX.ms from time to time (where the
                                 Change Request is being raised by OX.ms), and each such person shall have authority on
                                 behalf of the party that it represents to authorize all aspects of the Change Request.
                                 Each Change Request will include a summary description of the Change together with
                                 reasons for the Change Request, and if the Change Request is being initiated by OX.ms
                                 the Change Request will also include an Impact Review covering the information set out
                                 in Section 5.</li>
                              <li>Both parties shall process each Change Request promptly. While the parties are
                                 following this procedure, then unless otherwise agreed by both Service Delivery
                                 Managers or Project Managers or such other relevant persons authorized by either party
                                 to issue/approve Change Requests, each Party shall continue to perform its obligations
                                 as if there had been no Change Request.</li>
                              <li>Upon receipt of a Change Request from Client, OX.ms may either accept or reject the
                                 Change Request (acting reasonably) and if OX.ms rejects the Change Request OX.ms shall
                                 provide to Client its reasons for such rejection. If OX.ms accepts the Change Request
                                 then it shall prepare an Impact Review (as described below under Section 5) within a
                                 reasonable time, and where the Change Request is raised by Client OX.ms reserves the
                                 right to charge for its work effort in preparing such Impact Review at the Professional
                                 Services Rates to which the Change relates, where such work effort exceeds three (3)
                                 hours and provided Client has approved such charges. If no such approval is given,
                                 OX.ms shall be under no obligation to prepare the Impact Review.</li>
                              <li>Each Impact Review shall contain a description of the work to be carried out in
                                 implementing the Change, a description of planned work no longer required, the
                                 estimated additional cost or anticipated savings as a result of the Change, a timetable
                                 for undertaking the planned work including the impact on schedule dates, positive or
                                 adverse effects on the continued delivery of the services to which the Change relates,
                                 and any other information that OX.ms has knowledge of and which is relevant for the
                                 Client&#8217;s ability possibility to assess whether or not to proceed with the Change.
                              </li>
                              <li>The cost or savings calculations in the Impact Review shall be made in accordance with
                                 the standard Professional Services terms as set out in Section 10 (to the extent
                                 relevant) and shall be itemized so that Client can assess the reasonableness of the
                                 forecasted costs or savings. The Impact Review shall serve as a binding quotation for
                                 the work described in the Impact Review and shall remain valid for acceptance by Client
                                 for the period of time specified by OX.ms in the relevant Impact Review.</li>
                              <li>Following receipt of the Impact Review, Client shall either:</li>
                           </ul>
                        </li>
                     </ol>
                     <h2>a. approve the proposed Change (and such approval shall be deemed to have been given when the
                        Impact Review is accepted by Client in writing);</h2>
                     <h2>b. decline the proposed Change; or</h2>
                     <h2>c. request changes to, which shall be reasonable, or explanations of (or both) any aspect of
                        the Impact Review and if the parties agree on any changes to the Impact Review, OX.ms shall
                        issue a revised Impact Review and the revised Impact Review shall be subject to the approval or
                        rejection by Client in accordance with the provisions of this Section 19.</h2>
                     <ul>
                        <li>Client may withdraw a Change Request any time prior to its authorization to proceed with the
                           relevant Change. Upon approval by Client of the Change, OX.ms shall begin to carry out the
                           work described in accordance with the approved Impact Review.</li>
                        <li>In the event Client terminates an approved Change and/or requests that OX.ms cease carrying
                           out the work described in the final Impact Review, any termination fee as may be applicable
                           shall be as set out in Section 2 or as specified otherwise in the relevant Change.</li>
                        <li>Notwithstanding any other provisions of this Section 19, OX.ms may make changes without
                           requiring adherence to the Change Control Procedure set out in this Section 19 to (a) the
                           OX.ms Solution Detail; or (b) the Environments or the operation of the Solution, provided
                           that such changes (i) do not incur additional fees to Client; and (ii) such changes are
                           unlikely, in OX.ms’s reasonable assessment, to adversely affect the provision of the Solution
                           in accordance with this Order.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ol start="20">
                        <li><strong><u>Other Provisions.</u></strong>
                           <ul>
                              <li><strong>Notices</strong><strong>. </strong>All notices, consents and other
                                 communications under or regarding this Agreement shall be in writing and shall be
                                 deemed to have been received on the earlier of: (a) the date of actual receipt; (b) the
                                 third business day after being mailed by first class, certified or air mail; (c) the
                                 same day if delivered by hand; or (d) the first business day after being sent by a
                                 reputable overnight delivery service. Any notice may be given by facsimile, or email if
                                 notice by one of the foregoing is provided promptly thereafter. Client&#8217;s address
                                 for notices is stated on the Order. OX.ms’s address for notices is stated on the Order.
                                 In the case of: (i) any notice by Client alleging a breach of this Agreement by OX.ms;
                                 or (ii) a termination of this Agreement, Client shall also mail a written notice to
                                 OX.ms, The Cow Shed, 19 Warf Road, Shillingford, Oxon, England, Attention: Chief Legal
                                 Officer and such notices shall identify the Order name (including the Solution being
                                 provided thereunder), date, specific parties and OX.ms Order Number. Either party may
                                 change its address for notices by giving written notice of the new address to the other
                                 party.</li>
                           </ul>
                        </li>
                     </ol>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Parties in </strong><strong>Interest</strong><strong>.</strong></li>
                     </ul>
                     <ul>
                        <li>This Agreement shall bind, benefit and be enforceable by and against OX.ms and Client and
                           their respective permitted successors and assigns.</li>
                        <li>Client shall not assign this Agreement or any of its rights hereunder, nor delegate any of
                           its obligations hereunder, without OX.ms’s prior written consent, except such consent shall
                           not be required in the case of an assignment of this Agreement (but not of any individual
                           rights or obligations hereunder) to: (i) a purchaser of or successor to substantially all of
                           Client&#8217;s business (unless such purchaser or successor is a software, data processing or
                           computer services vendor that is a competitor of OX.ms, its parent company or any of its
                           Affiliates); or (ii) an Affiliate of Client, provided in the case of such an assignment,
                           Client guarantees the obligations of the assignee and the use of the Solution is not
                           broadened beyond the Scope of Use. Any assignment by Client in breach of this Section shall
                           be void.</li>
                        <li>Any express assignment of this Agreement, any change in control of Client (or its Affiliate
                           in the case of an assignment to that Affiliate under this Section 2 and any assignment by
                           merger or otherwise by operation of law, shall constitute an assignment of this Agreement by
                           Client for purposes of this Section 20.2 (“<strong>Client</strong>
                           <strong>Assignment</strong>”).</li>
                     </ul>
                     <p>&nbsp;</p>
                     <p>In the event of a Client Assignment, or any acquisition of additional business by Client,
                        whether by asset acquisition, merger or otherwise by operation of law (collectively with the
                        Client Assignment, “<strong>Client Additional Business Acquisition</strong>”), Client shall give
                        notice to OX.ms notifying OX.ms if Client desires to use the Solution to process any additional
                        business related to such Client Additional Business Acquisition (“<strong>Acquired
                           Business</strong>”).</p>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Export Laws. </strong>Client acknowledges that the OX.ms Solution Details and the
                           services provided by OX.ms hereunder and this Agreement are subject to the Export Laws.
                           Client shall not violate the Export Laws or otherwise export, re-export or use, directly or
                           indirectly (including via remote access), any part of the Solution, Confidential Information
                           or services in a manner, or to or for any person or entity, for which a license or other
                           authorization is required under the Export Laws without first obtaining such license or
                           authorization.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li>The relationship between the parties created by this Agreement is that of independent
                           contractors and not partners, joint ventures or agents.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong>Entire Understanding. </strong>This Agreement, which includes and incorporates the
                           Order, and any other schedules, exhibits and addenda hereto states the entire
                           <strong>understanding</strong> between the parties with respect to its subject matter, and
                           supersedes all prior proposals, marketing materials, negotiations, representations (whether
                           negligently or innocently made), agreements and other written or oral communications between
                           the parties with respect to the subject matter of this Agreement. In the event of a conflict
                           between the provisions of the OST and an Order incorporating the OST, the terms of such Order
                           shall prevail. Any written, printed or other materials which OX.ms provides to Client that
                           are not included in the Documentation are provided on an “as is” basis, without warranty, and
                           solely as an accommodation to Client. In entering into this Agreement each party acknowledges
                           and agrees that it has not relied on any express or implied representation, warranty,
                           collateral contract or other assurance (whether negligently or innocently made), except those
                           expressly set out in this Agreement. Each party waives all rights and remedies which, but for
                           this Section 5, might otherwise be available to it in respect of any such representation
                           (whether negligently or innocently made), warranty, collateral contract or other assurance.
                           Nothing in this Agreement shall limit or exclude any liability for fraud or fraudulent
                           misrepresentation.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Modification and Waiver. </strong>No modification of this Agreement, and no waiver
                           of any breach of this Agreement, shall be effective unless in writing and signed by an
                           authorized representative of the party against whom enforcement is sought. This Agreement may
                           not be modified or amended by electronic means without <strong>written</strong> agreement of
                           the parties with respect to formats and protocols. No waiver of any breach of this Agreement,
                           and no course of dealing between the parties, shall be construed as a waiver of any
                           subsequent breach of this Agreement.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li><strong>Severability, Heading and Counterparts. </strong>A determination that any provision
                           of this Agreement is invalid or unenforceable shall not affect the other provisions of
                           <strong>this</strong> Section headings are for convenience of reference only and shall not
                           affect the interpretation of this Agreement. This Agreement may be executed in one or more
                           counterparts, each of which shall be deemed an original and all of which together shall
                           constitute one and the same instrument.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li>Client acknowledges that: (a) OX.ms expends substantial time and money, on an ongoing basis,
                           to recruit and train its senior programmers, trainers, data processing, client support and
                           professional services team personnel (“OX.ms Personnel”); (b) OX.ms’s business is highly
                           competitive, is marketed throughout Europe and in many other locations worldwide, and
                           requires long sales lead times often exceeding one (1) year; and (c) if Client were to hire
                           OX.ms Personnel, then OX.ms may suffer lost sales opportunities and would incur substantial
                           time and money in hiring and training replacement(s) for those OX.ms Personnel. Accordingly,
                           if Client, directly or through one or more subsidiaries or other controlled entities,
                           solicits, entices away, or attempts to entice away from OX.ms, any OX.ms Personnel who has
                           been directly working with the Client at any time when such OX.ms Personnel is employed or
                           engaged by OX.ms or during the three (3) months after such employment or engagement ends,
                           then Client shall pay to OX.ms as liquidated damages (and not a penalty) an amount equal to
                           twelve (12) months of such OX.ms Personnel&#8217;s salary and other compensation (including
                           bonus or commission payments) at the time of leaving his/her employment or engagement with
                           OX.ms. This provision will remain in effect during the term of this Agreement and for a
                           period of one (1) year after expiration or termination of this Agreement</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li>OX.ms may issue (but not more than once per annum), and Client will promptly complete and
                           return to OX.ms, requests certifying Client’s current and past compliance with the Scope of
                           Use and the Export Laws.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li>It is the express wish of the parties that this Agreement and all related documents be drawn
                           up in English.</li>
                     </ul>
                     <p><strong> </strong></p>
                     <ul>
                        <li><strong>Jurisdiction and Governing Law. </strong>The United Nations Convention on Contracts
                           for the International Sale of Goods does not apply to this Agreement or its subject matter.
                           If Client is, as of the Order Effective Date, headquartered inside the Americas, then only
                           Section (a) below applies. If Client is, as of the Order Effective Date, headquartered in the
                           European Economic Area, United Kingdom, Croatia and Switzerland then only Section (b) below
                           applies.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <ul>
                        <li>This Agreement and any dispute or claim arising, directly or indirectly, out of or in
                           connection with it or its subject matter or formation (including non-contractual disputes or
                           claims) is governed by, and shall be construed and enforced in accordance with, the laws of
                           England and Wales excluding choice of law. Each party irrevocably agrees that the courts of
                           England and Wales shall have exclusive jurisdiction to settle any dispute, controversy or
                           claim arising, directly or indirectly, out of or in connection with this Agreement, or the
                           breach, termination or validity thereof (including non-contractual disputes or claims). The
                           Contracts (Rights of Third Parties) Act 1999 shall not apply to this Agreement.</li>
                        <li>This Agreement and any dispute or claim arising, directly or indirectly, out of or in
                           connection with it or its subject matter or formation (including non-contractual disputes or
                           claims) is governed by, and shall be construed and enforced in accordance with, the laws of
                           England and Wales excluding choice of law. Each party irrevocably agrees that the any
                           dispute, controversy or claim arising, directly or indirectly, out of or in connection with
                           this Agreement, or the breach, termination or validity thereof (including non-contractual
                           disputes or claims), shall be referred to and finally resolved by the International Court of
                           Arbitration of the International Chamber of Commerce under the Rules of Arbitration of the
                           International Chamber of Commerce (<strong>&#8220;ICC&#8221;</strong>) for the time being in
                           force, which rules are deemed to be incorporated by reference in this Section. The location
                           and seat of the arbitration shall be: (i) London if Client is headquartered in Europe, Middle
                           East or Africa; and (ii) Singapore if Client is headquartered in Asia Pacific. There shall be
                           one arbitrator. The arbitrator shall be agreed between the parties. Failing agreement, or if
                           the arbitrator selected is unable or is unwilling to act, the appointing authority shall be
                           the ICC. The arbitration proceedings shall be conducted in English. The decision of the
                           arbitrator shall be final and binding upon both parties and shall be enforceable in any court
                           of law. Each of the parties waives irrevocably their right to any form of appeal, review or
                           recourse to any state court or other judicial authority, insofar as such waiver may be
                           validly made. Notwithstanding anything to the contrary in this Agreement, either party may at
                           any time seek an interim injunction or other interlocutory relief in a court of competent
                           jurisdiction in order to protect any urgent interest of such party, including, but not
                           limited to, the confidentiality provisions of this Agreement. The law governing the
                           arbitration agreement contained in this Section, the arbitration, and the conduct and
                           procedure of the arbitration, shall be the laws of England and Wales.</li>
                     </ul>
                     <p>&nbsp;</p>
                     <p><a href="#_ftnref1" name="_ftn1"></a></p>
                  </div>

               </div>
            </div>

         </article>

      </div>

   </div>



</div>