import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CaseService } from '../../../../../src/app/ApiServices/Case/case.service';
import { environment } from '../../../../../src/environments/environment';
declare var $:any;
@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.css']
})
export class CaseComponent implements OnInit {
CaseDeatils:any
Case:any={};
caseValue:any={};
SendNotesobj: any={};
caseId:any;
file: any;
CaseID: any;


  msg: any;
    constructor( private caseService: CaseService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    localStorage.removeItem('CaseID');
    this.getCase();
  }
  getCase(){debugger
    debugger;
       //*************Show / hide loader *******************/
       $('#ftco-loader').addClass('show');
       /**************************************** */
    
       this.caseService.getCase(environment.LoginUserId).subscribe((res) => {
   
         if (res != null) {
          this.CaseDeatils   = res;
         }
         setTimeout(function () {
           $('#caseRecord').DataTable();
       }, 50);
        // Change src attribute of image
        $("#your-image10").attr("src", "../../../assets/images/Documents.png");
        $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
        $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
        $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
        $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
        $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
        $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
        $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
        $("#your-image").attr("src", "../../../assets/images/profile.png");
        $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
        $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
      
         //*************Show / hide loader *******************/
         $('#ftco-loader').removeClass('show');
         /**************************************** */
       }, (err) => {
         //*************Show / hide loader *******************/
         $('#ftco-loader').removeClass('show');
         /****************** -------- **********************/
         this.toastr.error(err);
         this.router.navigate(['/']);
       });
     }
     editcase(data){
      debugger;
      this.Case= data;
      //
     if(this.Case.priority=='High'){
      this.Case.prioritycode="1"
     } 
     if(this.Case.priority=='Normal'){
      this.Case.prioritycode="2"
     } 
     if(this.Case.priority=='Low'){
      this.Case.prioritycode="3"
     } 
     //
     if(this.Case.casetype=='Question'){
      this.Case.casetypecode="1"
     } 
     if(this.Case.casetype=='Problem'){
      this.Case.casetypecode="2"
     } 
     if(this.Case.casetype=='Request'){
      this.Case.casetypecode="3"
     } 
      this.Case.Id = data.Id;
      this.CaseID = data.Id;
     }

  onSubmit(it: NgForm) {
    debugger;
    $('#ftco-loader').addClass('show');
    this.caseValue= it.form.value
    this.caseService.createCase(this.caseValue,environment.LoginUserId).subscribe((res) => {
      debugger;
      $("#Modal1 .close").click();
      this.toastr.success("Online Consultation Created Successfully");
      $('#ftco-loader').removeClass('show');
      $('#Modal1 form')[0].reset();
      this.ReloadComponent();   
      // if(res == true){
      //   this.toastr.success("Case Created Successfully");
      // }
    });

  }
  //
  onUpdate(Data) {
    debugger;
    this.caseValue= Data
    $('#ftco-loader').addClass('show');
    this.caseService.upadteCase(this.caseValue,environment.LoginUserId).subscribe((res) => {
      debugger;
      $("#Modal2 .close").click();
      this.toastr.success("Online Consultation Updated Successfully");
      $('#ftco-loader').removeClass('show');
      $('#Modal1 form')[0].reset();
      this.ReloadComponent();
      // if(res == true){
      //   this.toastr.success("Case Updated Successfully");
      // }
    });

  }
  ReloadComponent(): void {
    let Location = this.router.url;
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([Location], { skipLocationChange: true });
    });
  }


  openSendSMS(CaseID,data) {
    debugger
    //  this.getNotes(appointment);
    // $("#myModalcomment").modal("toggle");
    localStorage.setItem('CaseID',CaseID)
    localStorage.setItem('ConsultationnotesName',data.title)
    localStorage.setItem('CaseType',data.casetype)
    localStorage.setItem('createdOn' ,data.createdon)
    localStorage.setItem('StatusOnlineConsultation' ,data.statuscodename)
    this.router.navigate(['profile/notes'], { skipLocationChange: true })

  }


  IncomingFile(event) {
    debugger
    this.file = event[0];
    $('#ftco-loader').addClass('show');
    /**************************************** */
    const formData = new FormData();
    formData.append('caseId', this.CaseID);
    formData.append('file', this.file);
    formData.append('langid', "1");
    this.caseService.createNotes(formData).subscribe((res) => {
      debugger;
      if (res) {
        // localStorage.removeItem('CaseID');
        // $("#Modal2 .close").click();
         $('#ftco-loader').removeClass('show');
        this.toastr.success("file added successfully");
        // this.ReloadComponent();
      }
    });

  }

  

  
}
