<div class="main-wrapper">
    <div class="page-wrapper">
      <div class="page-content">
  
        <div class="row">
          <div class="col-md-12 ">
            <div class="card p-2">
             
                    <form class="form" #it="ngForm" (ngSubmit)="onSubmit(it)" #f="ngForm" autocomplete="off">
            
                      <div class="form-group row">
                        <div class="col-md-6">
                            <!-- <label class="control-label"> </label>   -->
                            <select  name="bcrm_patientguardian" [(ngModel)]="bcrm_patientguardianvalue" id="bcrm_patientguardian"
                             ngModel #bcrm_patientguardian="ngModel" class="form-control" (change)="ChecktheGuardianValue($event)">
                                <option value="" disabled>Select Guardian / Proxy</option>
                                <option value={{userMainId}}>{{userMainname}} (Me)</option>
                                <option [value]="data.relatedPersonId" *ngFor="let data of ProxyData" class="form-control">{{data.fullName}}
                                </option>
                              </select>
                        </div>
            
                        <div class="col-md-6">
                            <div class="form-group row bmd-form-group"  *ngIf="GuardianValue == true">
                                <!-- <div class="col-sm-1">
                                    <input type="checkbox" id="GuardianValue"  name="bcrm_isproxy" [(ngModel)]="GuardianValue">
                                </div> -->
                                <label class="md-label-floating col-sm-6">Guardian / Proxy</label>
                            </div>
                        </div>
                      </div>
                     <div class="table-responsive">
                       <table class="table table-bordered tablelist" id="prescriptionRecord">
                        <thead>
                          <tr>
                            <th style="display: none;">S/No</th>
                            <th>Select</th>
                            <th>Drug</th>
                            <th>Dosage</th>
                            <th>Dosage Unit</th>
                            <!-- <th>Frequency</th>-->
                            <th>Expiry Date</th> 
                            <th>Status</th>
                            <th>Reason</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let data of repeatPrescriptionDrug; index as i">
                            <td style="display: none;">{{i + 1}}</td>
                            <td>
                              <div class="custom-control custom-checkbox">
                                  <!-- <input  *ngIf="data.IsReasonshow" type="checkbox" id="{{data.Id}}_checkbox" name="name1" disabled>&nbsp;   -->
                                  <input  *ngIf="
                                  data.IsReasonshow" type="checkbox" id="{{data.Id}}_checkbox" name="name1">&nbsp;                       
                              </div>       
                          </td>
                            <td>{{data.Name}}</td>
                            <td>{{data.bcrm_dosage}}</td>
                            <td>{{data.bcrm_dosageunit}}</td>
                            <!-- <td>{{data.bcrm_frequency}}</td>-->
                            <td><span
                              *ngIf="data.bcrm_expirydate!= '0001-01-01T00:00:00'">{{data.bcrm_expirydate | date:'dd/MM/yyyy'}}</span>
                          </td> 
                          <td *ngIf="!data.IsReasonshow">{{data.bcrm_statusFormattedName}}</td>
                          <td *ngIf="data.IsReasonshow" style="color: red;">Expired</td>
                            <!-- <td>{{data.bcrm_startcomment}}</td> -->
                            <td *ngIf="!data.IsReasonshow"><input type="text" class="form-control" id="{{data.Id}}_comment" placeholder="Enter comments"></td>
                            <td *ngIf="data.IsReasonshow" class="text-justify">This drug has been expired. Please contact your GP.</td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="form-group row" id="bcrm_commentfornewdrug">
                      <label class="control-label col-sm-2">Comment :</label>
                      <div class="col-sm-10">
                        <textarea type="text" class="form-control" id="bcrm_commentfornewdrug" name="bcrm_commentfornewdrug" rows="3" #name="ngModel" ngModel
                          #bcrm_reason="ngModel"></textarea>
                      </div>
                    </div>
                      <input type="submit" class="btn btn-primary pull-right" value="Request">
                    </form>
                   
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </div>