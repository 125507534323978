import { Component, OnInit } from '@angular/core';
import { GetAppointmentsService } from 'src/app/ApiServices/Booking/get-appointments.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LobbyService } from 'src/app/ApiServices/Booking/lobby.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HeaderComponent } from '../../../Navigation/header/header.component';
import { SickNotesService } from '../../../../../src/app/ApiServices/Sick/sick-notes.service';

declare var $: any;
@Component({
  selector: 'app-waiting-list-view',
  templateUrl: './waiting-list-view.component.html',
  styleUrls: ['./waiting-list-view.component.css']
})
export class WaitingListViewComponent implements OnInit {
  /********************Varilables*************************/
  allappointments: any; Lobbydata: any;
  loggedInUserID: string = environment.LoginUserId;
  CurrentAppointmentData: any; CurrentlobbyData: any;
  Obj: any; msg: any;
  SendMessageObj: any; SendMsgObj: any;
  ProxyData: any;
  userMainname: any;
  userMainId: any;
  bcrm_patientguardianvalue: any;
  Dataofthepatient: any;
  bcrm_patientguardianvalueAll: any;
  allappointmentsMain: any;
  UserName:any;
  RebrandUrl:any = [];
  RebrandUrlChecklength:boolean=false;
  RebrandUrldata:any = String;
  /**STRATING  Date Range */
  ToDate: string = new Date().toISOString().split("T")[0];
  FromDate: string = new Date().toISOString().split("T")[0];


  constructor(private appointmentsService: GetAppointmentsService,
    public lobbyservice: LobbyService,
    private router: Router,
    private toastr: ToastrService,
    private HeaderComponent: HeaderComponent, private sickNotesService: SickNotesService) {
    $(".waitingRoomNav").removeClass("active");
    $("#ListView").addClass("active");
  }

  ngOnInit() {
    debugger
    this.userMainname = environment.LoginUserfullName;
    this.userMainId = environment.LoginUserId;
    this.bcrm_patientguardianvalue = environment.LoginUserId;
    this.bcrm_patientguardianvalueAll = environment.LoginUserId;
    this.getAllProxy();
    this.getLooby(new Date().toISOString(), new Date().toISOString());
    setTimeout(() => {
      this.getFutureAppointment();
    }, 3000);
 

    $('body').on('click', '.fa-btn', function () {
      let allClass = $(this).find(".fa").attr('class');
      if (allClass.includes("fa-arrow-circle-down")) {
        $(this).find(".fa").removeClass('fa-arrow-circle-down').addClass("fa-arrow-circle-up")
      }
      else {
        $(this).find(".fa").removeClass('fa-arrow-circle-up').addClass("fa-arrow-circle-down")
      }
    });
  }



  



  /***************************Getting Appointments  Details ********************************** */
  getFutureAppointment(): void {

    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.appointmentsService.getFutureAppointment(environment.LoginUserId).subscribe((res) => {
        this.allappointmentsMain = res;         
      debugger
      this.allappointments =  this.allappointmentsMain ;
        if(this.allappointmentsMain != null){
          this.allappointments = this.allappointmentsMain.filter(x => x.bcrm_patientguardianName == environment.LoginUserfullName || !x.bcrm_patientguardianName);
          // for (let i = 0; i < this.allappointments.length; i++) {
          //   if(this.allappointments[i].bcrm_customerresponse != null){
          //     this.allappointments[i].bcrm_customerresponse = this.allappointments[i].bcrm_customerresponse;
          //   }
          // }
        }
      console.log(this.allappointments, "--allappointments");
      if (this.allappointments != null && this.allappointments.length > 5) {
        $("#allappointments").addClass("nav-list-grid");
      }
          $('#ftco-loader').removeClass('show');

      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    },
    
    (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/

    });
  }

  getAllProxy() {
    debugger
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.ProxyData = null;
    this.sickNotesService.getProxyDetails(environment.LoginUserId).subscribe((res) => {
      if (res != null) {
        this.ProxyData = res;
      }

      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/']);
    });
  }

  /***************************Getting Appointments  Details ********************************** */
  callAppointmentsAPI(ToDate, FromDate): void {

    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.appointmentsService.getAppointmentsByEmailAndRange(environment.LoginUserId, ToDate, FromDate).subscribe((res) => {
      debugger;
      this.allappointmentsMain = res;

      if (this.allappointmentsMain != null ) {
        this.allappointments = this.allappointmentsMain.filter(x => x.bcrm_patientguardianName == environment.LoginUserfullName || !x.bcrm_patientguardianName);
        console.log(this.allappointments, 'allappointments');
      }
      else{
        this.allappointments = null;
      }
      if (this.allappointments != null && this.allappointments.length > 5) {
        $("#allappointments").addClass("nav-list-grid");
      }
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/

    });
  }
  /***************************Getting lobby Details ********************************** */
  getLooby(ToDate, FromDate): void {
    this.lobbyservice.getLobbyDetailsByDateRange(environment.LoginUserId, ToDate, FromDate).subscribe((res) => {
      debugger;
      this.Lobbydata = res;
      console.log(this.Lobbydata, 'lobbydata');

      if (this.Lobbydata != null && this.Lobbydata.length > 5) {
        $("#Lobbydata").addClass("nav-list-grid");
      }
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
    });
  }
  ReloadComponent() {
    this.HeaderComponent.ReloadComponent();
  }
  /***************************On date Range Changed ********************************** */
  onSubmit(it: NgForm) {
    debugger
    var DateRange = it.value;
    let st_date = new Date(DateRange.toStartDate).toISOString();
    let end_Date = new Date(DateRange.fromEndDate).toISOString();
    if (end_Date >= st_date) {
      this.callAppointmentsAPI(st_date, end_Date);
      this.getLooby(st_date, end_Date);
    }
    else {
      this.toastr.show("Please Select Valid Date Range")
    }
  }
  openSendSMS(appointment) {
    debugger
    var localThis = this;
    localThis.Obj = appointment;
    let SMSObj = {
      appointmentId: localThis.Obj.Id,
      bcrm_patientname: localThis.Obj.contact_name,
      bcrm_doctorname: localThis.Obj.doctor_name,
      bcrm_doctoremail: localThis.Obj.docotremail,
      userId: localThis.Obj.contactid,
      bcrm_phonenumber: localThis.Obj.patientPhoneNumber,
      bcrm_smstype: 1, // 1 = Message
      bcrm_smsfrom: 2, // 2 == Patient
    };
    this.SendMsgObj = SMSObj;
    this.getMessages(this.SendMsgObj.appointmentId);
    $("#myModalcomment").modal("toggle");

  }
  sendMessage(SMS: NgForm) {
    debugger;
    $("#bcrm_message").val(null);
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /****************** -------- **********************/
    this.SendMessageObj = this.SendMsgObj
    //this.SendMessageObj.bcrm_message = SMS.value.bcrm_message;

          let MsgText = SMS.value.bcrm_message;

            $.each(this.RebrandUrl, function (index, value) {
                let indexCount = index + 1;
                if (index > 0) {
                  MsgText += ` {$N} ${indexCount} ${value}`;
                } else {
                  MsgText += `{$N}Attachments {$N}${indexCount} ${value}`;
                }
            });

      this.SendMessageObj.bcrm_message = MsgText;

    this.lobbyservice.sendMessage(this.SendMessageObj).subscribe((results) => {
      debugger;
      if (results == 'Success') {
        this.getMessages(this.SendMsgObj.appointmentId);
        $('#ftco-loader').removeClass('show');
        this.RebrandUrl = [];
      }
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/']);
    });
  }

  getMessages(appointmentId): void {
    debugger
    //*************Show / hide loader *******************/
     $('#ftco-loader').addClass('show');
    /**************************************** */
    this.msg = null;
    this.lobbyservice.getMessages(appointmentId).subscribe((res) => {
      debugger;
      if (res != null) {
        this.msg = res;
      }
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/'], { skipLocationChange: true });
    });
  }

  StartMeeting(appointment) {
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.appointmentsService.getStartMeetingUrl(appointment.Id, environment.LoginUserId).subscribe((res) => {
      var responce = res;
      if (responce != null) {
        //window.open(responce['URl'], `VideoCall`, `width=800,height=800`);
        let UserInfomation = JSON.parse(localStorage.getItem("idTokenClaims"));
        this.UserName = UserInfomation.name.split(" ")[0];
       // window.location.replace(`https://oxdh-acs-app.azurewebsites.net/?meetingUrl=${responce['URl']}&name=${this.UserName}`
        window.open(`https://oxvhmeeting.azurewebsites.net/home/JoinMeeeting?PId=${environment.LoginUserId}&AId=${appointment.Id}&GPId=${responce['GroupParticipantID']}&name=${this.UserName}&useAlternateUrl=False`
     
        ); 
       
      }
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      // this.router.navigate(['/'], { skipLocationChange: true });
    });
  }//StartMeeting

  ChecktheValueofProxyandFilterdata(Event) {
    debugger
    this.allappointments = null;
    var Dataofproxy = Event.currentTarget.value
    this.allappointments = this.allappointmentsMain.filter(x => x.bcrm_patientguardian == Dataofproxy);
  }


  ClosetheMsgPopuponBack(){
    $("#myModalcomment").modal("toggle");
  }

  openFileExplorer(openFileExplorerFor) {
    debugger;
    document.getElementById(openFileExplorerFor).click();
   // $scope.errorMsg = "";

}

onUploadFile(changeEvent): void {
  debugger
  var file = changeEvent.target.files[0];
  //if (file != undefined) {
    //return
  //} else {
    var filesize = Number(((file.size / 1024) / 1024).toFixed(4)); // MB  

    const validImageTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'application/pdf',
      'video/mp4',
      'text/plain',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-powerpoint',
      'application/vnd.ms-excel',
      'application/msword'
    ];

    if (!validImageTypes.includes(file['type'])) {
      this.toastr.info("This File Type Not Allowed to Upload ", "")
      $('input[type="file"]').val('');

    } else {
      //if (filesize <= 1) {
        //*************Show / hide loader *******************/
        $('#ftco-loader').addClass('show');
        /****************** -------- **********************/
        var payload = new FormData();
        payload.append("file", file);
        this.lobbyservice.GetRebrand(payload).subscribe((results) => {
          this.RebrandUrldata = results[0];
          if(results[0] == null){
            this.toastr.warning("File already exist, Please choose a different file");
          }
          else{
            this.RebrandUrl.push(this.RebrandUrldata);
          }
      
          if(this.RebrandUrl.length > 0){
            this.RebrandUrlChecklength = true;
          }
          $('#ftco-loader').removeClass('show');
          //this.toastr.success("File uploded Successfully ");         
        }, (err) => {
          //*************Show / hide loader *******************/
          $('#ftco-loader').removeClass('show');
          /****************** -------- **********************/
          //this.toastr.error(err, "Something went wrong");
          //this.router.navigate(['/']);
        });
     // }
      // else {
      //   $('input[type="file"]').val('');
      //   this.toastr.info("The Maximum Size Limit of Document is 1 MB", "");
      // }
    }
  //}
}

removeLink(item) {
  debugger;
  var index = this.RebrandUrl.indexOf(item);
  this.RebrandUrl.splice(index, 1);
}


}