import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class AllergyService {

  constructor(private http: HttpClient) { }

  getAllergy(ID) {
    
    return this.http.get(`${environment.apiUrl}/api/Allergy/GetAllergy?Id=${ID}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  createAllergy(allergy, userId) {
    
    return this.http.post(`${environment.apiUrl}/api/Allergy/CreateAllergy?userId=${userId}`, allergy).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  updateAllergy(allergy, userId) {
    
    return this.http.post(`${environment.apiUrl}/api/Allergy/UpdateAllergy?userId=${userId}`, allergy).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  deleteAllergy(allergyid) {
    
    return this.http.delete(`${environment.apiUrl}/api/Allergy/DeleteAllergy?Id=${allergyid}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }



  handleError(error) {
    
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */
    let errorMessage = '';
    let errorMessageUi='';
    if (error.error instanceof ErrorEvent) {
      // client-side error 
      errorMessage = `Error: ${error.error.message}`;  
      errorMessageUi=error.error.message;
    } else {
      // server-side error 
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      if(error.error!=undefined){
        errorMessageUi=error.error.message
      }
      else{
        errorMessageUi=error.message
      }
    }
    //  // Show error on hte Ui and Deirect to home
    //  this.toastr.error("Error", errorMessageUi);
    //  this.router.navigate(['/']);

    return throwError(errorMessageUi);
  }
}
