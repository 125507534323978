<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">

                        <div class="row">
                            <div class="col-md-3 d-none d-md-block">
                                <div class="card">
                                    <div class="card-body">
                                        <h6 class="card-title mb-4">Appointments Status</h6>
                                        <div id='external-events' class='external-events'>
                                            <div id='external-events-listing'>
                                                <div class='fc-event'
                                                    style="border-left: 3px solid #10b759;background: #f1fef7;">
                                                    Completed</div>
                                                <div class='fc-event'
                                                    style="border-left: 3px solid #ff3366;background: #fff0f4;">
                                                    Cancelled </div>
                                                <div class='fc-event'
                                                    style="border-left: 3px solid #66d1d1;background: #f7fdfd;">
                                                    Running</div>
                                                <div class='fc-event'
                                                    style="border-left: 3px solid #fbbc06;background: #fffbf2;">
                                                    Waiting</div>
                                                <div class='fc-event'
                                                    style="border-left: 3px solid #495057;background: #f7fdfd;">
                                                    Others Appointments</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="card"
                                    *ngIf="CurrentAppointmentData!=null && CurrentAppointmentData.isMyAppointment==true">
                                    <div class="card-body">
                                        <h6 class="card-title mb-4">Appointment Details</h6>
                                        <div id='external-events' class='external-events'>
                                            <table class="table table-bordered">
                                                <tr class="text-center">
                                                    <th colspan="2" class="text-center">Appointment Details</th>
                                                </tr>
                                                <tr>
                                                    <td>Doctor</td>
                                                    <td>{{CurrentAppointmentData.title}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Doctor Status</td>
                                                    <td>{{CurrentAppointmentData.bcrm_doctorstatusformatted}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Subject</td>
                                                    <td>{{CurrentAppointmentData.subject}}</td>
                                                </tr>
                                                <tr class="text-center"
                                                    *ngIf="!CurrentAppointmentData.bcrm_ispatientready && CurrentAppointmentData.bcrm_doctorstatus==1">
                                                    <td colspan="2"><button class="btn btn-primary"
                                                            (click)="setPatientStatus(CurrentAppointmentData)">Set
                                                            Status Ready</button></td>
                                                </tr>
                                                <tr class="text-center">
                                                    <!--*ngIf="CurrentAppointmentData.bcrm_ispatientready"-->
                                                    <td colspan="2"
                                                        *ngIf="CurrentAppointmentData.bcrm_doctorstatus==2 &&  CurrentAppointmentData.teams_URL!=null">
                                                        <a (click)="openTeamsMeeting(CurrentAppointmentData.teams_URL)"
                                                            class="btn btn-primary"
                                                            style="color: white;background-color: #727cf5;" disabled>
                                                            Join
                                                            Meeting Now
                                                        </a>
                                                    </td>
                                                    <td colspan="2" *ngIf="CurrentAppointmentData.bcrm_doctorstatus==1">
                                                        Waiting For Doctor's
                                                        Side
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-9">
                                <div class="card">
                                    <div class="card-body">
                                        <div id='fullcalendar1'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>