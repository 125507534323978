import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CrmconditionsService {

  constructor(private http: HttpClient) { }
  getConditions(id) { 
    return this.http.get(`${environment.apiUrl}/api/Condition/GetConditions?id=${id}`);
  }
}
