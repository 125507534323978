<div class="bradcam_area breadcam_bg bradcam_overlay">
    <div class="container">
       <div class="row">
          <div class="col-xl-12">
             <div class="bradcam_text">
                <h3>Privacy Policy</h3>
                <p><a href="">Home /</a> Privacy Policy</p>
             </div>
          </div>
       </div>
    </div>
 </div>
 <br />
 <div class="parent-page">   

            
			
            
    <div class="section content-wrap">   

    <div class="container">
    
        <article class="row">
            
            <div class="col-sm-12">
                
                <div class="row">
                    <div class="main-content col-sm-12">
                        
<p><strong>OX.virtual clinic (ox.vc) Privacy Policy</strong></p>
<ol>
<li>Introduction</li>
</ol>
<p>This privacy policy (<strong>Policy</strong>) relates to your personal information in connection with your use of and access to the OX. Virtual Clinic ‘Platform’. We sometimes shorten the name to the OX.vc Platform, which is made up of the <a href="http://www.oxvc.health">www.oxvc.health</a> ‘Website’ and the ‘Online Services’ that can be accessed via the website.   </p>
<p>We are committed to protecting your Information and your right to privacy.  If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at <a href="mailto:info@oxvc.health">info@oxvc.health</a>.</p>
<p>When you use the OX.vc Platform, you trust us with your Information. We take your privacy very seriously. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. <strong>If you are not happy for your information to be used in the ways described in this Policy you should stop using the OX.vc Platform immediately.  </strong></p>
<ol start="2">
<li>Changes to this Policy or your personal information</li>
</ol>
<p>We review this Policy regularly and it is your responsibility to check regularly and determinate whether you still agree to comply with the policy.  If you do not agree to any change to this Policy then you must immediately stop using OX.vc’s Platform.  In the event we make any significant changes to this Policy we will use our reasonable endeavours to inform you or such changes in advance in writing.</p>
<p>It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes for example you change your email address.</p>
<ol start="3">
<li>About us</li>
</ol>
<p>The OX.vc Platform is owned and operated by OX. Virtual Clinic Limited a company registered in England and Wales with company number 10664365 and whose registered office is situated at The Cow Shed, 19 Wharf Road, Shillingford, Oxfordshire, OX10 7EW (“<strong>OX. Virtual Clinic OX.vc/we/us/our</strong>”).  The term “you” refers to the user wishing to access and/or use OX.vc’s Platform. </p>
<ol>
<li>Virtual Clinic is not a provider of medical or diagnostic services. Our Platform enables medical clinicians and individuals seeking medical advice or assistance to connect through the Platform. Through their use of the Platform, Patients and Clinicians can schedule appointments, access a virtual waiting room and meet for virtual consultations during which Clinicians can take notes, give medical advice, issue prescriptions and refer Patients for tests or specialist consultations.</li>
</ol>
<p>&nbsp;</p>
<p>The Platform should not be used for support in relation to medical emergencies.  If you believe that you or the person you are assisting is in need of urgent medical care you should immediately dial 999.</p>
<p>&nbsp;</p>
<p>For the purpose of Data Protection Laws, we are the  data controller for any personal data that you share with us in relation to your account when using the Platform.  We are the processor, on behalf of your Clinician, in respect of any medical data or information which you provide through the Platform for use by the Clinician providing Clinical Services.</p>
<ol start="4">
<li>Information we may collect about you
<ul>
<li><strong>Personal information you disclose to us</strong></li>
</ul>
</li>
</ol>
<p><strong>In Short: We collect personal information that you provide to us including information such as name, address, contact information and health and medical data. </strong></p>
<p>We collect personal information that you voluntarily provide to us when registering and using OX.vc’s Platform, or otherwise contacting us. The personal information that we collect depends on the context of your interactions, the choices you make and the products and features you use. When you use the Platform and/or when you otherwise deal with us, we may collect the following information about you (the Information):</p>
<ul>
<li>Identity Data which includes first name, last name and gender.</li>
<li>Contact Data which means the data we use to contact you including your billing address, delivery address, email address and contact number.</li>
<li>If you make payments through OX.vc’s Platform, Financial Data which means the payment method and card association used to process your payments for your orders. We do not store or process your card details ourselves, they are processed and stored via one of our contracted third party service providers. We encrypt your payment card details in your browser and securely transfer this data to our relevant third-party payment provider to process a payment.</li>
<li>Transaction Data which means details about transactions you have made using the Platform including the payments to and from you along with other details of products you have purchased from us.</li>
<li>Profile Data which includes your username (email address), your login data, purchases or orders made by you, your interests, preferences, feedback and survey responses.</li>
<li>Health and Medical Data, such as the information which you provide us when you register to use the Platform and when you use the Clinical Services. This might include your NHS number, the Clinicians you use, details of the consultations with Clinicians including details of your treatment and care (including any diagnosis, medical advice and comments your Clinician captures), interactions with our Online Services (e.g. chatbots), details of your prescriptions, details on any devices or wearables you use, results of any investigations carried in connection with the Clinical Service that are uploaded in connection with the Platform.  It may also include information on your next of kin and carers. If you have given consent for us to do so, the Clinician we will send the consultation notes that they take during your use of the Clinical Services to your NHS GP.
<ul>
<li>We retain recordings of our consultations and interactions in connection with the Clinical Services. This can include your use of our chatbot service, video and audio recordings or audio-only recordings. This is in order to provide you with an easy way to check your Clinical Services when you wish to, and so that we can ensure high quality care is provided to you. To monitor our service quality, we may retain records of when you contact our support teams via email, phone or interactions with our Online Services (e.g. chatbots)</li>
<li>Information about your ethnicity, sexual orientation, sex life, religious beliefs or opinion or genetic data where this has been captured in connection with the Clinical Services for demographic analysis.</li>
</ul>
</li>
<li>Usage Data which includes information about how you use the Platform. This includes your browsing patterns and information such as how long you might spend on one of our Webpages OX.vc’s Platform and what you look at and for, the page that referred you to OX.vc’s Platform and the click stream during your visit to our website, page response times and page interaction information (clicks you make on a page). We do this to improve our Website and the provision of Online Services.</li>
<li>Marketing and Communications Data which includes your preferences in receiving marketing from us and your communication preferences. You many opt in or out.</li>
<li>Other information relevant to services, customer surveys and/or offers.</li>
</ul>
<p>All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to your Information.</p>
<ul>
<li><strong>Information automatically collected </strong></li>
</ul>
<p><strong>In Short: Some information – such as IP address and/or browser and device characteristics – is collected automatically when you use the OX.vc’s Platform.</strong></p>
<p>We automatically collect certain information when you visit, use or navigate OX.vc’s Platform. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use the Platform and other technical information. This information is primarily needed to maintain the security and operation of the Platform, and for our internal analytics and reporting purposes.</p>
<p>Like many businesses, we also collect information through cookies and similar technologies. You can find out more about this in our Cookies Policy.</p>
<ol start="5">
<li>How do we use your Information?</li>
</ol>
<p><strong>In Short: We process your Information for purposes based on legitimate business interests, the fulfilment of our contract with you, compliance with our legal obligations, and/or your consent.</strong></p>
<p>We use your Information collected via OX.vc’s Platform for a variety of business purposes described below. We process your Information for these purposes in reliance on our legitimate business interests (Business Purposes), in order to enter into or perform a contract with you (Contractual), with your consent (Consent), and/or for compliance with our legal obligations (Legal Reasons). We indicate the specific processing grounds we rely on next to each purpose listed below.</p>
<ul>
<li>We may process your Information for the following purposes:</li>
<li>Fulfil and manage any Online Services (Contractual). We may use your Information or pass it onto Clinicians in order to do this.</li>
<li>To send administrative information to you for Business Purposes, Legal Reasons and/or possibly Contractual. We may use your Information to send you product, service and new feature information and/or information about changes to our Terms and policies.</li>
<li>To send you marketing and promotional communications for Business Purposes and/or with your Consent. We and/or our brand partners may use your Information for our marketing purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing emails, see below for further details. You many opt in or out.</li>
<li>To facilitate account creation and logon process with your Consent.</li>
<li>Where you have provided your explicit consent, we will use your medical information (always having removed personal identifiers, such as your name, address and contact details) to improve the Platform, and our artificial intelligence system, so that we can deliver better services to you and other users. This medical information (with your personal identifiers removed in the way described above) may include your medical record (both records received and created by us), transcripts and recordings of your consultations, and your interactions with our digital services. This does not involve making any decisions which would have a significant effect on you – it is only about improving the Platform so that we can deliver a better experience to you and other users. Strict confidentiality and data security provisions apply at all times. This consent relates to information that can identify you.</li>
<li>Request Feedback for our Business Purposes and/or with your Consent. We may use your Information to request feedback and to contact you about your use of the Platform.</li>
<li>To protect OX.vc’s Platform for Business Purposes and/or Legal Reasons. We may use your Information as part of our efforts to keep the Platform safe and secure (for example, for fraud monitoring and prevention).</li>
<li>To enforce our Terms and policies for Business Purposes, Legal Reasons and/or possibly Contractual.</li>
<li>To respond to legal requests and prevent harm for Legal Reasons. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</li>
<li>For other Business Purposes. We may use your Information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve the Platform and your experience.</li>
<li>In addition, where the personal data that we will be processing is “special data” (also known as “sensitive data” which should be given additional safeguards, such as health information, we will only process that information if there is a valid special condition for processing (as set out in Article 9 of the GDPR).  For the purposes of health data, we will rely on Special Condition Article 10(2)(h) (processing of health data where there are adequate safeguards and confidentiality obligations in place).</li>
</ul>
<ol start="6">
<li>Will your Information be shared with anyone?</li>
</ol>
<p><strong>In Short: We only share information to comply with Contractual obligations, fulfil our Business Purposes, or with your Consent.</strong></p>
<p>We only share and disclose your Information in the following situations:</p>
<ul>
<li>Contractual: to share information with Clinicians and other providers such as pharmacy and radiology to enable any services to be fulfilled.</li>
<li>Compliance with Legal Obligations. We may disclose your Information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements). Your Information may also be shared with regulators like the General Medical Council or the Health Service Ombudsman. We will use our commercially reasonable endeavours to notify you in advance if your Information is to be disclosed to any regulators (where permitted).</li>
<li>Vital Interests. We may disclose your Information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved. For example, we may share information with bodies with public health responsibilities such as local councils and Public Health England to control infectious diseases such as meningitis, tuberculosis (TB) or measles and manage public health incidents.</li>
<li>Third-Party Service Providers. We may share your Information with third-party vendors, service providers, contractors or agents who perform services and require access to such information to do that work. Examples include: data analysis, email delivery, hosting services, customer service and marketing efforts. They will only have access to your Information to the extent that they need to perform those services. They are required to keep your Information confidential and may not use it other than as we ask them to and always in accordance with this Policy.</li>
<li>Third-Party Advertisers. We may use third-party advertising companies to serve ads when you visit OX.vc’s Platform. These companies may use information about your visits to the Platform and other websites that are contained in web cookies and other tracking technologies in order to provide advertisements about goods and services of interest to you. See our Cookie Policy for further information</li>
<li>Business Partners. Provided you have given us Consent to do so we may share your Information with our business partners to offer you certain products, services or promotions.</li>
<li>With your Consent. We may disclose your Information for any other purpose with your Consent, for example if you give us Consent to share information with your General Practitioner (GP).</li>
</ul>
<p>We may disclose aggregated, anonymous information (i.e. information from which you cannot be personally identified), or insights based on such anonymous information (for example the number of users of the Platform, to selected third parties, including (without limitation) analytics and search engine providers to assist us in the improvement and optimisation of the Platform. In such circumstances we do not disclose any information which can identify you personally.</p>
<ol start="7">
<li>Is your Information transferred internationally?</li>
</ol>
<p><strong>In Short: We may transfer, store, and process your Information in countries other than your own but will take all reasonable steps to ensure it is protected.</strong></p>
<p>Whenever we transfer your Information outside of the UK, we will take all reasonable measures that we can to protect your Information in accordance with this Policy and applicable law.  To the extent that any transfer requires approved safeguards are in place (for example if transferring outside of the European Economic Area that the EU model contract clauses or if to the USA that the entity it is transferred to is EU/US Privacy Shield registered) we will ensure these measures are in place.</p>
<ol start="8">
<li>Third-party websites</li>
</ol>
<p><strong>In Short: We are not responsible for the safety of any information that you share with third-party providers who feature or advertise, but are not affiliated with OX.vc. </strong></p>
<p>OX.vc’s Platform may feature links to third-party websites or contain advertisements from third parties that are not affiliated with us and which may link to other websites, online services or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this Policy. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services or applications that may be linked to or from OX.vc’s Platform. You should review the policies of such third parties and contact them directly to respond to your questions.</p>
<ol start="9">
<li>How long do we keep your Information?</li>
</ol>
<p><strong>In Short: We keep your Information for as long as necessary to fulfil the purposes outlined in this Policy unless otherwise required by law.</strong></p>
<p>We will only keep your Information for as long as it is necessary for the purposes set out in this Policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements).</p>
<p>When we have no ongoing Business Purpose to process your Information, we will either delete or anonymize it, or, if this is not possible (for example, because your Information has been stored in backup archives), then we will securely store your Information and isolate it from any further processing until deletion is possible.</p>
<ol start="10">
<li>How do we keep your Information safe?</li>
</ol>
<p><strong>In Short: We aim to protect your Information through a system of organisational and technical security measures.</strong></p>
<p>We have implemented appropriate technical and organizational security measures designed to protect the security of any Information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your Information, transmission of personal information to and from OX.vc’s Platform is at your own risk. You should only access our services within a secure environment.</p>
<ol start="11">
<li>Do we collect information from minors?</li>
</ol>
<p><strong>In Short: We take steps to ensure that the Platform is only used by those over 18 years of age and we do not knowingly collect data from or market to children under 18 years of age.</strong></p>
<p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Platform, you represent that you are at least 18 years of age. If we learn that Information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at <a href="mailto:info@oxvc.health">info@oxvc.health</a>.</p>
<ol start="12">
<li>Your Consent to processing</li>
</ol>
<p>You will be required to give Consent to certain processing activities before we can process your Information. Where applicable, we will seek Consent from you when you first submit Information to or through the Platform.</p>
<p>If you have previously given your Consent you may freely withdraw such Consent at any time. You can do this by emailing <a href="mailto:info@oxvc.health">info@oxvc.health</a>.</p>
<p>If you withdraw your Consent, and if we do not have another legal basis for processing your Information, then we will stop processing your Information. If we do have another legal basis for processing your Information, then we may continue to do so subject to your legal rights.</p>
<p>Please note that if we need to process your Information in order for you to use the Platform and you object or do not provide Consent to us processing your Information, the Platform will not be available to you.</p>
<ol start="13">
<li>Marketing and opting out</li>
</ol>
<p>If you have given Consent to marketing we may contact you about our products, services, promotions and special offers.  If you no longer wish to receive such information you can withdraw your Consent at any time by sending an email to <a href="mailto:info@oxvc.health">info@oxvc.health</a> or unsubscribe from the communications.</p>
<p>If you have given Consent, we may share your Information with carefully selected third-party organisations and business partners and they may contact you directly. If you prefer not to receive direct marketing communications from the third-party please contact them directly to withdraw the Consent.</p>
<ol start="14">
<li>Account Information</li>
</ol>
<p>You may at any time review or change your personal account information by logging into your OX.vc account. </p>
<p>If you wish to terminate your OX.vc account please contact us via <a href="mailto:info@oxvc.health">info@oxvc.health</a> and we can arrange this for you.  Some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms and/or comply with legal requirements.</p>
<ol start="15">
<li>What are your privacy rights?</li>
</ol>
<p><strong>In Short: You have certain rights in respect of your Information.</strong></p>
<p>You have certain rights in relation to the Information that we hold about you.  Details of these rights and how to exercise them are set out below.  Please note we will require evidence of your identity before we are able to respond to your request. This is a security measure to ensure that your Information is not disclosed to a person who does not have the right to receive it.  We may also contact you to ask you for further information in relation to your request to speed up your response.   To exercise or discuss any of your rights please contact us at <a href="mailto:info@oxvc.health">info@oxvc.health</a>.</p>
<ul>
<li><strong>Right of Access.</strong> You have the right at any time to ask us for a copy of the Information that we hold about you and to check that we are lawfully processing it.  Where we have good reason, and where data protection law permits, we can refuse your request for a copy of your Information, or certain elements of the request.  If we refuse your request or any element of it, we will provide you with our reasons for doing so.</li>
<li><strong>Right of Correction or Completion.</strong> If Information we hold about you is not accurate or is out of date and requires amendment or correction you have a right to have the data rectified or completed.</li>
<li><strong>Right of Erasure.</strong> In certain circumstances, you have the right to request that the Information we hold about you is erased e.g. if the information is no longer necessary for the purposes for which it was collected or processed or our processing of the Information is based on your consent and there are no other legal grounds on which we may process the Information.</li>
<li><strong>Right to Object to or Restrict Processing</strong>. In certain circumstances, you have the right to object to our processing of your Information.  For example, if we are processing your Information on the basis of our legitimate interests and there are no compelling legitimate grounds for our processing which override your rights and interests.</li>
</ul>
<p>You may also have the right to restrict our use of your Information, such as in circumstances where you have challenged the accuracy of the Information and during the period where we are verifying its accuracy.</p>
<ul>
<li><strong>Right of Data Portability</strong>. In certain instances, you have a right to receive your Information that we hold about you in a structured, commonly used and machine-readable format.</li>
</ul>
<p>In such circumstances, you can ask us to transmit your Information to you or directly to a third party organisation.</p>
<p>While we are happy for such requests to be made, we are not able to guarantee technical compatibility with a third party organisation’s systems.  We are also unable to comply with requests that relate to personal information of others without their consent.</p>
<p>If we are relying on Consent to process your Information, you have the right to withdraw your Consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.</p>
<ol start="16">
<li>Contact us</li>
</ol>
<p>We welcome your feedback and questions on this Policy. If you wish to contact us about this Policy, about our Information or any other questions, please email us at <a href="mailto:contact@oxvc.health">contact@oxvc.health</a>.</p>
<p>You have the right to make a complaint at any time to the Information Commissioner’s Office (the <strong>ICO</strong>), the UK supervisory authority for data protection issues (<a href="https://ico.org.uk/concerns/">https://ico.org.uk/concerns/</a>). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.</p>
<p>&nbsp;</p>
                    </div>

                                        </div>
            </div>
            
        </article>
    
    </div>

</div>
            


</div>

