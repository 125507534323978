<!-- <section style="margin: 3% 0% 1% 0%;">
  <div class="row no-gutters" style="justify-content: center;">

      <div class="col-lg-9 col-md-12 heading-section3">
        <h1 style="color: #fff;font-family: 'Yantramanav', sans-serif;margin: 0% 0% 0% 2%;">Prescriptions</h1>
      </div>
    </div>
</section>
ftco-section -->
<section class="m-3 ">
    <div class="container px-md-0 table-responsive">

        <table class="table table-bordered" id="prescriptionRecord">
            <thead class="thead-light">
                <tr class="trColor">
                    <th class="thFont" colspan="11">
                        <div class="pull-right"><input type="button" routerLink='../requestprescription' skipLocationChange class="btn btn-primary" value="{{'MedicalHistory.Prescription.RequestPrescription'|translate}}"></div>
                    </th>
                </tr>
                <tr>
                    <th style="display: none;">S/No</th>
                    <!-- <th>{{'MedicalHistory.Tabs.prescription'|translate}}</th> -->
                    <th>{{'MedicalHistory.Prescription.Drug'|translate}}</th>
                    <th>{{'PrecriptionDetail.Dosage'|translate}}</th>
                    <th>{{'MedicalHistory.Prescription.DosageUnit'|translate}}</th>
                    <th>{{'MedicalHistory.Prescription.Directions'|translate}}</th>
                    <!-- <th>{{'MedicalHistory.Prescription.StartDate'|translate}}</th>
                    <th>{{'MedicalHistory.Prescription.EndDate'|translate}}</th>
                    <th>{{'MedicalHistory.Prescription.ExpiryDate'|translate}}</th> -->
                    <th>{{'Common.comments'|translate}}</th>
                    <!-- <th>{{'MedicalHistory.StoredSample.type'|translate}}</th> -->
                    <th>{{'Common.Status'|translate}}</th>
                    <th>Requested On</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of prescriptiondrugs; index as i">
                    <td style="display: none;">{{i + 1}}</td>
                    <!-- <td>{{data.bcrm_prescriptionHeaderName}}</td> -->
                    <td>{{data.bcrm_drug}}</td>
                    <td>{{data.bcrm_dosage}}</td>
                    <td>{{data.bcrm_dosageunit}}</td>
                    <td>{{data.bcrm_directions}}</td>
                    <!-- <td><span *ngIf="data.bcrm_drugstartdate!= '0001-01-01T00:00:00'">{{data.bcrm_drugstartdate | date:'dd/MM/yyyy'}}</span>
                    </td>
                    <td><span *ngIf="data.bcrm_drugenddate!= '0001-01-01T00:00:00'">{{data.bcrm_drugenddate | date:'dd/MM/yyyy'}}</span>
                    </td>
                    <td><span *ngIf="data.bcrm_expirydate!= '0001-01-01T00:00:00'">{{data.bcrm_expirydate | date:'dd/MM/yyyy'}}</span>
                    </td> -->
                    <td>{{data.bcrm_startcomment}}</td>
                    <!-- <td>{{data.bcrm_type}}</td> -->
                    <td>{{data.bcrm_statusFormattedName}}</td>
                    <td>{{data.createdOn | date:'dd-MM-yyyy'}}</td>
            </tbody>
        </table>
        
    </div>
</section>

<div id="Modal1" class="modal fade">
    <!-- class modal and fade -->
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <!-- modal header -->
                <h5 class="modal-title">{{'MedicalHistory.Prescription.RequestPrescription'|translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
            ×
          </button>
            </div>
            <div class="modal-body  result-shade1">
                <!-- modal body -->
                <form class="form" #it="ngForm" (ngSubmit)="onSubmit(it)" #f="ngForm" autocomplete="off">

                    <div class="form-group row" id="name">
                        <label class="control-label col-sm-2">{{'Common.name'|translate}}:</label>
                        <div class="col-sm-10">
                            <input type="text" name="Name" class="form-control" [(ngModel)]="prescriptionName" ngModel required #Name="ngModel" placeholder="Enter name">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="control-label col-sm-2">{{'StoredSample.type'|translate}}:</label>
                        <div class="col-sm-10">
                            <select class="form-control" id='purpose' name="bcrm_type" (change)="onType($event.target.value)" ngModel required #bcrm_type="ngModel" #name="ngModel" [(ngModel)]="selectedQuantity">
                  <option [disabled]="item.disabled" *ngFor="let item of items" [value]="item.id">{{item.name}}</option>
                </select>
                        </div>
                    </div>

                    <div class="form-group row" id="Prescription">
                        <label class="control-label col-sm-2">{{'MedicalHistory.Prescription.Drug'|translate}}:</label>
                        <div class="col-sm-10">
                            <div class="row">
                                <div class="col-md-4 col-sm-6 col-xs-12">
                                    <select class="form-control" id="drugid">
                          <option value="" selected disabled>Select Drugs</option>
                          <option  *ngFor="let drug of prescriptiondrugsdropdown" [value]="drug.Id">{{drug.name}}</option>
                      </select>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-12">
                                    <input class="form-control" type="text" id="comments" placeholder="Add Comments" />
                                </div>
                                <div class="col-md-2 col-sm-6 col-xs-12">
                                    <a style="cursor: pointer;color: #3498DB;"><i class="fa fa-plus" aria-hidden="true" (click)="addDrugIntoList()"></i></a>
                                </div>
                            </div><br>
                            <table class="table tablelist">
                                <thead class="">
                                    <tr>
                                        <th></th>
                                        <th>Drug</th>
                                        <th>{{'PrecriptionDetail.Frequency'|translate}}</th>
                                        <th>{{'PrecriptionDetail.Dosage'|translate}}</th>
                                        <th>{{'Common.comments'|translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let prescriptionDrug of prescriptiondruglist; let i = index;">
                                        <td>{{i+1}}</td>
                                        <td>{{prescriptionDrug.bcrm_name}}</td>
                                        <td>{{prescriptionDrug.bcrm_frequency}}</td>
                                        <td>{{prescriptionDrug.bcrm_dosage}}</td>
                                        <td>{{prescriptionDrug.bcrm_startcomment}}</td>
                                        <td><i class="fa fa-window-close" style="color:red;cursor: pointer;" (click)="removedrugs(prescriptionDrug.Id)" aria-hidden="true"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="form-group row" id="drugs">
                        <label class="control-label col-sm-2">{{'MedicalHistory.Prescription.Drug'|translate}}:</label>
                        <div class="col-sm-10">
                            <select class="js-example-basic-multiple" placeholder="Enter name" multiple="multiple" id="drugList">
                </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label col-sm-2">{{'MedicalHistory.Prescription.Reason'|translate}} :</label>
                        <div class="col-sm-10">
                            <textarea type="text" class="form-control" id="bcrm_reason" name="bcrm_reason" rows="3" #name="ngModel" ngModel #bcrm_reason="ngModel" #name="ngModel"></textarea>
                        </div>
                    </div>



                    <input type="submit" class="btn btn-primary pull-right" value="{{'Common.Save'|translate}}">
                </form>


            </div>
        </div>
        <!-- / .modal-content -->

    </div>
    <!-- / .modal-dialog -->

</div>