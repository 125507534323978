<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">

            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <!-- <h6 class="card-title">Procedures</h6> -->
                            <!-- <p class="card-description">Read the <a href="https://datatables.net/" target="_blank"> Official DataTables Documentation </a>for a full list of instructions and other options.</p> -->
                            <div class="row heading">
                                <div class="col">

                                    <h5>  Online Consultation <span
                                            style="color: gray;"></span></h5>
                                </div>

                                <div class="col"> &nbsp; <button type="button" class="btn btn-primary float-right ml-1"
                                    (click)="RefreshPage()"><i class="fa fa-refresh ref"
                                        aria-hidden="true"></i></button>
                                        &nbsp; <button type="submit" href="#Modal1"
                                        data-toggle="modal" class="btn btn-primary float-right mr-2 mb-2 mb-md-0">+ Add Online
                                        Consultation</button>
                                </div>

                            </div>

                            <hr>
                            <div class="table-responsive">
                                <table class="table" id="dataTableExample">
                                    <thead>
                                        <tr>
                                            <!-- <th>{{'Documents.OnlineConsultationNumber'|translate}}</th> -->
                                            <th style="display: none;">S/No</th>
                                            <th>Title</th>
                                            <th>Type</th>
                                            <th>{{'Documents.Priority'|translate}}</th>
                                            <!-- <th>Created By</th> -->
                                            <th>Patient/Proxy</th>
                                            <th>{{'Common.createdOn'|translate}}</th>
                                           
                                            <!-- <th>{{'Documents.Customer'|translate}}</th> -->
                                            <th>{{'Common.status'|translate}}</th>
                                            <th>Number</th> 
                                            <th>Edit / Attachment</th>
                                        </tr>
                                        
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of CaseDeatils; index as i">
                                            <!-- <td>{{data.ticketnumber}}</td> -->
                                            <td style="display: none;">{{i + 1}}</td>
                                            <td> {{data.title}}</td>
                                            <td> {{data.casetype}}</td>
                                            <td> {{data.priority}}</td>
                                            <!-- <td>{{data.customeridname}}</td> -->
                                            <td>{{data.customeridname}} <p  *ngIf="data.guardianName">({{data.guardianName}})</p></td>
                                            <td> {{data.createdon | date:"dd-MMMM-yyyy, HH:mm"}}</td>
                                            <!-- <td> {{data.customeridname}}</td> -->
                                            <td>{{data.statuscodename}}</td>
                                            <td>{{data.ticketnumber}}</td>
                                            <th style="padding-left: 23px !important;">
                                                <span *ngIf="data.statuscodename == 'In Progress'"
                                                    href="#myModalcomment"><a href="#Modal2" data-toggle="modal"
                                                        class="fa fa-pencil" (click)="editcase(data)"
                                                        aria-hidden="true"></a></span>&nbsp;&nbsp;&nbsp;
                                                <a (click)="openSendSMS(data.Id,data)" style="color: white;"><i
                                                        class="fa fa-comments" aria-hidden="true"
                                                        style="color:#3498DB;"></i>
                                                </a>
                                            </th>

                                        </tr>
                                    </tbody>
                                </table>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="Modal1" class="modal fade">
                <!-- class modal and fade -->
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <!-- modal header -->
                            <h5>Add Online Consultation</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clearOnlineC()">
                                ×
                            </button>
                        </div>
                        <div class="modal-body  result-shade1">
                            <!-- modal body -->
                            <form class="form" #case="ngForm" (ngSubmit)="onSubmit(case)" #f="ngForm"
                                autocomplete="off">

                                <div class="form-group row">
                                    <label class="control-label col-sm-3 required">{{'Common.title'|translate}}</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" placeholder="Enter title" name="title"
                                            ngModel required #title="ngModel" id="title">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="control-label col-sm-3 required">Patient/Proxy</label>
                                    <div class="col-sm-9">
                                    <select class="list-dt  form-control" id="bcrm_patientguardian" name="prioritycode"
                                    #bcrm_patientguardian="ngModel" [(ngModel)]="bcrm_patientguardianvalue"  (change)="ChecktheGuardianValue($event)">
                                            <option value="" disabled>Select Guardian
                                            </option>
                                            <option value={{userMainId}}>{{userMainname}}
                                                (Me)</option>
                                            <option [value]="data.relatedPersonId"
                                                *ngFor="let data of ProxyData"
                                                class="form-control">{{data.fullName}}
                                            </option>
                                        </select>
                                </div>
                            </div>

                                <div class="form-group row">
                                    <label class="control-label col-sm-3 ">{{'Documents.Priority'|translate}}</label>
                                    <div class="col-sm-9">
                                        <select class="list-dt  form-control" id="prioritycode" name="prioritycode"
                                            ngModel #prioritycode="ngModel">
                                            <option value="" selected disabled>Select Priority</option>
                                            <option value="1">High</option>
                                            <option value="2">Normal</option>
                                            <option value="3">Low</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label
                                        class="control-label col-sm-3 ">{{'Documents.OnlineConsultationType'|translate}}</label>
                                    <div class="col-sm-9">
                                        <select class="list-dt  form-control" id="casetypecode" name="casetypecode"
                                            ngModel #casetypecode="ngModel">
                                            <option value="" selected disabled>Select Type</option>
                                            <option value="1">Question</option>
                                            <option value="2">Problem</option>
                                            <option value="3">Request</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label
                                        class="control-label col-sm-3 required">{{'Common.description'|translate}}</label>
                                    <div class="col-sm-9">
                                        <textarea type="text" class="form-control" placeholder="Enter Description"
                                            name="description" ngModel required #description="ngModel" id="description"
                                            rows="10"></textarea>
                                    </div>
                                </div>

                                <!-- <div class="form-group row">
                        <label class="control-label col-sm-3 required">Upload file</label>
                        <div class="col-sm-9">
                            <p>:-<br> (jpg, jpeg, png, xlsx, word, pdf, mp4, mp3, docx, doc, txt)</p>
                            <input type="file" (change)="IncomingFile($event.target.files);"/>
                        </div>
                    </div> -->
                                <input type="submit" class="btn btn-primary pull-right" value="Submit"
                                    [disabled]="!case.valid">
                            </form>
                        </div>
                    </div>
                    <!-- / .modal-content -->

                </div>
                <!-- / .modal-dialog -->

            </div>

            <div id="Modal2" class="modal fade">
                <!-- class modal and fade -->
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <!-- modal header -->
                            <h5>Edit Online Consultation</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                                ×
                            </button>
                        </div>
                        <div class="modal-body  result-shade1">
                            <!-- modal body -->
                            <form class="form" #editCase="ngForm" (ngSubmit)="onUpdate(Case)" #f="ngForm"
                                autocomplete="off">
                                <div class="form-group row">
                                    <label class="control-label col-sm-3 required">{{'Common.title'|translate}}</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" ngModel name="title"
                                            [(ngModel)]="Case.title" #title="ngModel">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="control-label col-sm-3">{{'Documents.Priority'|translate}}</label>
                                    <div class="col-sm-9">
                                        <select class="list-dt  form-control" id="prioritycode" ngModel
                                            name="prioritycode" #name="ngModel" [(ngModel)]="Case.prioritycode">
                                            <option value=" " selected disabled>Select Priority</option>
                                            <option value="1">High</option>
                                            <option value="2">Normal</option>
                                            <option value="3">Low</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row ">
                                    <label
                                        class="control-label col-sm-3">{{'Documents.OnlineConsultationType'|translate}}</label>
                                    <div class="col-sm-9 ">
                                        <select class="list-dt  form-control" id="casetypecode" ngModel #name="ngModel"
                                            [(ngModel)]="Case.casetypecode" ngModel name="casetypecode">
                                            <option value=" " selected disabled>Select Type</option>
                                            <option value="1">Question</option>
                                            <option value="2">Problem</option>
                                            <option value="3">Request</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label
                                        class="control-label col-sm-3 required">{{'Common.description'|translate}}</label>
                                    <div class="col-sm-9 ">
                                        <textarea type="text" class="form-control" #name="ngModel" name="description"
                                            [(ngModel)]="Case.description" ngModel id="description" rows="10"
                                            required></textarea>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="control-label col-sm-3 required">Upload file</label>
                                    <div class="col-sm-9">
                                        <p>:-<br> (jpg, jpeg, png, xlsx, word, pdf, mp4, mp3, docx, doc, txt)</p>
                                        <input type="file" (change)="IncomingFile($event.target.files);" />
                                    </div>
                                </div>

                                <input type="submit" class="btn btn-primary pull-right" value="Update">
                            </form>
                        </div>
                    </div>
                    <!-- / .modal-content -->

                </div>
                <!-- / .modal-dialog -->

            </div>
        </div>
    </div>
</div>