<div class="bradcam_area breadcam_bg bradcam_overlay">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="bradcam_text">
            <h3>Cookies</h3>
            <p><a href="">Home /</a> Cookies</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br/>
 
  <div class="container">
    <div class="row">
        <div class="card">
            <div class="card-body">
                <ol>
                   <li>OX. Virtual Clinic Cookie Policy</li>
                   <li>INFORMATION ABOUT OUR USE OF COOKIES</li>
                </ol>
                <p>OX.virtual clinic&rsquo;s website <a href="https://oxvc.health">https://oxvc.health</a> (the Site) uses cookies to distinguish you from other users of the Site. This helps us to provide you with a good experience when you browse the Site and also allows us to improve the Site. By continuing to browse the Site, you are agreeing to our use of cookies. Please be aware that restricting cookies may impact on your user experience and may prevent you from using parts of the Site.</p>
                <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer&rsquo;s hard drive.</p>
                <p>We use the following cookies:</p>
                <ul>
                   <li><strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of the Site. They include, for example, cookies that enable you to log into secure areas of the Site, use a shopping cart or make use of e-billing services.</li>
                   <li><strong>Analytical/performance cookies.</strong> They allow us to recognise and count the number of visitors and to see how visitors move around the Site when they are using it. This helps us to improve the way the Site works, for example, by ensuring that users are finding what they are looking for easily.</li>
                   <li><strong>Functionality cookies.</strong> These are used to recognise you when you return to the Site. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</li>
                   <li><strong>Targeting cookies.</strong> These cookies record your visit to the Site, the pages you have visited and the links you have followed. We will use this information to make the Site and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</li>
                </ul>
                <p>You can find more information about the individual cookies we use and the purposes for which we use them in the below table:</p>
                <table class="table table-bordered" style="height: 725px;" width="703">
                   <tbody>
                      <tr>
                         <td style="width: 170px;">
                            <p style="text-align: center;"><strong>Cookie Type</strong></p>
                         </td>
                         <td style="width: 168px;">
                            <p><strong>Cookie Name</strong></p>
                         </td>
                         <td style="width: 168px;">
                            <p><strong>Purpose</strong></p>
                         </td>
                         <td style="width: 169px;">
                            <p><strong>More information</strong></p>
                         </td>
                      </tr>
                      <tr>
                         <td style="width: 170px;">
                            <p><strong>Analytical/ performance cookies</strong></p>
                         </td>
                         <td style="width: 168px;">
                            <p>Google Analytics</p>
                         </td>
                         <td style="width: 168px;">
                            <p>This cookie enables us to:</p>
                            <p>(a) Estimate our audience size and usage pattern.</p>
                            <p>(b) Understand how visitors navigate to and through the Site.</p>
                            <p>(c) Track the effectiveness of digital marketing campaigns.</p>
                            <p>(d) Recognise you when you return to our site.</p>
                            <p>&nbsp;</p>
                         </td>
                         <td style="width: 169px;">
                            <p>You can find out more about Google Analytics <a href="http://www.google.com/analytics">here</a>.</p>
                            <p>&nbsp;</p>
                         </td>
                      </tr>
                      <tr>
                         <td style="width: 170px;">
                            <p><strong>Targeting Cookies</strong></p>
                         </td>
                         <td style="width: 168px;">
                            <p>Google Adwords Conversion Tracking</p>
                         </td>
                         <td style="width: 168px;">
                            <p>This cookie enables us to help us deliver targeted online adverts based on past visits to the Site.</p>
                            <p>&nbsp;</p>
                         </td>
                         <td style="width: 169px;">
                            <p>These cookies expire within 30 days and do not contain information that can identify you personally. Please refer to the&nbsp;<a href="https://www.google.com/privacy/ads/">Google Advertising Privacy Notice</a>&nbsp; and <a href="https://policies.google.com/privacy">Google Privacy Policy</a> for more information.</p>
                         </td>
                      </tr>
                      <tr>
                         <td style="width: 170px;">
                            <p><strong>Targeting Cookies</strong></p>
                         </td>
                         <td style="width: 168px;">
                            <p>Google Adwords Remarketing</p>
                         </td>
                         <td style="width: 168px;">
                            <p>This cookie enables us to help us deliver targeted online adverts based on past visits to the Site.</p>
                         </td>
                         <td style="width: 169px;">
                            <p>Please refer to the&nbsp;<a href="https://www.google.com/privacy/ads/">Google Advertising Privacy Notice</a> for more information about the ability to opt out and <a href="https://policies.google.com/privacy">Google Privacy Policy</a>.</p>
                         </td>
                      </tr>
                      <tr>
                         <td style="width: 170px;">
                            <p><strong>Targeting Cookies</strong></p>
                         </td>
                         <td style="width: 168px;">
                            <p>Google Tag manager</p>
                         </td>
                         <td style="width: 168px;">
                            <p>This cookie enables us to help us understand visitor information and source.</p>
                         </td>
                         <td style="width: 169px;">
                            <p>You can find out more about <a href="https://www.google.co.uk/analytics/tag-manager/use-policy/">Google Tags manager</a></p>
                            <p>&nbsp;</p>
                         </td>
                      </tr>
                      <tr>
                         <td style="width: 170px;">
                            <p><strong>Targeting Cookies </strong></p>
                         </td>
                         <td style="width: 168px;">
                            <p>Facebook Advertising</p>
                         </td>
                         <td style="width: 168px;">
                            <p>This cookie enables us to help us deliver targeted online adverts based on visits to the Site.</p>
                         </td>
                         <td style="width: 169px;">
                            <p>Please refer to the <a href="https://www.facebook.com/policies/cookies/">Facebook cookie policy</a> for more information</p>
                         </td>
                      </tr>
                      <tr>
                         <td style="width: 170px;">
                            <p><strong>[insert details]</strong></p>
                         </td>
                         <td style="width: 168px;">
                            <p>&nbsp;</p>
                         </td>
                         <td style="width: 168px;">
                            <p>&nbsp;</p>
                         </td>
                         <td style="width: 169px;">
                            <p>&nbsp;</p>
                         </td>
                      </tr>
                   </tbody>
                </table>
                <ol start="2">
                   <li>MANAGING COOKIES</li>
                </ol>
                <p>The most popular web browsers all allow you to manage cookies. You can choose to accept or reject all cookies, or just specific types of cookies.</p>
                <ul>
                   <li>How to manage cookies in different web browsers</li>
                </ul>
                <p>Most browsers will allow you to turn off cookies. Please note that turning off cookies will restrict your use of the Site.</p>
                <p>The following links provide information on how to modify the cookies settings on some popular browsers:</p>
                <ul>
                   <li>Google Chrome</li>
                   <li>Microsoft Edge</li>
                   <li>Mozilla Firefox</li>
                   <li>Microsoft Internet Explorer</li>
                   <li>Opera</li>
                   <li>Apple Safari</li>
                </ul>
                <p>&nbsp;</p>
                <p>Please note that we do not currently respond to Do Not Track (DNT) signals.</p>
                <p>We strongly recommend that you leave Cookies active, because they enable you to take advantage the most attractive features of the Site, but this remains your personal choice.</p>
                <p>You can also visit the <a href="https://www.aboutcookies.org/">About Cookies</a>, for more information about cookies and how to manage them.</p>
                <ol start="3">
                   <li>THIRD PARTY COOKIES</li>
                </ol>
                <p>Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control.</p>
                <p>You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.</p>
                <p>&nbsp;</p>
                <p>If you would like more information about these third-party cookies, it is available from to <a href="http://www.youronlinechoices.com/uk/">youronlinechoices.com/UK/</a>.</p>
                <p>Note that when third parties are collecting information about you, these third parties are acting as controllers in their own right (ie. they are not acting on our behalf).</p>
                <ol start="4">
                   <li>CHANGES TO THIS COOKIE POLICY</li>
                </ol>
                <p>We will review, and where necessary update, this policy. If we have your e-mail address, we may also e-mail you with information on those changes. If we need to, we will also ask you to confirm that you are happy with those changes.</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <div id="gtx-trans" style="position: absolute; left: 181px; top: 381.469px;">&nbsp;</div>
            </div>
        </div>
    </div>
  </div><br>