import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './Modules/home/home.component';
import { RegistrationComponent } from './Navigation/registration/registration.component';
import { DoctorsComponent } from './Modules/doctors/doctors.component';
import { DeparmentsComponent } from './Modules/deparments/deparments.component';
import { LiveWellComponent } from './Modules/live-well/live-well.component';
import { ConditionsComponent } from './Modules/conditions/conditions.component';
import { MedicinesComponent } from './Modules/medicines/medicines.component';
import { VideoComponent } from './Modules/video/video.component';
import { BookAppointmentsComponent } from './Modules/book-appointments/book-appointments.component';
import { WaitingRoomComponent } from './Modules/waiting-room/waiting-room.component';
import { NHSComponent } from './Modules/nhs/nhs.component';
import { CoronaComponent } from './Modules/corona/corona.component';
import { ProfileComponent } from './Modules/profile/profile.component';
import { AllDoctorViewComponent } from './Modules/waiting-room/all-doctor-view/all-doctor-view.component';
import { WaitingListViewComponent } from './Modules/waiting-room/waiting-list-view/waiting-list-view.component'
import { PatientOnlyViewComponent } from './Modules/waiting-room/patient-only-view/patient-only-view.component'
import { ContactUsComponent } from './Modules/contact-us/contact-us.component';
import { KBArticleComponent } from './Modules/kbarticle/kbarticle.component';
import { KBArticalDetailsComponent } from './Modules/kbartical-details/kbartical-details.component';
import { RequestAppointmentComponent } from './Modules/request-appointment/request-appointment.component';
import { SharePointComponent } from './Modules/share-point/share-point.component';
import { CimarComponent } from './Modules/cimar/cimar.component';
import { TermsComponent } from './Modules/terms/terms.component';
import { CookiesComponent } from './Modules/cookies/cookies.component';
import { AllergiesComponent } from './Modules/profile/allergies/allergies.component';
import { ProfileDetailComponent } from './Modules/profile/profile-detail/profile-detail.component';
import { NewconditionsComponent } from './Modules/profile/newconditions/newconditions.component';
import { NewinvoicesComponent } from './Modules/profile/newinvoices/newinvoices.component';
import { NewTdlComponent } from './Modules/profile/new-tdl/new-tdl.component';
import { NewobservationsComponent } from './Modules/profile/newobservations/newobservations.component';
import { NewprescriptionsComponent } from './Modules/profile/newprescriptions/newprescriptions.component';
import { RequestprescriptionComponent } from './Modules/profile/requestprescription/requestprescription.component';
import { NewDigitalimageryComponent } from './Modules/profile/new-digitalimagery/new-digitalimagery.component';
import { NewdocumentsComponent } from './Modules/profile/newdocuments/newdocuments.component';
import { HowitworksComponent } from './Modules/howitworks/howitworks.component';
import { CookiedetailsComponent } from './Modules/cookiedetails/cookiedetails.component';
import { ProcedureComponent } from './Modules/profile/procedure/procedure.component';
import { ConsentComponent } from './Modules/profile/consent/consent.component';
import { ContactdetailComponent } from './Modules/contact-us/contactdetail/contactdetail.component';
import { JoinusComponent } from './Modules/joinus/joinus.component';
import { PrivacyComponent } from './Modules/privacy/privacy.component';
import { ObservationDetailComponent } from './Modules/profile/observation-detail/observation-detail.component';
import { CaseComponent } from './Modules/profile/case/case.component';
import { NotesComponent } from './Modules/profile/notes/notes.component';
import { SickNotesComponent } from './Modules/profile/sick-notes/sick-notes.component';
import { ChatHistoryComponent } from './Modules/profile/chat-history/chat-history.component';
import { NavigateComponent } from './Navigation/navigate/navigate.component';
import { ShowProfileDataComponent } from './Module/Profile/show-profile-data/show-profile-data.component';
import { AllergyComponent } from './Module/Profile/allergy/allergy.component';
import { ConditionNewComponent } from './Module/Profile/condition-new/condition-new.component';
import { ConsentsComponent } from './Module/Profile/consents/consents.component';
import { DigitalImageryComponent } from './Module/Profile/digital-imagery/digital-imagery.component';
import { DocumentsComponent } from './Module/Profile/documents/documents.component';
import { InvoicesComponent } from './Module/Profile/invoices/invoices.component';
import { ObservationsComponent } from './Module/Profile/observations/observations.component';
import { OnlineConsultationsComponent } from './Module/Profile/online-consultations/online-consultations.component';
import { PrescriptionsComponent } from './Module/Profile/prescriptions/prescriptions.component';
import { SickNoteNewComponent } from './Module/Profile/sick-note-new/sick-note-new.component';
import { TestResultsComponent } from './Module/Profile/test-results/test-results.component';
import { HistoryChatComponent } from './Module/Profile/history-chat/history-chat.component';
import { RequestPrescriptionNewComponent } from './Module/Profile/request-prescription-new/request-prescription-new.component';
import { BookappointmentnewComponent } from './Module/bookappointmentnew/bookappointmentnew.component';
import { ClinicianDeptComponent } from './Module/clinician-dept/clinician-dept.component';
import { HowitworknewComponent } from './Module/howitworknew/howitworknew.component';
import { RequestappointmentnewComponent } from './Module/requestappointmentnew/requestappointmentnew.component';
import { WaitingRoomNewComponent } from './Module/waiting-room-new/waiting-room-new.component';
import { WaitingListNewComponent } from './Module/waiting-room-new/waiting-list-new/waiting-list-new.component';
import { ConsentsProcedureComponent } from './Module/Profile/consents-procedure/consents-procedure.component';
import { NoteNewComponent } from './Module/Profile/note-new/note-new.component';
import { AllDoctorViewNewComponent } from './Module/waiting-room-new/all-doctor-view-new/all-doctor-view-new.component';
import { ObChartsComponent } from './Module/Profile/ob-charts/ob-charts.component';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'nav', component: NavigateComponent },
    // { path: 'PDComponent', component: ProfileDetailComponents, children: [{ path: '', component: ProfileEditComponent },{ path: 'PDEdit', component: ProfileEditComponent }] },
    {path:'ObChartsComponent', component: ObChartsComponent},
   
   
    { path: 'doctors/:id', component: DoctorsComponent },
    { path: 'bookAppointment', component: BookAppointmentsComponent },
    { path: 'bookAppointment/:userName', component: BookAppointmentsComponent },
   
    {
        path: 'waitingRoom', component: WaitingRoomComponent, children: [
            { path: '', component: WaitingListViewComponent },
            { path: 'doctorsView', component: AllDoctorViewComponent },
            { path: 'WaitingListView', component: WaitingListViewComponent },
            { path: 'patienView', component: PatientOnlyViewComponent }
        ]
    },
    { path: 'PAtientDetailEdit', component: ProfileDetailComponent },
    { path: 'nhs', component: NHSComponent },
    { path: 'livewell', component: LiveWellComponent },
    { path: 'conditions', component: ConditionsComponent },
    { path: 'medicines', component: MedicinesComponent },
    { path: 'video', component: VideoComponent },
    { path: 'registration', component: RegistrationComponent },
    { path: 'departments', component: DeparmentsComponent },
    { path: 'corona', component: CoronaComponent },
    { path: 'PDComponent', component: ShowProfileDataComponent },
  
    { path: 'allergy', component: AllergyComponent },
    { path: 'ChatHistory', component: HistoryChatComponent },
    { path: 'ConditionNew', component: ConditionNewComponent },
    { path: 'consents', component: ConsentsComponent },
    { path: 'ConsentProcedure/:id', component: ConsentsProcedureComponent },
    { path: 'digiImage', component: DigitalImageryComponent },
    { path: 'docs', component: DocumentsComponent },
    { path: 'invoices', component: InvoicesComponent },
    { path: 'observations', component: ObservationsComponent },
    { path: 'onlineconsult', component: OnlineConsultationsComponent },
    { path: 'prescript', component: PrescriptionsComponent },
    { path: 'testResult', component: TestResultsComponent },
    { path: 'sick', component: SickNoteNewComponent },
    { path: 'reqPrescript', component: RequestPrescriptionNewComponent },
    { path: 'howitWork', component: HowitworknewComponent },
    { path: 'bookNewApt', component: BookappointmentnewComponent },
    { path: 'bookNewApt/:userName', component: BookappointmentnewComponent },
    { path: 'reuestAptNew', component: RequestappointmentnewComponent },
    { path: 'noteNew', component: NoteNewComponent },
    { path: 'allDoctor/:id', component: AllDoctorViewNewComponent },
    { path: 'observationDetail/:id', component: ObservationDetailComponent },
    {
         path: 'root', component: WaitingRoomNewComponent 
    , children: [
        { path: '', component: WaitingListNewComponent },
        // { path: 'doctorsView', component: AllDoctorViewComponent },
        // { path: 'WaitingListView', component: WaitingListViewComponent },
        // { path: 'patienView', component: PatientOnlyViewComponent }
    ]
},
    { path: 'DeptClinic', component: ClinicianDeptComponent },
  

    {
        path: 'profile', component: ProfileComponent, children: [
        
            { path: 'allergies', component: AllergiesComponent },
            { path: 'newconditions', component: NewconditionsComponent },
            { path: 'newinvoices', component: NewinvoicesComponent },
            { path: 'newobservations', component: NewTdlComponent },
            { path: 'newobservationOOB', component: NewobservationsComponent },
            { path: 'newprescriptions', component: NewprescriptionsComponent },
            { path: 'newcimar', component: NewDigitalimageryComponent },
            { path: 'newdocument', component: NewdocumentsComponent },
            { path: 'profiledetail', component: ProfileDetailComponent },
            { path: 'consent', component: ConsentComponent },
            { path: 'procedure/:id', component: ProcedureComponent },
            { path: 'requestprescription', component: RequestprescriptionComponent },

            { path: 'case', component: CaseComponent },
            { path: 'notes', component: NotesComponent },
            { path: 'SickNotes', component: SickNotesComponent },
            { path: 'ChatHistory', component: ChatHistoryComponent },
        ]
    },
    {
        path: 'contactus', component: ContactUsComponent, children: [
            { path: '', component: ContactdetailComponent },]
    },
    { path: 'kbarticle', component: KBArticleComponent },
    { path: 'Joinus', component: JoinusComponent },
    { path: 'kbarticleDetails/:id', component: KBArticalDetailsComponent },
    { path: 'requestAppointment', component: RequestAppointmentComponent },
    { path: 'sharepointdocument', component: SharePointComponent },
    { path: 'cimar', component: CimarComponent },
    { path: 'TermsConditions', component: TermsComponent },
    { path: 'privacy', component: PrivacyComponent },
    { path: 'cookies', component: CookiesComponent },
    { path: 'howitworks', component: HowitworksComponent },
    { path: 'cookie-details', component: CookiedetailsComponent },
    { path: '**', component: HomeComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: true,
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }