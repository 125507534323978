<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">

                        <section>
                            <div class="row">

                                <!-- <div class="col-lg-2 col-md-3 col-xs-12 col-sm-3 imgcard">
                                    <div class="card testimonial-card mt-2 mb-3">
                                        <button (click)="fileInput.click()" class="openimg"> <i
                                                class="fa fa-plus"></i></button>

                                        <div class="avatar mx-auto white" style="text-align: center;">

                                            <div class="image-upload">
                                                <label for="file-input">
                                                    <img class="img rounded-circle" id="blah" [src]="imgURL">
                                                </label>
                                                <input #fileInput type="file"
                                                    (change)="IncomingFile($event.target.files);"
                                                    style="display:none;" />

                                            </div>

                                        </div>
                                        <div class="row justify-content-center"><input type="button"
                                                class="btn2 btn-primary"
                                                value="{{'Profile.UploadProfilePhoto'|translate}}"
                                                (click)="Uploadfile()"></div>

                                    </div>

                                </div> -->
                                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 detailcard">

                                    <div class="bs-example">
                                        <ul class="row nav nav-tabs">
                                            <li class="col-md-3 border nav-item">
                                                <a href="#home" class="nav-link active"
                                                    data-toggle="tab">{{'ProfileTabs.profile'|translate}}</a>
                                            </li>
                                            <li class="col-md-3 border nav-item">
                                                <a href="#profiledetail" (click)="getrelationship()" class="nav-link"
                                                    data-toggle="tab">{{'Profile.otherInformation'|translate}}</a>
                                            </li>
                                        </ul>
                                        <form class="form" #it="ngForm" (ngSubmit)="onSubmit(it)" #f="ngForm"
                                            autocomplete="off">
                                            <div class="tab-content">
                                                <div class="tab-pane fade show active" id="home">

                                                    <div class="card-body">
                                                        <div class="row ">
                                                            <div class="col-md-3">
                                                                <div class="imgcard ">
                                                                    <div class="card mt-2 mb-3">
                                                                        <button type="button" (click)="fileInput.click()" class="openimg"> <i
                                                                                class="fa fa-plus"></i></button>
                                
                                                                        <div class="avatar mx-auto white" style="text-align: center;">
                                
                                                                            <div class="image-upload">
                                                                                <label for="file-input">
                                                                                    <img class="img shadow p" id="blah" [src]="imgURL">
                                                                                </label>
                                                                                <input #fileInput type="file"
                                                                                    (change)="IncomingFile($event.target.files);"
                                                                                    style="display:none;" />
                                
                                                                            </div>
                                
                                                                        </div>
                                                                        <div class="row justify-content-center"><input type="button"
                                                                                class="btn2 btn-primary"
                                                                                value="{{'Profile.UploadProfilePhoto'|translate}}"
                                                                                (click)="Uploadfile()"></div>
                                
                                                                    </div>
                                
                                                                </div>
                                                            </div>
                                                            <div class="col-md-9">
                                                            <div class="row">
                                                            <div class="col-md-4">
                                                                <div class="form-group row bmd-form-group"
                                                                    *ngIf="(profile | keyvalue)?.length">
                                                                    <label
                                                                        class="bmd-label-floating required col-sm-3">{{'Profile.firstName'|translate}}</label>
                                                                    <div class="col-sm-12">
                                                                        <input type="text" class="form-control"
                                                                            name="firstname"
                                                                            [(ngModel)]="profile.firstname"
                                                                            #name="ngModel" ngModel #firstname="ngModel"
                                                                            required
                                                                            placeholder="First name">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="form-group row bmd-form-group"
                                                                    *ngIf="(profile | keyvalue)?.length">
                                                                    <label
                                                                        class="bmd-label-floating required col-sm-3">{{'Profile.lastName'|translate}}</label>
                                                                    <div class="col-sm-12">
                                                                        <input type="text" class="form-control"
                                                                            name="lastName"
                                                                            [(ngModel)]="profile.lastname"
                                                                            #name="ngModel" ngModel #lastName="ngModel"
                                                                            required
                                                                            placeholder="Last name">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="form-group row bmd-form-group"
                                                                    *ngIf="(profile | keyvalue)?.length">
                                                                    <label
                                                                        class="bmd-label-floating required col-sm-3">{{'Profile.email'|translate}}</label>
                                                                        <div class="col-sm-12">
                                                                    <input type="email" class="form-control"
                                                                        name="emailaddress1"
                                                                        [(ngModel)]="profile.emailaddress1"
                                                                        #name="ngModel" required disabled
                                                                        placeholder="Email">
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="form-group row bmd-form-group"
                                                                    *ngIf="(profile | keyvalue)?.length">
                                                                    <label
                                                                        class="bmd-label-floating required col-sm-3">{{'Profile.Phone'|translate}}</label>
                                                                    <div class="col-sm-12">
                                                                        <input class="form-control" name="mobilephone"
                                                                            #name="ngModel" type="text" id="mobilephone"
                                                                            ng2TelInput
                                                                            (countryChange)="onCountryChange($event)"
                                                                            (intlTelInputObject)="telInputObject($event,profile.mobilephone)"
                                                                            [(ngModel)]="profile.mobilephone" disabled
                                                                            placeholder="Phone" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="form-group row bmd-form-group"
                                                                    *ngIf="(profile | keyvalue)?.length">
                                                                    <label
                                                                        class="bmd-label-floating required col-sm-3">{{'Profile.gender'|translate}}</label>
                                                                    <div class="col-sm-12">
                                                                        <select class="form-control" id='purpose'
                                                                            name="gendercode"
                                                                            [(ngModel)]="profile.gendercode"
                                                                            #name="ngModel" required>
                                                                            <option value="1">Male</option>
                                                                            <option value="2">Female</option>
                                                                            <option value="3">Other</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="form-group row bmd-form-group"
                                                                    *ngIf="(profile | keyvalue)?.length">
                                                                    <label class="bmd-label-floating  col-sm-3">Date
                                                                        of Birth</label>
                                                                    <div class="col-sm-12">

                                                                        <input class='form-control' type="date"
                                                                            id="birthday" name="birthday"
                                                                            name="birthdate" ngModel
                                                                            #birthdate="ngModel" #name="ngModel"
                                                                            [(ngModel)]="profile.birthdate">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        </div>

                                                        <div class="row ">
                                                            <div class="col-md-3">
                                                                <div class="form-group row bmd-form-group"
                                                                    *ngIf="(profile | keyvalue)?.length">
                                                                    <label
                                                                        class="bmd-label-floating  col-sm-3">{{'Profile.NHSNumber'|translate}}</label>
                                                                    <div class="col-sm-12">
                                                                        <input class='form-control' type="text"
                                                                            id="bcrm_nhsnumber" name="bcrm_nhsnumber"
                                                                            name="bcrm_nhsnumber" ngModel
                                                                            #bcrm_nhsnumber="ngModel" #name="ngModel"
                                                                            [(ngModel)]="profile.bcrm_nhsnumber"
                                                                            placeholder="NHS number">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group row bmd-form-group">
                                                                    <label
                                                                        class="bmd-label-floating  col-sm-3">{{'Profile.addressLookup'|translate}}</label>
                                                                    <div class="col-sm-12">
                                                                        <input type="text" class="form-control"
                                                                            (keydown.enter)="$event.preventDefault()"
                                                                            placeholder="Enter Address Lookup"
                                                                            autocorrect="off" autocapitalize="off"
                                                                            spellcheck="off" type="text" #search>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group row bmd-form-group"
                                                                    *ngIf="(profile | keyvalue)?.length">
                                                                    <label
                                                                        class="bmd-label-floating  col-sm-3">{{'Profile.street'|translate}}</label>
                                                                    <div class="col-sm-12">
                                                                        <input type="text" class="form-control"
                                                                            name="address1_line1"
                                                                            [(ngModel)]="profile.address1_line1"
                                                                            #name="ngModel" id="route"
                                                                            placeholder="Street">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                           

                                                            <div class="col-md-3">
                                                                <div class="form-group row bmd-form-group"
                                                                    *ngIf="(profile | keyvalue)?.length">
                                                                    <label
                                                                        class="bmd-label-floating  col-sm-3">{{'Profile.city'|translate}}</label>
                                                                    <div class="col-sm-12">
                                                                        <input type="text" class="form-control"
                                                                            name="address1_city"
                                                                            [(ngModel)]="profile.address1_city"
                                                                            #name="ngModel" id="locality"
                                                                            placeholder="City">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="row parantRow"> -->
                                                        <!-- <div class="col-md-6">
                                            <div class="form-group row bmd-form-group" *ngIf="(profile | keyvalue)?.length">
                                                <label class="bmd-label-floating  col-sm-3">{{'Profile.Funded'|translate}}</label>
                                                <div class="col-sm-9">
                                                    <select class="form-control" id='purpose' name="bcrm_funded" [(ngModel)]="profile.bcrm_funded" #name="ngModel" required (change)="IsCompanyEnable($event.target.value)">
                            <option value="0">Not Selected</option>
                            <option value="1">Private</option>
                            <option value="2">NHS</option>
                            <option value="3">Insurance</option>
                          </select>
                                                </div>
                                            </div>
                                        </div> -->
                                                        <!-- <div class="col-md-6">
                                            <div class="form-group row bmd-form-group">
                                                <label class="bmd-label-floating  col-sm-3">{{'Profile.addressLookup'|translate}}</label>
                                                <div class="col-sm-9">
                                                    <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Enter Address Lookup" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
                                                </div>
                                            </div>
                                        </div> -->
                                                        <!-- </div> -->
                                                        <!-- <div class="col-md-6">
                                            <div class="form-group row bmd-form-group" *ngIf="(profile | keyvalue)?.length && !isSelectfunded">
                                                <label class="bmd-label-floating  col-sm-3">{{'Profile.PolicyNumber'|translate}}</label>
                                                <div class="col-sm-9">
                                                    <input class='form-control' type="text" id="bcrm_policynumber" name="bcrm_policynumber" name="bcrm_policynumber" ngModel #bcrm_policynumber="ngModel" #name="ngModel" [(ngModel)]="profile.bcrm_policynumber" placeholder="Policy number">
                                                </div>
                                            </div>
                                        </div> -->

                                                        <div class="row ">
                                                            <div class="col-md-3">

                                                                <div class="form-group row bmd-form-group"
                                                                    *ngIf="(profile | keyvalue)?.length">
                                                                    <label
                                                                        class="bmd-label-floating  col-sm-3">{{'Profile.Proxy'|translate}}</label>
                                                                    <div class="col-sm-12">
                                                                        <input type="text" class="form-control"
                                                                            name="emergencycontact"
                                                                            [(ngModel)]="profile.emergencycontact"
                                                                            #name="ngModel" id="emergencycontact"
                                                                            [readonly]=readonlyemergencycontact>
                                                                        <a href="#Modal1" data-toggle="modal"
                                                                            id="addproxyLink"
                                                                            (click)="getrelationshipForproxy()">{{'Profile.AddProxy'|translate}}</a>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div class="col-md-3">
                                                                <div class="form-group row bmd-form-group"
                                                                    *ngIf="(profile | keyvalue)?.length">
                                                                    <label
                                                                        class="bmd-label-floating  col-sm-3">{{'Profile.country'|translate}}</label>
                                                                    <div class="col-sm-12">
                                                                        <input type="text" class="form-control"
                                                                            name="address1_country"
                                                                            [(ngModel)]="profile.address1_country"
                                                                            #name="ngModel" id="country"
                                                                            placeholder="Country">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-3">
                                                                <div class="form-group row bmd-form-group"
                                                                    *ngIf="(profile | keyvalue)?.length">
                                                                    <label
                                                                        class="bmd-label-floating  col-sm-3">{{'Profile.state'|translate}}</label>
                                                                    <div class="col-sm-12">
                                                                        <input type="text" class="form-control"
                                                                            name="address1_stateorprovince"
                                                                            [(ngModel)]="profile.address1_stateorprovince"
                                                                            #name="ngModel"
                                                                            id="administrative_area_level_1"
                                                                            placeholder="State">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div class="col-md-3">
                                                                <div class="form-group row bmd-form-group"
                                                                    *ngIf="(profile | keyvalue)?.length">
                                                                    <label
                                                                        class="bmd-label-floating  col-sm-3">{{'Profile.zip'|translate}}</label>
                                                                    <div class="col-sm-12">
                                                                        <input type="text" class="form-control"
                                                                            name="address1_postalcode"
                                                                            [(ngModel)]="profile.address1_postalcode"
                                                                            #name="ngModel" id="postal_code"
                                                                            placeholder="Postal Code">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row ">
                                                            <!-- <div class="col-md-6">
                                            <div class="form-group row bmd-form-group" *ngIf="!isSelectfunded  && (profile | keyvalue)?.length">
                                                <label class="bmd-label-floating  col-sm-3">{{'Profile.Insurance'|translate}}</label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" id='purpose' name="parentcustomerid" [(ngModel)]="profile.parentcustomerid" #name="ngModel" [disabled]="!isInsuranceEnable">
                            <option *ngFor="let insuranceCompany of insuranceCompanyList"
                              [value]="insuranceCompany.accountid">
                              {{insuranceCompany.name}}
                            </option>
                          </select>
                                                </div>
                                            </div>
                                        </div> -->




                                                        </div>

                                                        <!-- <div class="col-md-6">
                                            <div class="form-group row bmd-form-group" *ngIf="!isSelectfunded && (profile | keyvalue)?.length">
                                                <label class="bmd-label-floating  col-sm-3">{{'Profile.HealthCode'|translate}}</label>
                                                <div class="col-sm-9">
                                                    <input type="text" class="form-control" name="bcrm_healthcodenumber" [(ngModel)]="profile.bcrm_healthcodenumber" #name="ngModel" id="bcrm_healthcodenumber" placeholder="Health code">
                                                </div>
                                            </div>
                                        </div> -->



                                                        <div class="row ">
                                                      
                                                            <div class="col-md-3">
                                                                <p style="color: black;font-size: 20px;">
                                                                    {{'Profile.ABOUTUS'|translate}}</p>
                                                                <div class="form-group row bmd-form-group"
                                                                    *ngIf="(profile | keyvalue)?.length">
                                                                    <div class="col-lg-8">
                                                                        <select class="form-control"
                                                                            id='wheredidyouhearaboutus'
                                                                            name="bcrm_wheredidyouhearaboutus"
                                                                            #name="ngModel"
                                                                            [(ngModel)]="profile.bcrm_wheredidyouhearaboutus"
                                                                            #bcrm_wheredidyouhearaboutus="ngModel"
                                                                            ngModel>
                                                                            <option disabled>Where did you here
                                                                                about us?</option>
                                                                            <option value="1">DOCTORnow Cars
                                                                            </option>
                                                                            <option value="2">Local Marketing e.g.
                                                                                Banners</option>
                                                                            <option value="3">Railway Station
                                                                            </option>
                                                                            <option value="4">Employer/Work</option>
                                                                            <option value="5">Local/Previous
                                                                                Knowledge</option>
                                                                            <option value="6">Social Media</option>
                                                                            <option value="7">Hospital/Specialist/
                                                                                Pharmacy</option>
                                                                            <option value="8">Magazine/Door Drop
                                                                            </option>
                                                                            <option value="9">Word of
                                                                                Mouth/Recommendation</option>
                                                                            <option value="10">Insurance Company
                                                                            </option>
                                                                            <option value="11">MASTA</option>
                                                                            <option value="12">Internet</option>
                                                                            <option value="13">NHS GP</option>
                                                                            <option value="14">Other</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-9">
                                                                <h4>{{'Profile.GDPR'|translate}}</h4>
                                                                <div class="row ">
                                                                    <div class="col-md-4">
                                                                        <div class="form-group row bmd-form-group"
                                                                            *ngIf="(profile | keyvalue)?.length">
                                                                            <label
                                                                                class="bmd-label-floating  col-sm-4">{{'Profile.email'|translate}}</label>
                                                                            <div class="col-sm-3">
                                                                                <label class="switch"><input type="checkbox"
                                                                                        id="togBtn" name="donotemail"
                                                                                        [(ngModel)]="profile.donotemail"
                                                                                        #name="ngModel"
                                                                                        (change)="getEmail($event.target.checked)">
                                                                                    <div class="slider round">
                                                                                        <!--ADDED HTML --><span
                                                                                            class="on">{{'Common.Yes'|translate}}</span>
                                                                                        <span
                                                                                            class="off">{{'Common.No'|translate}}</span>
                                                                                        <!--END-->
                                                                                    </div>
                                                                                </label>
        
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="form-group row bmd-form-group"
                                                                            *ngIf="(profile | keyvalue)?.length">
                                                                            <label
                                                                                class="bmd-label-floating  col-sm-4">{{'Profile.FollowMail'|translate}}</label>
                                                                            <div class="col-sm-3">
                                                                                <label class="switch"><input type="checkbox"
                                                                                        id="togBtn" name="followemail"
                                                                                        [(ngModel)]="profile.followemail"
                                                                                        #name="ngModel"
                                                                                        (change)="getFollowEmail($event.target.checked)">
                                                                                    <div class="slider round">
                                                                                        <!--ADDED HTML --><span
                                                                                            class="on">{{'Common.Yes'|translate}}</span>
                                                                                        <span
                                                                                            class="off">{{'Common.No'|translate}}</span>
                                                                                        <!--END-->
                                                                                    </div>
                                                                                </label>
        
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="form-group row bmd-form-group"
                                                                            *ngIf="(profile | keyvalue)?.length">
                                                                            <label
                                                                                class="bmd-label-floating  col-sm-4">{{'Profile.Newsletter'|translate}}</label>
                                                                            <div class="col-sm-3">
                                                                                <label class="switch"><input type="checkbox"
                                                                                        id="togBtn" name="donotbulkemail"
                                                                                        [(ngModel)]="profile.donotbulkemail"
                                                                                        (change)="getBulkEmail($event.target.checked)"
                                                                                        #name="ngModel">
                                                                                    <div class="slider round">
                                                                                        <!--ADDED HTML --><span
                                                                                            class="on">{{'Common.Yes'|translate}}</span>
                                                                                        <span
                                                                                            class="off">{{'Common.No'|translate}}</span>
                                                                                        <!--END-->
                                                                                    </div>
                                                                                </label>
        
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row ">
                                                                    <div class="col-md-4">
                                                                        <div class="form-group row bmd-form-group"
                                                                            *ngIf="(profile | keyvalue)?.length">
                                                                            <label
                                                                                class="bmd-label-floating  col-sm-4">{{'Profile.PhoneCalls'|translate}}</label>
                                                                            <div class="col-sm-3">
                                                                                <label class="switch"><input type="checkbox"
                                                                                        id="togBtn" name="donotphone"
                                                                                        [(ngModel)]="profile.donotphone"
                                                                                        #name="ngModel"
                                                                                        (change)="getPhone($event.target.checked)">
                                                                                    <div class="slider round">
                                                                                        <!--ADDED HTML --><span
                                                                                            class="on">{{'Common.Yes'|translate}}</span>
                                                                                        <span
                                                                                            class="off">{{'Common.No'|translate}}</span>
                                                                                        <!--END-->
                                                                                    </div>
                                                                                </label>
        
        
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="form-group row bmd-form-group"
                                                                            *ngIf="(profile | keyvalue)?.length">
                                                                            <label
                                                                                class="bmd-label-floating  col-sm-4">{{'Profile.Mails'|translate}}</label>
                                                                            <div class="col-sm-3">
                                                                                <label class="switch"><input type="checkbox"
                                                                                        id="togBtn" name="donotpostalmail"
                                                                                        [(ngModel)]="profile.donotpostalmail"
                                                                                        (change)="getMails($event.target.checked)"
                                                                                        #name="ngModel">
                                                                                    <div class="slider round">
                                                                                        <!--ADDED HTML --><span
                                                                                            class="on">{{'Common.Yes'|translate}}</span>
                                                                                        <span
                                                                                            class="off">{{'Common.No'|translate}}</span>
                                                                                        <!--END-->
                                                                                    </div>
                                                                                </label>
        
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="form-group row bmd-form-group"
                                                                            *ngIf="(profile | keyvalue)?.length">
                                                                            <label
                                                                                class="bmd-label-floating  col-sm-4">{{'Profile.Faxes'|translate}}</label>
                                                                            <div class="col-sm-3">
                                                                                <label class="switch"><input type="checkbox"
                                                                                        id="togBtn" name="donotfax"
                                                                                        [(ngModel)]="profile.donotfax"
                                                                                        #name="ngModel"
                                                                                        (change)="getFax($event.target.checked)">
                                                                                    <div class="slider round">
                                                                                        <!--ADDED HTML --><span
                                                                                            class="on">{{'Common.Yes'|translate}}</span>
                                                                                        <span
                                                                                            class="off">{{'Common.No'|translate}}</span>
                                                                                        <!--END-->
                                                                                    </div>
                                                                                </label>
        
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                     
                                                    </div>
                                                </div>


                                                <div class="tab-pane fade" id="profiledetail">
                                                    <div class="card-body" *ngIf="(profile | keyvalue)?.length">
                                                        <p class="heading">
                                                            {{'Profile.OtherInfo.NEXTOFKIN'|translate}}</p>
                                                        <div class="row parantRow pt-2">
                                                            <div class="col-md-3">

                                                                <label
                                                                    class="bmd-label-floating  ">{{'Profile.firstName'|translate}}</label>

                                                                <input type="text" class="form-control"
                                                                    name="bcrm_kinsfirstname"
                                                                    [(ngModel)]="profile.bcrm_kinsfirstname"
                                                                    #name="ngModel" />


                                                            </div>
                                                            <div class="col-md-3">

                                                                <label
                                                                    class="bmd-label-floating ">{{'Profile.OtherInfo.Surname'|translate}}</label>

                                                                <input type="text" class="form-control"
                                                                    name="bcrm_kinslastname"
                                                                    [(ngModel)]="profile.bcrm_kinslastname"
                                                                    #name="ngModel" />


                                                            </div>
                                                            <div class="col-md-3">

                                                                <label
                                                                    class="bmd-label-floating  ">{{'Profile.OtherInfo.Relationship'|translate}}</label>

                                                                <select class="js-example-basic-single round" style="height: 37px;"
                                                                    name="msemr_contact1relationship"
                                                                    [(ngModel)]="profile.msemr_contact1relationship"
                                                                    #msemr_contact1relationship="ngModel"
                                                                    placeholder="Enter name" id="mySelect2">
                                                                </select>


                                                            </div>
                                                            <div class="col-md-3">

                                                                <label
                                                                    class="bmd-label-floating ">{{'Profile.OtherInfo.Contacttelno'|translate}}</label>

                                                                <input type="text" class="form-control"
                                                                    name="telephone1" [(ngModel)]="profile.telephone1"
                                                                    #name="ngModel" />


                                                            </div>
                                                        </div>

                                                        <div class="row parantRow">
                                                            <div class="col-md-3">
                                                                <label
                                                                    class="bmd-label-floating  ">{{'Profile.address'|translate}}</label>
                                                                <input type="text" class="form-control"
                                                                    name="address2_line1"
                                                                    [(ngModel)]="profile.address2_line1"
                                                                    #name="ngModel" />
                                                            </div>
                                                            <div class="col-md-3">
                                                                <label
                                                                    class="bmd-label-floating">{{'Profile.OtherInfo.Postcode'|translate}}</label>
                                                                <input type="text" class="form-control"
                                                                    name="address2_postalcode"
                                                                    [(ngModel)]="profile.address2_postalcode"
                                                                    #name="ngModel" />
                                                            </div>
                                                            <div class="col-md-3">

                                                                <label
                                                                    class="bmd-label-floating ">{{'PatnerInfo.emailAddress'|translate}}</label>

                                                                <input type="email" class="form-control"
                                                                    name="emailaddress2"
                                                                    [(ngModel)]="profile.emailaddress2"
                                                                    #name="ngModel" />

                                                            </div>

                                                        </div>

                                                        <p class="heading">
                                                            {{'Profile.OtherInfo.CURRENTGPDETAILS'|translate}}</p>
                                                        <div class="row parantRow pt-2">
                                                            <div class="col-md-3">
                                                                <label
                                                                    class="bmd-label-floating  ">{{'Profile.GP.gpSurgeryName'|translate}}</label>

                                                                <input type="email" class="form-control"
                                                                    name="bcrm_gpsurgeryname"
                                                                    [(ngModel)]="profile.bcrm_gpsurgeryname"
                                                                    #name="ngModel" />
                                                            </div>

                                                            <div class="col-md-3">
                                                                <label
                                                                    class="bmd-label-floating ">{{'Profile.OtherInfo.GPType'|translate}}</label>
                                                                <select class="form-control" id='gptype'
                                                                    name="bcrm_gptype" [(ngModel)]="profile.bcrm_gptype"
                                                                    #name="ngModel" required>
                                                                    <option value="1">NHS GP</option>
                                                                    <option value="2">Private GP</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <label
                                                                    class="bmd-label-floating ">{{'Profile.OtherInfo.Town'|translate}}</label>
                                                                <input type="email" class="form-control"
                                                                    name="address3_city"
                                                                    [(ngModel)]="profile.address3_city"
                                                                    #name="ngModel" />
                                                            </div>
                                                            <div class="col-md-3">

                                                                <label
                                                                    class="bmd-label-floating ">{{'Profile.OtherInfo.DoctorName'|translate}}</label>

                                                                <select class="form-control" id='contact1'
                                                                    name="bcrm_doctorsname"
                                                                    [(ngModel)]="profile.bcrm_doctorsname"
                                                                    #name="ngModel" required>
                                                                    <option *ngFor="let contactgp of contactgplist"
                                                                        [value]="contactgp.Id">
                                                                        {{contactgp.fullname}}</option>
                                                                </select>


                                                            </div>
                                                        </div>

                                                        <div class="row parantRow">
                                                            <div class="col-md-6">
                                                                <label
                                                                    class="bmd-label-floating ">{{'Profile.OtherInfo.Telno'|translate}}</label>
                                                                <input type="text" class="form-control"
                                                                    name="telephone3" [(ngModel)]="profile.telephone3"
                                                                    #name="ngModel" />
                                                            </div>
                                                        </div>
                                                        <p class="heading">
                                                            {{'Profile.OtherInfo.SOCIALHISTORY'|translate}}</p>
                                                        <div class="row  pt-2">
                                                            <div class="col-md-3">
                                                                <label
                                                                    class="bmd-label-floating ">{{'Profile.occupation'|translate}}</label>
                                                                <input type="text" class="form-control"
                                                                    name="bcrm_occuption"
                                                                    [(ngModel)]="profile.bcrm_occuption"
                                                                    #name="ngModel" />
                                                            </div>

                                                            <div class="col-md-3">
                                                                <label
                                                                    class="bmd-label-floating ">{{'Profile.OtherInfo.SmokingStatus'|translate}}</label>
                                                                <select class="form-control" id='bcrm_smokingstatus'
                                                                    name="bcrm_smokingstatus"
                                                                    [(ngModel)]="profile.bcrm_smokingstatus"
                                                                    #name="ngModel" required>
                                                                    <option value="1">Currently smoke
                                                                    </option>
                                                                    <option value="2">Ex-smoker</option>
                                                                    <option value="3">Never smoked</option>
                                                                    <option value="4">Trying to give up
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <label
                                                                    class="bmd-label-floating ">{{'Profile.OtherInfo.StoppedSmoking'|translate}}</label>
                                                                <input type="text" class="form-control"
                                                                    name="bcrm_stoppedsmoking"
                                                                    [(ngModel)]="profile.bcrm_stoppedsmoking"
                                                                    #name="ngModel" />
                                                            </div>
                                                            <div class="col-md-3">
                                                                <label
                                                                    class="bmd-label-floating col-sm-3">{{'Profile.OtherInfo.Alcoholintake'|translate}}</label>
                                                                <select class="form-control" id=''
                                                                    name="bcrm_alcoholintake"
                                                                    [(ngModel)]="profile.bcrm_alcoholintake"
                                                                    #name="ngModel">
                                                                    <option value="1">Social Driker</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="row parantRow">
                                                            <div class="col-md-3">
                                                                <label
                                                                    class="bmd-label-floating">{{'Profile.OtherInfo.StoppedDrinkingAlcohol'|translate}}</label>
                                                                <input type="text" class="form-control"
                                                                    name="bcrm_stoppeddrinkingalcohol"
                                                                    [(ngModel)]="profile.bcrm_stoppeddrinkingalcohol"
                                                                    #name="ngModel" />
                                                            </div>
                                                            <div class="col-md-3">
                                                                <label
                                                                    class="bmd-label-floating ">{{'Profile.OtherInfo.Otherdetails'|translate}}</label>
                                                                <input type="text" class="form-control"
                                                                    name="otherDetails"
                                                                    [(ngModel)]="profile.otherDetails"
                                                                    #name="ngModel" />
                                                            </div>
                                                        </div>

                                                        <p class="heading" style="text-transform: uppercase;">
                                                            {{'Profile.OtherInfo.Alcoholconsumption'|translate}}</p>
                                                        <div class="row ">
                                                            <div class="col-md-6">
                                                                <div class="form-group row bmd-form-group">
                                                                    <label
                                                                        class="bmd-label-floating  col-sm-4">{{'Profile.OtherInfo.doYouDrinkAlcohol'|translate}}</label>
                                                                    <div class="col-sm-8">
                                                                        <label class="switch"><input type="checkbox"
                                                                                name="bcrm_neverdrinkalcohol"
                                                                                [(ngModel)]="profile.bcrm_neverdrinkalcohol"
                                                                                (change)="isDrink($event.target.checked)"
                                                                                id="neverdrink">
                                                                            <div class="slider round">
                                                                                <!--ADDED HTML --><span
                                                                                    class="on">{{'Common.Yes'|translate}}</span><span
                                                                                    class="off">{{'Common.No'|translate}}</span>
                                                                                <!--END-->
                                                                            </div>
                                                                        </label>
                                                                        <!-- <input type="checkbox" name="bcrm_neverdrinkalcohol"
                                                        [(ngModel)]="profile.bcrm_neverdrinkalcohol" (change)="isDrink($event.target.checked)" id="neverdrink"/>&nbsp;
                                                         <span
                                                        style="color: black;"></span> -->

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6" *ngIf="isalcoholdrink">
                                                                <div class="form-group row bmd-form-group">
                                                                    <label
                                                                        class="bmd-label-floating  col-sm-9">{{'Profile.OtherInfo.averageweek'|translate}}</label>
                                                                    <div class="col-sm-3">
                                                                        <input type="text" class="form-control"
                                                                            name="bcrm_howmanyunitsofalcoholdoyoudrinkinanavera"
                                                                            [(ngModel)]="profile.bcrm_howmanyunitsofalcoholdoyoudrinkinanavera"
                                                                            id="alcoholunit" />

                                                                    </div>
                                                                </div>
                                                            </div>



                                                        </div>



                                                        <p class="heading" style="text-transform:uppercase;">
                                                            {{'Profile.OtherInfo.followingconditions'|translate}}
                                                        </p>
                                                        <div class="row">
                                                            <div class="col-md-3 col-sm-3">
                                                                <input type="checkbox" class="widthCheck" name="bcrm_diabetes" 
                                                                    [(ngModel)]="profile.bcrm_diabetes" />&nbsp;<span
                                                                    style="color: black;">{{'Profile.OtherInfo.Diabetes'|translate}}</span>
                                                            </div>
                                                            <div class="col-md-3 col-sm-3">
                                                                <input type="checkbox" class="widthCheck" name="bcrm_thyroiddisease" 
                                                                    [(ngModel)]="profile.bcrm_thyroiddisease" />&nbsp;<span
                                                                    style="color: black;">
                                                                    {{'Profile.OtherInfo.Thyroiddisease'|translate}}</span>
                                                            </div>
                                                            <div class="col-md-3 col-sm-3">
                                                                <input type="checkbox" class="widthCheck"
                                                                    name="bcrm_transientischaemicattack"
                                                                    [(ngModel)]="profile.bcrm_transientischaemicattack" />&nbsp;<span
                                                                    style="color: black;">{{'Profile.OtherInfo.IschaemicAttack'|translate}}</span>
                                                            </div>
                                                            <div class="col-md-3 col-sm-3">
                                                                <input type="checkbox" class="widthCheck" name="bcrm_asthma"
                                                                    [(ngModel)]="profile.bcrm_asthma" />&nbsp;<span
                                                                    style="color: black;">{{'Profile.OtherInfo.Asthma'|translate}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-3 col-sm-3">
                                                                <input type="checkbox" class="widthCheck" name="bcrm_highbloodpressure"
                                                                    [(ngModel)]="profile.bcrm_highbloodpressure" />&nbsp;<span
                                                                    style="color: black;">
                                                                    {{'Profile.OtherInfo.Highbloodpressure'|translate}}</span>
                                                            </div>
                                                            <div class="col-md-3 col-sm-3">
                                                                <input type="checkbox" class="widthCheck" name="bcrm_epilepsy"
                                                                    [(ngModel)]="profile.bcrm_epilepsy" />&nbsp;<span
                                                                    style="color: black;">{{'Profile.OtherInfo.Epilepsy'|translate}}</span>
                                                            </div>
                                                            <div class="col-md-3 col-sm-3">
                                                                <input type="checkbox" class="widthCheck" name="bcrm_mentalillness"
                                                                    [(ngModel)]="profile.bcrm_mentalillness" />&nbsp;<span
                                                                    style="color: black;">{{'Profile.OtherInfo.Mentalillness'|translate}}</span>
                                                            </div>
                                                            <div class="col-md-3 col-sm-3">
                                                                <input type="checkbox" class="widthCheck" name="bcrm_heartdisease"
                                                                    [(ngModel)]="profile.bcrm_heartdisease" />&nbsp;<span
                                                                    style="color: black;">{{'Profile.OtherInfo.Heartdisease'|translate}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-3 col-sm-3">
                                                                <input type="checkbox" class="widthCheck" name="bcrm_kidneydisease"
                                                                    [(ngModel)]="profile.bcrm_kidneydisease" />&nbsp;<span
                                                                    style="color: black;">
                                                                    {{'Profile.OtherInfo.Kidneydisease'|translate}}</span>
                                                            </div>
                                                            <div class="col-md-3 col-sm-3">
                                                                <input type="checkbox" class="widthCheck" name="bcrm_depression"
                                                                    [(ngModel)]="profile.bcrm_depression" />&nbsp;<span
                                                                    style="color: black;">{{'Profile.OtherInfo.Depression'|translate}}</span>
                                                            </div>
                                                            <div class="col-md-3 col-sm-3">
                                                                <input type="checkbox" class="widthCheck" name="bcrm_heartrhythmproblems"
                                                                    [(ngModel)]="profile.bcrm_heartrhythmproblems" />&nbsp;<span
                                                                    style="color: black;">{{'Profile.OtherInfo.Heartrhythmproblems'|translate}}</span>
                                                            </div>
                                                            <div class="col-md-3 col-sm-3">
                                                                <input type="checkbox" class="widthCheck" name="bcrm_stroke"
                                                                    [(ngModel)]="profile.bcrm_stroke" />&nbsp;<span
                                                                    style="color: black;">{{'Profile.OtherInfo.Stroke'|translate}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="row">


                                                            <div class="col-md-3 col-sm-3">
                                                                <input type="checkbox" class="widthCheck" name="bcrm_cancer"
                                                                    [(ngModel)]="profile.bcrm_cancer" />&nbsp;<span
                                                                    style="color: black;">{{'Profile.OtherInfo.Cancer'|translate}}</span>
                                                            </div>

                                                        </div>
                                                        <br>

                                                        <div class="row">
                                                            <label
                                                                for="">{{'Profile.OtherInfo.surgicaloperations'|translate}}:</label>
                                                        </div>
                                                        <div class="row">
                                                            <textarea class="form-control" rows="4"
                                                                name="bcrm_pleasegivedetailsofanyothersignificantill"
                                                                [(ngModel)]="profile.bcrm_pleasegivedetailsofanyothersignificantill"
                                                                id="">
                                        </textarea>
                                                        </div>


                                                        <div class="row">
                                                            <label
                                                                for="">{{'Profile.OtherInfo.yourimmediatefamily'|translate}}:</label>
                                                        </div>
                                                        <div class="row">
                                                            <textarea class="form-control" rows="4"
                                                                name="bcrm_pleasegivedetailsofanymedicalconditionswi"
                                                                [(ngModel)]="profile.bcrm_pleasegivedetailsofanymedicalconditionswi"
                                                                id=""></textarea>
                                                        </div>

                                                        <div class="row ">
                                                            <label
                                                                for="">{{'Profile.OtherInfo.anymedications'|translate}}</label>
                                                        </div>
                                                        <div class="row"> <textarea class="form-control" rows="4"
                                                                name="bcrm_pleasegivedetailsifyouhaveanyallergiestoa"
                                                                [(ngModel)]="profile.bcrm_pleasegivedetailsifyouhaveanyallergiestoa"
                                                                id=""></textarea>
                                                        </div>
                                                        <br>
                                                        <p class="heading">
                                                            {{'Profile.OtherInfo.PATIENTCONSENOPTIONS'|translate}}
                                                        </p>
                                                        <div class="row">
                                                            <div class="col-md-10 col-sm-10"><input type="checkbox" class="widthCheck"
                                                                    name="bcrm_iunderstandthatdoctornowisaprivategpservi"
                                                                    [(ngModel)]="profile.bcrm_iunderstandthatdoctornowisaprivategpservi" />&nbsp;<span
                                                                    style="color: black;font-size: 16px;">
                                                                    {{'Profile.OtherInfo.consultationsandtreatments'|translate}}</span>
                                                            </div>

                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-10 col-sm-10"><input type="checkbox" class="widthCheck"
                                                                    name="bcrm_iunderstandthatacancellationfeewillbeappl"
                                                                    [(ngModel)]="profile.bcrm_iunderstandthatacancellationfeewillbeappl" />&nbsp;<span
                                                                    style="color: black;font-size: 16px;">
                                                                    {{'Profile.OtherInfo.4hoursnotic'|translate}}</span>
                                                            </div>

                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-10 col-sm-10"> <input type="checkbox" class="widthCheck"
                                                                    name="bcrm_iwishtoreceivetextmessagesfromdoctornow"
                                                                    [(ngModel)]="profile.bcrm_iwishtoreceivetextmessagesfromdoctornow" />&nbsp;<span
                                                                    style="color: black;font-size: 16px;">
                                                                    {{'Profile.OtherInfo.messagesfromOXvc'|translate}}</span>
                                                            </div>

                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-10 col-sm-10"><input type="checkbox" class="widthCheck"
                                                                    name="bcrm_iwishtoreceiveemailsfromdoctornow"
                                                                    [(ngModel)]="profile.bcrm_iwishtoreceiveemailsfromdoctornow" />&nbsp;<span
                                                                    style="color: black;font-size: 16px;">
                                                                    {{'Profile.OtherInfo.EMAILSfromOXvc'|translate}}</span>
                                                            </div>

                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-10 col-sm-10"> <input type="checkbox" class="widthCheck"
                                                                    name="bcrm_iwishtoreceivepostalcommunicationfromdoct"
                                                                    [(ngModel)]="profile.bcrm_iwishtoreceivepostalcommunicationfromdoct" />&nbsp;<span
                                                                    style="color: black;font-size: 16px;">
                                                                    {{'Profile.OtherInfo.communicationfromOXvc'|translate}}</span>
                                                            </div>

                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-md-10 col-sm-10"><input type="checkbox" class="widthCheck"
                                                                    name="bcrm_iwouldliketoreceiveupdatesaboutnewservice"
                                                                    [(ngModel)]="profile.bcrm_iwouldliketoreceiveupdatesaboutnewservice" />&nbsp;<span
                                                                    style="color: black;font-size: 16px;">
                                                                    {{'Profile.OtherInfo.developmentsatOXvc'|translate}}</span>
                                                            </div>

                                                        </div>
                                                        <div class="row ">
                                                            <div class="col-md-10 col-sm-10"> <input type="checkbox" class="widthCheck"
                                                                    name="bcrm_iamhappyfordoctornowtocontactmynextofkin"
                                                                    [(ngModel)]="profile.bcrm_iamhappyfordoctornowtocontactmynextofkin" />&nbsp;<span
                                                                    style="color: black;font-size: 16px;">
                                                                    {{'Profile.OtherInfo.Ihavetheirconsentforthis'|translate}}</span>
                                                            </div>

                                                        </div>

                                                    </div>



                                                </div>
                                                <!-- <div class="row pull-left" style="padding: 1% 1% 3% 1%;"> -->
                                                <div class="row pull-left">
                                                    <div class="col-md-1"><button type="submit"
                                                            class="btn btn-primary px-2"
                                                            value="{{'Profile.update'|translate}}">{{'Profile.update'|translate}}</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </form>

                                    </div>

                                </div>
                            </div>
                            <!-- 
    
 -->
                            <div id="Modal1" class="modal fade">
                                <!-- class modal and fade -->
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <!-- modal header -->
                                            <h5>Add Proxy</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                                                (click)="cleardata()">
                                                ×
                                            </button>
                                        </div>
                                        <div class="modal-body result-shade1">
                                            <form class="form" (ngSubmit)="AddProxy(proxy)" #proxy="ngForm" #f="ngForm"
                                                autocomplete="off">
                                                <div class="form-group row">
                                                    <label
                                                        class="bmd-label-floating col-sm-3">{{'Profile.firstName'|translate}}</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" class="form-control" name="firstname"
                                                            #name="ngModel" ngModel #firstname="ngModel"
                                                            placeholder="{{'Profile.firstName'|translate}}">

                                                    </div>
                                                </div><br>

                                                <div class="form-group row">
                                                    <label
                                                        class="bmd-label-floating col-sm-3 required">{{'Profile.lastName'|translate}}</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" class="form-control" name="lastname"
                                                            #name="ngModel" ngModel #lastname="ngModel"
                                                            required placeholder="{{'Profile.lastName'|translate}}">
                                                    </div>
                                                </div><br>

                                                <div class="form-group row">
                                                    <label
                                                        class="bmd-label-floating col-sm-3 ">{{'Profile.OtherInfo.Relationship'|translate}}</label>
                                                    <div class="col-sm-9">
                                                        <select class="Searchable_slect2" name="relationship"
                                                            #relationship="ngModel" ngModel
                                                            placeholder="Enter name" id="mySelect3">
                                                        </select>
                                                    </div>
                                                </div><br>

                                                <div class="form-group row">
                                                    <label
                                                        class="bmd-label-floating required col-sm-3 ">{{'Profile.email'|translate}}</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" class="form-control" name="emailaddress1"
                                                            #name="ngModel" ngModel #emailaddress1="ngModel"
                                                             required
                                                            placeholder="{{'Profile.email'|translate}}"
                                                            (keyup)='checkEmail($event)'>
                                                        <span id="emailInfo" class="tooltiptext">
                                                            <p
                                                                [ngClass]="(!specialCharacteremail ? 'text-danger': 'tooltiptext')  ">
                                                                <i class="material-icons"></i>Please enter valid email
                                                                address
                                                            </p>
                                                        </span>
                                                    </div>
                                                </div><br>

                                                <div class="form-group row">
                                                    <label
                                                        class="bmd-label-floating required col-sm-3 ">{{'PatnerInfo.mobileNumber'|translate}}</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" class="form-control" id="mobilephoneproxy"
                                                            name="mobilephone" #name="ngModel" ngModel
                                                            #mobilephone="ngModel" required
                                                            placeholder="{{'PatnerInfo.mobileNumber'|translate}}"
                                                            ng2TelInput
                                                            (countryChange)="onCountryChangehomePhone($event)"
                                                            (keyup)='checkmobilephoneproxy($event)'
                                                            [ng2TelInputOptions]="{initialCountry: 'gb'}"
                                                            maxlength="20">
                                                        <span id="mobileInfo" class="tooltiptext" style="border: 0px;">
                                                            <p
                                                                [ngClass]="(!specialCharactermobile ? 'text-danger': 'tooltiptext') ">
                                                                <i class="material-icons"></i>Please enter valid mobile
                                                                number eg(+447895462415)
                                                            </p>
                                                        </span>


                                                    </div>
                                                </div><br>

                                                <div class="row pull-right" style="padding: 0% 5% 3% 1%;">
                                                    <button type="submit" class="btn btn-primary"
                                                        value="{{'Common.Save'|translate}}">{{'Common.Save'|translate}}
                                                    </button>
                                                </div>

                                            </form>
                                        </div>




                                    </div>
                                </div>
                            </div>
                            <!--  -->
                        </section>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<br>