import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from 'src/app/ApiServices/Invoice/invoice.service';
declare var $: any;
@Component({
  selector: 'app-newinvoices',
  templateUrl: './newinvoices.component.html',
  styleUrls: ['./newinvoices.component.css']
})
export class NewinvoicesComponent implements OnInit {

  invoiceList: object;
  constructor(private invoiceService: InvoiceService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getInvoices()
  }
  
  getInvoices(): void {
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.invoiceService.getInvoices(environment.LoginUserId).subscribe((res) => {
      
      if(res != null){
        this.invoiceList = res;
        
      }
        $("#your-image3").attr("src", "../../../assets/images/Invoice.png");
        $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
        $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
        $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
        $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
        $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
        $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
        $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
        $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
        $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
        $("#your-image").attr("src", "../../../assets/images/profile.png");
        $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
      setTimeout(function () {
        $('#invoiceRecord').DataTable();
        }, 50);
     //*************Show / hide loader *******************/
     $('#ftco-loader').removeClass('show');
     /**************************************** */
    });
  }

}
