import { Component, OnInit, ViewChild} from '@angular/core';
import { ObservationsService } from 'src/app/ApiServices/Observation/observations.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ElementRef } from '@angular/core';
import Swal from 'sweetalert2';
import axios from 'axios';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import * as Chart from 'chart.js'
import * as printJS from 'print-js'
declare var $: any;

@Component({
  selector: 'app-observations',
  templateUrl: './observations.component.html',
  styleUrls: ['./observations.component.css']
})
export class ObservationsComponent implements OnInit {
  @ViewChild('ObCharts') ObCharts:ElementRef;
  ObservationsList: object;
  Observation: any = {};
  snomedCodeList: any;
  snomedjson: any;
  chartData:any = {
    FullDate:'',
    day:'',
    month:'',
    year:'',
    formattedDate:'',
    ObData:[],
    datacases:{
      negative:[],
      positive:[]},
    labels:[],
    heading:'',
    AxisLabel:{
      L1:'',
      L2:''
    }
  };
  canvas:any;
  ctx:any;
  chart:any;
  
  
  
  
  
  constructor(private observationService: ObservationsService,
    private router: Router,
    private toastr: ToastrService) { }
  QueryResult: any = [];




  ngOnInit(): void {
    var data: any = JSON.parse(localStorage.getItem("userProfileDataClaims"));
    environment.LoginUserEmail = data.userEamil;
    environment.LoginUserId = data.userId;
    let lt_this = this;
    this.getAllObservationByPatient(environment.LoginUserId);
    //***********************get Patient on hte keyUp of input feilds*********************************************************
    $('.getSnomed').keyup(function () {
      if ($('.getSnomed').val) {
        if ($('.getSnomed').val().length >= 3) {
          lt_this.getSnomadByName($('.getSnomed').val());
        }
      }
    });

    $("#msemr_valuerangehighlimit").hide();
    $("#msemr_valuerangelowlimit").hide();
    $("#bcrm_bloodsugarbeforemeals").hide();
    $("#bcrm_bloodsugaraftermeal").hide();
    $("#bcrm_weightinkilograms").hide();
    $("#bcrm_heightinmetres").hide();
    $("#bcrm_bmicalculation").hide();
    $("#msemr_description").hide();
    $("#Snomed").hide();
    $("#SnomedCode").hide();
    $("#bcrm_heartrate").hide();
    $("#bcrm_oxygensaturation").hide();
    $("#bcrm_respiratoryrate").hide();
    $("#bcrm_temperature").hide();
    $("#bcrm_bmi").hide()

  }

  RefreshPage(){
    this.ngOnInit()
  }

 
  ////#region Calculate Weight Observation
  weightConverter(calculateBy) {
    debugger
    let Value = parseFloat($("#" + calculateBy).val());
    let valNum1 = 0;
    let valNum2 = 0;
    if (Value) {
      switch (calculateBy) {
        case "bcrm_weight_kg":
          // Weight In KG To Pound
          valNum1 = Value * 2.2046;
          $('#bcrm_weight_pound').val(valNum1.toFixed(2));
          // Weight In KG To Gram
          valNum2 = Value * 1000;
          $('#bcrm_weight_gram').val(valNum2.toFixed(2));
          break;

        case "bcrm_weight_gram":
          // Weight In Gram To Pound
          valNum1 = Value / 453.59237;
          $('#bcrm_weight_pound').val(valNum1.toFixed(2));
          // Weight In Gram To KG
          valNum2 = Value / 1000;
          $('#bcrm_weight_kg').val(valNum2.toFixed(2));
          break;

        case "bcrm_weight_pound":
          // Weight In Pound To KG
          valNum1 = Value * 0.45359237;
          $('#bcrm_weight_kg').val(valNum1.toFixed(2));
          // Weight In Pound To Gram
          valNum2 = Value * 453.59237;
          $('#bcrm_weight_gram').val(valNum2.toFixed(2));
          break;

          case "bcrm_weight_kg_BMI":
          // Weight In KG To Pound
          valNum1 = Value * 2.2046;
          $('#bcrm_weight_pound_BMI').val(valNum1.toFixed(2));
          // Weight In KG To Gram
          valNum2 = Value * 1000;
          $('#bcrm_weight_gram_BMI').val(valNum2.toFixed(2));
          
          // if($('#bcrm_height_meter_BMI').val() == '' || $('#bcrm_height_meter_BMI').val() == undefined){
          //   this.calculateBmi()
          // }
          if($('#bcrm_height_meter_BMI').val()){
            this.calculateBmi()
          }
          
          break;

        case "bcrm_weight_gram_BMI":
          // Weight In Gram To Pound
          valNum1 = Value / 453.59237;
          $('#bcrm_weight_pound_BMI').val(valNum1.toFixed(2));
          // Weight In Gram To KG
          valNum2 = Value / 1000;
          $('#bcrm_weight_kg_BMI').val(valNum2.toFixed(2));

          if($('#bcrm_height_meter_BMI').val()){
            this.calculateBmi()
          }
          
          break;

        case "bcrm_weight_pound_BMI":
          // Weight In Pound To KG
          valNum1 = Value * 0.45359237;
          $('#bcrm_weight_kg_BMI').val(valNum1.toFixed(2));
          // Weight In Pound To Gram
          valNum2 = Value * 453.59237;
          $('#bcrm_weight_gram_BMI').val(valNum2.toFixed(2));

          if($('#bcrm_height_meter_BMI').val()){
            this.calculateBmi()
          }

          break;
      }
    }
    else {
      $('#bcrm_weight_pound').val(null);
      $('#bcrm_weight_gram').val(null);
      $('#bcrm_weight_kg').val(null);
      $('#bcrm_weight_pound_BMI').val(null);
      $('#bcrm_weight_gram_BMI').val(null);
      $('#bcrm_weight_kg_BMI').val(null);
    }

  }
  ////#endregion



  // Calculate Height Observation//
  LengthConverter(calculateBy) {

    let Value = parseFloat($("#" + calculateBy).val());
    let valNum1 = 0; let valNum2 = 0;
    if (Value) {
      switch (calculateBy) {
        case "bcrm_height_meter":
          //Height In Meter To Feet
          valNum1 = Value * 3.2808
          $('#bcrm_height_feet').val(valNum1.toFixed(2));
          //Height In Meter To CM
          valNum2 = Value / 0.01;
          $('#bcrm_height_cm').val(valNum2.toFixed(2));
          break;
          
        case "bcrm_height_cm":
          //Height In CM To Feet
          valNum1 = Value / 30.48;
          $('#bcrm_height_feet').val(valNum1.toFixed(2));
          //Height In CM To Meter
          valNum2 = Value / 100;
          $('#bcrm_height_meter').val(valNum2.toFixed(2));
          break;

        case "bcrm_height_feet":
          //Height In Feet To Meter
          valNum1 = Value * 0.3048
          $('#bcrm_height_meter').val(valNum1.toFixed(2));
          //Height In Feet To CM
          valNum2 = Value * 30.48;
          $('#bcrm_height_cm').val(valNum2.toFixed(2));
          break;

        case "bcrm_height_meter_BMI":
          //Height In Meter To Feet
          valNum1 = Value * 3.2808
          $('#bcrm_height_feet_BMI').val(valNum1.toFixed(2));
          //Height In Meter To CM
          valNum2 = Value / 0.01;
          $('#bcrm_height_cm_BMI').val(valNum2.toFixed(2));
          if($('#bcrm_weight_kg_BMI').val()){
            this.calculateBmi()
          }
          break;

        case "bcrm_height_cm_BMI":
          //Height In CM To Feet
          valNum1 = Value / 30.48;
          $('#bcrm_height_feet_BMI').val(valNum1.toFixed(2));
          //Height In CM To Meter
          valNum2 = Value / 100;
          $('#bcrm_height_meter_BMI').val(valNum2.toFixed(2));
          if($('#bcrm_weight_kg_BMI').val()){
            this.calculateBmi()
          }
          break;

        case "bcrm_height_feet_BMI":
          //Height In Feet To Meter
          valNum1 = Value * 0.3048
          $('#bcrm_height_meter_BMI').val(valNum1.toFixed(2));
          //Height In Feet To CM
          valNum2 = Value * 30.48;
          $('#bcrm_height_cm_BMI').val(valNum2.toFixed(2));
          if($('#bcrm_weight_kg_BMI').val()){
            this.calculateBmi()
          }
          break; 
      }
    }
    else {
      $('#bcrm_height_feet').val(null);
      $('#bcrm_height_cm').val(null);
      $('#bcrm_heightinmetres').val(null);
      $('#bcrm_height_feet_BMI').val(null);
      $('#bcrm_height_cm_BMI').val(null);
      $('#bcrm_heightinmetres_BMI').val(null);
    }
    // if($('#bcrm_height_inch').val()!='' || $('#bcrm_weight_kg').val()!='' ){

    //   this.calculateBmi()


    //  }
  }
  getAllObservationByPatient(patientId: string): void {
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    $('#dataTableExample').DataTable().destroy();
    this.ObservationsList = null;

    this.observationService.getAllObservationListByPatientId(patientId).subscribe((res) => {
      debugger;
      if (res != null) {
        this.ObservationsList = res;
      }

      setTimeout(function () {
        $('#dataTableExample').DataTable({
          "aLengthMenu": [
            [10, 30, 50, -1],
            [10, 30, 50, "All"]
          ],
          "iDisplayLength": 10,
          "language": {
            search: ""
          }
        });
        $('#dataTableExample').each(function () {
          var datatable = $(this);
          // SEARCH - Add the placeholder for Search and Turn this into in-line form control
          var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
          search_input.attr('placeholder', 'Search');
          search_input.removeClass('form-control-sm');
          // LENGTH - Inline-Form control
          var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
          length_sel.removeClass('form-control-sm');
        });
      }, 50);

      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      // this.router.navigate(['/']);
    });

  }

// Observation Chart Start 

DisplayChart(data){
  this.chartData = {
    FullDate:'',
    day:'',
    month:'',
    year:'',
    formattedDate:'',
    ObData:[],
    datacases:{
      negative:[],
      positive:[]},
    labels:[],
    heading:'',
    AxisLabel:{
      L1:'',
      L2:''
    }
  };
  for(let i in this.ObservationsList){
    if(this.ObservationsList[i].bcrm_readingcategories == data){    
      if(data == '101'){
        this.chartData.FullDate = new Date(this.ObservationsList[i].createdOn);
        this.chartData.day = this.chartData.FullDate.getDate();
        this.chartData.month = this.chartData.FullDate.toLocaleString('default', { month: 'short' });
        this.chartData.year = this.chartData.FullDate.getFullYear();
        this.chartData.formattedDate = `${this.chartData.day}-${this.chartData.month}-${this.chartData.year}`
        this.chartData.ObData.push(this.ObservationsList[i])
        this.chartData.datacases.positive.push(this.ObservationsList[i].msemr_valuerangehighlimit)      
        this.chartData.datacases.negative.push(this.ObservationsList[i].msemr_valuerangelowlimit)
        this.chartData.labels.push(this.chartData.formattedDate)      
        this.chartData.heading = this.ObservationsList[i].bcrm_readingcategories_name
      }
      else if(data == '102'){
        this.chartData.FullDate = new Date(this.ObservationsList[i].createdOn);
        this.chartData.day = this.chartData.FullDate.getDate();
        this.chartData.month = this.chartData.FullDate.toLocaleString('default', { month: 'short' });
        this.chartData.year = this.chartData.FullDate.getFullYear();
        this.chartData.formattedDate = `${this.chartData.day}-${this.chartData.month}-${this.chartData.year}`
        this.chartData.ObData.push(this.ObservationsList[i])
        this.chartData.datacases.positive.push(this.ObservationsList[i].bcrm_bloodsugaraftermeal)      
        this.chartData.datacases.negative.push(this.ObservationsList[i].bcrm_bloodsugarbeforemeals)
        this.chartData.labels.push(this.chartData.formattedDate)      
        this.chartData.heading = this.ObservationsList[i].bcrm_readingcategories_name
      } 
      else if(data == '107'){
        this.chartData.FullDate = new Date(this.ObservationsList[i].createdOn);
        this.chartData.day = this.chartData.FullDate.getDate();
        this.chartData.month = this.chartData.FullDate.toLocaleString('default', { month: 'short' });
        this.chartData.year = this.chartData.FullDate.getFullYear();
        this.chartData.formattedDate = `${this.chartData.day}-${this.chartData.month}-${this.chartData.year}`
        this.chartData.ObData.push(this.ObservationsList[i])
        this.chartData.datacases.positive.push(this.ObservationsList[i].bcrm_heartrate)      
        this.chartData.labels.push(this.chartData.formattedDate)      
        this.chartData.heading = this.ObservationsList[i].bcrm_readingcategories_name
      } 
      else if(data == '108'){
        this.chartData.FullDate = new Date(this.ObservationsList[i].createdOn);
        this.chartData.day = this.chartData.FullDate.getDate();
        this.chartData.month = this.chartData.FullDate.toLocaleString('default', { month: 'short' });
        this.chartData.year = this.chartData.FullDate.getFullYear();
        this.chartData.formattedDate = `${this.chartData.day}-${this.chartData.month}-${this.chartData.year}`
        this.chartData.ObData.push(this.ObservationsList[i])
        this.chartData.datacases.positive.push(this.ObservationsList[i].bcrm_oxygensaturation)      
        this.chartData.labels.push(this.chartData.formattedDate)      
        this.chartData.heading = this.ObservationsList[i].bcrm_readingcategories_name
      }
      else if(data == '109'){
        this.chartData.FullDate = new Date(this.ObservationsList[i].createdOn);
        this.chartData.day = this.chartData.FullDate.getDate();
        this.chartData.month = this.chartData.FullDate.toLocaleString('default', { month: 'short' });
        this.chartData.year = this.chartData.FullDate.getFullYear();
        this.chartData.formattedDate = `${this.chartData.day}-${this.chartData.month}-${this.chartData.year}`
        this.chartData.ObData.push(this.ObservationsList[i])
        this.chartData.datacases.positive.push(this.ObservationsList[i].bcrm_respiratoryrate)      
        this.chartData.labels.push(this.chartData.formattedDate)      
        this.chartData.heading = this.ObservationsList[i].bcrm_readingcategories_name
      }
      else if(data == '110'){
        this.chartData.FullDate = new Date(this.ObservationsList[i].createdOn);
        this.chartData.day = this.chartData.FullDate.getDate();
        this.chartData.month = this.chartData.FullDate.toLocaleString('default', { month: 'short' });
        this.chartData.year = this.chartData.FullDate.getFullYear();
        this.chartData.formattedDate = `${this.chartData.day}-${this.chartData.month}-${this.chartData.year}`
        this.chartData.ObData.push(this.ObservationsList[i])
        this.chartData.datacases.positive.push(this.ObservationsList[i].bcrm_peakflow)      
        this.chartData.labels.push(this.chartData.formattedDate)      
        this.chartData.heading = this.ObservationsList[i].bcrm_readingcategories_name
      }
      else if(data == '111'){
        this.chartData.FullDate = new Date(this.ObservationsList[i].createdOn);
        this.chartData.day = this.chartData.FullDate.getDate();
        this.chartData.month = this.chartData.FullDate.toLocaleString('default', { month: 'short' });
        this.chartData.year = this.chartData.FullDate.getFullYear();
        this.chartData.formattedDate = `${this.chartData.day}-${this.chartData.month}-${this.chartData.year}`
        this.chartData.ObData.push(this.ObservationsList[i])
        this.chartData.datacases.positive.push(this.ObservationsList[i].bcrm_temperature)      
        this.chartData.labels.push(this.chartData.formattedDate)      
        this.chartData.heading = this.ObservationsList[i].bcrm_readingcategories_name
      }else{      
        this.chartData.heading =''
      }
    }
  }

  if(data == '105'){
    this.chartData.AxisLabel.L1 = 'Lable1'
    this.chartData.AxisLabel.L2 = 'Label2'
  }
  console.log(this.chartData)
    console.log(this.ObservationsList)
    this.createLineChart(this.chartData.labels,this.chartData.datacases,this.chartData.heading,this.chartData.AxisLabel)

}






createLineChart(labels,datacases,heading,Axislabel){
  this.canvas = document.getElementById('myChart');
  this.ctx = this.canvas.getContext('2d');
  let data=[]
  
  console.log(heading)

  if(heading == 'Blood Pressure'){
    Axislabel.L1 = 'Systolic'
    Axislabel.L2 = 'Diasystolic'
    
    
    data.push({
      label:Axislabel.L1,
      data:datacases.positive,
      backgroundColor:'#800080',
      borderColor:'#800080',
      fill: false,
      tension: 0.1
    },{
      label:Axislabel.L2,
      data:datacases.negative,
      backgroundColor:'#FFC0CB',
      borderColor:'#FFC0CB',
      fill: false,
      tension: 0.1
    })  
  }
  else if(heading == 'Diabetes'){
    Axislabel.L1 = 'Sugar Before Meal'
    Axislabel.L2 = 'Sugar After Meal'
  
    data.push({
    label:Axislabel.L1,
    data:datacases.positive,
    backgroundColor:'#800080',
    borderColor:'#800080',
    fill: false,
    tension: 0.1
  },{
    label:Axislabel.L2,
    data:datacases.negative,
    backgroundColor:'#FFC0CB',
    borderColor:'#FFC0CB',
    fill: false,
    tension: 0.1
  })
  }
  else if(heading == 'Heart Rate'){
    Axislabel.L1 = 'Heart Rate'
    data.push({
      label:Axislabel.L1,
      data:datacases.positive,
      backgroundColor:'#800080',
      borderColor:'#800080',
      fill: false,
      tension: 0.1
    })
  }
  else if(heading == 'Oxygen Saturation (SpO2)'){
    Axislabel.L1 = 'Oxygen Saturation'
    data.push({
      label:Axislabel.L1,
      data:datacases.positive,
      backgroundColor:'#800080',
      borderColor:'#800080',
      fill: false,
      tension: 0.1
    })
  }
  else if(heading == 'Respiratory Rate(rr)'){
    Axislabel.L1 = 'Respiratory Rate(rr)'
    data.push({
      label:Axislabel.L1,
      data:datacases.positive,
      backgroundColor:'#800080',
      borderColor:'#800080',
      fill: false,
      tension: 0.1
    })
  }
  else if(heading == 'peakFlow'){
    Axislabel.L1 = 'Peak Flow'
    data.push({
      label:Axislabel.L1,
      data:datacases.positive,
      backgroundColor:'#800080',
      borderColor:'#800080',
      fill: false,
      tension: 0.1
    })
  }
  else if(heading == 'Temperature'){
    Axislabel.L1 = 'Temperature'
    data.push({
      label:Axislabel.L1,
      data:datacases.positive,
      backgroundColor:'#800080',
      borderColor:'#800080',
      fill: false,
      tension: 0.1
    })
  }
  else{
      data.push({
      label:'No Data',
      data:'No Data',
      backgroundColor:'#800080',
      borderColor:'#800080',
      fill: false,
      tension: 0.1
    })
  }
  
  


  this.chart = new Chart(this.ctx,{
    type:'line',
    data:{
      labels: labels,
      datasets:data
    },
    options:{
      responsive: true, 
      // maintainAspectRatio: false,
      title:{
        display: true,
        text: heading
      },
      animation: {
        onComplete: function () {
          // debugger
          // document.getElementById('JPEG').setAttribute('href',this.chart.toBase64Image())
          // document.getElementById('JPEG').setAttribute('Download','Chart.jpeg')
          // document.getElementById('PNG').setAttribute('href',this.chart.toBase64Image())
          // document.getElementById('PNG').setAttribute('Download','Chart.png')
          }
      }
    }
  })
  this.chart.render()
  $(this.ObCharts.nativeElement).modal('show'); 
}

//Download Functions 

download_Png(){
  var canvas = <HTMLCanvasElement>document.getElementById("myChart");
  let image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
  var link = document.createElement('a');
  link.download = `${this.chartData.heading}.png`;
  link.href = image;
  link.click();
}

download_Jpg(){
  var canvas = <HTMLCanvasElement>document.getElementById("myChart");
  let image = canvas.toDataURL("image/jpg").replace("image/jpg", "image/octet-stream");
  var link = document.createElement('a');
  link.download = `${this.chartData.heading }.jpg`;
  link.href = image;
  link.click();
}

DownloadPdf():void {
  debugger
  let data = document.getElementById('myChart');
  // Create Image
    html2canvas(data).then(canvas => {
      var imgWidth = 190;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 10, position, imgWidth, imgHeight)
      pdf.save(this.chartData.heading + " Chart.pdf");
    });
}

printCanvas()  
{  
  let data = <HTMLCanvasElement>document.querySelector("#myChart")
    printJS({printable: data.toDataURL(), type: 'image', imageStyle: 'width:100%'});

}



closeChart(){
  this.chart.destroy();
}



//Observation Chart End


  getSnomadByName(startWith: string): void {

    //*************Show / hide loader *******************/
    //$('#ftco-loader').addClass('show');
    /**************************************** */
    this.QueryResult = [];
    let baseUrl = "https://browser.ihtsdotools.org/snowstorm/snomed-ct";
    let edition = "MAIN";
    let version = "2019-07-31";

    let findByQueryUrl = baseUrl + '/' + edition + '/' + version + '/concepts?term=' + encodeURIComponent(startWith) + '&activeFilter=true&offset=0&limit=20';

    axios.get(findByQueryUrl)
      .then((response) => {
        if (response != null && response != undefined) {
          let dropdownList = response.data.items
          this.QueryResult = dropdownList
          this.snomedjson = {
            Id: this.QueryResult[0].id, conceptId: this.QueryResult[0].conceptId, active: this.QueryResult[0].active, definitionStatus: this.QueryResult[0].definitionStatus,
            moduleId: this.QueryResult[0].moduleId, effectiveTime: this.QueryResult[0].effectiveTime, fsn_term: this.QueryResult[0].fsn.term, fsn_lang: this.QueryResult[0].fsn.lang,
            pt_term: this.QueryResult[0].pt.term, pt_lang: this.QueryResult[0].pt.lang
          };
          // let filterDataObject = [];
          dropdownList.forEach(function (item) {
            if (startWith == item.pt.term) {
              (<HTMLInputElement>document.getElementById("snomedname")).value = startWith;
              (<HTMLInputElement>document.getElementById("bcrm_conceptid")).value = item.conceptId;
              (<HTMLInputElement>document.getElementById("msemr_code")).value = item.conceptId;
            }

          });
        }
      });

  }

  onSubmit(it: NgForm) {


    var ObservationData = it.value;
    if (ObservationData.bcrm_readingcategories == '') {
      return this.toastr.error("Please fill  mandatory feilds")
    }
    var newcategory;
    switch (ObservationData.bcrm_readingcategories) {
      case '101':
        newcategory = 'Blood Pressure'
        if (ObservationData.bcrm_readingcategories == '' || ObservationData.msemr_valuerangehighlimit == '' || ObservationData.msemr_valuerangelowlimit == '') {
          return this.toastr.error("Please fill  mandatory feilds")
        }
        break;
      case '102':
        newcategory = 'Diabetes'
        if (ObservationData.bcrm_readingcategories == '' || ObservationData.bcrm_bloodsugarbeforemeals == '' || ObservationData.bcrm_bloodsugaraftermeal == '') {
          return this.toastr.error("Please fill  mandatory feilds")
        }
        break;
      case '103':
        newcategory = 'Height'
        if (ObservationData.bcrm_heightinmetres == '') {
          ObservationData.bcrm_heightinmetres = $('#bcrm_height_meter').val();
        }
        if (ObservationData.bcrm_readingcategories == '' || ObservationData.bcrm_heightinmetres == '') {
          return this.toastr.error("Please fill  mandatory feilds")
        }
        break;
      case '104':
        if (ObservationData.bcrm_weightinkilograms == '') {
          ObservationData.bcrm_weightinkilograms = $('#bcrm_weight_kg').val();
        }
        newcategory = 'Weight'
        if (ObservationData.bcrm_readingcategories == '' || ObservationData.bcrm_weightinkilograms == '') {
          return this.toastr.error("Please fill  mandatory feilds")
        }
        break;
      case '105':
        newcategory = 'Other'
        break;
      case '106':
        if (ObservationData.bcrm_bmi == '') {
          ObservationData.bcrm_bmicalculation = $('#bcrm_bmi').val();
        }
        newcategory = 'BMI'
        if (ObservationData.bcrm_readingcategories == '' || ObservationData.bcrm_bmi == '') {
          return this.toastr.error("Please fill  mandatory feilds")
        }
        break;
      case '107':
        if (ObservationData.bcrm_heartrate == '') {
          ObservationData.bcrm_heartrate = $('#bcrm_heartrate').val();
        }
        newcategory = 'Heart Rate'
        if (ObservationData.bcrm_readingcategories == '' || ObservationData.bcrm_heartrate == '') {
          return this.toastr.error("Please fill  mandatory feilds")
        }
        break;
      case '108':
        if (ObservationData.bcrm_oxygensaturation == '') {
          ObservationData.bcrm_oxygensaturation = $('#bcrm_oxygensaturation').val();
        }
        newcategory = 'Oxygen Saturation (SpO2)'
        if (ObservationData.bcrm_readingcategories == '' || ObservationData.bcrm_oxygensaturation == '') {
          return this.toastr.error("Please fill  mandatory feilds")
        }
        break;
      case '109':
        if (ObservationData.bcrm_respiratoryrate == '') {
          ObservationData.bcrm_respiratoryrate = $('#bcrm_respiratoryrate').val();
        }
        newcategory = 'Respiratory Rate (rr)'
        if (ObservationData.bcrm_readingcategories == '' || ObservationData.bcrm_respiratoryrate == '') {
          return this.toastr.error("Please fill  mandatory feilds")
        }
        break;
      // case '110':
      //   if (ObservationData.bcrm_heartrate == '') {
      //     ObservationData.bcrm_heartrate = $('#bcrm_heartrate').val();
      //   }
      //   newcategory = 'Peak Flow'
      //   if (ObservationData.bcrm_readingcategories == '' || ObservationData.bcrm_heartrate == '') {
      //     return this.toastr.error("Please fill  mandatory feilds")
      //   }
      //   break;
      case  '111':
        if (ObservationData.bcrm_temperature == '') {
          ObservationData.bcrm_temperature = $('#bcrm_temperature').val();
        }
        newcategory = 'Temperature'
        if (ObservationData.bcrm_readingcategories == '' || ObservationData.bcrm_temperature == '') {
          return this.toastr.error("Please fill  mandatory feilds")
        }
        break;
    }
    // 
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    let dd1, mm1;
    if (dd < 10)
      dd1 = '0' + dd;
    else
      dd1 = dd;
    if (mm < 10)
      mm1 = '0' + mm;
    else
      mm1 = mm;
    //set description//
    var todayDate = dd1 + '/' + mm1 + '/' + yyyy;
    var time = today.getHours() + ":" + today.getMinutes();
    ObservationData.msemr_description = newcategory + "_" + todayDate + " " + time;

    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /************************************************** */
    let observationToBeSend = {
      bcrm_concept: JSON.stringify(this.snomedjson),
      bcrm_snomedname: (<HTMLInputElement>document.getElementById("snomedname")).value,
      msemr_description: ObservationData.msemr_description,
      bcrm_weightinkilograms: ObservationData.bcrm_weightinkilograms,
      bcrm_heightinmetres: ObservationData.bcrm_heightinmetres,
      bcrm_bloodsugarbeforemeals: ObservationData.bcrm_bloodsugarbeforemeals,
      bcrm_bloodsugaraftermeal: ObservationData.bcrm_bloodsugaraftermeal,
      msemr_valuerangehighlimit: ObservationData.msemr_valuerangehighlimit,
      msemr_valuerangelowlimit: ObservationData.msemr_valuerangelowlimit,
      msemr_valuetypequantityvalue: ObservationData.msemr_valuetypequantityvalue,
      msemr_valueboolean: true,
      bcrm_readingcategories: ObservationData.bcrm_readingcategories,
      msemr_comment: ObservationData.msemr_comment,
      bcrm_modifiedby: "Patient_" + environment.LoginUserEmail + "",
      bcrm_observationcreatedby: 102,
      bcrm_heartrate: ObservationData.bcrm_heartrate,
      bcrm_oxygensaturation:ObservationData.bcrm_oxygensaturation,
      bcrm_respiratoryrate:ObservationData.bcrm_respiratoryrate,
      bcrm_temperature:ObservationData.bcrm_temperature,
      bcrm_bmicalculation:ObservationData.bcrm_bmicalculation

    }


    this.observationService.createNewObservationInDynamics(environment.LoginUserId, observationToBeSend).subscribe((res) => {

      if (res == true) {
        this.toastr.success("Observation successfully created");
        $('#CreateObservationModel').modal('hide');
        $("#CreateObservation")[0].reset();
        this.RefreshPage()
      }
      else {
        this.toastr.error("Error while creating observation");
      }
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/']);
    });
  }
  editObservation(data) {
    this.Observation = data;
    let SnomedCode = [{
      conceptId: this.Observation.bcrm_conceptid,
      code: this.Observation.bcrm_snomedname
    }]
    this.snomedCodeList = SnomedCode;
  }

  onUpdate(EditDetails: NgForm) {

    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.observationService.updateObservationInDynamics(environment.LoginUserId, this.Observation).subscribe((res) => {
      if (res == true) {
        this.toastr.success("Observation Updated");
        $('#UpdateObservationModel').modal('hide');
        this.getAllObservationByPatient(environment.LoginUserId);
      }
      else {
        this.toastr.error("Observation Not Update Due to Some Error");
      }
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
  }

  showConfirm(ObservationId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No, Cancel'
    }).then((result) => {
      if (result.value) {
        //*************Show / hide loader *******************/
        $('#ftco-loader').addClass('show');
        /**************************************** */
        this.observationService.deleteObservationById(ObservationId).subscribe((res) => {

          if (res == true) {
            this.toastr.info("Deleted Successfully");
            this.getAllObservationByPatient(environment.LoginUserId);
          }
          $('#ftco-loader').removeClass('show');
          /**************************************** */
        }, (err) => {
          //*************Show / hide loader *******************/
          $('#ftco-loader').removeClass('show');
          /****************** -------- **********************/
          this.toastr.error(err, "Error : ");
          this.router.navigate(['/']);
        });

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
        );
      }
    });

  }

  onCategory(event): void {
    debugger

    var newcategory;
    switch (event) {
      case '101':
        newcategory = 'Blood Pressure'
        break;
      case '102':
        newcategory = 'Diabetes'
        break;
      case '103':
        newcategory = 'Height'
        break;
      case '104':
        newcategory = 'Weight'
        break;
      case '105':
        newcategory = 'Other'
        break;
      case '106':
      newcategory = 'BMI'
        break;
      case '107':
        newcategory = 'Heart Rate'
       break;
      case '108':
        newcategory = 'Oxygen Saturation (SpO2)'
        break;
      case '109':
        newcategory = 'Respiratory Rate (rr)'
        break;  
      case '110':
        newcategory = 'Peak Flow'
        break;
      case  '111': 
        newcategory = 'Temperature'
        break;
    
      }
    // 
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    let dd1, mm1;
    if (dd < 10)
      dd1 = '0' + dd;
    else
      dd1 = dd;
    if (mm < 10)
      mm1 = '0' + mm;
    else
      mm1 = mm;
    //set description//
    var todayDate = dd1 + '/' + mm1 + '/' + yyyy;
    $('#msemr_description1').val(newcategory + "_" + todayDate);

    if (event == "101") {
      $("#msemr_valuerangehighlimit").show();
      $("#msemr_valuerangelowlimit").show();
      $("#bcrm_bloodsugarbeforemeals").hide();
      $("#bcrm_bloodsugaraftermeal").hide();
      $("#bcrm_weightinkilograms").hide();
      $("#bcrm_heightinmetres").hide();
      $("#bcrm_bmicalculation").hide();
      $("#msemr_description").show();
      $("#Snomed").hide();
      $("#SnomedCode").hide();
      $("#bcrm_heartrate").hide();
      $("#bcrm_oxygensaturation").hide();
      $("#bcrm_respiratoryrate").hide();
      $("#bcrm_temperature").hide();
      $("#bcrm_bmi").hide();

      var snomeddata = (<HTMLInputElement>document.getElementById("browser1")).value = 'Blood pressure';
      this.getSnomadByName(snomeddata);
    }

    else if (event == "102") {
      $("#bcrm_bloodsugarbeforemeals").show();
      $("#bcrm_bloodsugaraftermeal").show();
      $("#msemr_valuerangehighlimit").hide();
      $("#msemr_valuerangelowlimit").hide();
      $("#bcrm_weightinkilograms").hide();
      $("#bcrm_heightinmetres").hide();
      $("#bcrm_bmicalculation").hide();
      $("#msemr_description").show();
      $("#snomedsearch_input").show();
      $("#Snomed").hide();
      $("#SnomedCode").hide();
      $("#bcrm_heartrate").hide();
      $("#bcrm_oxygensaturation").hide();
      $("#bcrm_respiratoryrate").hide();
      $("#bcrm_temperature").hide();
      $("#bcrm_bmi").hide()

      var snomeddata = (<HTMLInputElement>document.getElementById("browser1")).value = 'Diabetes type';
      this.getSnomadByName(snomeddata);
    }
    else if (event == "103") {
      $("#bcrm_weightinkilograms").hide();
      $("#bcrm_heightinmetres").show();
      $("#bcrm_bmicalculation").hide();
      $("#msemr_description").show();
      $("#msemr_valuerangehighlimit").hide();
      $("#msemr_valuerangelowlimit").hide();
      $("#bcrm_bloodsugarbeforemeals").hide();
      $("#bcrm_bloodsugaraftermeal").hide();
      $("#snomedsearch_input").hide();
      $("#Snomed").hide();
      $("#SnomedCode").hide();
      $("#bcrm_heartrate").hide();
      $("#bcrm_oxygensaturation").hide();
      $("#bcrm_respiratoryrate").hide();
      $("#bcrm_temperature").hide();
      $("#bcrm_bmi").hide()
      var snomeddata = (<HTMLInputElement>document.getElementById("browser1")).value = 'Body height measure';
      this.getSnomadByName(snomeddata);
    }
    else if (event == "104") {
      $("#bcrm_weightinkilograms").show();
      $("#bcrm_heightinmetres").hide();
      $("#bcrm_bmicalculation").hide();
      $("#msemr_description").show();
      $("#msemr_valuerangehighlimit").hide();
      $("#msemr_valuerangelowlimit").hide();
      $("#bcrm_bloodsugarbeforemeals").hide();
      $("#bcrm_bloodsugaraftermeal").hide();
      $("#snomedsearch_input").hide();
      $("#Snomed").hide();
      $("#SnomedCode").hide();
      $("#bcrm_heartrate").hide();
      $("#bcrm_oxygensaturation").hide();
      $("#bcrm_respiratoryrate").hide();
      $("#bcrm_temperature").hide();
      $("#bcrm_bmi").hide()
      var snomeddata = (<HTMLInputElement>document.getElementById("browser1")).value = 'Weight';
      this.getSnomadByName(snomeddata);
    }
    else if (event == "105") {
      $("#msemr_description").show();
      $("#msemr_valuerangehighlimit").hide();
      $("#msemr_valuerangelowlimit").hide();
      $("#bcrm_bloodsugarbeforemeals").hide();
      $("#bcrm_bloodsugaraftermeal").hide();
      $("#bcrm_weightinkilograms").hide();
      $("#bcrm_heightinmetres").hide();
      $("#bcrm_bmicalculation").hide();
      $("#snomedsearch_input").show();
      $("#Snomed").show();
      $("#SnomedCode").show();
      $("#bcrm_heartrate").hide();
      $("#bcrm_oxygensaturation").hide();
      $("#bcrm_respiratoryrate").hide();
      $("#bcrm_temperature").hide();
      $("#bcrm_bmi").hide()
      var snomeddata = (<HTMLInputElement>document.getElementById("browser1")).value = '';
    } 
    else if (event == "106") {
      $("#msemr_description").show();
      $("#msemr_valuerangehighlimit").hide();
      $("#msemr_valuerangelowlimit").hide();
      $("#bcrm_bloodsugarbeforemeals").hide();
      $("#bcrm_bloodsugaraftermeal").hide();
      $("#bcrm_weightinkilograms").hide();
      $("#bcrm_heightinmetres").hide();
      $("#bcrm_bmicalculation").show();
      $("#bcrm_bmi").show();      
      $("#snomedsearch_input").show();
      $("#Snomed").hide();
      $("#SnomedCode").hide();
      $("#bcrm_heartrate").hide();
      $("#bcrm_oxygensaturation").hide();
      $("#bcrm_respiratoryrate").hide();
      $("#bcrm_temperature").hide();

      var snomeddata = (<HTMLInputElement>document.getElementById("browser1")).value = 'Heart rate';
      this.getSnomadByName(snomeddata);
    }
    else if (event == "107") {
      $("#msemr_description").show();
      $("#msemr_valuerangehighlimit").hide();
      $("#msemr_valuerangelowlimit").hide();
      $("#bcrm_bloodsugarbeforemeals").hide();
      $("#bcrm_bloodsugaraftermeal").hide();
      $("#bcrm_weightinkilograms").hide();
      $("#bcrm_heightinmetres").hide();
      $("#bcrm_bmicalculation").hide();
      $("#snomedsearch_input").hide();
      $("#Snomed").hide();
      $("#SnomedCode").hide();
      $("#bcrm_heartrate").show();
      $("#bcrm_oxygensaturation").hide();
      $("#bcrm_respiratoryrate").hide();
      $("#bcrm_bmi").hide()
      var snomeddata = (<HTMLInputElement>document.getElementById("browser1")).value = 'Body mass index';
      this.getSnomadByName(snomeddata);
    }
    else if (event == "108") {
      $("#msemr_description").show();
      $("#msemr_valuerangehighlimit").hide();
      $("#msemr_valuerangelowlimit").hide();
      $("#bcrm_bloodsugarbeforemeals").hide();
      $("#bcrm_bloodsugaraftermeal").hide();
      $("#bcrm_weightinkilograms").hide();
      $("#bcrm_heightinmetres").hide();
      $("#bcrm_bmicalculation").hide();
      $("#snomedsearch_input").hide();
      $("#Snomed").hide();
      $("#SnomedCode").hide();
      $("#bcrm_heartrate").hide();
      $("#bcrm_oxygensaturation").show();
      $("#bcrm_respiratoryrate").hide();
      $("#bcrm_temperature").hide();
      $("#bcrm_bmi").hide()
      var snomeddata = (<HTMLInputElement>document.getElementById("browser1")).value = 'Peripheral oxygen saturation';
      this.getSnomadByName(snomeddata);
    }
    else if (event == "109") {
      $("#msemr_description").show();
      $("#msemr_valuerangehighlimit").hide();
      $("#msemr_valuerangelowlimit").hide();
      $("#bcrm_bloodsugarbeforemeals").hide();
      $("#bcrm_bloodsugaraftermeal").hide();
      $("#bcrm_weightinkilograms").hide();
      $("#bcrm_heightinmetres").hide();
      $("#bcrm_bmicalculation").hide();
      $("#snomedsearch_input").hide();
      $("#Snomed").hide();
      $("#SnomedCode").hide();
      $("#bcrm_heartrate").hide();
      $("#bcrm_oxygensaturation").hide();
      $("#bcrm_respiratoryrate").show();
      $("#bcrm_temperature").hide();
      $("#bcrm_bmi").hide()
      var snomeddata = (<HTMLInputElement>document.getElementById("browser1")).value = 'Respiratory rate ';
      this.getSnomadByName(snomeddata);
    }
    else if (event == "111") {
      $("#msemr_description").show();
      $("#msemr_valuerangehighlimit").hide();
      $("#msemr_valuerangelowlimit").hide();
      $("#bcrm_bloodsugarbeforemeals").hide();
      $("#bcrm_bloodsugaraftermeal").hide();
      $("#bcrm_weightinkilograms").hide();
      $("#bcrm_heightinmetres").hide();
      $("#bcrm_bmicalculation").hide();
      $("#snomedsearch_input").hide();
      $("#Snomed").hide();
      $("#SnomedCode").hide();
      $("#bcrm_heartrate").hide();
      $("#bcrm_oxygensaturation").hide();
      $("#bcrm_respiratoryrate").hide();
      $("#bcrm_temperature").show();
      $("#bcrm_bmi").hide()
      var snomeddata = (<HTMLInputElement>document.getElementById("browser1")).value = 'Temperature';
      this.getSnomadByName(snomeddata);
    }
    else {
      $("#msemr_valuerangehighlimit").hide();
      $("#msemr_valuerangelowlimit").hide();
      $("#bcrm_bloodsugarbeforemeals").hide();
      $("#bcrm_bloodsugaraftermeal").hide();
      $("#bcrm_weightinkilograms").hide();
      $("#bcrm_heightinmetres").hide();
      $("#bcrm_bmicalculation").hide();
      $("#msemr_description").hide();
      $("#snomedsearch_input").show();
      $("#Snomed").hide();
      $("#SnomedCode").hide();
      $("#bcrm_heartrate").hide();
      $("#bcrm_oxygensaturation").hide();
      $("#bcrm_respiratoryrate").hide();
      $("#bcrm_temperature").hide();
      $("#bcrm_bmi").hide()
    }
  }
  calculateBmi(): void {
    debugger
    var weight = $("#bcrm_weight_kg_BMI").val();
    var height = $("#bcrm_height_meter_BMI").val();
    if (weight > 0 && height > 0) {
      var finalBmi = weight / (height * height)
      $("#bcrm_bmi").val(finalBmi.toFixed(2));
    }
  }
  ReloadComponent(): void {
    let Location = this.router.url;
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([Location], { skipLocationChange: true });
    });

  }
  clearObservation(){
    $("#purpose").val("");
    $("#msemr_description1").val("");
    $("#DiastolicPressure").val("");
    $("#SystolicPressure").val("");
    $("#BeforeMeals").val("");
    $("#AfterMeals").val("");
    $("#bcrm_weight_kg").val("");
    $("#bcrm_weight_gram").val("");
    $("#bcrm_weight_pound").val("");
    $("#bcrm_height_meter").val("");
    $("#bcrm_height_feet").val("");
    $("#bcrm_height_cm").val("");
    $("#bcrm_weight_kg_BMI").val("");
    $("#bcrm_weight_gram_BMI").val("");
    $("#bcrm_weight_pound_BMI").val("");
    $("#bcrm_height_meter_BMI").val("");
    $("#bcrm_height_feet_BMI").val("");
    $("#bcrm_height_cm_BMI").val("");
    $("#comment").val("");
    $("#bcrm_heartrate").val("");
    $("#bcrm_oxygensaturation").val("");
    $("#bcrm_respiratoryrate").val("");
    $("#bcrm_temperature").val("");
    $("#bcrm_bmi").val("");
    $("#msemr_valuerangehighlimit").hide();
    $("#msemr_valuerangelowlimit").hide();
    $("#bcrm_bloodsugarbeforemeals").hide();
    $("#bcrm_bloodsugaraftermeal").hide();
    $("#bcrm_weightinkilograms").hide();
    $("#bcrm_heightinmetres").hide();
    $("#bcrm_bmicalculation").hide();
    $("#msemr_description").hide();
    $("#snomedsearch_input").show();
    $("#Snomed").hide();
    $("#SnomedCode").hide();
    $("#bcrm_heartrate").hide();
    $("#bcrm_oxygensaturation").hide();
    $("#bcrm_respiratoryrate").hide();
    $("#bcrm_temperature").hide();
    $("#bcrm_bmi").hide()
  }
}
