<div class="bradcam_area breadcam_bg bradcam_overlay">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="bradcam_text">
            <h3>Departments</h3>
            <p><a href="index.html">Home /</a> Departments</p>
          </div>
        </div>
      </div>
    </div>
  </div>



<div class="container">
  <br /><br /><div class="section_title mb-55">
    <form class="navbar-form" autocomplete="off">
      <span class="bmd-form-group">
        <div class="input-group no-border">
          <input class="form-control" type="text" name="search" [(ngModel)]="searchDept"
            placeholder="Search Departments in list">
        </div>
      </span>
    </form>
    <hr />
  </div>
  <!-- Card -->
<!-- Card -->
<br /><div class="row">
  <div class="col-md-3" *ngFor="let x of data | filter:searchDept">
    <div class="card">
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <img class="card-img-top" src="{{x.bcrm_imageurl}}" alt="Card image cap" style="width:292px;height:180px;">
            <div class="card-body">
              <!-- Title -->        
              <h6 class="card-title"><a class="tittle" [routerLink]="['/kbarticleDetails', x.id ]" skipLocationChange>{{x.title}}</a></h6>
              <!-- Text -->
                
              <!-- Button -->
            </div>
          </div>
          <div class="flip-card-back" [routerLink]="['/kbarticleDetails', x.id ]" skipLocationChange>
            <div class="text">
              <h4><u style="color: black; cursor: pointer !important;">{{x.title}}</u></h4>
              <p class="card-text ellipsis is-ellipsis-2">{{x.bcrm_description}}</p> 
            </div>
          </div>
        </div>



      <!-- Card image -->
      
      <!-- Card content -->
     
    </div>
  </div>
</div><br />



<!-- Card -->
<!-- Card -->

   