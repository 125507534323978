import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TdlobservationService } from 'src/app/ApiServices/Observation/tdlobservation.service';
declare var $:any;
@Component({
  selector: 'app-observation-detail',
  templateUrl: './observation-detail.component.html',
  styleUrls: ['./observation-detail.component.css']
})
export class ObservationDetailComponent implements OnInit {
  id:any;
  observationInfo:any
  ObservationDetail: any;
  observationsResult: Object;
  ocr1:any;
  ocr2:any = [];
  ocr3:any;
  age:any;
  constructor(private router: Router,private activatedRoute: ActivatedRoute,private ObservationService: TdlobservationService, private toastr: ToastrService) { }

  ngOnInit(): void {
   this.id = this.activatedRoute.snapshot.paramMap.get('id');
   this.ObservationDetail = history.state.ObservationDetail;
    this.getData();
    this.getTDLObservationsResult();
    var data: any = JSON.parse(localStorage.getItem("userProfileDataClaims"));
    this.age =data.Age;
  }


getData(){
this.ObservationService.getObservationRequestReport(this.id).subscribe((res) => {
  debugger;
  if(res['length']>0){
    this.ocr2 =[];
    this.observationInfo = res;

    this.ocr1 = "A" +" "+"RE - Observations/Performed Service to follow"+" "+this.observationInfo[0].PlaceOrderNumber+" "+this.observationInfo[0].FillerOrderNumber+" " +"with the status"+" "+this.ObservationDetail.bcrm_orderstatus ;
      
    for (var i = 0; i < res['length']; i++) {
     
     // this.ocr3 = +"Observation Reques Segment (OBR)"+""+this.observationInfo[i].;

     let trail = this.ObservationDetail.bcrm_consultingdoctor+" "+"ordered"+" "+( this.observationInfo[i].Identifier )+" "+this.observationInfo[i].PlaceOrderNumber+" "+this.observationInfo[i].FillerOrderNumber+ " requested";

     this.ocr2.push(trail);

    }
  }

})
}

getTDLObservationsResult(): void {
  debugger;
  this.ObservationService.getObservationResults(this.id).subscribe((res) => {
    if(res != null){
      
      this.observationsResult = res;
      console.log("Obser", this.observationsResult);

    }

  }, (err) => {
    this.toastr.error(err);
  });
}
  getObservation(){
  this.router.navigate(['testResult'],{skipLocationChange: true});
  }

}
