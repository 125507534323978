import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class SharepointService {

  constructor(private http: HttpClient) { }

  getFile(userId) {
    debugger
    return this.http.get(`${environment.apiUrl}/api/SharepointFiles/GetFileList?userGuid=${userId}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }  
  downloadFile(retrivalURL) {   
    return this.http.get(`${environment.apiUrl}/api/SharepointFiles/DownloadFile?retrivalURL=${retrivalURL}`, {responseType: 'arraybuffer'}).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }

  UploadFolder(userId,parentUrl,CurrentDirectory,FolderName,NewFolder) {
    return this.http.post(`${environment.apiUrl}/api/SharepointFiles/UploadFile?contactNumber=${userId}&parentUrl=${parentUrl}&currentFolderPath=${CurrentDirectory}&FolderName=${FolderName}&NewFolder=${NewFolder}`,null).pipe(
      // retry(1),       
      catchError(this.handleError)
    );
  }

  UploadFile(userId,parentUrl,CurrentDirectory,FolderName,NewFolder,formData) {
    return this.http.post(`${environment.apiUrl}/api/SharepointFiles/UploadFile?contactNumber=${userId}&parentUrl=${parentUrl}&currentFolderPath=${CurrentDirectory}&FolderName=${FolderName}&NewFolder=${NewFolder}`,formData).pipe(
      // retry(1),       
      catchError(this.handleError)
    );
  }


  handleError(error) {    
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */
    let errorMessage = '';
    let errorMessageUi='';
    if (error.error instanceof ErrorEvent) {
      // client-side error 
      errorMessage = `Error: ${error.error.message}`;  
      errorMessageUi=error.error.message;
    } else {
      // server-side error 
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      if(error.error!=undefined){
        errorMessageUi=error.error.message
      }
      else{
        errorMessageUi=error.message
      }
    }
    //  // Show error on hte Ui and Deirect to home
    //  this.toastr.error("Error", errorMessageUi);
    // this.router.navigate(['/']);

    return throwError(errorMessageUi);
  }
}
