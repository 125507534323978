<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <section style="padding: 2%;">
                            <div class="container">
                                <div class="row justify">
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12"><img
                                            src="../../../assets/images/howitwork1.png" alt=""></div>
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 textalignment">
                                        <p class="textflex">Create your profile and consent to the agreements.</p>
                                    </div>

                                </div>

                                <div class="row justify">
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 textalignment">
                                        <p class="textbefore">Select the type of doctor you want to see. Then chose the
                                            actual doctor you want to
                                            see.
                                        </p>
                                    </div>
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12"><img
                                            src="../../../assets/images/howitwork2.png" alt=""></div>

                                </div>

                                <div class="row justify">
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12"><img
                                            src="../../../assets/images/howitwork3.png" alt=""></div>
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 textalignment">
                                        <p class="textflex">The doctor’s availability is shown in the calendar.Choose a
                                            time that works for you.
                                        </p>
                                    </div>
                                </div>

                                <div class="row justify">
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 textalignment">
                                        <p class="textbefore">15 minutes before your appointment, get a text reminder
                                            and a link to the video call
                                            from your online calendar.</p>
                                    </div>
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12"> <img
                                            src="../../../assets/images/howitwork4.png" alt=""></div>
                                </div>
                                <div class="row justify">
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12"><img
                                            src="../../../assets/images/howitwork5.png" alt=""></div>
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 textalignment">
                                        <p class="textflex">Enter the site a few minutes early to update Alfred the
                                            chatbot. Alfred shares this information with the doctor and updates your
                                            profile.</p>
                                    </div>
                                </div>

                                <div class="row justify">
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 textalignment">
                                        <p class="textbefore">Click the ‘Join-Now’ button that appears when the doctor
                                            is ready, and the video call begins. </p>
                                    </div>
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12"><img
                                            src="../../../assets/images/howitwork6.png" alt=""></div>

                                </div>
                                <div class="row justify">
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12"><img
                                            src="../../../assets/images/howitwork7.png" alt=""></div>
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 textalignment">
                                        <p class="textflex">With your permission the call can be recorded and will
                                            appear on your profile so you can remind yourself about what was said.
                                        </p>
                                    </div>
                                </div>

                                <div class="row justify">
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 textalignment">
                                        <p class="textbefore">The doctor can make a prescription that will be posted to
                                            you, order a test at a location near you, over-time refer you to inpatient
                                            services,or to see another doctor if needed.</p>
                                    </div>
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12"> <img
                                            src="../../../assets/images/howitwork8.png" alt=""></div>


                                </div>

                                <div class="row justify">
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12"><img
                                            src="../../../assets/images/howitwork9.png" alt=""></div>
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 textalignment">
                                        <p class="textflex">A follow-up can be arranged. </p>
                                    </div>


                                </div>
                                <div class="row justify">
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 textalignment">
                                        <p class="textbefore">Payment is taken either by card or by using your medical
                                            insurance details. </p>
                                    </div>
                                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12"><img
                                            src="../../../assets/images/howitwork10.png" alt=""></div>

                                </div>
                            </div>

                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>