import { Component, ViewChild, NgZone, ElementRef, OnInit } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Ng2TelInputModule } from 'ng2-tel-input';
import axios from 'axios';
import { ProfileService } from 'src/app/ApiServices/Profile/profile.service';
declare var $: any
@Component({
  selector: 'app-show-profile-data',
  templateUrl: './show-profile-data.component.html',
  styleUrls: ['./show-profile-data.component.css']
})
export class ShowProfileDataComponent implements OnInit {
  profile: any;
  imgURL: any;
  constructor(private profileservice: ProfileService,
    private toastr: ToastrService,
    ) { }

  ngOnInit(): void {
    debugger;
    // var data:any=JSON.parse(localStorage.getItem("emailfoooter"));
    // environment.LoginUserEmail=data.userEamil;
    this.getProfile();
  }
  getProfile(): void {
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */

    this.profileservice.getProfile(environment.LoginUserEmail).subscribe((res) => {
      debugger;
      //*************Show / hide loader *******************/

      $('#ftco-loader').addClass('show');
      /**************************************** */
      this.profile = res;
      this.profile.imageBytes = 'data:image/png;base64,' + res['entityimage'];
      this.imgURL = this.profile.imageBytes;
      var date = this.profile.birthdate.toString("yyyy-mm-dd").split('T')[0];
      this.profile.birthdate = date;
      // 
   
      // Set toggle as allow mans false in crm but set ture for user experience//
      //#region 
      if (this.profile.donotbulkemail == false) {
        this.profile.donotbulkemail = true;
  
      } else {
        this.profile.donotbulkemail = false;
    
      }

      //
      if (this.profile.donotemail == false) {
        this.profile.donotemail = true;
   

      }
      else {
        this.profile.donotemail = false;
       
      }

      //
      if (this.profile.donotfax == false) {
        this.profile.donotfax = true;

      } else {
        this.profile.donotfax = false;
  
      }

      //
      if (this.profile.donotphone == false) {
        this.profile.donotphone = true;

      } else {
        this.profile.donotphone = false;
       
      }
      //
      if (this.profile.donotpostalmail == false) {
        this.profile.donotpostalmail = true;
      
      }

      else {
        this.profile.donotpostalmail = false;
       
      }


      //
      if (this.profile.followemail == false) {
        this.profile.followemail = false;
      
      } else {
        this.profile.followemail = true;
    ;
      }


      //#endregion

      var phonenumber = this.profile.mobilephone;

      if (this.profile.bcrm_funded == 3) {
      
      }
      if (this.profile.bcrm_funded == 1 && this.profile.bcrm_funded_name == 'Private' || this.profile.bcrm_funded == 2 && this.profile.bcrm_funded_name == 'NHS') {
     
      } else {
      
      }
      if (this.profile.bcrm_neverdrinkalcohol == true) {
    
      } else {
   
      }


      setTimeout(function () {
        $("#mobilephone").val(phonenumber);
      }, 1000);
      setTimeout(function () {

        $('#ftco-loader').removeClass('show');
      }, 3000);
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');

      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
  }
  RefreshPage(){
    this.ngOnInit()
  }
}
