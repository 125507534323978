<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">

                        <div class="card-body">
                            <div class="row heading">
                                <div class="col">
                                    <h5> Waiting Room <span style="color: gray;"></span>
                                    </h5>
                                    &nbsp; <button type="button" class="btn btn-primary float-right ml-1"
                                        (click)="RefreshPage()"><i class="fa fa-refresh ref"
                                            aria-hidden="true"></i></button>
                                            &nbsp; 
                                </div>

                                <!-- <p><a href="index.html">Home /</a> Profile</p> -->

                            </div>

                            <br>

                            <div class="row min-height">
                             
                                <div class="col-md-12">
                                    <router-outlet></router-outlet>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <br>
                            <!-- Chat Bot Section -->

                            <header id="botContainerHeader" style="display:none">
                                <span id="closeBot" onclick="closeChat()">Close</span>
                            </header>

                            <div id="botContainer" style="display:none;">

                            </div>

                            <div id="Bot-circle" class="btn btn-raised">

                                <img id="bot-loader" src="../../../assets/css/ajax-loader.gif" alt=""
                                    style="width:30px;display: none;">
                                <i id="bot-icon" class="fa fa-comment fa-3x"
                                    (click)="acquireTokenSilentAndStartBot()"></i>
                                <p id="bot-icon" (click)="acquireTokenSilentAndStartBot()"
                                    style="margin-left: -17px; font-size: 9px;"><b>Chat Now</b></p>
                            </div>
                            <div id="floating-bot" style="display: none;">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- end live-chat -->