import { Component, OnInit } from '@angular/core';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from 'src/app/ApiServices/Profile/profile.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
declare var $;


@Component({
  selector: 'app-consents-procedure',
  templateUrl: './consents-procedure.component.html',
  styleUrls: ['./consents-procedure.component.css']
})
export class ConsentsProcedureComponent implements OnInit {

  //declare of variable
  procedureId: any;
  videourl: any;
  proceduredata: any;
  profile: any;
  dialcode: any;
  inputObj: any;
  consentquestion: any;
  consentsavedata: any;
  consentanswers: any;
  consentanswersarray: any[];
  IsGaveConsent: any;
  IsOutResearch: any;
  consenttype: any;
  Comment: any;
  DOBInYear: any;
  firstStep: any;
  bcrm_pleaseenterwhatisnotcorrect: any;
  stepVideo: any;
  procedureTime: any;
  stepVideodata: boolean = false;
  stepQuestiondata: any;
  ModalToggle: any;
  IsConsentOnline: any;
  additionalInfoList;
  urlList: object;
  additionalvalue: object;
  constructor(private profileservice: ProfileService, private activatedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService, private sanitizer: DomSanitizer,
    private intlObj: Ng2TelInputModule) {
    this.getProfile()
  }

  ngOnInit() {
    var data:any=JSON.parse(localStorage.getItem("userProfileDataClaims"));
    environment.LoginUserEmail=data.userEamil;
    
    //this.procedureId = history.state.data;
    this.procedureId = this.activatedRoute.snapshot.paramMap.get('id');
    this.Comment = null;
    this.bcrm_pleaseenterwhatisnotcorrect = null;
    var localthis = this;
    setTimeout(function () {
      localthis.getproceduredata();
    }, 700);
    this.consentanswersarray = [];
    this.IsGaveConsent = true;
    this.IsOutResearch = true;
    this.stepVideo = true;
    this.stepQuestiondata = false;
    this.videourl = null;
    this.firstStep = true;
    this.proceduredata = null;
    this.IsConsentOnline = true;
    this.consentquestion = [];

    // //this.ModalToggle=false;
    // var checkboxes = $("#exampleCheck1"),
    //   submitButt = $("#btn1tab");
    var checkboxes1 = $("#exampleCheck2"),
      submitButt1 = $("#btn2tab");

    checkboxes1.click(function () {
      submitButt1.attr("disabled", !checkboxes1.is(":checked"));

    });

    var checkboxes3 = $("#exampleCheck4"),
      submitButt3 = $("#btn4tab");

    checkboxes3.click(function () {
      submitButt3.attr("disabled", !checkboxes3.is(":checked"));
    });

  }


  getProfile() {
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.profileservice.getProfile(environment.LoginUserEmail).subscribe((res) => {

      this.profile = res;
      this.profile.imageBytes = 'data:image/png;base64,' + res['entityimage'];

      var date = this.profile.birthdate.toString("yyyy-mm-dd").split('T')[0];
      this.profile.birthdate = date;
      var phonenumber = this.profile.mobilephone;
      // this.getconsentquestion();
      setTimeout(function () {
        $("#mobilephone").val(phonenumber);
      }, 1000);

      setTimeout(function () {
        $('#ftco-loader').removeClass('show');
      }, 1000);
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err);
      //this.router.navigate(['/']);
    });

  }

  getproceduredata() {
    debugger;

    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */

    this.profileservice.getProceduredetail(this.procedureId).subscribe((res: any) => {
      // ****************Split additional info data into urls******************
      var additionalArray = [];
      this.additionalvalue = res.additionalInfo;
      if (res.additionalInfo != undefined) {
        let d = res.additionalInfo;
        this.additionalInfoList = d.split(';');
        for (var i = 0; i < this.additionalInfoList.length; i++) {
          let values = this.additionalInfoList[i].split("<>");
          let dataToPush = {
            name: values[0],
            url: values[1]
          };
          additionalArray.push(dataToPush);
        }
      } else {
        $("#clickHere").css("display", "none");
        //$("#clickHere").hide();
      }
      this.urlList = additionalArray;
      // ****************End of code for split url******************
      this.proceduredata = res;
      this.proceduredata.description = res.description;
      this.consentquestion = res.procedureQuestion;
      // this.videourl = res[0].bcrm_videourl

      this.videourl = this.sanitizer.bypassSecurityTrustResourceUrl(res.videoUrl);

      var datestarttime = this.proceduredata.performedStartDate.toString("dd-MMMM-yyyy").split('T');
      //
      this.procedureTime = datestarttime[1].substring(0, datestarttime[1].length - 1).split(":")[0] + ":" + datestarttime[1].substring(0, datestarttime[1].length - 1).split(":")[1];

      if (Date.parse(this.proceduredata.performedStartDate) > Date.parse(this.profile.birthdate)) {
        let milisecond = Date.parse(this.proceduredata.performedStartDate) - Date.parse(this.profile.birthdate);
        let yeardouble = (milisecond / 31556952000).toString();
        this.DOBInYear = parseInt(yeardouble);
        localStorage.setItem('DOB', this.DOBInYear);
      } else {
        this.DOBInYear = 0;
      }
      $("#your-image9").attr("src", "../../../assets/images/Documents.png");
      $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
      $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
      $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
      $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
      $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
      $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
      $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image").attr("src", "../../../assets/images/profile.png");
      $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
      setTimeout(function () {
        $('#ftco-loader').removeClass('show');
      }, 1000);

    });
  }
  // getconsentquestion() {    
  //   this.profileservice.getconsentquestion(this.procedureId).subscribe((res) => {
  //     this.consentquestion = res;
  //   });
  // }
  savequestion() {
    if (this.firstStep == false) {
      this.toastr.info("A member of our teams will be contacting you to correct the information");
    }
  }

  savealldata() {
    debugger;
    $('#ftco-loader').addClass('show');
    this.getconsentanswer();
    this.checkconsenttype();
    this.consentsavedata = {
      Id: this.proceduredata.Id,
      userId: this.proceduredata.patientId,

      description: this.proceduredata.description,
      consentType: this.consenttype,
      procedureQuestion: this.consentanswersarray,
      //detail: this.Comment
      consentStatus: 1
    }
    this.profileservice.saveproceduredata(this.consentsavedata).subscribe((res) => {
      if (res) {
        this.toastr.success("Consent Out for Signature");
        $('#ftco-loader').removeClass('show');
        this.ReloadComponent();
      };
    });
    this.router.navigate(['/profile/consent'], { skipLocationChange: true });
    $('#ftco-loader').removeClass('show');
  }
  getconsentanswer() {
    // for consent question
    if (this.consentquestion != null) {
      this.consentquestion.forEach(obj => {

        this.consentanswers = {
          Id: obj.Id,
          answer: obj.bcrm_answer
        }
        this.consentanswersarray.push(this.consentanswers);
      });
    }

    //end con
  }
  checkconsenttype() {

    if (this.IsGaveConsent == true && this.IsOutResearch == true) {
      this.consenttype = 3;
    }
    if (this.IsGaveConsent == false && this.IsOutResearch == false) {
      this.consenttype = 1;
    }
    if (this.IsGaveConsent == true && this.IsOutResearch == false) {
      this.consenttype = 2;
    }
    if (this.IsOutResearch == true && this.IsGaveConsent == false) {
      this.consenttype = 1;
    }
    // else{
    // }
  }
  onCountryChange($event) {
    this.dialcode == $event.dialCode;
  }
  telInputObject(obj, val) {
    this.inputObj = obj;
    if (val) {
      obj.setNumber(val);
    }
  }
  calculateDOBYear() {

    if (Date.parse(this.proceduredata.performedStartDate) > Date.parse(this.profile.birthdate)) {
      let milisecond = Date.parse(this.proceduredata.performedStartDate) - Date.parse(this.profile.birthdate);
      let yeardouble = (milisecond / 31556952000).toString();
      this.DOBInYear = parseInt(yeardouble);
    } else {
      this.DOBInYear = 0;
    }
  }

  showMessage() {
    this.IsConsentOnline = $('#consentonline').is(":checked");
    if (this.IsConsentOnline == false) {
      alert('A member of our team will be contacting you shortly');
      //this.toastr.info("A member of our team will be contacting you shortly");
      this.router.navigate(['/profile/consent'], { skipLocationChange: true });
    }
  }
  openDoc() {

    this.stepVideodata = $('#poupdataopen').is(":checked");
    if (this.stepVideodata == true) {
      $("#docList").show();
    } else {
      $("#docList").hide();
    }
  }
  ReloadComponent(): void {
    let Location = this.router.url;
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([Location], { skipLocationChange: true });
    });
  }

}
