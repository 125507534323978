import { Component, OnInit } from '@angular/core';
import { PrescriptionsService } from 'src/app/ApiServices/Prescription/prescriptions.service';
import { environment } from 'src/environments/environment';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
declare var $: any;
@Component({
  selector: 'app-prescriptions',
  templateUrl: './prescriptions.component.html',
  styleUrls: ['./prescriptions.component.css']
})
export class PrescriptionsComponent implements OnInit {
  prescriptiondrugs: object;
  PrescriptDataView: any;
  fullName: any;
  Age: any;
  Address: any;
  constructor(private prescription: PrescriptionsService) { }

  ngOnInit(): void {
    var data: any = JSON.parse(localStorage.getItem("userProfileDataClaims"));
    environment.LoginUserId = data.userId;
    this.fullName = data.fullName;
    this.Age = data.Age;
    this.Address = data.Address;
    debugger;
    this.getPrescriptionDrugs(environment.LoginUserId)
  }
  getPrescriptionDrugs(id): void {

    $('#dataTableExample').DataTable().destroy();
    // $(".hide-table-padding").show();
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.prescription.getPrescriptionDrugs(id).subscribe((res) => {

      if (res != null) {
        this.prescriptiondrugs = res;
        console.log("Prisc",this.prescriptiondrugs);
      }
      setTimeout(function () {
        $('#dataTableExample').DataTable({
          "aLengthMenu": [
            [10, 30, 50, -1],
            [10, 30, 50, "All"]
          ],
          "iDisplayLength": 10,
          "language": {
            search: ""
          }
        });
        $('#dataTableExample').each(function () {
          var datatable = $(this);
          // SEARCH - Add the placeholder for Search and Turn this into in-line form control
          var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
          search_input.attr('placeholder', 'Search');
          search_input.removeClass('form-control-sm');
          // LENGTH - Inline-Form control
          var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
          length_sel.removeClass('form-control-sm');
        });
      }, 50);

      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/

      //this.router.navigate(['/']);
    });
  }
  getData(data) {
   
    this.PrescriptDataView = data;
    this.PrescriptDataView.createdOn=this.PrescriptDataView.createdOn.slice(0,10);
    console.log("prescription--",this.PrescriptDataView)
  }
  RefreshPage(){
    debugger
    this.ngOnInit()

  }
  printDiv() {
    // debugger
    // var printContents = document.getElementById('GFG').innerHTML;
    // var originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;


    // window.print();
    // document.body.innerHTML = originalContents;
    // location.reload();
    var data = document.getElementById('GFG');
    html2canvas(data).then(canvas => {
      var imgWidth = 190;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 10, position, imgWidth, imgHeight)
      pdf.save(this.fullName + " Prescription " + this.PrescriptDataView.createdOn + ".pdf");
    });
  }
}
