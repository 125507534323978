import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class RequestAppService {

  constructor(private http: HttpClient) { }

  // Get Doctors for this api //

  getQuestionsByServiceId(servieId) {
    return this.http.get(`${environment.apiUrl}/api/BookingAppointment/GetQuestionsByServiceId?serviceId=${servieId}`).pipe(
      //  retry(1), 
      catchError(this.handleError)
    );
  }

/**Create Appointment EMR */
addAppiontmentERM(lobbyData){
   
  return this.http.post(`${environment.apiUrl}/api/OXDHAppointments/CreateAppointmentEmr`,lobbyData).pipe(
  // retry(1), 
  catchError(this.handleError)
);
}

  handleError(error) {
    
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */
    let errorMessage = '';
    let errorMessageUi = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error 
      errorMessage = `Error: ${error.error.message}`;
      errorMessageUi = error.error.message;
    } else {
      // server-side error 
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      errorMessageUi = error.error.message
    }
    //  // Show error on hte Ui and Deirect to home
    //  this.toastr.error("Error", errorMessageUi);
    //  this.router.navigate(['/']);

    return throwError(errorMessageUi);
  }
}
