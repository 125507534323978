import { Component, OnInit } from '@angular/core';
import { DoctorsService } from 'src/app/ApiServices/Doctors/doctors.service';
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { HeaderComponent } from 'src/app/Navigation/header/header.component';

declare var $: any
@Component({
  selector: 'app-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.css']
})
export class DoctorsComponent implements OnInit {

  //------ variables  -----//
  allDoctors: [];
  doctorDetails: any;
  doctorSpDetails: any;
  Token: string;
  allDoctores: boolean = true;
  profileImage: string = '../../../assets/images/alldoctors (1).png'
  DoctorID: any;
    isLoggedIn:boolean = false;
     skilldata:any;
      skill1:any;
       skill2:any;
 school1:any;
 school2:any;
  constructor(public doctorService: DoctorsService,
    private router: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private headerComponent:HeaderComponent) {
      
    this.isLoggedIn = headerComponent.loggedIn;
    if (!this.isLoggedIn) {      
      toastr.error("Have To login First", "To See The Doctors Details");
      this.router.navigate(['/']);
    }
  }



  ngOnInit(): void {
    debugger;
    var doctorId = this.activatedRoute.snapshot.paramMap.get('id');   
    
    this.getDoctorDetailSharePoint(doctorId);
    // /
  }

  getDoctorsDetailbyId(doctorId: string): void {
     //*************Show / hide loader *******************/
     $('#ftco-loader').addClass('show');
     /**************************************** */
    this.doctorService.getDoctorDetailById(doctorId).subscribe((res) => {
      
      this.doctorDetails = res;
      
      if (this.doctorDetails['image'] != null) {
        this.profileImage = `data:image/jpeg;base64,${this.doctorDetails['image']}`
      }
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
    });

  }

  getDoctorDetailSharePoint(doctorId: string): void {
     //*************Show / hide loader *******************/
     $('#ftco-loader').addClass('show');
     /**************************************** */
    this.doctorService.getDoctorDetailSharePoint(doctorId).subscribe((res) => {
      
      this.doctorSpDetails = res;
      
      if (this.doctorSpDetails['profilePic']!= null) {
        
        this.profileImage = `data:image/jpeg;base64,${this.doctorSpDetails['profilePic']}`
      }

      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
    });

  }

}
