<footer class="footer ">


    <div class="contanier">
        <div class="row pt-3 ">
            <div class="col-md-1 col-lg-1 col-xs-1"></div>
            <div class="col-md-1 col-lg-1 col-xs-1"></div>
            <div class="col-md-2 col-lg-2 col-xs-2">
                <img src="../../../assets/images/OX_DIGITAL_HEALTH_REV.png" alt="" style="width: 80%;">
            </div>
            <div class="col-xs-2 col-md-2">
                <p *ngIf="Istrue" class="footer_content">CONNECTING PATIENTS.CLINCIANS.DATA</p>
                <p *ngIf="!Istrue" class="footer_content">{{'Footer.connectingDoctorData'|translate}}</p>
            </div>

            <!-- <div class="col-md-3 col-lg-3 col-xs-3"></div> -->

            <div class="col-md-5 col-sm-5  col-xs-5" *ngIf="Istrue"><a routerLink='./howitworks'
                    class="btn-custom BacktoTop" skipLocationChange>HOW IT WORKS</a>&nbsp;&nbsp;&nbsp;<a
                    class="btn-custom1" href="#">FAQ</a>&nbsp;&nbsp;&nbsp;
                <a *ngIf="Istrue" id="Cssshide" class="btn-custom" routerLink='./Joinus' skipLocationChange>Join us as a
                    doctor</a>
            </div>

            <div class="col-md-6 col-sm-6  col-xs-6 " *ngIf="!Istrue"><a routerLink='./howitworks'
                    class="btn-custom BacktoTop"
                    skipLocationChange>{{'Footer.HOWITWORKS'|translate}}</a>&nbsp;&nbsp;&nbsp;<a class="btn-custom1"
                    href="#">{{'Footer.FAQ'|translate}}</a>&nbsp;&nbsp;&nbsp;
                <a *ngIf="Istrue" id="Cssshide" class="btn-custom" routerLink='./Joinus'
                    skipLocationChange>{{'Footer.Joinusasadoctor'|translate}}</a>
            </div>
        </div>

        <div class="row pt-3" *ngIf="Istrue">
            <div class="col-md-3 col-lg-3 col-xs-3"></div>
            <div class="col-md-2 col-lg-2 col-xs-1" style="text-align: center;"><a target="_blank"
                    href="https://oxdhblob.blob.core.windows.net/oxdh/Terms and Conditions-converted.pdf"
                    style="color: white;">Terms & Conditions</a></div>
            <div class="col-md-1 col-lg-1 col-xs-1" style="text-align: center;"><a target="_blank"
                    href="https://oxdhblob.blob.core.windows.net/oxdh/privacy%20policy-converted.pdf"
                    style="color: white;">Privacy Policy</a></div>
            <div class="col-md-1 col-lg-1 col-xs-1" style="text-align: center;"><a target="_blank"
                    href="https://oxdhblob.blob.core.windows.net/oxdh/Cookie%20Policy-converted.pdf"
                    style="color: white;">Cookies Policy</a></div>
            <div class="col-md-1 col-lg-1 col-xs-1" style="text-align: center;"><span class="footer_content BacktoTop"
                    style="cursor: pointer;"><a href="mailto:contact@oxdh.health" style="color: white;">Contact
                        Us</a></span>
            </div>
        </div>

        <div class="row pt-3" *ngIf="!Istrue">
            <div class="col-md-3 col-lg-3 col-xs-3"></div>
            <div class="col-md-2 col-lg-2 col-xs-1" style="text-align: center;"><a target="_blank"
                    href="https://oxdhblob.blob.core.windows.net/oxdh/Terms and Conditions-converted.pdf"
                    style="color: white;">{{'Footer.termsAndConditions'|translate}}</a></div>
            <div class="col-md-1 col-lg-1 col-xs-1" style="text-align: center;"><a target="_blank"
                    href="https://oxdhblob.blob.core.windows.net/oxdh/privacy%20policy-converted.pdf"
                    style="color: white;">{{'Footer.privacyPolicy'|translate}}</a></div>
            <div class="col-md-1 col-lg-1 col-xs-1" style="text-align: center;"><a target="_blank"
                    href="https://oxdhblob.blob.core.windows.net/oxdh/Cookie%20Policy-converted.pdf"
                    style="color: white;">{{'Footer.cookiesPolicy'|translate}}</a></div>
            <div class="col-md-1 col-lg-1 col-xs-1" style="text-align: center;"><span class="footer_content BacktoTop"
                    style="cursor: pointer;"><a href="mailto:contact@oxdh.health"
                        style="color: white;">{{'Footer.contactUs'|translate}}</a></span>
            </div>
        </div>

    </div>
</footer>