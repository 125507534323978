<div class="">
  <div class="col-md-4 offset-md-8"><span *ngIf="!Istoken">Forgot Password <a href="#" (click)="resetPassword()">Click
        here
      </a></span></div>
</div>
<div class="">
  <nav class="navbar navbar-expand-lg navbar-light bg-light1" id="ftco-navbar" id="myHeader">
    <a class="navbar-brand" routerLink="" style="width: 15%;"> <img [src]="logoPath" alt="" style="width: 200px !important;"> </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">

        <li *ngIf="!Istoken" class="nav-item active" id='nav-item-Home'><a routerLink="" class="nav-link">HOME</a></li>

        <li *ngIf="Istoken" class="nav-item active" id='nav-item-Home'><a routerLink="" class="nav-link">{{'Header.home'|translate}}</a></li>

        <!-- <li class="nav-item" id='nav-item-Appointment'><a routerLink="./contactus" class="nav-link">Contact Us</a></li> -->

        <li  *ngIf="!Istoken" class="nav-item" id='nav-item-Doctor' style="text-transform: uppercase;"><a  routerLink="./departments" class="nav-link"
            skipLocationChange>clinician</a></li>

            <li *ngIf="Istoken" class="nav-item" id='nav-item-Doctor' style="text-transform: uppercase;"><a  routerLink="./departments" class="nav-link"
              skipLocationChange>{{'Header.clinician'|translate}}</a></li>

        <!-- <li class="nav-item" id='nav-item-WaitingRoom' *ngIf="Istoken"><a routerLink="./waitingRoom" class="nav-link"
            skipLocationChange>{{'Header.RECEPTION'|translate}}</a>
        </li> -->

        <li *ngIf="!Istoken"  class="nav-item" id='nav-item-HowitWorks'><a routerLink='./howitworks' class="nav-link"
            skipLocationChange>HOW IT WORKS</a>
          <!--HOW IT WORKS -->
        </li>

        <li *ngIf="Istoken" class="nav-item" id='nav-item-HowitWorks'><a routerLink='./howitworks' class="nav-link"
          skipLocationChange>{{'Footer.HOWITWORKS'|translate}}</a>
        <!--HOW IT WORKS -->
      </li>

        <!-- <li class="nav-item" id='nav-item-faq'><a href="" class="nav-link">FAQ</a></li> -->
        <!-- <li class="nav-item" id='nav-item-legal'><a href="" class="nav-link">LEGALS</a></li> -->
        <!-- <li class="nav-item" id='nav-item-KBarticle' *ngIf="Istoken"><a routerLink="./kbarticle"
            class="nav-link">DEPARTMENTS</a></li> -->
        <!-- <li class="nav-item" id='nav-item-Appointment' *ngIf="Istoken"><a routerLink="./bookAppointment"
            class="nav-link">Book
            Appointments</a></li> -->
        <!--Update on 26 JUN  -->
        <!-- <li class="nav-item" id='nav-item-lobby' *ngIf="Istoken"><a routerLink="./lobby" class="nav-link">Lobby</a></li> -->
        <!--Update on 26 JUN  -->
        <!-- <li class="nav-item  dropdown" id='nav-item-NHS' *ngIf="Istoken">
          <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown"> NHS &nbsp;</a>
          <div class="dropdown-menu">
            <a routerLink="./livewell" class="dropdown-item">Live Well</a>
            <a routerLink="./conditions" class="dropdown-item">Conditions</a>
            <a routerLink="./medicines" class="dropdown-item">Medicines</a>
            <a routerLink="./video" class="dropdown-item">Video</a>
          </div>
        </li> -->
        <li> <button class="btn-custom" *ngIf="!Istoken" (click)="logincheck()">Book an Appointment</button></li>       
        <li class="nav-item  dropdown" id='nav-item-NHS' *ngIf="Istoken">
          <button type="button" href="#" class="btn-profile dropdown-toggle" data-toggle="dropdown"> {{UserName}}
            &nbsp;</button>
          <div class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="margin: 0px 0px 0px -55px;">
            <a class="dropdown-item" routerLink="./profile" skipLocationChange><i class="fa fa-user"
                aria-hidden="true"></i> {{'ProfileButtons.profile'|translate}}</a>
            <a class="dropdown-item" (click)="ReloadComponent()"><i class="fa fa-refresh" aria-hidden="true"></i>
              {{'ProfileButtons.refresh'|translate}}</a>
            <a class="dropdown-item" (click)="resetPassword()" style="cursor: pointer;"><i class="fa fa-lock"
                aria-hidden="true"></i> {{'ProfileButtons.resetPassword'|translate}}</a>
            <!-- <a class="dropdown-item" routerLink="./crmconditions">Conditions</a> -->
            <a class="dropdown-item" (click)="LogOut()" style="cursor: pointer;"><i class="fa fa-sign-out"
                aria-hidden="true"></i> {{'ProfileButtons.logout'|translate}}</a>
          </div>
        </li>

        
        <li class="nav-item dropdown" style="margin-top: 10px;"  *ngIf="Istoken">
          <select class="selectpicker" data-width="fit" #langSelect (change)="translate.use(langSelect.value)">  
            <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>  
        </select>
        </li>


        <!-- <li *ngIf="!Istoken">
              <button class="btn btn-primary" (click)="login()">Login</button>
          </li> -->
        <!-- <li class="nav-item"><a href="contact.html" class="nav-link">Contact</a></li> -->
      </ul>
      
    </div>
  </nav>
</div>
<!-- <div class="container">
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <img src="../../../assets/images/OXVhlogo2 .png" alt="">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a routerLink="" class="nav-link">Home</a>
      </li>
      <li class="nav-item" id='nav-item-Doctor'><a routerLink="./departments" class="nav-link">Doctors</a></li>
      <li class="nav-item" id='nav-item-KBarticle'><a routerLink="./kbarticle" class="nav-link">Departments</a></li>        
      <li class="nav-item" id='nav-item-Appointment'><a routerLink="./bookAppointment" class="nav-link">Book
          Appointments</a></li>
      <li class="nav-item" id='nav-item-WaitingRoom'><a routerLink="./waitingRoom" class="nav-link">Wating Room</a>
      </li>
      <li class="nav-item" id='nav-item-lobby'><a routerLink="./lobby" class="nav-link">Lobby</a></li>
      <li class="nav-item  dropdown" id='nav-item-NHS'>
        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown"> NHS &nbsp;</a>
        <div class="dropdown-menu">
          <a routerLink="./livewell" class="dropdown-item">Live Well</a>
          <a routerLink="./conditions" class="dropdown-item">Conditions</a>
          <a routerLink="./medicines" class="dropdown-item">Medicines</a>
          <a routerLink="./video" class="dropdown-item">Video</a>
        </div>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form>
  </div>
</nav>
</div> -->
<!-- END nav -->