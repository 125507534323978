<section class="m-3">
    <div class="container px-md-0 table-responsive">
        <!-- table for case -->
        <h5 style="text-align: center;">Summary of Online Consultations</h5>
        <table class="table table-bordered " id="caseRecord">
            <thead class="thead-light">
                <tr class="trColor">
                    <th class="thFont" colspan="8">
                        <div class="pull-right"><input type="button" href="#Modal1" data-toggle="modal" class="btn btn-primary" value="{{'Documents.AddOnlineConsultation'|translate}}"></div>
                    </th>
                </tr>
                <tr>
                    <!-- <th>{{'Documents.OnlineConsultationNumber'|translate}}</th> -->
                    <th style="display: none;">S/No</th>
                    <th>Title of Online Consultation</th>
                    <th>Type of Online Consultation</th>
                    <th>{{'Documents.Priority'|translate}}</th>
                    <th>Created By</th>
                    <th>{{'Common.createdOn'|translate}}</th>
                   
                    <!-- <th>{{'Documents.Customer'|translate}}</th> -->
                    <th>{{'Common.status'|translate}}</th>
                    <th>{{'Documents.OnlineConsultationNumber'|translate}}</th>
                   
                    <th>Edit / Attachment</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of CaseDeatils; index as i">
                    <!-- <td>{{data.ticketnumber}}</td> -->
                    <td style="display: none;">{{i + 1}}</td>
                    <td> {{data.title}}</td>
                    <td> {{data.casetype}}</td>
                    <td> {{data.priority}}</td>
                    <td>{{data.customeridname}}</td>
                    <td> {{data.createdon | date:"dd-MMMM-yyyy, HH:mm"}}</td>                   
                    <!-- <td> {{data.customeridname}}</td> -->
                    <td>{{data.statuscodename}}</td>
                    <td>{{data.ticketnumber}}</td>
                   
                    <th style="padding-left: 23px !important;">
                        <span *ngIf="data.statuscodename == 'In Progress'" href="#myModalcomment"><a href="#Modal2" data-toggle="modal" class="fa fa-pencil" (click)="editcase(data)" aria-hidden="true"></a></span>&nbsp;&nbsp;&nbsp;
                        <a (click)="openSendSMS(data.Id,data)" style="color: white;"><i class="fa fa-comments"aria-hidden="true" style="color:#3498DB;"></i>
                           </a>
                    </th>


                </tr>
            </tbody>
        </table>
    </div>
</section>


<div id="Modal1" class="modal fade">
    <!-- class modal and fade -->
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <!-- modal header -->
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
      ×
    </button>
            </div>
            <div class="modal-body  result-shade1">
                <!-- modal body -->
                <form class="form" #case="ngForm" (ngSubmit)="onSubmit(case)" #f="ngForm" autocomplete="off">

                    <div class="form-group row">
                        <label class="control-label col-sm-3 required">{{'Common.title'|translate}}</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Enter title" name="title" ngModel required #title="ngModel" id="title">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label col-sm-3 ">{{'Documents.Priority'|translate}}</label>
                        <div class="col-sm-9">
                            <select class="list-dt  form-control" id="prioritycode" name="prioritycode" ngModel #prioritycode="ngModel" #prioritycode="ngModel">
                                <option value="" selected disabled>Select Priority</option>
                                <option value="1">High</option>
                                <option value="2">Normal</option>
                                <option value="3">Low</option>
                            </select> </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label col-sm-3 ">{{'Documents.OnlineConsultationType'|translate}}</label>
                        <div class="col-sm-9">
                            <select class="list-dt  form-control" id="casetypecode" name="casetypecode" ngModel #casetypecode="ngModel" #casetypecode="ngModel">
                                <option value="" selected disabled>Select Type</option>
                                <option value="1">Question</option>
                                <option value="2">Problem</option>
                                <option value="3">Request</option>
                            </select> </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label col-sm-3 required">{{'Common.description'|translate}}</label>
                        <div class="col-sm-9">
                            <textarea type="text" class="form-control" placeholder="Enter Description" name="description" ngModel required #description="ngModel" id="description" rows="10"></textarea>
                        </div>
                    </div>

                    <!-- <div class="form-group row">
                        <label class="control-label col-sm-3 required">Upload file</label>
                        <div class="col-sm-9">
                            <p>:-<br> (jpg, jpeg, png, xlsx, word, pdf, mp4, mp3, docx, doc, txt)</p>
                            <input type="file" (change)="IncomingFile($event.target.files);"/>
                        </div>
                    </div> -->
                    <input type="submit" class="btn btn-primary pull-right" value="Submit" [disabled]="!case.valid">
                </form>
            </div>
        </div>
        <!-- / .modal-content -->

    </div>
    <!-- / .modal-dialog -->

</div>

<div id="Modal2" class="modal fade">
    <!-- class modal and fade -->
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <!-- modal header -->
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
      ×
    </button>
            </div>
            <div class="modal-body  result-shade1">
                <!-- modal body -->
                <form class="form" #editCase="ngForm" (ngSubmit)="onUpdate(Case)" #f="ngForm" autocomplete="off">
                    <div class="form-group row">
                        <label class="control-label col-sm-3 required">{{'Common.title'|translate}}</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" ngModel name="title" [(ngModel)]="Case.title" #title="ngModel">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label col-sm-3">{{'Documents.Priority'|translate}}</label>
                        <div class="col-sm-9">
                            <select class="list-dt  form-control" id="prioritycode" ngModel name="prioritycode" #name="ngModel" [(ngModel)]="Case.prioritycode">
                                <option value=" " selected disabled>Select Priority</option>
                                <option value="1">High</option>
                                <option value="2">Normal</option>
                                <option value="3">Low</option>
                            </select> </div>
                    </div>
                    <div class="form-group row ">
                        <label class="control-label col-sm-3">{{'Documents.OnlineConsultationType'|translate}}</label>
                        <div class="col-sm-9 ">
                            <select class="list-dt  form-control" id="casetypecode" ngModel #name="ngModel" [(ngModel)]="Case.casetypecode" ngModel name="casetypecode">
                                <option value=" " selected disabled>Select Type</option>
                                <option value="1">Question</option>
                                <option value="2">Problem</option>
                                <option value="3">Request</option>
                            </select> </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label col-sm-3 required">{{'Common.description'|translate}}</label>
                        <div class="col-sm-9 ">
                            <textarea type="text" class="form-control" #name="ngModel" name="description" [(ngModel)]="Case.description" ngModel id="description" rows="10" required></textarea>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="control-label col-sm-3 required">Upload file</label>
                        <div class="col-sm-9">
                            <p>:-<br> (jpg, jpeg, png, xlsx, word, pdf, mp4, mp3, docx, doc, txt)</p>
                            <input type="file" (change)="IncomingFile($event.target.files);"/>
                        </div>
                    </div>

                    <input type="submit" class="btn btn-primary pull-right" value="Update">
                </form>
            </div>
        </div>
        <!-- / .modal-content -->

    </div>
    <!-- / .modal-dialog -->

</div>