import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../src/environments/environment';

declare var $:any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  Istrue = false;

  constructor(private router:Router) { }

  ngOnInit(): void {
    $(".BacktoTop").click(function(event) {
      event.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "slow");
      return false;
  });
  if(environment.LoginUserEmail != ""){
    localStorage.setItem("emailfoooter",environment.LoginUserEmail)
   }
   if (localStorage.emailfoooter == "" || localStorage.emailfoooter == undefined )
{
  this.Istrue = true;
}

  this.HidetheJoinusbtn();
  }
  HidetheJoinusbtn(){
    var UrlFullpath = window.location.href;
    var UrlPath = UrlFullpath.split("#");

    if (UrlPath[1] == "/Consultant") {
      $("#Cssshide").hide();
    }
     else {
      $("#Cssshide").show();
     }

  }

}
