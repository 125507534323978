import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ValidateLoginService } from 'src/app/ApiServices/Login/validate-login.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';
import { isIE, b2cPolicies } from 'src/app/app-config';
import { ProfileService } from 'src/app/ApiServices/Profile/profile.service';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  //#region variables
  B2CLoginURL: string;
  B2CSignUpURL: string;
  IsLogin: string;
  Istoken = false;
  UserName: string;

  TokenData: string;
  //-----------
  userName: string;
  title = 'Virtual Hospital';
  loggedIn = false;
  isIframe = false;
  logoPath = '../../../assets/images/OX_general practice.png'
  //#endregion

  //#region constructor Data 
  constructor(public validateLoginService: ValidateLoginService,
    private toastr: ToastrService,
    private router: Router,
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private profileService: ProfileService,
    public translate: TranslateService) {
      debugger;
    //*************Show / hide loader *******************/
    // $('#ftco-loader').removeClass('show');
    /****************** -------- **********************/

    this.isIframe = window !== window.parent && !window.opener;
    this.checkAccount();
    //this.userService.currentUser.subscribe(message => this.userName = message);
    // event listeners for authentication status
    this.broadcastService.subscribe('msal:loginSuccess', (success) => {
      debugger;
      // We need to reject id tokens that were not issued with the default sign-in policy.
      // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
      // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
      if (success.idToken.claims['tfp'] !== b2cPolicies.names.signUpSignIn) {
        toastr.success("Password has been reset successfully. \nPlease sign-in with your new password");
        return this.authService.logout()
      }
      localStorage.setItem("idTokenClaims", JSON.stringify(success["idTokenClaims"]));
      // --- Define Name and User Info after login ---

      environment.LoginUserEmail = success["idTokenClaims"].emails[0];
      this.UserName = success["idTokenClaims"].name.split(" ")[0];
      this.getProfileDetails(environment.LoginUserEmail);
      this.Istoken = true;
      console.log('login succeeded. id token acquired at: ' + new Date().toString());
      console.log(success);

       this.checkAccount();
    });
    this.broadcastService.subscribe('msal:loginFailure', (error) => {
debugger;
      // Check for forgot password error
      // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
      if (error.errorMessage.indexOf('AADB2C90118') > -1) {

        if (isIE) {
         //this.authService.loginRedirect(b2cPolicies.authorities.resetPassword);
        } else {
          //this.authService.loginPopup(b2cPolicies.authorities.resetPassword);
        }
      }
    });
    this.broadcastService.subscribe('msal:acquireTokenFailure', x => {
      debugger;
      if(x.name=="InteractionRequiredAuthError"){
        Swal.fire({
          title: 'Cookies Policy Issue',
          text: "You haven't enabled third parties Cookies in your browser yet.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Okay',
        }).then((result) => {
          this.LogOut();
        });
      } else {
        if(this.loggedIn == true){
        Swal.fire({
          title: 'Session Expired',
          text: "Your Session has been Expired! Please Login Again ",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Okay',
        }).then((result) => {
          this.LogOut();
        });
      }
    }

    });
    // redirect callback for redirect flow (IE)
    this.authService.handleRedirectCallback((authError, response) => {

      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }

      console.log('Redirect Success: ', response);
    });

    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));
  }
  //#endregion

  //#region Ng Oninit Funtion 
  ngOnInit(): void {

    // Sticky logo//
    window.addEventListener("scroll", this.scroll, true);
    window.onscroll = function () { myFunction() };

    var header = document.getElementById("myHeader");
    var sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
    if (!this.Istoken) {

      let UserInfomation = JSON.parse(localStorage.getItem("idTokenClaims"));

      if (UserInfomation != null) {

        let ProfileInfo = null;

        if (ProfileInfo != null) {

          environment.LoginUserId = ProfileInfo.userId;
          environment.LoginUserMobile = ProfileInfo.userMobile;
          environment.LoginUserfullName = ProfileInfo.fullName;
          environment.LoginUserEmail = ProfileInfo.userEamil;

        }
        this.UserName = UserInfomation.name.split(" ")[0];
        this.Istoken = true;
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      }

    }
  }
  //#endregion

  //#region Logo change after scolling
  scroll = () => {
    const scrollPos = document.documentElement.scrollTop;
    // change '50' according to when you want to change the image
    if (scrollPos > 5) {
      this.logoPath = '../../../assets/images/OX_general practice.png';
    }
    else {
      this.logoPath = '../../../assets/images/OX_general practice.png';
    }
  };
  //#endregion

  //#region Check the Login details Before the Going to Booking Appointment
  logincheck() {
    if (!this.Istoken) {
      // this.toastr.error("You have to login first");
      this.authService.loginRedirect();
    } else {
      this.router.navigate(['./bookAppointment'], { skipLocationChange: true });

    }
  }
  //#endregion

  //#region API Call to Get the Profile Details
  getProfileDetails(email: string): void {
debugger
    if (email != null && email != "") {
      this.profileService.getProfile(email).subscribe((res) => {
        if (res != null) {

          environment.LoginUserId = res['Id'];
          environment.LoginUserMobile = res['mobilephone'];
          environment.LoginUserfullName = res["fullname"];
          environment.LoginUserEmail = res["emailaddress1"];

          //set Data into Local Storage
          let setProfileData = {
            userId: environment.LoginUserId,
            userMobile: environment.LoginUserMobile,
            fullName: environment.LoginUserfullName,
            userEamil: environment.LoginUserEmail
          }

          //localStorage.setItem("userProfileDataClaims", JSON.stringify(setProfileData));
        }

        // $('#ftco-loader').removeClass('show');
      }, (err) => {
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /****************** -------- **********************/
        //this.toastr.error(err, "Error : ");
        //this.router.navigate(['/']);
      });
    }
  }
  //#endregion

  //#region Check the Account login or Not
  checkAccount() {
    debugger
    this.loggedIn = !!this.authService.getAccount();
    if (this.loggedIn) {
      let accessTokenExpStamp = this.authService.account['idTokenClaims'].exp;
      let currentTimeStamp = Math.round(new Date().getTime() / 1000);
      if (accessTokenExpStamp < currentTimeStamp) {
        this.LogOut();
      }
    }

  }
  //#endregion

  //#region Function For Call Login Service
  login() {
    //if (isIE) {
    this.authService.loginRedirect();
    //} else {
    // this.authService.loginPopup();
    //}
  }
  //#endregion

  //#region Function For Reload the Current Component
  ReloadComponent(): void {
    let Location = this.router.url;
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([Location], { skipLocationChange: true });
    });
  }
  //#endregion

  //#region Function For Reset Password
  resetPassword() {
    this.authService.loginRedirect(b2cPolicies.authorities.resetPassword);
  }
  //#endregion 

  //#region Function For Logout the Details 
  LogOut() {
    localStorage.clear();
    this.authService.logout();
  }
  //#endregion

}
