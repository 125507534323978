<section class="ftco-section">
    <div class="container px-md-0">
<div class="row">
        <div class="column"> 
            <div class="pull-left">
            <a (click)="backtogrid()"><i  class="fa fa-arrow-circle-o-left" style="font-size: 36px;"></i></a></div>
            </div>
        <div class="column"> <h5 style="text-align: center;">{{consultationnotesName}} &nbsp; ({{CaseType}})</h5><br/> <h6 style="text-align: center; margin-top: -31px;">{{CreatedOn | date:'MMMM dd '}}</h6>
            <br/> <h6 style="text-align: center; margin-top: -31px;">{{StatusOnlineConsultation}}</h6>
        </div>
        
        <div class="column"><div class="pull-right"><input type="button" *ngIf="StatusOnlineConsultation == 'In Progress'" href="#myModalcomment" data-toggle="modal" class="btn btn-primary" value="Add Comments +"></div></div>
    </div>

       
           
        
        <!-- <div style="text-align: center;"><h5>{{consultationnotesName}}</h5></div> -->

        
        <br>
        <br>
        
        <div class="row">
            <div class="col-md-12 ">
                <div class="comments-list">
                    <div class="card">
                        <div class="card-body custome_msg-scroll">
                            <!-- <div style="text-align: center;"><h5>{{consultationnotesName}}</h5></div> -->
                            <div class="media" *ngFor="let Msg of msg">
                                <div class="media-body" style="background-color: aliceblue;">
                                    <div class="row">

                                        <!-- <div class="columnmsg">sdafsdf</div>
                                        <div class="columnmsg">fdsafdsf</div>
                                        <div class="columnmsg">dsafsdfsdafasf</div>
                                        -->

                                        
                                        <div class="columnmsg">


                                            <div class="incoming_msg" *ngIf="Msg.CreatedFrom == '1'"  style="margin-left: 28px;">  
                                                
                                                <div class="row">
                                                    <div class="col-md-2"> <img src="../../../assets/images/patient.jpg" alt="Avatar" style="width:83px; height:76px; border-radius: 50%;"></div>
                                                    <div class="col-md-10">
                                                        <p style="font-weight:600; font-size: 11px; color: #00000078;  margin-bottom: 5px;" class="time_date" *ngIf="Msg.CreatedFrom == '1'">
                                                            By <span style="font-weight:600;color:#009FE3">Patient </span>&nbsp;|  <span style="font-weight:600; color: #00000078;" class="time_date">
                                                               {{Msg.createdOn | date:'MMMM dd '}}</span>
                                                           </p>
           
                                                           <h6 style="font-weight:500; color: #00000078;"><i class="fa fa-user" aria-hidden="true"></i>&nbsp;{{Msg.subject}}</h6>
                                                           <h6 style="font-size:15px; color: #00000078;">{{Msg.description}}</h6>
                                                          
                                                           <a *ngIf="Msg.extension=='png' || Msg.extension=='jpeg' ||Msg.extension=='jpg'">
                                                               <img src="data:image/jpeg;base64,{{Msg.documentbody}}" style="width: 120px;">
                                                           </a>
                                                           <!-- <p *ngIf="Msg.extension!='png' || Msg.extension!='jpeg' ||Msg.extension!='jpg'"></p> -->
                                                           <h6><a style="color:#009FE3 ;font-size:14px" (click)="getDocument(Msg)">{{Msg.FileName}}</a></h6>
                                                    </div>
                                                    
                                                </div>                                          
                                              
                                            
                                            </div>

                                        </div>
                                        
                                        <div class="columnmsg">

                                            <div  class="incoming_msg" *ngIf="Msg.CreatedFrom != '1'" style="margin-left: 28px;">
                                               
                                               <div class="row">
                                                  
                                                   <div class="col-md-8"> <p style="font-weight:600; font-size: 11px; color:#00000078; margin-bottom: 5px; text-align: right;" class="time_date" *ngIf="Msg.CreatedFrom != '1'">
                                                     By <span style="font-weight:600;color:#009FE3">Clinician&nbsp;|  <span style="font-weight:600; color: #00000078;" class="time_date">
                                                        {{Msg.createdOn | date:'MMMM dd '}}</span></span>
                                                <h6 style="font-weight:500; color: #00000078; text-align: right;"><i class="fa fa-user" aria-hidden="true"></i>&nbsp;{{Msg.subject}}</h6>
                                                <h6 style="font-size:15px; text-align: right;">{{Msg.description}}</h6>

                                            <a *ngIf="Msg.extension=='png' || Msg.extension=='jpeg' ||Msg.extension=='jpg'">
                                                <img src="data:image/jpeg;base64,{{Msg.documentbody}}" style="width: 120px;">
                                            </a>
                                            <!-- <p *ngIf="Msg.extension!='png' || Msg.extension!='jpeg' ||Msg.extension!='jpg'"></p> -->
                                            <h6 style="text-align: right;"><a style="color:#009FE3 ;font-size:14px; text-align: right;" (click)="getDocument(Msg)">{{Msg.FileName}}</a></h6></div>

                                            <div class="col-md-4">  <img src="../../../assets/images/doctor.jpg" alt="Avatar" style="width:83px; height: 83px; border-radius: 50%;"></div>
                                                 
                                               </div>                                     

                                        </div>


                                           

                                            <!-- <div class="incoming_msg" *ngIf="Msg.CreatedFrom == '1'">

                                                <h6 style="font-weight:600;"><i class="fa fa-user" aria-hidden="true"></i>&nbsp;{{Msg.subject}}</h6>
                                                <h6 style="font-size:16px">{{Msg.description}}</h6>
                                                <span style="font-weight:600;" class="time_date">
                                                    {{Msg.createdOn | date:'HH:mm | MMMM dd'}}</span>
                                                <p style="font-weight:600;" class="time_date" *ngIf="Msg.CreatedFrom == '1'">
                                                    Created By <span style="font-weight:600;color:#009FE3">Patient</span>
                                                </p>
                                               
                                                <a *ngIf="Msg.extension=='png' || Msg.extension=='jpeg' ||Msg.extension=='jpg'">
                                                    <img src="data:image/jpeg;base64,{{Msg.documentbody}}" style="width: 120px;">
                                                </a>
                                                <p *ngIf="Msg.extension!='png' || Msg.extension!='jpeg' ||Msg.extension!='jpg'"></p>
                                                <h6><a style="color:#009FE3 ;font-size:14px" (click)="getDocument(Msg)">{{Msg.FileName}}</a></h6>
                                                <p> <span  class="time_date">Modified on
                                                {{Msg.modifiedon | date:'MM/dd/yyyy HH:mm a'}}</span></p>
    
                                            </div> -->
                                        </div>

                                        
                                      



                                        <div class="col-md-1"></div>
                                 
                                        <div class="col-md-4"></div>

                                   

                                        <div class="col-md-3">
                                            <!-- <a *ngIf="Msg.extension=='png' || Msg.extension=='jpeg' ||Msg.extension=='jpg'">
                                                <img src="data:image/jpeg;base64,{{Msg.documentbody}}" style="width: 120px;">
                                            </a>
                                            <p *ngIf="Msg.extension!='png' || Msg.extension!='jpeg' ||Msg.extension!='jpg'"></p>
                                            <h6><a style="color:#009FE3 ;font-size:14px" (click)="getDocument(Msg)">{{Msg.FileName}}</a></h6>
                                            <p> <span  class="time_date">Modified on
                                            {{Msg.modifiedon | date:'MM/dd/yyyy HH:mm a'}}</span></p> -->

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</section>


<div class="modal fade" id="myModalcomment" tabindex="-1" role="dialog" aria-labelledby="comment" data-keyboard="false" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Comment Box</h5>
                <!-- modal header -->
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        ×
                    </button>
            </div>
            <div class="modal-body result-shade1">
                <!-- modal body -->
                <form class="form" #SMS="ngForm" (ngSubmit)="sendNotes(SMS)" #f="ngForm" autocomplete="off">
                    <div class="form-group row">
                        <label class="control-label col-sm-3 required">Title</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control"  name="subject" #title="ngModel" ngModel [(ngModel)]="consultationnotesName" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label col-sm-3 ">Comment</label>
                        <div class="col-sm-9">
                            <textarea type="text" class="form-control" #name="ngModel" name="description" ngModel id="description" rows="10"></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label col-sm-3 "></label>
                        <div class="col-sm-9">
                            <input id="file-input" type="file" (change)="IncomingFile($event.target.files);" />
                        </div>
                    </div>

                    <button class="btn btn-primary pull-right" type="submit" [disabled]="!SMS.valid">send</button>
                </form>
            </div>

        </div>

    </div>

    <!-- /.modal-dialog -->
</div>