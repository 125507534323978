import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TdlobservationService {

  constructor(private http: HttpClient) { }
  getObservation(id) {
    
    return this.http.get(`${environment.apiUrl}/api/TDLObservation/GetObservations?id=${id}`);
  }
  getObservationResults(observationID) {
    
    return this.http.get(`${environment.apiUrl}/api/TDLObservation/GetObservationResult?observationID=${observationID}`);
  }
  getObservationRequestReport(observationID) {
    
    return this.http.get(`${environment.apiUrl}/api/TDLObservation/GetObservationRequestByObservationId?id=${observationID}`);
  }
  getPivottable(observationID) {
    
    return this.http.get(`${environment.apiUrl}/api/TDLObservation/GetObservationResultsByPatientId?id=${observationID}`);
  }
}
