import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CaseService } from 'src/app/ApiServices/Case/case.service';
import { environment } from 'src/environments/environment';
import { SickNotesService } from 'src/app/ApiServices/Sick/sick-notes.service';
declare var $: any;
@Component({
  selector: 'app-online-consultations',
  templateUrl: './online-consultations.component.html',
  styleUrls: ['./online-consultations.component.css']
})
export class OnlineConsultationsComponent implements OnInit {
  CaseDeatils:any
  Case:any={};
  caseValue:any={};
  SendNotesobj: any={};
  caseId:any;
  file: any;
  CaseID: any;
  ProxyData: any;  
  userMainname: any;
  userMainId: any; bcrm_patientguardianvalue: any; GuardianValue: boolean = false;
    msg: any;
      constructor( private caseService: CaseService, private toastr: ToastrService, private router: Router,private sickNotesService: SickNotesService 
        ) { }
  
    ngOnInit(): void {
      var data:any=JSON.parse(localStorage.getItem("userProfileDataClaims"));
      environment.LoginUserEmail=data.userEamil;
       environment.LoginUserId= data.userId;
       this.userMainname = environment.LoginUserfullName;
       this.userMainId = environment.LoginUserId;
       this.bcrm_patientguardianvalue = environment.LoginUserId;
      localStorage.removeItem('CaseID');
      this.getCase();
    }
    RefreshPage(){
      this.ngOnInit()
    }


    ChecktheGuardianValue(Event) {
      debugger
      var DataofEvent = Event.currentTarget.value;
      if (DataofEvent != environment.LoginUserId) {
        this.GuardianValue = true
  
      }
      else {
        this.GuardianValue = false;
      }
  
    }

    
  getAllProxy() {

    debugger
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.ProxyData = null;
    this.sickNotesService.getProxyDetails(environment.LoginUserId).subscribe((res) => {
      if (res != null) {
        this.ProxyData = res;
       
      }

      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/']);
    });
  }

    getCase(){debugger
      debugger;
         //*************Show / hide loader *******************/
         $('#ftco-loader').addClass('show');
         /**************************************** */
      
         this.caseService.getCase(environment.LoginUserId).subscribe((res) => {
     
           if (res != null) {
            this.CaseDeatils   = res;
            this.getAllProxy()
           }
           setTimeout(function () {
             $('#caseRecord').DataTable();
         }, 50);
          // Change src attribute of image
       
        
           //*************Show / hide loader *******************/
           $('#ftco-loader').removeClass('show');
           /**************************************** */
         }, (err) => {
           //*************Show / hide loader *******************/
           $('#ftco-loader').removeClass('show');
           /****************** -------- **********************/
           this.toastr.error(err);
           this.router.navigate(['/']);
         });
       }
       editcase(data){
        debugger;
        this.Case= data;
        //
       if(this.Case.priority=='High'){
        this.Case.prioritycode="1"
       } 
       if(this.Case.priority=='Normal'){
        this.Case.prioritycode="2"
       } 
       if(this.Case.priority=='Low'){
        this.Case.prioritycode="3"
       } 
       //
       if(this.Case.casetype=='Question'){
        this.Case.casetypecode="1"
       } 
       if(this.Case.casetype=='Problem'){
        this.Case.casetypecode="2"
       } 
       if(this.Case.casetype=='Request'){
        this.Case.casetypecode="3"
       } 
        this.Case.Id = data.Id;
        this.CaseID = data.Id;
       }
  
    onSubmit(it: NgForm) {
      debugger;
      $('#ftco-loader').addClass('show');
      this.caseValue= it.form.value
      this.caseValue.guardianId = this.bcrm_patientguardianvalue ;
      this.caseService.createCase(this.caseValue,environment.LoginUserId).subscribe((res) => {
        debugger;
        $("#Modal1 .close").click();
        this.toastr.success("Online Consultation Created Successfully");
        $('#ftco-loader').removeClass('show');
        $('#Modal1 form')[0].reset();
        this.ReloadComponent();   
        // if(res == true){
        //   this.toastr.success("Case Created Successfully");
        // }
      });
  
    }
    //
    onUpdate(Data) {
      debugger;
      this.caseValue= Data
      $('#ftco-loader').addClass('show');
      this.caseService.upadteCase(this.caseValue,environment.LoginUserId).subscribe((res) => {
        debugger;
        $("#Modal2 .close").click();
        this.toastr.success("Online Consultation Updated Successfully");
        $('#ftco-loader').removeClass('show');
        $('#Modal1 form')[0].reset();
        this.ReloadComponent();
        // if(res == true){
        //   this.toastr.success("Case Updated Successfully");
        // }
      });
  
    }
    ReloadComponent(): void {
      let Location = this.router.url;
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate([Location], { skipLocationChange: true });
      });
    }
  
  
    openSendSMS(CaseID,data) {
      debugger
      //  this.getNotes(appointment);
      // $("#myModalcomment").modal("toggle");
      localStorage.setItem('CaseID',CaseID)
      localStorage.setItem('ConsultationnotesName',data.title)
      localStorage.setItem('CaseType',data.casetype)
      localStorage.setItem('createdOn' ,data.createdon)
      localStorage.setItem('StatusOnlineConsultation' ,data.statuscodename)
      this.router.navigate(['noteNew'], { skipLocationChange: true })
  
    }
  
  
    IncomingFile(event) {
      debugger
      this.file = event[0];
      $('#ftco-loader').addClass('show');
      /**************************************** */
      const formData = new FormData();
      formData.append('caseId', this.CaseID);
      formData.append('file', this.file);
      formData.append('langid', "1");
      this.caseService.createNotes(formData).subscribe((res) => {
        debugger;
        if (res) {
          // localStorage.removeItem('CaseID');
          // $("#Modal2 .close").click();
           $('#ftco-loader').removeClass('show');
          this.toastr.success("File uploaded successfully");
          // this.ReloadComponent();
        }
      });
  
    }
    clearOnlineC(){
      $("#title").val("");
      $("#prioritycode").val("");
      $("#casetypecode").val("");
      $("#description").val("");
    }
  
    
}
