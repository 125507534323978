import { Component, OnInit } from '@angular/core';
import { TdlobservationService } from 'src/app/ApiServices/Observation/tdlobservation.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-new-tdl',
  templateUrl: './new-tdl.component.html',
  styleUrls: ['./new-tdl.component.css']
})
export class NewTdlComponent implements OnInit {

  observations: any;
  observationsdata: any;
  observationsResult:any;
  pivotarray: any = [];
  datearray: any = [];
  constructor(private ObservationService: TdlobservationService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    debugger
   
    this.getTDLObservations();
    this.getObservations()
  }
  getObservations(): void {
    debugger
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.ObservationService.getObservation(environment.LoginUserId).subscribe((res) => {
      if(res != null){
        
        this.observations = res;
        setTimeout(function () {
          $('#tdlTable').DataTable();
      }, 50);
     
      }
      $("#your-image4").attr("src", "../../../assets/images/TDL-Observation.png");
      $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
      $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
      $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
      $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
      $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
      $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
      $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image").attr("src", "../../../assets/images/profile.png");
      $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
     //*************Show / hide loader *******************/
     $('#ftco-loader').removeClass('show');
     /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/']);
    });
  }
  getTDLObservations(){
    this.ObservationService.getPivottable(environment.LoginUserId).subscribe((res) => {
      if (res != null) {

        this.observationsdata = res;

        this.onload(this.observationsdata);

      }
      else{
        this.pivottable();
      }
    }) 
  }
 
  onload(results) {
    this.pivotarray = []
    this.datearray = []
    debugger;

    function formatDate(date) {
      var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();
      var time=date.toLocaleTimeString();
      
      return day + '-' + monthNames[monthIndex] + '-' + year+' '+ time ;
  }

    for (var i = 0; i < results.length; i++) {


      var pivottablem = {
  
        Date_Of_Test: formatDate (new Date(results[i]["DateTimeofTransaction"])),
        Value: results[i]["bcrm_observationvalue"],
        Test: results[i]["bcrm_text"],
        Unit: results[i]["bcrm_units"],
        Range: results[i]["bcrm_referenceRange"],
        Code: results[i]["bcrm_identifier"],
        Flag: results[i]["bcrm_abnormalFlags"],
      }
      this.pivotarray.push(pivottablem);
      this.datearray.push(results[i]["DateTimeofTransaction"]);

      

    }
    this.pivottable();
  }

  pivottable() {
    debugger;

  var derivers = $.pivotUtilities.derivers;
    var renderers = $.extend($.pivotUtilities.renderers,
        $.pivotUtilities.c3_renderers);
           var sortAs =$.pivotUtilities.sortAs; 
          $("#output").pivotUI(this.pivotarray, {
           renderers: renderers,
           rows: ["Date_Of_Test"],
           cols: ["Test","Unit"],
           vals: ["Value"],
           aggregatorName: "Last",
           rendererName: "Col Heatmap",
           showAggregationOnValueField:false,
           rendererOptions: {
               c3: { data: {colors: {
                   Liberal: '#dc3912', Conservative: '#3366cc', NDP: '#ff9900',
                   Green:'#109618', 'Bloc Quebecois': '#990099'
               }}}
           },
           sorters:{

               "Date_Of_Test": sortAs(this.datearray),
              }
       }); 




  //   var sortAs = $.pivotUtilities.sortAs;
  //   $("#output").pivotUI(this.pivotarray, {
  //     rows: ["Date_Of_Test"],
  //     cols: ["Test", "Unit"],
  //     vals: ["Value"],
  //     aggregatorName: "Last",
  //     rendererName: "Col Heatmap",
  //     showAggregationOnValueField: false,
  //     sorters: {
  //       "createdOn": sortAs(this.datearray),
  //     }
  //   });
   }

  OpenDetails(Observation) {debugger
    this.router.navigate(['profile/observationDetail', Observation.Id],{state: {ObservationDetail: Observation }});
  }
}
